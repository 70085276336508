import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import IAssetNotifications from '@/types/IAssetNotifications';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type IAssetNotificationsNull = OrNull<IAssetNotifications>;

interface TAjaxResponseAssetNotification {
  status: string;
  message: string;
  // rows: string;
  rows: IAssetNotificationsNull[];
}

interface TAjaxResponseEditAssetNotification {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetAssetNotification {
  status: string;
  message: string;
  assetNotification: IAssetNotificationsNull;
}

interface TAjaxResponseGetAssetNotificationNumber {
  status: string;
  message: string;
  nr_of_asset_notifications: number;
}

// interface TAjaxResponseGetAsset {
//   status: string;
//   message: string;
//   asset: iAssetNotifications;
// }

// interface TAjaxResponseUpdateAsset {
//   status: string;
//   message: string;
// }

export class ServiceAssetNotifications {
  public static async getAssetNotifications(appid: number): Promise<TAjaxResponseAssetNotification> {
    const response = await axios.get(`${CONFIG_ENV.URL_ASSETS.asset_notifications}/${appid}`);
    return response.data;
  }
  
  public static async getAssetsNotificationList(is_all: number, searchParams:any): Promise<TAjaxResponseAssetNotification> {
    const response = await axios.post(`${CONFIG_ENV.URL_ASSETS.assets_notification_list}/${is_all}`, searchParams);
    return response.data;
  }

  public static async getAssetNotification(appid: number): Promise<TAjaxResponseGetAssetNotification> {
    const response = await axios.get(`${CONFIG_ENV.URL_ASSETS.asset_notification}/${appid}`);
    return response.data;
  }
  
  public static async postAssetNotification(pAssetNotification: IAssetNotifications): Promise<TAjaxResponseEditAssetNotification> {
    const response = await axios.post(`${CONFIG_ENV.URL_ASSETS.asset_notification}`, pAssetNotification);
    return response.data;
  }

  public static async postUpdateAssetNotification(pAssetNotification: IAssetNotifications): Promise<TAjaxResponseEditAssetNotification> {
    const response = await axios.post(`${CONFIG_ENV.URL_ASSETS.update_asset_notification}`, pAssetNotification);
    return response.data;
  }

  public static async getNumberAssetNotifications(): Promise<TAjaxResponseGetAssetNotificationNumber> {
    const response = await axios.get(`${CONFIG_ENV.URL_ASSETS.asset_notifications_nr}`);
    return response.data;
  }
}
