import {defineAsyncComponent, defineComponent, inject, ref} from 'vue';
import {getModule} from 'vuex-module-decorators';
import {CONFIG_ENV} from '@/config';
import Nomenclatoare from '@/store/Nomenclatoare';
import LoadingComponent from "@/components/LoadingComponent.vue";
import ErrorComponent from "@/components/ErrorComponent.vue";
import Notify from 'quasar/src/plugins/Notify.js';;
import {FileUploadService} from 'v-file-upload';
import {dateToStringDDMonYYYY, humanReadableBytes} from '@/modules/Utils';
import moment from 'moment';
import {ServicePontajPrezenta} from "@/modules/ServicePontajPrezenta";
import IUser from "@/types/IUser";
import User from '@/store/User';
import IScanPontajPrezenta from "@/types/IScanPontajPrezenta";
import IActionOnStepTask from '@/types/IActionOnStepTask';
import IPontajPrezenta from '@/types/IPontajPrezenta';
import INomTipPontajPrezenta from '@/types/INomTipPontajPrezenta';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type IPontajPrezentaOrNull = OrNull<IPontajPrezenta>;

interface IPontajPrezentaExtended extends IPontajPrezentaOrNull {
    scan_documents: IScanPontajPrezenta[],
}

const DialogOpenScan = defineAsyncComponent({
    loader: () => import('@/components/DialogOpenScan/DialogOpenScan.vue'),
    loadingComponent: LoadingComponent,
    errorComponent: ErrorComponent,
    timeout: 3000,
    delay: 200
});

const TaskList = defineAsyncComponent({
    loader: () => import('@/components/Tasks/TaskList.vue'),
    loadingComponent: LoadingComponent,
    errorComponent: ErrorComponent,
    // The error component will be displayed if a timeout is
    // provided and exceeded. Default: Infinity.
    timeout: 3000,
    delay: 200
  });

const myForm = ref({} as any);
const qDateProxy1 = ref(null);
const qDateProxy2 = ref(null);

export default defineComponent({
    name: 'EditPontajPrezentaDetail',

    components: {
        DialogOpenScan, TaskList
    },
    
    props: {
        appid: {
            type: Number,
            required: true,
            default: 0,
            readonly: true
        },
        closeWindow: {
            type: Function,
            default: undefined
        },
        task: {
            type: Object,
            task_id: 0,
            task_type: '',
            action_on_step: {} as IActionOnStepTask
        }        
    },

    setup() {
        const emitter = inject("emitter") as any;
        return {
            emitter,
            myForm,
            qDateProxy1,
            qDateProxy2
        }
    },

    data() {
        return {
            isElementShow: false,
            prezenta: {
                id: 0,
                user_id: null,
                descriere: '',
                pontaj_date: '',
                efort: 0,                
                stare: '',
                track_date: '',
                appid_tip_pontaj_prezenta: null as INomTipPontajPrezenta|null,
                tip_pontaj_prezenta: '',
                appid_tip_pontaj_prezenta_child: null as INomTipPontajPrezenta|null,
                tip_pontaj_prezenta_child: '',
                flag: 0,
                scan_documents: []
            } as IPontajPrezentaExtended,
            myLocale: CONFIG_ENV.myLocale,
            userStore: getModule(User),
            nomenclatoareStore: getModule(Nomenclatoare),
            loadingData: false,
            uploadScanTitle: '',
            urlForUpload: CONFIG_ENV.URL_PONTAJPREZENTA_SCAN.uploadScanPrezenta,
            uploadingFileToServer: false,
            headers: {} as any,
            filesSelectedForUpload: [] as any,
            dialogUploadFile: false,
            loadingPdfForDownload: false,
            // tipPrezentaOptions: ['TeleMunca', 'Prezenta'],
            isSuccess: false
        }
    },

    methods: {        
        displayData(pdata:string) :string{
            if(pdata){
              const day = moment(pdata.substring(0,10), 'YYYY-MM-DD');
              return dateToStringDDMonYYYY(day);
            }else{ 
              return '';
            }
        },

        getPrezenta() {
            const vueInst = this;
            ServicePontajPrezenta.getPontajPrezenta(Number(vueInst.prezenta.id)).then((presponse) => {
                vueInst.loadingData = false;
                if (presponse.status === 'success') {
                    const userId = presponse.prezenta?.user_id;
                    vueInst.prezenta = { ...presponse.prezenta, user_id: userId, scan_documents: [] };
                    vueInst.isElementShow = vueInst.prezenta.stare === 'initiat';
                    vueInst.prezenta.pontaj_date = vueInst.prezenta.pontaj_date == null ? '' : vueInst.displayData(vueInst.prezenta.pontaj_date);

                    vueInst.getScanDocuments();
                    vueInst.isSuccess = true;
                }
            })
        },

        openFormUploadFile() {
            this.filesSelectedForUpload = [];
            this.uploadScanTitle = '';
            this.dialogUploadFile = true;
        },

        getScanDocuments() {
            const vueInst = this;
            vueInst.uploadingFileToServer = true;
            ServicePontajPrezenta.getScansPontaj(Number(vueInst.prezenta.id)).then((presponse) => {
                vueInst.loadingData = false;
                vueInst.uploadingFileToServer = false;
                if (presponse.status === 'success') {
                    vueInst.prezenta.scan_documents = JSON.parse(JSON.stringify(presponse.rows));
                }
            });
        },

        askIfRemoveScan(pscan: IScanPontajPrezenta) {
            const vueInst = this;
            vueInst.$q.dialog({
                title: 'Confirm',
                message: `Stergeti fisierul reprezentand ${pscan.description}?`,
                cancel: true,
                persistent: true,
                html: true
            }).onOk(() => {
                ServicePontajPrezenta.deleteScan(pscan.appid, { userid: vueInst.user.userid, token: vueInst.user.csrf_token }).then(presponse => {
                    if (presponse.status === 'success') {
                        vueInst.$q.notify({
                            color: 'teal',
                            textColor: 'white',
                            type: 'positive',
                            message: presponse.message,
                            position: 'top',
                            timeout: 1000,
                            html: true
                        });

                        this.getScanDocuments();
                    }
                });
            })
        },

        prepareForUpload(event: any) {
            this.filesSelectedForUpload = event.target.files[0];
        },

        uploadFile() {
            const vueInst = this;            

            if (vueInst.uploadScanTitle.length == 0) {
                Notify.create({
                  color: 'orange',
                  textColor: 'white',
                  type: 'warning',
                  message: 'Trebuie sa completati titlu documentului!',
                  position: 'top',
                  timeout: 3500,
                  html: true
                });
            } else if (vueInst.checkIfFileIsSelected()) {
                vueInst.uploadingFileToServer = true;

                const fileUpload = new FileUploadService(
                    this.urlForUpload,
                    this.headers,
                    this.onProgress
                );
                
                fileUpload.upload(vueInst.filesSelectedForUpload as File, {
                    appidPontaj: vueInst.prezenta.id,
                    description: vueInst.uploadScanTitle,
                    userid: vueInst.user.userid,
                    token: vueInst.user.csrf_token
                })
                    .then((e: any) => {
                        vueInst.dialogUploadFile = false;
                        vueInst.getScanDocuments();
                        if (e.target && e.target.response.status && e.target.response.status == 'error') {
                            Notify.create({
                                color: 'red',
                                textColor: 'white',
                                type: 'negative',
                                message: e.target.response.message,
                                position: 'top',
                                timeout: 3500,
                                html: true
                            })
                        } else {
                            vueInst.$q.notify({
                                color: 'teal',
                                textColor: 'white',
                                type: 'positive',
                                message: e.target.response.message,
                                position: 'top',
                                timeout: 1000,
                                html: true
                            });

                            ServicePontajPrezenta.getScansPontaj(Number(vueInst.prezenta.id)).then(
                                presponse => {
                                    vueInst.prezenta.scan_documents = JSON.parse(JSON.stringify(presponse.rows))
                                }
                            )
                        }
                    })
                    .catch((e: any) => {
                    })
            } else {
                Notify.create({
                    color: 'orange',
                    textColor: 'white',
                    type: 'warning',
                    message: 'Trebuie sa selectati fisierul!',
                    position: 'top',
                    timeout: 3500,
                    html: true
                });
            }
        },

        humanReadableBytes(nrPfBytes: number): string {
            return humanReadableBytes(nrPfBytes);
        },

        checkIfFileIsSelected(): boolean {
            return this.filesSelectedForUpload != undefined && this.filesSelectedForUpload.length != 0;
        },

        onProgress(event: any) {
            // Handdle the progress
        },

        onState(operator: string){
            switch(operator) {
              case 'Refresh':
                this.getPrezenta();
                break;
              case 'Close':          
                this.closeWindow?.();
                break;
            }
        }, 
    },

    computed: {
        user(): IUser {
            return this.userStore.User;
        },
    },

    mounted() {
        const vueInst = this;
        vueInst.nomenclatoareStore.set_currentpagetitle('Pontaj prezenta');

        // if (this.appid != 0) {
        //     this.prezenta.id = this.appid;
        //     this.loadingData = true;
        //     this.getPrezenta();
        // }
    },

    watch: {
        appid: {
            immediate: true,
            handler (newVal: number, oldVal: number) {        
                const vueInst = this;
                vueInst.loadingData = true;                
                if (newVal != 0 && vueInst.appid != 0) {
                    vueInst.prezenta.id = vueInst.appid;
                    vueInst.getPrezenta();
                }
            },            
        },
    },
});