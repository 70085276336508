<template>
    <div class="q-pa-sm">
        <q-spinner-gears
            v-if="loadingAssetData"
            color="primary"
            size="4em"
            class="absolute-center"
        />
        <div v-else>
            <div class="well well-sm">
                <div class="panel">
                    <div class="panel-body" style="display:flex; align-items:center;">
                        <div style="min-width: 90px; min-height: 90px;">
                            <img :src="getLinkToQrCode" />
                        </div>
                        <div style="display: flex;flex-direction: column">
                            <div v-bind:class="{ 'column': $q.platform.is.mobile, 'row': $q.platform.is.desktop }">
                                <span class="text__title--medium">Numar</span>
                                
                                <!-- <span class="text__title--medium">{{docApp.id_asset}} / {{displayDataDoc()}}</span> -->
                                <span class="text__title--medium" style='padding-left: 5px;'>{{ assetApp.id_asset }}</span>
                            </div>
                            <q-btn color="white" text-color="black" icon-right="print" label="Tipareste!" :disable="loadingPdfForDownload" no-caps :loading="loadingPdfForDownload" @click="prepareCoverPdfForDownload" >
                                <template v-slot:loading>
                                    <q-spinner-gears class="on-right" />
                                    Pregatire...
                                </template>
                            </q-btn>
                        </div>
                        <!-- <div style="margin-left: auto; min-height: 90px;display: flex;position: relative; top:-20px;">
                           <ExpeditiiDocument :appid="appid"/>                           
                        </div> -->
                    </div>
                </div>                
                <div class="row">
                    <div class="col-md-7" style="padding-right: 5px;">
                        <table class="table table-nocondensed">
                            <tbody>                               
                                <tr v-if="(!(assetApp.denumire_categ_active == 'Medicina muncii' || assetApp.denumire_categ_active == 'Telemedicina') && ['APP_BRANDTEAM'].includes(APP_NAME)) || (!['APP_BRANDTEAM'].includes(APP_NAME))">
                                    <td class="text__title--medium">
                                        <div style="display: flex;">
                                            <span>Status:</span>
                                            <span class="material-icons" style="margin-left: auto;">create</span>
                                        </div>
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{getDenStatus(assetApp.status as string)}}
                                        <q-popup-edit v-model="assetApp.status" :validate="val => val.length > 0">
                                            <template v-slot="scope">
                                                <q-select outlined v-model="scope.value" :options="optionsStatus" style="min-width:300px;"
                                                    option-value="status"
                                                    option-label="label"                                                    
                                                    emit-value
                                                    map-options
                                                    lazy-rules
                                                    dense
                                                    autofocus
                                                    hint="Modificare stare"
                                                    :input-style="{ fontFamily: 'Nunito Sans',fontWeight: 'bolder', color: 'black' }"
                                                    :rules="[ val => val && val.length > 0 || 'Alegeti tipul de stare!']"
                                                >
                                                    <template v-slot:after>
                                                        <q-btn flat dense color="negative" icon="cancel" @click.stop="scope.cancel" />
                                                        <q-btn flat dense color="positive" icon="check_circle" @click.stop="tryUpdateStatus(scope)" :disable="scope.validate(scope.value) === false || scope.initialValue === scope.value" />
                                                    </template>
                                                </q-select>
                                            </template>
                                        </q-popup-edit>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text__title--medium">
                                        Categorie:
                                    </td>
                                    <td class="text__input--medium bg-white">
                                       {{ assetApp.denumire_categ_active }}
                                        <!-- <q-popup-edit v-model="assetApp.id_categ_active" :validate="val => val.length > 0">
                                            <template v-slot="scope">
                                                <q-select outlined v-model="scope.value" :options="optionsCategorieActive" style="min-width:300px;"
                                                    option-value="id_categ_active"
                                                    option-label="den_lang_1"
                                                    emit-value
                                                    map-options
                                                    lazy-rules
                                                    dense
                                                    autofocus
                                                    hint="Modificare categorie"
                                                    :input-style="{ fontFamily: 'Nunito Sans',fontWeight: 'bolder', color: 'black' }"
                                                    :rules="[ val => val && val.length > 0 || 'Alegeti categorie!']"
                                                >
                                                    <template v-slot:after>
                                                        <q-btn flat dense color="negative" icon="cancel" @click.stop="scope.cancel" />
                                                        <q-btn flat dense color="positive" icon="check_circle" @click.stop="tryUpdateCategorieActiva(scope)" :disable="scope.validate(scope.value) === false || scope.initialValue === scope.value" />
                                                    </template>
                                                </q-select>
                                            </template>
                                        </q-popup-edit> -->
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text__title--medium">
                                        Tip:
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{ getDenTipActive(assetApp.appid_tip_active as number) }}
                                        <!-- <q-popup-edit v-model="assetApp.id_tip_active" :validate="val => val.length > 0">
                                            <template v-slot="scope">
                                                <q-select outlined v-model="scope.value" :options="optionsTipActive" style="min-width:300px;"
                                                    option-value="id_tip_active"
                                                    option-label="den_lang_1"
                                                    emit-value
                                                    map-options
                                                    lazy-rules
                                                    dense
                                                    autofocus
                                                    hint="Modificare tip"
                                                    :input-style="{ fontFamily: 'Nunito Sans',fontWeight: 'bolder', color: 'black' }"
                                                    :rules="[ val => val && val.length > 0 || 'Alegeti tipul!']"
                                                >
                                                    <template v-slot:after>
                                                        <q-btn flat dense color="negative" icon="cancel" @click.stop="scope.cancel" />
                                                        <q-btn flat dense color="positive" icon="check_circle" @click.stop="tryUpdateTipActiva(scope)" :disable="scope.validate(scope.value) === false || scope.initialValue === scope.value" />
                                                    </template>
                                                </q-select>
                                            </template>
                                        </q-popup-edit> -->
                                    </td>
                                </tr>

                                <tr v-if="(!(assetApp.denumire_categ_active == 'Medicina muncii' || assetApp.denumire_categ_active == 'Telemedicina') && ['APP_BRANDTEAM'].includes(APP_NAME)) || (!['APP_BRANDTEAM'].includes(APP_NAME))">
                                    <td class="text__title--medium">
                                        <div style="display: flex;">
                                            <span>Denumire:</span>
                                            <span class="material-icons" style="margin-left: auto;">create</span>
                                        </div>
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{assetApp.titlu}}
                                        <q-popup-edit v-model="assetApp.titlu" :validate="val => val.length > 0">
                                            <template v-slot="scope">
                                                <q-input
                                                    autofocus
                                                    dense
                                                    v-model="scope.value"
                                                    style="min-width:300px;"
                                                    :value="assetApp.titlu"
                                                    hint="Modificare denumire"
                                                    maxlength="100"
                                                    lazy-rules
                                                    :rules="[ val => val && val.length > 0 || 'Completati denumire!']"
                                                >
                                                    <template v-slot:after>
                                                        <q-btn flat dense color="negative" icon="cancel" @click.stop="scope.cancel" />
                                                        <q-btn flat dense color="positive" icon="check_circle" @click.stop="tryUpdateTitlu(scope)" :disable="scope.validate(scope.value) === false || scope.initialValue === scope.value" />
                                                    </template>
                                                </q-input>
                                            </template>
                                        </q-popup-edit>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text__title--medium">
                                        <div style="display: flex;">
                                            <span>Descriere:</span>
                                            <span class="material-icons" style="margin-left: auto;">create</span>
                                        </div>
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{assetApp.descriere}}
                                        <q-popup-edit v-model="assetApp.descriere" :validate="val => val.length > 0">
                                            <template v-slot="scope">
                                                <q-input
                                                    autofocus
                                                    dense
                                                    v-model="scope.value"
                                                    style="min-width:400px;"
                                                    :value="assetApp.descriere"
                                                    hint="Modificare descriere"
                                                    maxlength="1000"
                                                    lazy-rules                                                    
                                                    rows="3"
                                                    type="textarea"
                                                    :rules="[ val => val && val.length > 0 || 'Completati descriere!']"                                                    
                                                >
                                                    <template v-slot:after>
                                                        <q-btn flat dense color="negative" icon="cancel" @click.stop="scope.cancel" />
                                                        <q-btn flat dense color="positive" icon="check_circle" @click.stop="tryUpdateDescriere(scope)" :disable="scope.validate(scope.value) === false || scope.initialValue === scope.value" />
                                                    </template>
                                                </q-input>
                                            </template>
                                        </q-popup-edit>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text__title--medium">
                                        <div style="display: flex;">
                                            <span>Locatie:</span>
                                            <span class="material-icons" style="margin-left: auto;">create</span>
                                        </div>
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{assetApp.geo_text}}
                                        <q-popup-edit v-model="assetApp.geo_text" :validate="val => val.length > 0">
                                            <template v-slot="scope">
                                                <q-input
                                                    autofocus
                                                    dense
                                                    v-model="scope.value"
                                                    style="min-width:300px;"
                                                    :value="assetApp.geo_text"
                                                    hint="Modificare locatie"
                                                    maxlength="100"
                                                    lazy-rules
                                                    :rules="[ val => val && val.length > 0 || 'Completati locatia!']"                                                    
                                                >
                                                    <template v-slot:after>
                                                        <q-btn flat dense color="negative" icon="cancel" @click.stop="scope.cancel" />
                                                        <q-btn flat dense color="positive" icon="check_circle" @click.stop="tryUpdateLocatie(scope)" :disable="scope.validate(scope.value) === false || scope.initialValue === scope.value" />
                                                    </template>
                                                </q-input>
                                            </template>
                                        </q-popup-edit>
                                    </td>
                                </tr>

                                <tr v-if="(!(assetApp.denumire_categ_active == 'IOT' || assetApp.denumire_categ_active == 'Medicina muncii' || assetApp.denumire_categ_active == 'Telemedicina') && ['APP_BRANDTEAM'].includes(APP_NAME)) || (!['APP_BRANDTEAM'].includes(APP_NAME))">
                                    <td class="text__title--medium">
                                        <div style="display: flex;">
                                            <span>Latitudine:</span>
                                            <span class="material-icons" style="margin-left: auto;">create</span>
                                        </div>
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{assetApp.geo_lat}}
                                        <q-popup-edit v-model="assetApp.geo_lat">
                                            <template v-slot="scope">
                                                <q-input
                                                    autofocus
                                                    dense
                                                    v-model="scope.value"
                                                    style="min-width:300px;"
                                                    :value="assetApp.geo_lat"
                                                    hint="Modificare latitudine"
                                                    maxlength="100"
                                                    lazy-rules
                                                    mask="##.######"
                                                >
                                                    <template v-slot:after>
                                                        <q-btn flat dense color="negative" icon="cancel" @click.stop="scope.cancel" />
                                                        <q-btn flat dense color="positive" icon="check_circle" @click.stop="tryUpdateLatitudine(scope)" :disable="scope.initialValue === scope.value" />
                                                    </template>
                                                </q-input>
                                            </template>
                                        </q-popup-edit>
                                    </td>
                                </tr>

                                <tr v-if="(!(assetApp.denumire_categ_active == 'IOT' || assetApp.denumire_categ_active == 'Medicina muncii' || assetApp.denumire_categ_active == 'Telemedicina') && ['APP_BRANDTEAM'].includes(APP_NAME)) || (!['APP_BRANDTEAM'].includes(APP_NAME))">
                                    <td class="text__title--medium">
                                        <div style="display: flex;">
                                            <span>Longitudine:</span>
                                            <span class="material-icons" style="margin-left: auto;">create</span>
                                        </div>
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{assetApp.geo_long}}
                                        <q-popup-edit v-model="assetApp.geo_long">
                                            <template v-slot="scope">
                                                <q-input
                                                    autofocus
                                                    dense
                                                    v-model="scope.value"
                                                    style="min-width:300px;"
                                                    :value="assetApp.geo_long"
                                                    hint="Modificare longitudine"
                                                    maxlength="100"
                                                    lazy-rules
                                                    mask="##.######"
                                                >
                                                    <template v-slot:after>
                                                        <q-btn flat dense color="negative" icon="cancel" @click.stop="scope.cancel" />
                                                        <q-btn flat dense color="positive" icon="check_circle" @click.stop="tryUpdateLongitudine(scope)" :disable="scope.initialValue === scope.value" />
                                                    </template>
                                                </q-input>
                                            </template>
                                        </q-popup-edit>
                                    </td>
                                </tr>
                                <tr v-if="(assetApp.denumire_categ_active == 'Flota'  && ['APP_BRANDTEAM'].includes(APP_NAME))">
                                    <td class="text__title--medium">
                                        <div style="display: flex;">
                                            <span>GPS:</span>
                                            <span class="material-icons" style="margin-left: auto;">create</span>
                                        </div>
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{getDenStatusGPS(gpsStatus as string)}}
                                        <q-popup-edit v-model="gpsStatus" :validate="val => val.length > 0">
                                            <template v-slot="scope">
                                                <q-select outlined v-model="scope.value" :options="optionsStatusGPS" style="min-width:300px;"
                                                    option-value="status"
                                                    option-label="label"                                                    
                                                    emit-value
                                                    map-options
                                                    lazy-rules
                                                    dense
                                                    autofocus
                                                    hint="Modificare stare gps"
                                                    :input-style="{ fontFamily: 'Nunito Sans',fontWeight: 'bolder', color: 'black' }"
                                                    :rules="[ val => val && val.length > 0 || 'Alegeti stare gps!']"
                                                >
                                                    <template v-slot:after>
                                                        <q-btn flat dense color="negative" icon="cancel" @click.stop="scope.cancel" />
                                                        <q-btn flat dense color="positive" icon="check_circle" @click.stop="tryUpdateStatusGPS(scope)" :disable="scope.validate(scope.value) === false || scope.initialValue === scope.value" />
                                                    </template>
                                                </q-select>
                                            </template>
                                        </q-popup-edit>
                                    </td>
                                </tr>
                                <tr  v-if="(!(assetApp.denumire_categ_active == 'Medicina muncii' || assetApp.denumire_categ_active == 'Telemedicina') && ['APP_BRANDTEAM'].includes(APP_NAME)) || (!['APP_BRANDTEAM'].includes(APP_NAME))">
                                    <td class="text__title--medium">
                                        <div style="display: flex;">
                                            <span>ID intern / nr inventar:</span>
                                            <span class="material-icons" style="margin-left: auto;">create</span>
                                        </div>
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{assetApp.nr_inventare}}
                                        <q-popup-edit v-model="assetApp.nr_inventare" :validate="val => val.length > 0">
                                            <template v-slot="scope">
                                                <q-input
                                                    autofocus
                                                    dense
                                                    v-model="scope.value"
                                                    style="min-width:300px;"
                                                    :value="assetApp.nr_inventare"
                                                    hint="Modificare numar de inventar"
                                                    maxlength="100"
                                                    lazy-rules
                                                    :rules="[ val => val && val.length > 0 || 'Completati numar de inventar!']"                                                    
                                                >
                                                    <template v-slot:after>
                                                        <q-btn flat dense color="negative" icon="cancel" @click.stop="scope.cancel" />
                                                        <q-btn flat dense color="positive" icon="check_circle" @click.stop="tryUpdateNumarInventar(scope)" :disable="scope.validate(scope.value) === false || scope.initialValue === scope.value" />
                                                    </template>
                                                </q-input>
                                            </template>
                                        </q-popup-edit>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text__title--medium">                                        
                                        <div style="display: flex;">
                                            <span v-if="((assetApp.denumire_categ_active == 'Medicina muncii' || assetApp.denumire_categ_active == 'Telemedicina') && ['APP_BRANDTEAM'].includes(APP_NAME))">
                                                Data programarii:
                                            </span>
                                            <span v-else>Data achizitie:</span>
                                            <span class="material-icons" style="margin-left: auto;">create</span>
                                        </div>
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{assetApp.data_achizitie}} <!-- {{displayDataAchizitie(assetApp.data_achizitie)}} -->
                                        <q-popup-edit v-model="assetApp.data_achizitie" :validate="val => val.length > 0">
                                            <template v-slot="scope">
                                                <q-input outlined v-model="scope.value" dense mask="##.##.####"
                                                    emit-value
                                                    autofocus
                                                    :label="((assetApp.denumire_categ_active == 'Medicina muncii' || assetApp.denumire_categ_active == 'Telemedicina') && ['APP_BRANDTEAM'].includes(APP_NAME)) ? 'Data programarii' : 'Data achizitie'"
                                                    :value="assetApp.data_achizitie"
                                                    :hint="((assetApp.denumire_categ_active == 'Medicina muncii' || assetApp.denumire_categ_active == 'Telemedicina') && ['APP_BRANDTEAM'].includes(APP_NAME) ? 'Modificare data programarii' : 'Modificare data achizitie')"
                                                    lazy-rules
                                                    style="min-width:300px;"
                                                    :rules="[ val => val && val.length > 0 || ((assetApp.denumire_categ_active == 'Medicina muncii' || assetApp.denumire_categ_active == 'Telemedicina') && ['APP_BRANDTEAM'].includes(APP_NAME) ? 'Completati data programarii!' : 'Completati data achizitie!')]"                                                    
                                                >
                                                    <q-popup-proxy ref="qDateProxy0" transition-show="scale" transition-hide="scale">
                                                        <q-date minimal v-model="scope.value" mask="DD.MM.YYYY" @update:model-value="($refs['qDateProxy0'] as any).hide()" :locale="myLocale" />
                                                    </q-popup-proxy>
                                                    <template v-slot:append>
                                                        <q-icon name="event" class="cursor-pointer"></q-icon>
                                                    </template>
                                                    <template v-slot:after>
                                                        <q-btn flat dense color="negative" icon="cancel" @click.stop="scope.cancel" />
                                                        <q-btn flat dense color="positive" icon="check_circle" @click.stop="tryUpdateDataAchizitie(scope)" :disable="scope.validate(scope.value) === false || scope.initialValue === scope.value" />
                                                    </template>
                                                </q-input>
                                            </template>
                                        </q-popup-edit>
                                    </td>
                                </tr>
                                
                                <!-- <tr>
                                    <td class="text__title--medium">                                        
                                        Data termen:
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{assetApp.data_termen}}
                                        <q-popup-edit v-model="assetApp.data_termen" :validate="val => val.length > 0">
                                            <template v-slot="scope">
                                                <q-input outlined v-model="scope.value" dense mask="##.##.####"
                                                    emit-value
                                                    autofocus                                                    
                                                    label="Data termen"
                                                    :value="assetApp.data_termen"
                                                    hint="Modificare data termen"
                                                    lazy-rules
                                                    style="min-width:300px;"
                                                    :rules="[ val => val && val.length > 0 || 'Completati data termen!']"                                                    
                                                >
                                                    <q-popup-proxy ref="qDateProxy0" transition-show="scale" transition-hide="scale">
                                                        <q-date minimal v-model="scope.value" mask="DD.MM.YYYY" @update:model-value="($refs['qDateProxy0'] as any).hide()" :locale="myLocale" />
                                                    </q-popup-proxy>
                                                    <template v-slot:append>
                                                        <q-icon name="event" class="cursor-pointer"></q-icon>
                                                    </template>
                                                    <template v-slot:after>
                                                        <q-btn flat dense color="negative" icon="cancel" @click.stop="scope.cancel" />
                                                        <q-btn flat dense color="positive" icon="check_circle" @click.stop="tryUpdateDataTermen(scope)" :disable="scope.validate(scope.value) === false || scope.initialValue === scope.value" />
                                                    </template>
                                                </q-input>
                                            </template>
                                        </q-popup-edit>
                                    </td>
                                </tr> -->

                                <tr>
                                    <td class="text__title--medium">
                                        <div style="display: flex;">
                                            <span>Org Unit:</span>
                                            <span class="material-icons" style="margin-left: auto;">create</span>
                                        </div>
                                    </td>
                                    <td class="text__input--medium bg-white">                                        
                                        <q-input
                                            outlined
                                            autofocus
                                            dense
                                            style="min-width:300px;"
                                            v-model="assetApp.denumire_org_unit"
                                            lazy-rules
                                            readonly
                                            no-parent-event
                                            @click="inputOrgUnit"
                                            ref="refOrgUnit"
                                        >
                                        </q-input>                                        
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td class="text__title--medium">
                                        <div style="display: flex;">
                                            <span v-if="((assetApp.denumire_categ_active == 'Medicina muncii' || assetApp.denumire_categ_active == 'Telemedicina') && ['APP_BRANDTEAM'].includes(APP_NAME))">
                                                Angajat
                                            </span>
                                            <span v-else>Persoana responsabila:</span>
                                            <span class="material-icons" style="margin-left: auto;">create</span>
                                        </div>
                                    </td>
                                    <td class="text__input--medium bg-white">                                        
                                        <q-input
                                            outlined
                                            autofocus
                                            dense
                                            style="min-width:300px;"
                                            v-model="assetApp.den_user_deponent"
                                            lazy-rules
                                            readonly
                                            no-parent-event
                                            @click="inputPersoana"
                                            ref="refPersoana"
                                        >
                                        </q-input>                                        
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>                        
                    </div>

                    <div class="col-md-5" style="padding-left:2em;display: flex;flex-direction:column; justify-content: flex-start; align-items: center;">
                        <div style="display: flex;align-items: center;justify-content: flex-start; width: 100%;">
                            <div>
                                <span class="text__title--medium">Galerie</span>
                                <div v-show="assetApp.scan_galeries != null && assetApp.scan_galeries.length==0 && !uploadingFileToServer" class="text__label--thin" style="width: 100%; text-align: center;">Nimic atasat</div>
                                <!-- <div v-show="assetApp.scan_galeries != null && assetApp.scan_galeries.length==0">Pentru a finaliza inregistrarea este necesara incarcarea fisierului cu galerie scanat.</div> -->
                            </div>
                            <div v-if="user.auth_for_upload_scan=='y' ">
                                <q-btn flat color="primary" icon="add_box" no-caps :disable="loadingAssetData" @click="openFormUploadFile('n')" >
                                    <q-tooltip
                                        transition-show="rotate"
                                        transition-hide="rotate"
                                    >
                                        Incarca galerie nou
                                    </q-tooltip>
                                </q-btn>
                            </div>
                        </div>
                        <div style="display: flex;justify-content: center; width: 100%;">
                            <q-spinner
                                v-if="uploadingFileToServer"
                                color="primary"
                                size="3em"
                                :thickness="10"
                            />
                            <div v-else style="width: 90%;">
                                <div class="q-pa-xs" v-if="Number(assetApp.scan_galeries?.length) > 0">
                                    <div style="flex-direction: row; display: flex; flex-wrap: wrap; max-height: 200px; overflow-x: scroll;">
                                        <div v-for="(scan_galerie, key) in assetApp.scan_galeries" v-bind:key="scan_galerie.appid" 
                                            :name="key" :img-src="getLinkToImgAsset(scan_galerie.appid?.toString())">
                                            <q-img :src="getLinkToImgAsset(scan_galerie.appid?.toString())" style="min-height:65px;max-height: 65px;min-width: 65px;max-width: 65px; margin: 10px" v-on:click=" onPressFullScreen()">
                                                <q-icon class="absolute all-pointer-events" size="16px" name="delete_forever" color="red" style="top: 1px; left: 1px" v-on:click.stop="askIfRemoveScan(scan_galerie)" >
                                                    <q-tooltip>
                                                    Sterge
                                                    </q-tooltip>
                                                </q-icon>
                                            </q-img>
                                        </div>                                                                            
                                    </div>
                                </div>
                                <br />
                            </div>                    
                        </div>

                        <div style="display: flex;align-items: center;justify-content: center; width: 100%;border-bottom: 1px solid black;"></div>

                        <div style="display: flex;align-items: center;justify-content: flex-start; width: 100%;">
                            <div>
                                <span class="text__title--medium">Documente</span>
                                <div v-show="assetApp.scan_documents != null && assetApp.scan_documents.length==0 && !uploadingFileToServer" class="text__label--thin" style="width: 100%; text-align: center;">Nimic atasat</div>
                                <!-- <div v-show="assetApp.scan_documents != null && assetApp.scan_documents.length==0">Pentru a finaliza inregistrarea este necesara incarcarea fisierului cu documentul scanat.</div> -->
                            </div>
                            <div v-if="user.auth_for_upload_scan=='y' ">
                                <q-btn flat color="primary" icon="add_box" no-caps :disable="loadingAssetData" @click="openFormUploadFile('y')" >
                                    <q-tooltip
                                        transition-show="rotate"
                                        transition-hide="rotate"
                                    >
                                        Incarca document nou
                                    </q-tooltip>
                                </q-btn>
                            </div>
                        </div>

                        <div style="display: flex;justify-content: center;">
                            <q-spinner
                                    v-if="uploadingFileToServer"
                                    color="primary"
                                    size="3em"
                                    :thickness="10"
                            />
                            <table v-else class="table table-bordered table-condensed table-responsive" style="width: 100%; max-height: 50vh;">
                                <tbody>
                                    <tr v-for="scan_document in assetApp.scan_documents" v-bind:key="scan_document.appid">
                                        <td>
                                            <!-- v-if="user.is_admin=='y'" -->
                                            <span class="material-icons cursor-pointer" style="color: red; font-size: 1.3rem;" @click="askIfRemoveScan(scan_document)">delete_forever</span>
                                        </td>
                                        <td>
                                            {{scan_document.file_ext}}
                                        </td>
                                        <td>
                                            {{humanReadableBytes(scan_document.file_size)}}
                                        </td>
                                        <td>
                                            <div class="desktop-only" style="max-width:30vw;">{{scan_document.description}}</div>
                                            <div class="mobile-only">{{scan_document.description}}</div>
                                        </td>
                                        <td>
                                            <q-btn round color="black" icon="cloud_download" size="xs"/>
                                            <q-menu fit
                                                    transition-show="scale"
                                                    transition-hide="scale"
                                            >
                                                <DialogOpenScan :appid="scan_document.appid" type_scan="Asset"/>
                                            </q-menu>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div style="display: flex;align-items: center;justify-content: center; width: 100%;border-bottom: 1px solid black;"></div>

                    <div style="display: flex;align-items: left;justify-content: left; width: 100%;">
                        <div style="margin-top: 5px;">
                            <span class="text__title--medium">Planificari</span>                                
                        </div>
                        <div>
                            <q-btn flat color="primary" icon="add_box" no-caps :disable="loadingAssetData" @click="openFormAssetPlan">
                                <q-tooltip
                                    transition-show="rotate"
                                    transition-hide="rotate"
                                >
                                    Adaugare plan nou
                                </q-tooltip>
                            </q-btn>
                        </div>
                        <br/>
                    </div>
                    <div style="display: flex;justify-content: center; width: 100%">
                        <q-spinner
                            v-if="loadingNotification"
                            color="primary"
                            size="3em"
                            :thickness="10"
                        />
                        <q-table dense :rows-per-page-options="[0]" :rows="notifications"
                            :columns="assetsNotificationListColumns" :visible-columns="visibleAssetsNotificationListColumns"
                            row-key="appid" :filter="filterAssetsNotificationList" :loading="loadingNotification"
                            :pagination.sync="myPagination"
                            v-else class="table table-bordered table-condensed table-responsive"
                        >
                            <template v-slot:top>                                
                                <q-space />
                                <!--test-->
                                <q-input dense debounce="300" color="primary" v-model="filterAssetsNotificationList">
                                    <template v-slot:append>
                                        <q-icon name="search" />
                                    </template>
                                </q-input>
                            </template>

                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="appid" :props="props" :sortable="true">
                                        {{ props.row.appid }}
                                    </q-td>
                                    <q-td key="state_notification" :props="props">
                                        <q-icon name="lens" style="font-size: 25px;" v-bind:class="{
                                            'app__status--gray': (props.row.state_notification == 'gray'),
                                            'app__status--green': (props.row.state_notification == 'green'),
                                            'app__status--yellow': (props.row.state_notification == 'yellow'),
                                            'app__status--red': (props.row.state_notification == 'red'),
                                            'app__status--blue': (props.row.state_notification == 'blue')
                                        }" />
                                    </q-td>
                                    <q-td key="denumire_categorie_active" :props="props" :sortable="true"
                                        style="white-space:pre-wrap; word-wrap:break-word">
                                        {{ props.row.denumire_categorie_active }}
                                    </q-td>
                                    <q-td key="denumire_tip_active" :props="props" :sortable="true"
                                        style="white-space:pre-wrap; word-wrap:break-word">
                                        {{ props.row.denumire_tip_active }}
                                    </q-td>
                                    <q-td key="denumire_asset" :props="props" :sortable="true"
                                        style="white-space:pre-wrap; word-wrap:break-word">
                                        {{ props.row.denumire_asset }}
                                    </q-td>
                                    <q-td key="solicitant" :props="props" :sortable="true"
                                        style="white-space:pre-wrap; word-wrap:break-word">
                                        {{ props.row.solicitant }}
                                    </q-td>
                                    <q-td key="denumire_eveniment" :props="props" :sortable="true"
                                        style="white-space:pre-wrap; word-wrap:break-word">
                                        {{ props.row.denumire_eveniment }}
                                    </q-td>
                                    <q-td key="descriere" :props="props" :sortable="true"
                                        Style="white-space:pre-wrap; word-wrap:break-word">
                                        {{ props.row.descriere }}                        
                                    </q-td>
                                    <!-- <q-td key="denumire_utilizatori" :props="props" :sortable="true"
                                        style=" white-space:nowrap; word-wrap:normal; overflow: hidden; text-overflow: ellipsis;">
                                        {{ props.row.denumire_utilizatori }}
                                        <q-menu v-if="props.row.denumire_utilizatori.length > 0" fit transition-show="scale"
                                            transition-hide="scale" max-width=50vh>
                                            <div class="q-pa-md" style="min-width: 30vw;">
                                                {{ props.row.denumire_utilizatori }}
                                            </div>
                                        </q-menu>
                                    </q-td> -->
                                    <q-td key="denumire_utilizatori" :props="props" :sortable="true"
                                        style="white-space:pre-wrap; word-wrap:break-word">
                                        <div style="max-width:30vw;" class="container--span__file_scan">
                                            <div v-if="props.row.denumire_utilizatori?.length > 0 && props.row.denumire_utilizatori?.split(',')?.length > 1" class="cursor-pointer">
                                                <q-badge color="blue" transparent>
                                                    {{props.row.denumire_utilizatori.split(',').length}}
                                                    <q-menu fit                                          
                                                        transition-show="scale"
                                                        transition-hide="scale"                                          
                                                    >
                                                        <div class="q-pa-md" style="min-width: 30vw;">
                                                            <span v-for="utilizator in props.row.denumire_utilizatori.split(',')" v-bind:key="utilizator" class="span__repartizare">
                                                                <span class="material-icons">share</span>
                                                                <div class="row" style="display: flex; max-width: 450px;width:100%; margin-left:5px;">
                                                                    <div class="col-8" style="text-align:left;">{{ utilizator }}</div>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </q-menu>
                                                </q-badge>
                                            </div>
                                            <div v-else>
                                                <span style="color: rgb(134, 134, 134)">{{ props.row.denumire_utilizatori }}</span>
                                            </div>
                                        </div>
                                    </q-td>
                                    <q-td key="data_termen" :props="props" :sortable="true">
                                        {{ displayData(props.row.data_termen) }}
                                    </q-td>
                                    <q-td key="denumire_stare" :props="props" :sortable="true">
                                        {{ props.row.denumire_stare }}
                                    </q-td>
                                    <q-td key="scans" :props="props" style="white-space:pre-wrap; word-wrap:break-word">
                                        <div style="max-width:30vw;" class="container--span__file_scan">
                                            <span v-for="scan in props.row.scans" v-bind:key="scan.appid"
                                                class="cursor-pointer span__file_scan">
                                                <span class="material-icons rotated90">attachment</span>
                                                {{ humanReadableBytes(scan.file_size) }}.{{ scan.file_ext }}
                                                <q-menu fit transition-show="scale" transition-hide="scale">
                                                    <DialogOpenScan :appid="scan.appid" type_scan="AssetNotification" />
                                                </q-menu>
                                            </span>
                                        </div>
                                    </q-td>
                                    <q-td key="appid" :props="props" :sortable="false">
                                        <q-icon :name="'edit'" :class="props.row.status === 'n' && props.row.appid_users?.includes(userStore.User.userid) ? 'text-blue cursor-pointer pencil' : 'text-light cursor-pointer pencil'"                                        
                                            style="font-size: 1.2rem; margin-left: auto;"                                            
                                            @click="props.row.appid_users?.includes(userStore.User.userid) && props.row.status === 'n' && onPressEditRowAssetNotification(props.row)">
                                            <q-tooltip v-if="props.row.appid_users?.includes(userStore.User.userid) && props.row.status == 'n'">
                                                <span>Editeaza</span>
                                            </q-tooltip>
                                        </q-icon>
                                        <q-icon :name="props.row.appid_user_alocat ? 'person' : 'person_add_alt'"                                            
                                            :class="props.row.appid_users?.includes(userStore.User.userid) && props.row.status === 'n' ? (props.row.appid_user_alocat ? 'text-orange cursor-pointer pencil' : 'text-blue cursor-pointer pencil') : 'text-light'"                                            
                                            style="font-size: 1.2rem; margin-left: 1vh;">
                                            <q-menu v-if="(props.row.appid_users?.includes(userStore.User.userid) && (props.row.appid_user_alocat == null || props.row.appid_user_alocat != userStore.User.userid) && props.row.status == 'n')" fit transition-show="scale"                                        
                                                transition-hide="scale" min-width=50vh max-width=100vh>
                                                <div class="q-pa-md" style="min-width: 30vw; align-items: center;">
                                                    <div>Sunteti sigur sa preluati acest plan sau incident?</div>
                                                    <q-btn v-close-popup flat dense color="negative" icon="cancel"/>
                                                    <q-btn v-close-popup flat dense color="positive" icon="check_circle"
                                                        @click.stop="updateUserAlocat(props.row)" />
                                                </div>
                                            </q-menu>
                                            <q-tooltip v-if="props.row.appid_user_alocat == userStore.User.userid && props.row.status == 'n'">
                                                <span>Acest plan sau incident este preluat deja!</span>
                                            </q-tooltip>
                                            <q-tooltip v-else-if="props.row.appid_users?.includes(userStore.User.userid) && props.row.status == 'n'">
                                                <span>Preia in lucru</span>
                                            </q-tooltip>
                                        </q-icon>
                                        <q-icon :name="props.row.status === 'n' ? 'check_circle_outline' : 'check'"                                            
                                            :class="props.row.appid_users?.includes(userStore.User.userid) ? (props.row.status === 'n' ? 'text-blue cursor-pointer pencil' : 'text-green cursor-pointer pencil') : 'text-light cursor-pointer pencil'"
                                            style="font-size: 1.2rem; margin-left: 1vh;">
                                            <q-menu v-if="props.row.appid_user_alocat === userStore.User.userid && props.row.status === 'n'" fit transition-show="scale"
                                                transition-hide="scale" min-width=50vh max-width=100vh>
                                                <div class="q-pa-md" style="min-width: 30vw; align-items: center;">
                                                    <div>Setati ca si terminat acest plan?</div>
                                                    <q-btn v-close-popup flat dense color="negative" icon="cancel"/>
                                                    <q-btn v-close-popup flat dense color="positive" icon="check_circle"
                                                        @click.stop="updateAssetNotificationStatus(props.row)" />
                                                </div>
                                            </q-menu>
                                            <q-tooltip v-if="props.row.appid_user_alocat == userStore.User.userid && props.row.status === 'y'">
                                                <span>Acest plan sau incident este finalizat!</span>
                                            </q-tooltip>
                                            <q-tooltip v-else-if="props.row.appid_users?.includes(userStore.User.userid) && props.row.status == 'n' && props.row.appid_user_alocat">
                                                <span>Finalizeaza</span>
                                            </q-tooltip>
                                        </q-icon>
                                    </q-td>
                                </q-tr>
                            </template>
                        </q-table>
                        <!-- <table v-else class="table table-bordered table-condensed table-responsive" style="width: 100%; max-height: 50vh;">
                            <tbody>
                                <tr v-for="notification in notifications" v-bind:key="notification.appid">                                                                             
                                    <td>
                                        <div class="desktop-only" style="min-width:10vw;max-width:30vw;">{{notification.titlu}}</div>
                                        <div class="mobile-only">{{notification.titlu}}</div>
                                    </td>
                                    <td>
                                        <div class="desktop-only" style="min-width:15vw;max-width:30vw;">{{notification.status=='y' ? notification.descriere : ''}}</div>
                                        <div class="mobile-only">{{notification.status=='y' ? notification.descriere : ''}}</div>
                                    </td>
                                    <td>
                                        <div style="min-width:7vw;">{{displayDataTermen(notification.data_termen)}}</div>
                                    </td>
                                    <td>
                                        <div style="min-width:3vw;">                    
                                            <q-icon name="lens" style="font-size: 25px;"
                                                v-bind:class="{ 
                                                    'app__status--gray': (notification.state_notification == 'gray'),
                                                    'app__status--green': (notification.state_notification == 'green'),
                                                    'app__status--yellow': (notification.state_notification == 'yellow'),
                                                    'app__status--red': (notification.state_notification == 'red'),
                                                    'app__status--blue': (props.row.state_notification == 'blue')
                                                }"
                                            />
                                        </div>
                                    </td>
                                    <td v-if="notification.status=='n'">
                                        <q-btn round color="green" icon="check_circle" size="xs"/>                                            
                                        <q-popup-edit v-model="notification.descriere" :validate="val => val.length > 0">                                                
                                            <template v-slot="scope">
                                                <div>Setati ca si terminat acest plan?</div>
                                                <q-input
                                                    autofocus
                                                    dense
                                                    v-model="scope.value"
                                                    style="min-width:400px;"
                                                    :value="notification.descriere"
                                                    hint="Modificare descriere"
                                                    lazy-rules
                                                    maxlength="250"
                                                    rows="2"
                                                    type="textarea"                                                        
                                                    :rules="[ val => val && val.length > 0 || 'Completati descriere!']"                                                                                                              
                                                >
                                                    <template v-slot:after>
                                                        <q-btn flat dense color="negative" icon="cancel" @click.stop="scope.cancel" />
                                                        <q-btn flat dense color="positive" icon="check_circle" @click.stop="tryUpdateAssetNotification(scope, notification)" :disable="scope.validate(scope.value) === false || scope.initialValue === scope.value"/>
                                                    </template>
                                                </q-input>
                                            </template>
                                        </q-popup-edit>
                                    </td>
                                </tr>
                            </tbody>
                        </table> -->
                    </div>
                </div>
            </div>
            <!-- <div style="width:100%;display: flex; justify-content: flex-end;" v-if="!isFinalizat">
                <q-btn v-show="assetApp.scan_documents.length>0" color="primary" text-color="white" icon-right="bookmark_border" label="Finalizeaza inregistrarea!" no-caps @click="finalizeazaInregistrareAsset"></q-btn>
            </div> -->
        </div>

        <q-dialog v-model="viewDialogSelectPersoana" position="bottom">
            <q-card style="min-width: 70vw;"
                v-bind:class="{ 'partial80_height': $q.platform.is.mobile, 'partial60_height': $q.platform.is.desktop }"
            >
                <div style="display:flex;justify-content: flex-end;">
                    <span class="material-icons cursor-pointer" @click="viewDialogSelectPersoana=false" style="color:#747474;font-weight: bolder;font-size: 1.5em;">close</span>
                </div>

                <q-card-section>
                    <ComboUser :selectHandler="selectPersoana" title="Selectati persoana responsabil"/>
                </q-card-section>
            </q-card>
        </q-dialog>

        <q-dialog v-model="viewDialogSelectOrgUnit" position="bottom">
            <q-card style="min-width: 70vw;"
                v-bind:class="{ 'partial80_height': $q.platform.is.mobile, 'partial60_height': $q.platform.is.desktop }"
            >
                <div style="display:flex;justify-content: flex-end;">
                    <span class="material-icons cursor-pointer" @click="viewDialogSelectOrgUnit=false" style="color:#747474;font-weight: bolder;font-size: 1.5em;">close</span>
                </div>

                <q-card-section>
                    <ComboOrgUnit :selectHandler="selectOrgUnit" title="Selectati Org Unit"/>
                </q-card-section>
            </q-card>
        </q-dialog>

        <q-dialog v-model="dialogUploadFile" position="top">
            <q-card style="width: 350px">
                <q-linear-progress :value="0.6" color="pink" />

                <q-card-section class="column no-wrap">
                    <div class="text-weight-bold">Incarca fisier nou</div>
                    <div class="text-grey" style="margin-top: 4px; margin-bottom: 10px;"><q-input counter outlined v-model="uploadScanTitle" label="Titlu document (minim 4 caractere)" dense maxlength="200" /></div>
                    <input v-show="uploadScanTitle.length>3" type="file" name="fileToUpload" id="fileToUpload" ref="myFileToUpload" @change="prepareForUpload($event)" style="margin-bottom: 10px;" />
                    <q-btn :disable="!(uploadScanTitle.length>3)" v-show="checkIfFileIsSelected" icon="cloud_upload" color="white" text-color="black" label="Salveaza pe server" @click="uploadFile()" :loading="uploadingFileToServer" style="margin-bottom: 5px;" />
                </q-card-section>
            </q-card>
        </q-dialog>

        <q-dialog v-model="visibleDialogNotification" persistent >
            <q-card style="min-width: 60vw; max-width: 80vw;">
                <q-bar>
                    <q-icon name="add_box" />
                    <div>Adaugare plan</div>

                    <q-space />

                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>

                <q-card-section>
                    <EditAssetNotification :frenunt="renuntEditareAssetNotification" :fonsave="getNotifications" :appid_asset="assetApp.appid" :appid="appid_notification"/>
                </q-card-section>
            </q-card>
        </q-dialog>
        <q-dialog v-model="visibleDialogCarouselFullScreen" persistent >
             <q-card style="min-width: 60vw; max-width: 80vw;">
                <q-bar>
                    <q-icon name="add_box" />
                    <div>Galerie</div>

                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <q-carousel
                        swipeable
                        v-model="slide"
                        arrows
                        thumbnails
                        height="100"
                        style="min-width: 40vw; max-width: 80vw; min-height: 40vw;"
                    >
                        <template v-slot:control>
                            <q-carousel-control
                                position="top-right"
                                :offset="[10, 10]"                                                    
                                >
                            </q-carousel-control>
                        </template>
                        <q-carousel-slide 
                            v-for="(scan_galerie, key) in assetApp.scan_galeries" v-bind:key="scan_galerie.appid" 
                            :name="key" :img-src="getLinkToImgAsset(scan_galerie.appid?.toString())">
                            <div class="absolute-top custom-caption">                                                
                                 <span class="material-icons cursor-pointer" style="margin-top: 10px; margin-left: 10px; color: red; font-size: 1.5rem;" @click="askIfRemoveScan(scan_galerie)">delete_forever</span>
                            </div>
                        </q-carousel-slide>
                        <q-carousel-slide 
                            v-for="(scan_galerie, key) in assetApp.scan_galeries" v-bind:key="scan_galerie.appid" 
                            :name="key" :img-src="getLinkToImgAsset(scan_galerie.appid?.toString())">
                        </q-carousel-slide>
                    </q-carousel>
                </q-card-section>
            </q-card>
        </q-dialog>
    </div>
</template>

<script lang="ts" src="./WorkOnAsset.ts" />

<style scoped>
    .q-field--with-bottom {
        padding-bottom: 20px;
    }

    .q-field__native{
        font-family: "Nunito Sans";
        font-weight: bolder;
    }

    .mousechange:hover {
        cursor: pointer;
    }
</style>
