import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import IReferat from '@/types/IReferat';
import IReferatServicii from '@/types/IReferatServicii';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type IReferatNull = OrNull<IReferat>;
type IReferatServiciiNull = OrNull<IReferatServicii>;

interface TAjaxResponseReferat {
  status: string;
  message: string;
  rows: string;
}

interface TAjaxResponseEditReferat {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetReferat {
    status: string;
    message: string;
    referat: IReferatNull;
}

interface TAjaxResponseReferatServicii {
  status: string;
  message: string;
  rows: string;
}

interface TAjaxResponseEditReferatServiciu {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetReferatServiciu {
    status: string;
    message: string;
    referatServicii: IReferatServiciiNull;
}

export class ServiceReferate {
  public static async getReferate(is_all: number): Promise<TAjaxResponseReferat> {
    const response = await axios.get(`${CONFIG_ENV.URL_REFERATE.referate}/${is_all}`);
    return response.data;
  }

  // public static async deleteReferat(appid: number): Promise<TAjaxResponseEditReferat> {    
  //   const response = await axios.delete(`${CONFIG_ENV.URL_REFERATE.referat}/${appid}`);
  //   return response.data;
  // }

  public static async postReferat(referat: IReferatNull): Promise<TAjaxResponseEditReferat> {
    const response = await axios.post(`${CONFIG_ENV.URL_REFERATE.referat}`, referat);
    return response.data;
  }

  public static async getReferat(appid: number): Promise<TAjaxResponseGetReferat> {
    const response = await axios.get(`${CONFIG_ENV.URL_REFERATE.referat}/${appid}`);
    return response.data;
  }  

  public static async getReferatServicii(idReferat: number): Promise<TAjaxResponseReferatServicii> {
    const response = await axios.get(`${CONFIG_ENV.URL_REFERATE.referatservicii}/${idReferat}`);
    return response.data;
  }
  
  public static async deleteReferatServiciu(appid: number): Promise<TAjaxResponseEditReferatServiciu> {    
    const response = await axios.delete(`${CONFIG_ENV.URL_REFERATE.referatserviciu}/${appid}`);
    return response.data;
  }

  public static async getReferatServiciu(appid: number): Promise<TAjaxResponseGetReferatServiciu> {
    const response = await axios.get(`${CONFIG_ENV.URL_REFERATE.referatserviciu}/${appid}`);
    return response.data;
  }

  public static async postReferatServiciu(referatServiciu: IReferatServiciiNull): Promise<TAjaxResponseEditReferatServiciu> {
    const response = await axios.post(`${CONFIG_ENV.URL_REFERATE.referatserviciu}`, referatServiciu);
    return response.data;
  }
}