import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import INomDeconturi from '@/types/INomDeconturi';
import INomDeconturiDet from '@/types/INomDeconturiDet';
import IScanNomDeconturi from '@/types/IScanNomDeconturi';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type INomDeconturiNull = OrNull<INomDeconturi>;
type INomDeconturiDetNull = OrNull<INomDeconturiDet>;

interface TAjaxResponseNomDeconturi {
  status: string;
  message: string;
  rows: string;
}

interface TAjaxResponseEditDecont {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetDecont {
  status: string;
  message: string;
  decont: INomDeconturiNull;
}

interface TAjaxResponseNomDeconturiDet {
  status: string;
  message: string;
  rows: string;
}

interface TAjaxResponseEditDecontDet {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetDecontDet {
  status: string;
  message: string;
  decontdet: INomDeconturiDetNull;
}

interface TAjaxResponseUpdateDecont {
  status: string;
  message: string;
}

interface TAjaxResponseGetScansDeconturi {
  status: string;
  message: string;
  nr_of_records: number,
  rows: IScanNomDeconturi[];
}

interface TAjaxResponseGetInfoScanDecont {
  status: string;
  message: string;
  scan: IScanNomDeconturi;
}

interface TAjaxSimpleResponse {
  status: string;
  message: string;
}

export class ServiceNomDeconturi {
  public static async getNomDeconturi(is_all: number): Promise<TAjaxResponseNomDeconturi> {
    const response = await axios.get(`${CONFIG_ENV.URL_DECONTURI.deconturi}/${is_all}`);
    return response.data;
  }

  public static async postNomDecont(pDecont: INomDeconturi): Promise<TAjaxResponseEditDecont> {
    const response = await axios.post(`${CONFIG_ENV.URL_DECONTURI.decont}`, pDecont);
    return response.data;
  }

  public static async getNomDecont(appid: number): Promise<TAjaxResponseGetDecont> {
    const response = await axios.get(`${CONFIG_ENV.URL_DECONTURI.decont}/${appid}`);
    return response.data;
  }

  public static async getNomDeconturiDet(idDecont: number): Promise<TAjaxResponseNomDeconturiDet> {
    const response = await axios.get(`${CONFIG_ENV.URL_DECONTURI.deconturi_det}/${idDecont}`);
    return response.data;
  }

  public static async postNomDecontDet(pDecontDet: INomDeconturiDet): Promise<TAjaxResponseEditDecontDet> {
    const response = await axios.post(`${CONFIG_ENV.URL_DECONTURI.decont_det}`, pDecontDet);
    return response.data;
  }

  public static async getNomDecontDet(appid: number): Promise<TAjaxResponseGetDecontDet> {
    const response = await axios.get(`${CONFIG_ENV.URL_DECONTURI.decont_det}/${appid}`);
    return response.data;
  }

  public static async deleteDecontDet(appid: number): Promise<TAjaxResponseEditDecontDet> {
    const response = await axios.delete(`${CONFIG_ENV.URL_DECONTURI.decont_det}/${appid}`);
    return response.data;
  }

  public static async updateNomDecont(appid:number, tipupdate:string, ptext: string): Promise<TAjaxResponseUpdateDecont> {
    const params = new URLSearchParams();
    params.set('new_value', ptext);
    const response = await axios.post(`${CONFIG_ENV.URL_DECONTURI.update_decont}/${appid}/${tipupdate}`, params);
    return response.data;
  }

  public static async getScansDeconturi(appid:number): Promise<TAjaxResponseGetScansDeconturi> {
    const response = await axios.get(`${CONFIG_ENV.URL_DECONT_SCAN.getScansDeconturi}/${appid}`);
    return response.data;
  }

  public static async getScansDeconturiDet(appid:number): Promise<TAjaxResponseGetScansDeconturi> {
    const response = await axios.get(`${CONFIG_ENV.URL_DECONT_SCAN.getScansDeconturiDet}/${appid}`);
    return response.data;
  }

  public static async getInfoScan(appid:number): Promise<TAjaxResponseGetInfoScanDecont> {
    const response = await axios.get(`${CONFIG_ENV.URL_DECONT_SCAN.getInfoScan}/${appid}`);
    return response.data;
  }

  public static async deleteScan(appid:number, headers: any): Promise<TAjaxSimpleResponse> {
    const response = await axios.delete(`${CONFIG_ENV.URL_DECONT_SCAN.getInfoScan}/${appid}`, { headers });
    return response.data;
  }
}