import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';

interface TAjaxResponseNomTipMijlocDeTransport{
  status: string;
  message: string;
  rows: string;
}

export class ServiceNomTipMijlocDeTransport {
  public static async getNomTipMijlocDeTransport(): Promise<TAjaxResponseNomTipMijlocDeTransport> {
    const response = await axios.get(`${CONFIG_ENV.URL_NOMTIPMIJLOCDETRANSPORT.getNomTipMijlocDeTransport}`);
    return response.data;
  }
}