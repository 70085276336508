import { createApp } from 'vue/dist/vue.esm-bundler';
import App from './App.vue';
//import './registerServiceWorker';
import router from './router';
import store from './store';
import './assets/site.css';
import 'typeface-nunito-sans';
import 'typeface-pt-sans-narrow';
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Loading from 'quasar/src/plugins/Loading.js';;
import Interceptors from './modules/Interceptors';
import './styles/quasar.scss';
import lang from 'quasar/lang/ro.js';
import '@quasar/extras/material-icons/material-icons.css';
import VueApexCharts from "vue3-apexcharts"; 
import mitt from 'mitt';
import i18n from './i18n';
// import VueGapi from 'vue-gapi';

const emitter = mitt();

const quasarUserOptions = {
    plugins: {
        Notify, Dialog, Loading
    },
    lang: lang
};

Interceptors();

const app = createApp(App);
// app.config.globalProperties.emitter = emitter;
app.provide('emitter', emitter);

// app.use(VueGapi, {
//     clientId: '831498217804-n5f6uvt30h9q0lsdql4tq6qek8i6cggf.apps.googleusercontent.com',
//     scope: 'https://www.googleapis.com/auth/photoslibrary.readonly',
//     });

app.use(Quasar, quasarUserOptions).use(i18n).use(store).use(router).mount('#app');
app.use(VueApexCharts);
//createApp(App).use(Quasar, quasarUserOptions).use(store).use(router).mount('#app');
