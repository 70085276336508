// export const APP_NAME = 'APP_DEV';
// export const APP_NAME = 'APP_URBANESQ';
// export const APP_NAME = 'APP_CV';
export const APP_NAME = 'APP_BRANDTEAM';
// export const APP_NAME = 'APP_MAGIC';
// export const APP_NAME = 'APP_HR';
// export const APP_NAME = 'APP_SOPHORE';
// export const APP_NAME = 'APP_CJCV';
// export const APP_NAME = 'APP_MEAT';
// export const APP_NAME = 'APP_MULTICOMPANY';

export const APP_INFORMATION = {
  version: '1.0.147',
  date: '2023-10-11'
};

const APP_HOSTNAME = [
  //"APP_DEV"       - demo.odocs.ro (Bogdan)
  { name: 'DEMO ODOCS', value: 'demo.odocs.ro', api: '/api', fileserver: 'https://demo.odocs.ro/api', portalapi: '', messageapikey: ''},
  //"APP_URBANESQ"  - urbanesq.odocs.ro (Bogdan)
  { name: 'MEDIU LIVE', value: 'urbanesq.odocs.ro', api: '/api', fileserver: 'https://urbanesq.odocs.ro/api', portalapi: '', messageapikey: ''},
  //"APP_CV"        - Primaria Sfantu Gheorghe Covasna
  { name: 'MEDIU TEST', value: '192.168.108.110', api: '/odocs/api', fileserver: 'http://192.168.108.111', portalapi: 'http://192.168.108.110/portal-api/public/api/messages', messageapikey: '?apiKey=db493142-37ae-4a01-9021-37ee81c33f68' },
  { name: 'MEDIU LIVE', value: '192.168.108.100', api: '/api', fileserver: 'http://192.168.108.101', portalapi: 'https://api.portal.sepsi.ro/api/messages', messageapikey: '?apiKey=db493142-37ae-4a01-9021-37ee81c33f68' },
  //"APP_CJCV"      - Consiliul Judetean Covasna
  { name: 'MEDIU TEST', value: '192.168.66.15', api: '/api', fileserver: 'http://192.168.66.16/api', portalapi: 'https://api-syn.ecovasna.demo.milav.eu/api/external/messages', messageapikey: 'ApiKey 0058d547-8c74-4e7c-960a-ce8aecc8b06f' },
  { name: 'MEDIU LIVE', value: '192.168.66.19', api: '/api', fileserver: 'http://192.168.66.20/api', portalapi: 'https://api.ecovasna.ro/api/external/messages', messageapikey: 'ApiKey 5dd4032b-wnxv-6175-vbxv-ea5ed0ddb9c5' },
  //"APP_HR"        - Consiliul Judetean Harghita
  { name: 'MEDIU TEST', value: 'regisdra-test', api: '/api', fileserver: 'http://regisdra-test/api', portalapi: 'https://api.portal.uat.hargitamegye.ro', messageapikey: 'ApiKey 57269f0c-c528-40bc-8ae4-34c8cbd93863'},
  { name: 'MEDIU TEST', value: '192.168.12.37', api: '/api', fileserver: 'http://192.168.12.37/api', portalapi: 'https://api.portal.uat.hargitamegye.ro', messageapikey: 'ApiKey 57269f0c-c528-40bc-8ae4-34c8cbd93863'},
  { name: 'MEDIU LIVE', value: 'digithr', api: '/api', fileserver: 'http://192.168.12.36/api', portalapi: 'https://api.ugyfelkapuhr.ro/api/external/messages', messageapikey: 'ApiKey d9e3356f-afd9-4a5c-93f0-5738102a657b'},
  { name: 'MEDIU LIVE', value: '192.168.12.249', api: '/api', fileserver: 'http://192.168.12.36/api', portalapi: 'https://api.ugyfelkapuhr.ro/api/external/messages', messageapikey: 'ApiKey d9e3356f-afd9-4a5c-93f0-5738102a657b'},
  //"APP_SOPHORE"   - Sophore
  { name: 'MEDIU LIVE', value: '192.168.0.22', api: '/api', fileserver: 'http://192.168.0.22/api', portalapi: '', messageapikey: ''},
  //"APP_BRANDTEAM" - BrandTeam Magicsolutions
  { name: 'BRANDTEAM', value: '192.168.125.197', api: '/api', fileserver: 'http://192.168.125.197/api', portalapi: '', messageapikey: ''},
  //"APP_MAGIC"     - Magicsolutions
  { name: 'MAGICSOLUTIONS', value: '192.168.125.198', api: '/api', fileserver: 'http://192.168.125.198/api', portalapi: '', messageapikey: ''},
  //"APP_MEAT"     - Ministerul Economiei
  { name: 'Ministerul Economiei', value: '192.168.125.198', api: '/api', fileserver: 'http://192.168.125.198/api', portalapi: '', messageapikey: ''},
];

export const APP_NAME_TITLE = APP_HOSTNAME.find(item => item.value === location.hostname)?.name as string;
export const APP_CLASS = APP_HOSTNAME.find(item => item.value === location.hostname);

export const URL_API = ( function() {
  if (location.hostname === 'localhost') {     
    return process.env.NODE_ENV === 'production' ? 'http://localhost/api' : 'http://localhost:83/api'
    //return 'https://regis.theappsonline.com/api'
    //return 'http://regisdra-test/api';  
  } else {
    //return 'http://localhost/api'
    //return 'https://regis.theappsonline.com/api'
    //return '/api';
    //return '/odocs/api';        
    return APP_HOSTNAME.find(item => item.value === location.hostname)?.api;
  }
})();

export const URL_API_FILE = ( function() {
  if (location.hostname === 'localhost') {
    if (['APP_SOPHORE'].includes(APP_NAME)) { //'APP_HR', 
      return process.env.NODE_ENV === 'production' ? 'http://localhost/api' : 'http://localhost:82/api'
    } else {
      return process.env.NODE_ENV === 'production' ? 'http://localhost' : 'http://localhost:82/api'
    }    
    //return 'https://regis.theappsonline.com/api'
    //return 'http://regisdra-test/api';  
  } else {
    //return 'http://localhost/api'
    //return 'https://regis.theappsonline.com/api'
    //return '/api';    
    //return '/odocs/api';
    // return 'http://192.168.108.101';   
    return APP_HOSTNAME.find(item => item.value === location.hostname)?.fileserver;
  }
})();

export const CONFIG_ENV = {
  URL_USER: {
    getCurrentUserFromAD : URL_API + '/user/get_current_user_ad',
    getUserByMarca : URL_API + '/nom_users/get_info_user_by_marca',
    // granted_registre_marca : URL_API + '/nom_users/granted_registre_by_marca',    
    login : URL_API + '/user/login',
    login_google : URL_API + '/user/login_google',
    checkToken : URL_API + '/user/check_token',
    logOut : URL_API + '/user/log_out',
  },
  URL_HR_ADMIN_PREZENTA: {
    getPersonsWeekStatus : URL_API + '/hr_admin_prezenta/persons_week_status',
    getWeekInfo : URL_API + '/hr_admin_prezenta/week_info',
    AllRequestsMarca: URL_API + '/hr_admin_prezenta/requests_marca',
    TimeAccountsMarca: URL_API + '/hr_admin_prezenta/time_accounts_marca',
    getTimeRequest: URL_API + '/hr_admin_prezenta/time_request',
    approveTimeRequest: URL_API + '/hr_admin_prezenta/approve_time_request',
    rejectTimeRequest: URL_API + '/hr_admin_prezenta/reject_time_request',
    getPersonsAllStatus: URL_API + '/hr_admin_prezenta/persons_all_status',
    recallCO: URL_API + '/hr_admin_prezenta/recall_co',
    rescheduleCO: URL_API + '/hr_admin_prezenta/reschedule_co',
    cancellationTimeRequest: URL_API + '/hr_admin_prezenta/cancellation_time_request'
  },
  URL_SEARCH: {
    optionsSolicitanti : URL_API + '/search/options_solicitanti',
    // optionsOrganizatii : URL_API + '/search/options_organizatii',
    getStatusNumere : URL_API + '/search/status_numere',
    NrOfMyDocumentsPendingRegistering : URL_API + '/search/nr_of_my_docs_pending_registering',
    NrOfMyDocuments : URL_API + '/search/nr_of_my_docs',
    NrOfDocumentsDeRepartizat : URL_API + '/search/nr_of_docs_de_repartizat',
    ServerSentEvent: URL_API + '/search/sse'
  },
  URL_APPLOG: {
    getTipEntitate: URL_API + '/applog/tip_entitate',
    getLogEntitate: URL_API + '/applog/entitate'
  },
  URL_NOM: {
    categorie : URL_API + '/nom_categ/categorie',
    categoriePortal : URL_API + '/nom_categ/categorie_portal',
    categorii : URL_API + '/nom_categ/categorii',    
    NomAllowedFiletypes : URL_API + '/nom_allowed_filetypes',
    tip_doc : URL_API + '/nom_tip_doc/tip_doc',
    tip_doc_repartizare : URL_API + '/nom_tip_doc/tip_doc_repartizare',
    tip_doc_termen_raspuns : URL_API + '/nom_tip_doc/tip_doc_termen_raspuns',
    tip_doc_termen_arhivare : URL_API + '/nom_tip_doc/tip_doc_termen_arhivare',
    tip_docs : URL_API + '/nom_tip_doc/tip_docs',
    registru : URL_API + '/nom_registre/registru',
    registre : URL_API + '/nom_registre/registre',
    registru_portal : URL_API + '/nom_registre/registru_portal',
    registru_stampila : URL_API + '/nom_registre/registru_stampila',
    // hr_nom_org: URL_API + '/nom_hr/org',
    // deleteDivision: URL_API + '/nom_hr/division',
    // deleteDepartament: URL_API + '/nom_hr/departament',
    deleteOrgUnit: URL_API + '/nom_hr/org_unit',
    // allOrgForTree: URL_API + '/nom_hr/org_for_tree',
    // toateDepartamentele: URL_API + '/nom_hr/toate_departamentele',
    toateOrgUnits: URL_API + '/nom_hr/toate_org_unit',
    toateOrgUnitsTree: URL_API + '/nom_hr/toate_org_unit_tree',
    toateTipurileOrgUnits: URL_API + '/nom_hr/toate_org_unit_types',
    updateOrgUnit: URL_API + '/nom_hr/update_org_unit',
    updateStateOrgUnit: URL_API + '/nom_hr/update_state_org_unit',
    nom_companies: URL_API + '/nom_companies',
    // updateDivision: URL_API + '/nom_hr/update_division',
    // updateDepartament: URL_API + '/nom_hr/update_departament',
    nom_users: URL_API + '/nom_users',
    nom_user: URL_API + '/nom_users/nom_user',
    reset_password: URL_API + '/nom_users/reset_password',
    change_password: URL_API + '/nom_users/change_password',
    persoane_utilizatoare: URL_API + '/nom_users/persoane_utilizatoare',
    persoane_utilizatoare_restrictii: URL_API + '/nom_users/persoane_utilizatoare_restrictii',
    // chg_user_departament: URL_API + '/nom_users/chg_user_departament',
    chg_user_wan: URL_API + '/nom_users/chg_user_wan',
    chg_user_name: URL_API + '/nom_users/chg_user_name',
    chg_user_email: URL_API + '/nom_users/chg_user_email',
    chg_drepturi: URL_API + '/nom_users/chg_drepturi',
    chg_org_unit: URL_API + '/nom_users/chg_org_unit',
    chg_superior_user: URL_API + '/nom_users/chg_superior_user',
    chg_job: URL_API + '/nom_users/chg_job',
    chg_rang: URL_API + '/nom_users/chg_rang',
    delete_user: URL_API + '/nom_users/delete_user',
    chg_send_email_notification: URL_API + '/nom_users/chg_send_email_notification',
  },
  URL_DOCUMENT: {
    document : URL_API + '/documente/document',
    doc_intrare : URL_API + '/documente/doc_intrare',
    doc_iesire : URL_API + '/documente/doc_iesire',
    doc_intern : URL_API + '/documente/doc_intern',
    qr_code : URL_API + '/documente/qr_code',
    update_document : URL_API + '/documente/update',
    update_adresadocument : URL_API + '/documente/updateadresadocument',
    modifica_tip_document : URL_API + '/documente/modifica_tip_document',
    pdf_cover : URL_API + '/documente/pdf_cover',
    finalizeazaInregistrareDocument : URL_API + '/documente/finalizeaza_inregistrare_document',
    GetPageWithDocuments: URL_API + '/documente/get_page_with_documents',
    GetPageWithDocumentsCertificateUrbanism: URL_API + '/documente/get_page_with_documents_certificate_urbanism',
    GetPageWithDocumentsServiciiDeTransport: URL_API + '/documente/get_page_with_documents_servicii_de_transport',
    FastSearch : URL_API + '/documente/fast_search',
    Repartizeaza : URL_API + '/documente/repartizeaza',
    NuSeRepartizeaza: URL_API + '/documente/nuserepartizeaza',
    Expeditie: URL_API + '/expeditie',
    downloadPDFQuadCode: URL_API + '/documente/download_pdf_quadcode',
    documenteForReference: URL_API + '/documente/documente_for_reference',
    cancellationDocument : URL_API + '/documente/cancellation_document',
    DeleteRepartizare : URL_API + '/documente/delete_repartizare',
    update_referencedocument : URL_API + '/documente/updatereferencedocument',
    // MyDocumentsPendingRegistering : URL_API + '/documente/docs_pending_registering',
    // MyDocuments : URL_API + '/documente/my_documents',
    // DocumentsDeRepartizat : URL_API + '/documente/docs_de_repartizat',
    // DocumentsPendings: URL_API + '/documente/documents_pendings'
  },
  URL_DOCUMENTE_CETATENI: {
    documente_cetateni : URL_API + '/documente_cetateni/documente_cetateni',
    documentcetateni_detail: URL_API + '/documente_cetateni/documentcetatenidetail'
  },
  URL_FLUX: {
    nom_flux: URL_API + '/nom_flux/get_all_flux',
    delete_flux: URL_API + '/nom_flux/delete_flux',
    nom_flux_category: URL_API + '/nom_flux/get_all_flux_category',
    nom_flux_type: URL_API + '/nom_flux/get_all_flux_type',
    nom_flux_step_by_flux_id: URL_API + '/nom_flux_step/get_all_step',
    nom_flux_step_tip_responsabil: URL_API + '/nom_flux_step/get_all_flux_tip_responsabil',
    nom_flux_step_action_on_step: URL_API + '/nom_flux_step/get_all_flux_tip_action_on_step',
    nom_flux_step_responsabil_level: URL_API + '/nom_flux_step/get_all_flux_responsabil_level',
    nom_flux_step_delete: URL_API + '/nom_flux_step/delete_flux_step',
    nom_flux_step_update: URL_API + '/nom_flux_step',
    nom_flux_insert_update: URL_API + '/nom_flux/insert_update_flux',
    nom_flux_tasks: URL_API + '/tasks/get_all_user_task',
    nom_flux_tasks_istoric: URL_API + '/tasks/get_all_user_task_istoric',
    get_entity_tasks: URL_API + '/tasks/get_entity_tasks',
    approve: URL_API + '/tasks/approve',
    reject: URL_API + '/tasks/reject',
    nom_number_tasks: URL_API + '/tasks/get_number_tasks',
    nom_number_tasks_istoric: URL_API + '/tasks/get_number_tasks_istoric',
    create_task: URL_API + '/tasks/create_task',
    nom_flux_clone: URL_API + '/nom_flux/clone_flux',
  },
  URL_DOCUMENT_SCAN: {
    //uploadScan : URL_API_FILE + '/scan/upload',  
    getScans : URL_API + '/scan_document/scans',
    uploadScan : URL_API_FILE + '/scan_document/upload',
    getInfoScan : URL_API + '/scan_document/info',
    downloadScanFromApp : URL_API_FILE + '/scan_document/download_from_app',
    downloadScanForPreviewFromApp : URL_API_FILE + '/scan_document/download_preview_from_app',   
  },
  URL_ASSET_SCAN: {    
    getScans : URL_API + '/scan_asset/scans',
    getAssetNotificationScans : URL_API + '/scan_asset/notification_scans',
    uploadScan : URL_API_FILE + '/scan_asset/upload',
    uploadAssetNotificationScan : URL_API_FILE + '/scan_asset/upload_notification_scan',
    getInfoScan : URL_API + '/scan_asset/info',
    downloadScanFromApp : URL_API_FILE + '/scan_asset/download_from_app',
    downloadScanForPreviewFromApp : URL_API_FILE + '/scan_asset/download_preview_from_app',
  },
  URL_DECONT_SCAN: {    
    getScansDeconturi : URL_API + '/scan_decont/scan_deconturi',    
    getScansDeconturiDet : URL_API + '/scan_decont/scan_deconturi_det',    
    uploadScanDecont : URL_API_FILE + '/scan_decont/uploaddecont',
    uploadScanDecontDet : URL_API_FILE + '/scan_decont/uploaddecontdet',
    getInfoScan : URL_API + '/scan_decont/info',
    downloadScanFromApp : URL_API_FILE + '/scan_decont/download_from_app',
    downloadScanForPreviewFromApp : URL_API_FILE + '/scan_decont/download_preview_from_app',
  },
  URL_PROIECT_SCAN: {    
    getScansProiecte : URL_API + '/scan_proiect/scan_proiecte',    
    getScansProiecteEtape : URL_API + '/scan_proiect/scan_proiecte_etape',    
    getScansProiecteTaskuri : URL_API + '/scan_proiect/scan_proiecte_taskuri',
    getScansProiecteTaskuriNotite : URL_API + '/scan_proiect/scan_proiecte_taskuri_notite',    
    uploadScanProiect : URL_API_FILE + '/scan_proiect/uploadproiect',
    uploadScanProiectEtapa : URL_API_FILE + '/scan_proiect/uploadproiectetapa',
    uploadScanProiectTask : URL_API_FILE + '/scan_proiect/uploadproiecttask',
    uploadScanProiectTaskNota : URL_API_FILE + '/scan_proiect/uploadproiecttasknota',
    getInfoScan : URL_API + '/scan_proiect/info',
    downloadScanFromApp : URL_API_FILE + '/scan_proiect/download_from_app',
    downloadScanForPreviewFromApp : URL_API_FILE + '/scan_proiect/download_preview_from_app',
  },
  URL_CERERE_SCAN: {    
    getScansCereri : URL_API + '/scan_cerere/scan_cereri',    
    uploadScanCerere : URL_API_FILE + '/scan_cerere/uploadcerere',
    getInfoScan : URL_API + '/scan_cerere/info',
    downloadScanFromApp : URL_API_FILE + '/scan_cerere/download_from_app',
    downloadScanForPreviewFromApp : URL_API_FILE + '/scan_cerere/download_preview_from_app',
  },
  URL_NOMDEPLASARE_SCAN: {    
    getScansNomDeplasari : URL_API + '/scan_nom_deplasare/scan_nom_deplasari',    
    uploadScanDeplasare : URL_API_FILE + '/scan_nom_deplasare/uploadnomdeplasare',
    getInfoScan : URL_API + '/scan_nom_deplasare/info',
    downloadScanFromApp : URL_API_FILE + '/scan_nom_deplasare/download_from_app',
    downloadScanForPreviewFromApp : URL_API_FILE + '/scan_nom_deplasare/download_preview_from_app',
  },
  URL_REPARTIZARE: {
    getRepartizari : URL_API + '/repartizare/repartizari_doc',
    getRepartizariHistoric : URL_API + '/repartizare/repartizari_historic_doc'
  },
  URL_RESTRICTII_CONCEDIU: {
    restrictii: URL_API + '/restrictii_concediu/get_restrictii',
    deleteRestriciteiConcediu: URL_API + '/restrictii_concediu/delete_restrictii',
    editRestrictieConcediu: URL_API + '/restrictii_concediu/edit_restrictii',
  },
  URL_ASSETS: {
    categorieActive : URL_API + '/nom_categ_active/categorie_active',
    categoriiActive : URL_API + '/nom_categ_active/categorii_active',
    tip_active : URL_API + '/nom_tip_active/tip_active',
    jobs : URL_API + '/nom_jobs/get_jobs',
    deleteJob: URL_API + '/nom_jobs/job',
    editJob: URL_API + '/nom_jobs/job',
    tip_actives : URL_API + '/nom_tip_active/tip_actives',
    tip_evenimente: URL_API + '/nom_tip_evenimente/nom_tip_evenimente',
    asset : URL_API + '/assets/asset',
    assets : URL_API + '/assets/assets',
    getXlsAssetsByConditions : URL_API + '/assets/xls_assets_by_condition',
    getPdfAssetsByConditions : URL_API + '/assets/pdf_assets_by_condition',
    imgForGallery : URL_API_FILE + '/assets/gallery/img',
    qr_code : URL_API + '/assets/qr_code',
    update_asset : URL_API + '/assets/update',
    asset_notifications : URL_API + '/asset_notifications/asset_notifications',
    asset_notification : URL_API + '/asset_notifications/asset_notification',
    assets_notification_list : URL_API + '/asset_notifications/assets_notification_list',
    asset_notifications_nr : URL_API + '/asset_notifications/asset_notifications_nr',
    update_asset_notification: URL_API + '/asset_notifications/update_asset_notification',
    getXlsAssetsNotificationsByConditions : URL_API + '/asset_notifications/xls_assets_notification_by_condition',
  },
  URL_ENTITATE_ACCES: {
    entitati : URL_API + '/nom_entity_acces/get_entitys',
    deleteEntity: URL_API + '/nom_entity_acces/entity',
    editEntity: URL_API + '/nom_entity_acces/entity',
  },
  URL_TARI: {    
    tari : URL_API + '/tari/tari',    
  }, 
  URL_JUDETE: {    
    judete : URL_API + '/judete/judete',
    judet : URL_API + '/judete/judet',    
  },
  URL_LOCALITATI: {    
    localitati : URL_API + '/localitati/localitati',
    localitate : URL_API + '/localitati/localitate',    
  },  
  URL_ADRESE: {
    adresa : URL_API + '/adrese/adresa',
    adrese : URL_API + '/adrese/adrese',    
    adrese_filter : URL_API + '/adrese/adrese_filter',    
  },
  URL_PROIECTE: {
    proiect : URL_API + '/proiecte/proiect',
    proiect_detail : URL_API + '/proiecte/proiectdetail',
    proiecte : URL_API + '/proiecte/proiecte',   
    qr_code : URL_API + '/proiecte/qr_code', 
    proiecte_etape : URL_API + '/proiecte/proiecte_etape',   
    proiect_etapa : URL_API + '/proiecte/proiect_etapa',   
    proiecte_taskuri : URL_API + '/proiecte/proiecte_taskuri',   
    proiect_task : URL_API + '/proiecte/proiect_task',
    proiect_task_nr: URL_API + '/proiecte/proiect_task_nr',
    my_proiecte_taskuri : URL_API + '/proiecte/my_proiecte_taskuri',
    all_proiecte_taskuri : URL_API + '/proiecte/all_proiecte_taskuri',
    proiect_task_detail : URL_API + '/proiecte/proiect_task_detail',
    proiecte_taskuri_pontari: URL_API + '/proiecte/proiecte_taskuri_pontari',
    proiecte_taskuri_pontare: URL_API + '/proiecte/proiecte_taskuri_pontare',
    proiecte_taskuri_notite: URL_API + '/proiecte/proiecte_taskuri_notite',
    proiecte_taskuri_nota: URL_API + '/proiecte/proiecte_taskuri_nota',
    proiectecombo : URL_API + '/proiecte/proiectecombo',
    proiectetaskuricombo: URL_API + '/proiecte/proiectetaskuricombo',
    proiecte_taskuri_pontari_view: URL_API + '/proiecte/proiecte_taskuri_pontari_view',
    xls_proiecte_taskuri_pontari : URL_API + '/proiecte/xls_proiecte_taskuri_pontari'
  },
  URL_REFERATE: {    
    referat : URL_API + '/referate/referat',    
    referate : URL_API + '/referate/referate',  
    referatserviciu : URL_API + '/referate/referatserviciu', 
    referatservicii : URL_API + '/referate/referatservicii',      
  },
  URL_CERERI: {    
    cerere : URL_API + '/cereri/cerere',    
    cereri : URL_API + '/cereri/cereri',
  },
  URL_CONTURI: {    
    conturi : URL_API + '/conturi/conturi',    
  },
  URL_DEPLASARI: {
    deplasare : URL_API + '/nom_deplasari/deplasare',
    deplasari : URL_API + '/nom_deplasari/deplasari',
    deplasaricombo : URL_API + '/nom_deplasari/deplasaricombo', 
    getPageWithDeplasari : URL_API + '/reportdeplasari/get_page_with_deplasari',
    getXLSByConditionDeplasari : URL_API + '/reportdeplasari/xls_by_condition_deplasari',
    downloadScanForPreviewFromApp : URL_API + '/nom_deplasari/download_preview_from_app',
  },
  URL_FOI_PARCURS: {
    foaieParcurs : URL_API + '/foi_parcurs/foaie_parcurs',
    foiParcurs : URL_API + '/foi_parcurs/foi_parcurs',
    downloadScanForPreviewFromApp : URL_API + '/foi_parcurs/foaie_parcurs/download_preview_from_app',
  },
  URL_DEPLASARI_USERS: {
    deplasare_user : URL_API + '/nom_deplasari_users/deplasare_user',
    deplasari_users : URL_API + '/nom_deplasari_users/deplasari_users',    
  }, 
  URL_DECONTURI: {
    decont : URL_API + '/nom_deconturi/decont',
    deconturi : URL_API + '/nom_deconturi/deconturi',
    update_decont : URL_API + '/nom_deconturi/update',
    deconturi_det : URL_API + '/nom_deconturi/deconturi_det',   
    decont_det : URL_API + '/nom_deconturi/decont_det',      
  },
  URL_TIME_REQUESTS: {
    requests : URL_API + '/my_time_request/requests',
    myRequests : URL_API + '/my_time_request/myrequests',
    allRequests : URL_API + '/my_time_request/allrequests',
    getWorkingDays: URL_API + '/my_time_request/working_days',    
    getPageWithTimesRequestsCO : URL_API + '/reporttimesrequests/get_page_with_timesrequests_co',
    getPageWithTimesRequestsCO_HR : URL_API + '/reporttimesrequests/get_page_with_timesrequests_co_hr',
    getXLSByConditionTimesRequestsCO : URL_API + '/reporttimesrequests/xls_by_condition_timesrequests_co',
    getXLSByConditionTimesRequestsCO_HR : URL_API + '/reporttimesrequests/xls_by_condition_timesrequests_co_hr',
    getPageWithTimesRequestsEveniment : URL_API + '/reporttimesrequests/get_page_with_timesrequests_eveniment',
    getXLSByConditionTimesRequestsEveniment : URL_API + '/reporttimesrequests/xls_by_condition_timesrequests_eveniment',
    downloadScanFromApp : URL_API + '/my_time_request/download_from_app',
    downloadScanForPreviewFromAppCO : URL_API + '/my_time_request/download_preview_from_app_co', //URL_API_FILE
    downloadScanForPreviewFromAppRechemare : URL_API + '/my_time_request/download_preview_from_app_rechemare', //URL_API_FILE
    getPageWithTimesRequestsPlanAnual : URL_API + '/reporttimesrequests/get_page_with_timesrequests_plananual',
    getXLSByConditionTimesRequestsPlanAnual : URL_API + '/reporttimesrequests/xls_by_condition_timesrequests_plananual',
    getPDFByConditionTimesRequestsPlanAnual : URL_API + '/reporttimesrequests/pdf_by_condition_timesrequests_plananual',
    updateStatusPlanReqularAnual: URL_API + '/my_time_request/update_status_plan_reqular_anual',
    sendAprobarePlanAnualConcediu: URL_API + '/my_time_request/send_approve_all_plan_timesrequests'
  },
  URL_REPORTS: {
    getXlsByConditions : URL_API + '/reports/xls_by_condition',
    getPdfByConditions : URL_API + '/reports/pdf_by_condition',
    getXlsNomUsers : URL_API + '/reports/xls_nom_users'
  },
  URL_PONTAJE: {
    getXlsByConditions : URL_API + '/turnicheti/xls_by_condition',
    getPdfByConditions : URL_API + '/turnicheti/pdf_by_condition',
    getPontaje : URL_API + '/turnicheti/log'
  },
  URL_NOMCONCEDIU: {
    getNomConcediu : URL_API + '/nom_concediu/concedii'
  },
  URL_NOMTIPDEPLASARE: {
    getNomTipDeplasare : URL_API + '/nom_tip_deplasari/tip_deplasari'
  },
  URL_NOMTIPPONTAJPREZENTA: {
    getNomTipPontajePrezente : URL_API + '/nom_tip_pontajprezenta/tip_pontajeprezente'
  },
  URL_NOMTIPMIJLOCDETRANSPORT: {
    getNomTipMijlocDeTransport : URL_API + '/nom_tip_mijlocdetransport/tip_mijlocdetransport'
  },
  URL_TIMEACCOUNTS: {
    timeAccount : URL_API + '/time_accounts/timeaccount',
    timeAccounts : URL_API + '/time_accounts/timeaccounts',
    preluareDrepturiCO : URL_API + '/time_accounts/preluare_drepturi_co',
    recalculareDrepturiCO : URL_API + '/time_accounts/recalculare_drepturi_co',
  },
  URL_NOMZILIBEREOFICIALE: {
    nomZiLibereOficial : URL_API + '/nom_zi_libere_oficiale/nom_zi_libere_oficial',
    nomZiLibereOficiale : URL_API + '/nom_zi_libere_oficiale/nom_zi_libere_oficiale',
  },
  URL_NOMARHIVE: {
    nomArhivaCladire : URL_API + '/nom_arhiva_cladiri/arhiva_cladire',
    nomArhivaCladiri : URL_API + '/nom_arhiva_cladiri/arhiva_cladiri',
    nomArhivaCamere : URL_API + '/nom_arhiva_camere/arhiva_camere',
    nomArhivaCamera : URL_API + '/nom_arhiva_camere/arhiva_camera',
    nomArhivaDulapuri : URL_API + '/nom_arhiva_dulapuri/arhiva_dulapuri',
    nomArhivaDulap : URL_API + '/nom_arhiva_dulapuri/arhiva_dulap',
    nomArhivaRafturi : URL_API + '/nom_arhiva_rafturi/arhiva_rafturi',
    nomArhivaRaft : URL_API + '/nom_arhiva_rafturi/arhiva_raft',
    nomArhivaBiblioRafturi : URL_API + '/nom_arhiva_bibliorafturi/arhiva_bibliorafturi',
    nomArhivaBiblioRaft : URL_API + '/nom_arhiva_bibliorafturi/arhiva_biblioraft',
  }, 
  URL_ARHIVE: {    
    arhiva : URL_API + '/arhive/arhiva',    
    arhiv_arhive : URL_API + '/arhive/arhive',
    arhiv_documente : URL_API + '/arhive/documente'
  }, 
  URL_POARTA: {    
    porti : URL_API + '/porti/porti'
  }, 
  URL_ALOCARE_ENTITATE_ACCES: {
    alocareEntitati : URL_API + '/alocari_entitate_acces/alocare',
    deleteAlocareEntity: URL_API + '/alocari_entitate_acces/alocare',
    editAlocareEntity: URL_API + '/alocari_entitate_acces/alocare',
    qrCode: URL_API + '/alocari_entitate_acces/qr_code_alocare',
    downloadPDFQuadCode: URL_API + '/alocari_entitate_acces/download_pdf_quadcode',
    downloadPNGQuadCode: URL_API + '/alocari_entitate_acces/download_png_quadcode',
    sendMailQuadCode: URL_API + '/alocari_entitate_acces/sendmail_quadcode',
    sendToThermalPrinterQuadCode: URL_API + '/alocari_entitate_acces/send_tothermalprinterquadcode'
  },
  URL_PONTAJ_PROIECT: {
    pontajeProiect : URL_API + '/pontaj_proiect/proiect_user',
    pontajeProiecte : URL_API + '/pontaj_proiect/proiecte',
    getPontajProiect : URL_API + '/pontaj_proiect/get_proiect',
    deletePontajProiect: URL_API + '/pontaj_proiect/proiect',
    editPontajProiect: URL_API + '/pontaj_proiect/proiect',
    getPageWithPontajProiecte : URL_API + '/reportpontajproiecte/get_page_with_pontajproiecte',
    getXLSByConditionPontajProiecte : URL_API + '/reportpontajproiecte/xls_by_condition_pontajproiecte',
    getPDFByConditionPontajProiecte : URL_API + '/reportpontajproiecte/pdf_by_condition_pontajproiecte',
  },
  URL_PONTAJ_PREZENTA: {
    pontajePrezenta : URL_API + '/pontaj_prezenta/prezenta_user',
    pontajePrezente : URL_API + '/pontaj_prezenta/prezente',
    getPontajPrezenta : URL_API + '/pontaj_prezenta/get_prezenta',
    deletePontajPrezenta: URL_API + '/pontaj_prezenta/prezenta',
    editPontajPrezenta: URL_API + '/pontaj_prezenta/prezenta',
    getMonthInfo : URL_API + '/pontaj_prezenta/month_info',
    getPersonsMonthStatus : URL_API + '/pontaj_prezenta/persons_month_status',    
    OvertimeAccountMarca: URL_API + '/pontaj_prezenta/overtime_account_marca',
    getPageWithPontajPrezenta : URL_API + '/reportpontajprezenta/get_page_with_pontajprezenta',
    getXLSByConditionPontajPrezenta : URL_API + '/reportpontajprezenta/xls_by_condition_pontajprezenta',
    getXLSByConditionPontajPrezentaLunar : URL_API + '/pontaj_prezenta/xls_by_condition_pontajprezenta_lunar',
    getMonths: URL_API + '/pontaj_prezenta/months',
  },
  URL_EVENIMENTE: {
    eveniment : URL_API + '/evenimente/eveniment',
    evenimente : URL_API + '/evenimente/evenimente',    
  },
  URL_EVENIMENTE_SCAN: {    
    getScansEvenimente : URL_API + '/scan_evenimente/scan_evenimente',    
    uploadScanEvenimente : URL_API_FILE + '/scan_evenimente/uploadeveniment',
    getInfoScan : URL_API + '/scan_evenimente/info',
    downloadScanFromApp : URL_API_FILE + '/scan_evenimente/download_from_app',
    downloadScanForPreviewFromApp : URL_API_FILE + '/scan_evenimente/download_preview_from_app',
  },
  URL_CLIENTI: {
    client : URL_API + '/clienti/client',
    clienti : URL_API + '/clienti/clienti',
  },
  URL_CLIENTI_SCAN: {    
    getScansClienti : URL_API + '/scan_clienti/scan_clienti',    
    uploadScanClienti : URL_API_FILE + '/scan_clienti/uploadclient',
    getInfoScan : URL_API + '/scan_clienti/info',
    downloadScanFromApp : URL_API_FILE + '/scan_clienti/download_from_app',
    downloadScanForPreviewFromApp : URL_API_FILE + '/scan_clienti/download_preview_from_app',
  },
  URL_TIME_REQUESTS_SCAN: {
    scans : URL_API + '/scan_time_request/all_scans_for_time_request',
    uploadScan : URL_API_FILE + '/scan_time_request/upload',
    deleteScan : URL_API + '/scan_time_request',
    getInfoScan : URL_API + '/scan_time_request/info',
    downloadScanFromApp : URL_API_FILE + '/scan_time_request/download_from_app',
    downloadScanForPreviewFromApp : URL_API_FILE + '/scan_time_request/download_preview_from_app',
  },
  URL_PONTAJPREZENTA_SCAN: {
    getScansPontajePrezenta : URL_API + '/scan_pontaj_prezenta/scan_pontaje',
    uploadScanPrezenta : URL_API_FILE + '/scan_pontaj_prezenta/upload_pontaj',
    getInfoScan : URL_API + '/scan_pontaj_prezenta/info',
    downloadScanFromApp : URL_API_FILE + '/scan_pontaj_prezenta/download_from_app',
    downloadScanForPreviewFromApp : URL_API_FILE + '/scan_pontaj_prezenta/download_preview_from_app',
  },
  URL_REGISTRU_DREPT: {    
    registruDrepturi : URL_API + '/registru_drept/registru_drepturi',
    userDrepturi : URL_API + '/registru_drept/user_drepturi',
    userRegistruDrepturi : URL_API + '/registru_drept/user_registru_drepturi'
  },
  URL_BI_ANALITYCS: {    
    getBIAnalitycsChartCardsData : URL_API + '/biAnalitycs/getChartCardsData'
  },
  URL_PERSOANE_PROGRAM_LUCRU_SPECIAL: {
    persoane_program_lucru_special : URL_API + '/persoane_program_lucru_special/persoane_program_lucru_special'
  },
  URL_FILTERBYUSER: {
    filterbyuser : URL_API + '/filterbyuser/filterbyuser'
  },
  URL_MAIL: {
    save : URL_API + '/campanii_mail/mail',
    mail : URL_API + '/campanii_mail/mail',
    sentMail : URL_API + '/campanii_mail/sent_mail',
    testLaMine : URL_API + '/campanii_mail/mail/test_la_mine',
    addPersonToMail : URL_API + '/campanii_mail/mail/add_person',
    addMarcaToMail : URL_API + '/campanii_mail/mail/add_marca',
    getHeaderMails : URL_API + '/campanii_mail/mail/headers',
    getDestinatari :  URL_API + '/campanii_mail/mail/destinatari',
    loadXLSDestinatari :  URL_API + '/campanii_mail/mail/load_xls_destinatari',
    lanseazaCampania :  URL_API + '/campanii_mail/mail/lanseaza_campania',
    statusTrimitere :  URL_API + '/campanii_mail/mail/status_trimitere',
    getListaDestinatariRezolvati :  URL_API + '/campanii_mail/mail/lista_destinatari_rezolvati',
    getListaAtasament :  URL_API + '/campanii_mail/mail/lista_atasamente',
    deleteAtasament :  URL_API + '/campanii_mail/mail/lista_atasamente',
    golesteListaDestinatari :  URL_API + '/campanii_mail/mail/goleste_lista_destinatari',
    stergeDestinatar :  URL_API + '/campanii_mail/mail/sterge_destinatar',
    sendTestForDestinatarToMer :  URL_API + '/campanii_mail/mail/test_destinatar_to_me',
    previzualizare :  URL_API + '/campanii_mail/mail/previzualizare',
    updateTermenCampanie :  URL_API + '/campanii_mail/mail/update_termen_campanie',
    updateDescriereCampanie :  URL_API + '/campanii_mail/mail/update_descriere_campanie',
    token :  URL_API + '/campanii_mail/mail/token_campanie',
  },
  URL_NOMUNITATIMASURA: {
    nomUnitatiMasura : URL_API + '/nom_unitati_masura',
  },
  URL_NOMMONEDE: {
    nomMonede : URL_API + '/nom_monede',
  },
  URL_NOMTIPFIRMA: {
    nomTipFirma : URL_API + '/nom_tip_firma',
  },
  URL_NOMTVA: {
    nomTVA : URL_API + '/nom_tva',
  },
  URL_PARTENERI: {
    parteneri : URL_API + '/parteneri',
    partenerConturi : URL_API + '/partener_conturi',
  },
  URL_PARTENERI_SCAN: {
    getScans : URL_API + '/scan_partener/scans',
    uploadScan : URL_API_FILE + '/scan_partener/upload',
    getInfoScan : URL_API + '/scan_partener/info',
    downloadScanFromApp : URL_API_FILE + '/scan_partener/download_from_app',
    downloadScanForPreviewFromApp : URL_API_FILE + '/scan_partener/download_preview_from_app',
  },
  URL_SETARICOMPANIE: {
    setariCompanie : URL_API + '/setari_companie',
    setariCompanieConturi : URL_API + '/setari_companie_conturi',
    logo : URL_API + '/setari_companie/logo',
  },
  URL_SETARICOMPANIE_SCAN: {
    getScans : URL_API + '/scan_setari_companie/scans',
    uploadScan : URL_API_FILE + '/scan_setari_companie/upload',
    getInfoScan : URL_API + '/scan_setari_companie/info',
    getInfoScanLogo : URL_API + '/scan_setari_companie/logo',
    downloadScanFromApp : URL_API_FILE + '/scan_setari_Companie/download_from_app',
    downloadScanForPreviewFromApp : URL_API_FILE + '/scan_setari_companie/download_preview_from_app',
  },
  URL_FACTURI: {
    facturi : URL_API + '/facturi',
    qr_code : URL_API + '/facturi/qr_code',
    factura_articole : URL_API + '/factura_articole',
    downloadPDFFactura: URL_API + '/facturi/factura/download_pdf_factura',
  },
  URL_HRMS: {
    position : URL_API + '/hrms/position',
    FisaPersonalaForPerson : URL_API + '/hrms/fisa_personala/for_person_id',
    AdresaDomiciliuForPerson : URL_API + '/hrms/adresa_domiciliu/for_person_id',
    ProgramariMedicinaMunciiForPerson : URL_API + '/medicina_muncii/programari/for_person_id',
    positionsForOrgId : URL_API + '/hrms/positions_for_org_id',
    positionsByFilter : URL_API + '/hrms/positions_by_filter',
    downloadPositionsXLS : URL_API + '/hrms/positions_xlsx',
    InfoPersonForStartAssig : URL_API + '/hrms/info_person_for_start_assig',
    nom_jobs : URL_API + '/hrms/position',
    simpleOrg : URL_API + '/hrms/simple_org',
    orgDesign : URL_API + '/hrms/orgdesign',
    nomISCED : URL_API + '/hrms/isced',
    getPeople : URL_API + '/hrms/get_people',
    person : URL_API + '/hrms/person',
    personISCED : URL_API + '/hrms/person/isced',
    personTelefon : URL_API + '/hrms/person/telefon',
    personCI : URL_API + '/hrms/buletin_ci/person',
    assignment : URL_API + '/hrms/assignment',
    istoricAssignmentsPerPost : URL_API + '/hrms/istoric_assignment_for_position',
    istoricPersonId : URL_API + '/hrms/istoric_positions_for_person_id',
    StopAssignment : URL_API + '/hrms/stop_assignment',
    vassignmentByPosition : URL_API + '/hrms/vassignment_for_position',
    searchCodCOR : URL_API + '/hrms/search_cod_cor',
    getDenumireCodCOR : URL_API + '/hrms/get_denumire_cod_cor',
    PerformancePeople : URL_API + '/hrms/performance_track/people',
    PerformancePerson : URL_API + '/hrms/performance_track/person',
    PerformanceTrack : URL_API + '/hrms/performance_track',
    PerformanceTrackObiectiv : URL_API + '/hrms/performance_track/obiectiv',
    UpdatePerformanceTrackRealizare : URL_API + '/hrms/performance_track/update_procent_realizare',
    Photo : URL_API + '/hrms/person/photo',
    DocumenteHR : URL_API + '/hrms/documente_hr',
    VisibilitateDocumentHR : URL_API + '/hrms/visibilitate_document_hr',
    UploadDocumenteHR : URL_API + '/hrms/upload_document_hr',
  },
  URL_POSTURI: {
    post : URL_API + '/posturi/post',
    posturi : URL_API + '/posturi/posturi',
  },
  URL_MEDICINA_MUNCII: {
    programare : URL_API + '/medicina_muncii/programare',
    programari : URL_API + '/medicina_muncii/programari',
    downloadProgramariXLS : URL_API + '/medicina_muncii/programari_xls',
  },
  URL_DOCUMENTEXTENDED: {
    documentExtendedCertificateUrbanism : URL_API + '/documenteextended/documentextendedcertificateurbanism',
    documenteExtendedCertificateUrbanism : URL_API + '/documenteextended/documenteextendedcertificateurbanism',
    downloadScanFromAppDocCertificateUrbanism: URL_API + '/documenteextended/download_from_appdoccertificateurbanism',
    downloadScanForPreviewFromAppDocCertificateUrbanism: URL_API + '/documenteextended/download_preview_from_appdoccertificateurbanism',
    documentExtendedUpdateCertificateUrbanism: URL_API + '/documenteextended/update_documenteextendedcertificateurbanism',
    documentExtendedServiciiDeTransport : URL_API + '/documenteextended/documentextendedserviciidetransport',
    documenteExtendedServiciiDeTransport : URL_API + '/documenteextended/documenteextendedserviciidetransport',
    downloadScanFromAppDocServiciiDeTransport: URL_API + '/documenteextended/download_from_appdocserviciidetransport',
    downloadScanForPreviewFromAppDocServiciiDeTransport: URL_API + '/documenteextended/download_preview_from_appdocserviciidetransport',
    documentExtendedUpdateServiciiDeTransport: URL_API + '/documenteextended/update_documenteextendedserviciidetransport'
  },
  myLocale: {
    /* starting with Sunday */
    days: 'Duminica_Luni_Marti_Miercuri_Joi_Vineri_Sambata'.split('_'),
    daysShort: 'Dum_Lun_Mar_Mie_Joi_Vie_Sam'.split('_'),
    months: 'Ianuarie_Februarie_Martie_Aprilie_Mai_Iunie_Iulie_August_Septembrie_Octombrie_Noiembrie_Decembrie'.split('_'),
    monthsShort: 'Ian_Feb_Mar_Apr_Mai_Iun_Iul_Aug_Sep_Oct_Nov_Dec'.split('_'),
    firstDayOfWeek: 1
  }
};

export const FirebaseConfig = {
  apiKey: "AIzaSyD1eT6mfGU3EZ4NfkY2gq3YhwZXc3hh144",
  authDomain: "odocs-7676a.firebaseapp.com",
  projectId: "odocs-7676a",
  storageBucket: "odocs-7676a.appspot.com",
  messagingSenderId: "376475560121",
  appId: "1:376475560121:web:b6458c82fb080ceadf5368",
  measurementId: "G-LHFLW16H1L"
};
