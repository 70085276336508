import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import IDocumenteCertificateUrbanism from '@/types/IDocumenteCertificateUrbanism';
import IDocumenteServiciiDeTransport from '@/types/IDocumenteServiciiDeTransport';
import IDocumenteExtendedSearchParams from '@/types/IDocumenteExtendedSearchParams';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type IDocumenteCertificateUrbanismNull = OrNull<IDocumenteCertificateUrbanism>;
type IDocumenteServiciiDeTransportNull = OrNull<IDocumenteServiciiDeTransport>;

interface TAjaxResponseDocumenteCertificateUrbanism {
  status: string;
  message: string;
  totalNrOfPages: number;
	currentPage: number;
	nrRecordsPerPage: number;
	totalRecords: number;
  rows: IDocumenteCertificateUrbanism[];
}

interface TAjaxResponseGetDocumenteCertificateUrbanism {
  status: string;
  message: string;
  documenteCertificateUrbanism: IDocumenteCertificateUrbanismNull;
}

interface TAjaxResponseDocumenteServiciiDeTransport {
  status: string;
  message: string;
  totalNrOfPages: number;
	currentPage: number;
	nrRecordsPerPage: number;
	totalRecords: number;
  rows: IDocumenteServiciiDeTransport[];
}

interface TAjaxResponseGetDocumenteServiciiDeTransport {
  status: string;
  message: string;
  documenteServiciiDeTransport: IDocumenteServiciiDeTransportNull;
}

interface TAjaxResponseEdit {
  status: string;
  message: string;
  appid: number;
}

export class ServiceDocumentExtended {
  public static async getDocumenteCertificateUrbanism(nrRanduriTabel: number, pageNumber: number, searchParams: IDocumenteExtendedSearchParams): Promise<TAjaxResponseDocumenteCertificateUrbanism> {    
    const response = await axios.post(`${CONFIG_ENV.URL_DOCUMENTEXTENDED.documenteExtendedCertificateUrbanism}/${nrRanduriTabel}/${pageNumber}`, {searchParams});
    return response.data;
  }

  public static async postDocumentCertificateUrbanism(pIDocumenteCertificateUrbanism: IDocumenteCertificateUrbanism): Promise<TAjaxResponseEdit> {
    const response = await axios.post(`${CONFIG_ENV.URL_DOCUMENTEXTENDED.documentExtendedCertificateUrbanism}`, pIDocumenteCertificateUrbanism);
    return response.data;
  }

  public static async getDocumentCertificateUrbanism(appid: number): Promise<TAjaxResponseGetDocumenteCertificateUrbanism> {
    const response = await axios.get(`${CONFIG_ENV.URL_DOCUMENTEXTENDED.documentExtendedCertificateUrbanism}/${appid}`);
    return response.data;
  }

  public static async postUpdateCertificateUrbanismStatus(pIDocumenteCertificateUrbanism: IDocumenteCertificateUrbanism): Promise<TAjaxResponseEdit> {
    const response = await axios.post(`${CONFIG_ENV.URL_DOCUMENTEXTENDED.documentExtendedUpdateCertificateUrbanism}`, pIDocumenteCertificateUrbanism);
    return response.data;
  }

  public static async getDocumenteServiciiDeTransport(nrRanduriTabel: number, pageNumber: number, searchParams: IDocumenteExtendedSearchParams): Promise<TAjaxResponseDocumenteServiciiDeTransport> {    
    const response = await axios.post(`${CONFIG_ENV.URL_DOCUMENTEXTENDED.documenteExtendedServiciiDeTransport}/${nrRanduriTabel}/${pageNumber}`, {searchParams});
    return response.data;
  }

  public static async postDocumentServiciiDeTransport(pDocumenteServiciiDeTransport: IDocumenteServiciiDeTransport): Promise<TAjaxResponseEdit> {
    const response = await axios.post(`${CONFIG_ENV.URL_DOCUMENTEXTENDED.documentExtendedServiciiDeTransport}`, pDocumenteServiciiDeTransport);
    return response.data;
  }

  public static async getDocumentServiciiDeTransport(appid: number): Promise<TAjaxResponseGetDocumenteServiciiDeTransport> {
    const response = await axios.get(`${CONFIG_ENV.URL_DOCUMENTEXTENDED.documentExtendedServiciiDeTransport}/${appid}`);
    return response.data;
  }

  public static async postUpdateServiciiDeTransportStatus(pDocumenteServiciiDeTransport: IDocumenteServiciiDeTransport): Promise<TAjaxResponseEdit> {
    const response = await axios.post(`${CONFIG_ENV.URL_DOCUMENTEXTENDED.documentExtendedUpdateServiciiDeTransport}`, pDocumenteServiciiDeTransport);
    return response.data;
  }
}