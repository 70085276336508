import {defineComponent} from 'vue';
import Nomenclatoare from '@/store/Nomenclatoare';
import {getModule} from 'vuex-module-decorators';
import IOrgUnit from "@/types/IOrgUnit";
import {getRowNumberTable} from '@/modules/Utils';

export default defineComponent({
  name: 'ComboOrgUnit',

  props: {    
    selectHandler: {
      type: Function,
      default: () => {}
    },
    title: {
      type: String,
      required: true,
      default: 'Org Unit',
      readonly: true
    }
  },

  data() {
    return {
      loadingData: false,
      filterOrgUnits: '',
      nomenclatoareStore: getModule(Nomenclatoare),
      myPagination: {
        rowsPerPage: getRowNumberTable()
      },
      visibleColumns: ['organization_unit_descriere', 'tip_org_unit_id', 'parent_organization_unit_descriere', 'parent_tip_org_unit_id'],
      columns: [
        { name: 'organization_unit_descriere', label: 'Denumire OrgUnit', field: 'organization_unit_descriere', align: 'left', sortable: true },
        { name: 'tip_org_unit_id', label: 'Tip', field: 'tip_org_unit_id', align: 'left', sortable: true},
        { name: 'parent_organization_unit_descriere', label: 'Apartine de', field: 'parent_organization_unit_descriere', align: 'left', sortable: true },
        { name: 'parent_tip_org_unit_id', label: 'Tip directie', field: 'parent_tip_org_unit_id', align: 'left', sortable: true},
      ],
      tableDataOrgUnits: [] as IOrgUnit[]
    }
  },

  methods: {    
    getDenTip(pIdTip: string): string{
      const vueInst = this;
      let result = '';
      const Tip = vueInst.nomenclatoareStore.NomOrgUnitsTypes.find( tip =>{
        return tip.tip_org == pIdTip;
      });
      if(Tip){
        result = Tip.tip_org;
      }
      return result;
    },
  },

  mounted() {
    const vueInst = this;
    vueInst.loadingData = true;
    vueInst.tableDataOrgUnits = vueInst.nomenclatoareStore.NomOrgUnits;
    vueInst.loadingData = false;
  }
});
