<template>
    <div class="q-pa-sm">
        <q-spinner-gears
            v-if="loadingPartenerData"
            color="primary"
            size="4em"
            class="absolute-center"
        />
        <div v-else>
            <div class="well well-sm">               
                <div class="row">
                    <div class="col-md-7" style="padding-right: 5px;">
                        <table class="table table-nocondensed">
                            <tbody>
                                <tr>
                                    <td class="text__title--medium">
                                        <div style="display: flex;">
                                            <span>Denumire:</span>
                                        </div>
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{partener.denumire}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text__title--medium">
                                        <div style="display: flex;">
                                            <span>Status:</span>
                                        </div>
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{getDenStatus(partener.is_activ as string)}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text__title--medium">
                                        <div style="display: flex;">
                                            <span>CUI:</span>
                                        </div>
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{partener.cui}}
                                    </td>
                                </tr> 
                                <tr>
                                    <td class="text__title--medium">
                                        Reg.comert:
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{partener.reg_comert}}
                                    </td>
                                </tr>                      
                               
                                <tr>
                                    <td class="text__title--medium">
                                        Tip partener:
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{getDenTip(partener.tip as string)}}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text__title--medium">
                                        Tip firma:
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{ partener.tip_firma }}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text__title--medium">
                                        Tip organizatie:
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{ partener.tip_organizatie }}
                                    </td>
                                </tr>    

                                <tr>
                                    <td class="text__title--medium">
                                        Platitor TVA:
                                    </td>
                                    <td class="text__input--medium bg-white">
                                       {{ getDenIsTva(partener.is_tva) }}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text__title--medium">
                                        Adresa:
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{ partener.adresa }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text__title--medium">
                                        Nume/prenume contact:
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{ partener.nume_prenume_contact }}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text__title--medium">
                                        Functia:
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{ partener.functia }}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text__title--medium">
                                        Telefon Fix:
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{ partener.numar_telefon_fix }}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text__title--medium">
                                        Telefon Mobil:
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{ partener.numar_telefon_mobil }}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text__title--medium">
                                        Email:
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{ partener.email }}
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>                        
                    </div>

                    <div class="col-md-5" style="padding-left:2em;display: flex;flex-direction:column; justify-content: flex-start; align-items: center;">
                      

                        <div style="display: flex;align-items: center;justify-content: flex-start; width: 100%;">
                            <div>
                                <span class="text__title--medium">Documente</span>
                                <div v-show="partener.scan_documents != null && partener.scan_documents.length==0 && !uploadingFileToServer" class="text__label--thin" style="width: 100%; text-align: center;">Nimic atasat</div>
                                <!-- <div v-show="assetApp.scan_documents != null && assetApp.scan_documents.length==0">Pentru a finaliza inregistrarea este necesara incarcarea fisierului cu documentul scanat.</div> -->
                            </div>
                            <div v-if="user.auth_for_upload_scan=='y' ">
                                <q-btn flat color="primary" icon="add_box" no-caps :disable="loadingPartenerData" @click="openFormUploadFile('y')" >
                                    <q-tooltip
                                        transition-show="rotate"
                                        transition-hide="rotate"
                                    >
                                        Incarca document nou
                                    </q-tooltip>
                                </q-btn>
                            </div>
                        </div>

                        <div style="display: flex;justify-content: center;">
                            <q-spinner
                                    v-if="uploadingFileToServer"
                                    color="primary"
                                    size="3em"
                                    :thickness="10"
                            />
                            <table v-else class="table table-bordered table-condensed table-responsive" style="width: 100%; max-height: 50vh;">
                                <tbody>
                                    <tr v-for="scan_document in partener.scan_documents" v-bind:key="scan_document.appid">
                                        <td>
                                            <span class="material-icons cursor-pointer" style="color: red; font-size: 1.3rem;" @click="askIfRemoveScan(scan_document)">delete_forever</span>
                                        </td>
                                        <td>
                                            {{scan_document.file_ext}}
                                        </td>
                                        <td>
                                            {{humanReadableBytes(scan_document.file_size)}}
                                        </td>
                                        <td>
                                            <div class="desktop-only" style="max-width:30vw;">{{scan_document.description}}</div>
                                            <div class="mobile-only">{{scan_document.description}}</div>
                                        </td>
                                        <td>
                                            <q-btn round color="black" icon="cloud_download" size="xs"/>
                                            <q-menu fit
                                                    transition-show="scale"
                                                    transition-hide="scale"
                                            >
                                                <DialogOpenScan :appid="scan_document.appid" type_scan="Parteneri"/>
                                            </q-menu>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div style="display: flex;align-items: center;justify-content: center; width: 100%;border-bottom: 1px solid black;"></div>

                    <div style="display: flex;align-items: left;justify-content: left; width: 100%;">
                        <div style="margin-top: 5px;">
                            <span class="text__title--medium">Conturi bancare</span>                                
                        </div>
                        <div>
                            <q-btn flat color="primary" icon="add_box" no-caps :disable="loadingPartenerData" @click="openFormAssetPlan">
                                <q-tooltip
                                    transition-show="rotate"
                                    transition-hide="rotate"
                                >
                                    Adaugare cont nou
                                </q-tooltip>
                            </q-btn>
                        </div>
                        <br/>
                    </div>
                    <div style="display: flex;justify-content: center; width: 100%">
                        <q-spinner
                            v-if="loadingPartenerConturi"
                            color="primary"
                            size="3em"
                            :thickness="10"
                        />
                        <q-table dense :rows-per-page-options="[0]" :rows="partenerConturi"
                            :columns="partenerConturiListColumns" :visible-columns="visiblePartenerConturiListColumns"
                            row-key="appid" :filter="filterPartenerConturiList" :loading="loadingPartenerConturi"
                            :pagination.sync="myPagination"
                            v-else class="table table-bordered table-condensed table-responsive"
                        >
                            <template v-slot:top>                                
                                <q-space />
                                <q-input dense debounce="300" color="primary" v-model="filterPartenerConturiList">
                                    <template v-slot:append>
                                        <q-icon name="search" />
                                    </template>
                                </q-input>
                            </template>

                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="moneda" :props="props" :sortable="true"
                                        style="white-space:pre-wrap; word-wrap:break-word">
                                        {{ props.row.moneda}}
                                    </q-td>
                                    <q-td key="denumire_banca" :props="props" :sortable="true"
                                        style="white-space:pre-wrap; word-wrap:break-word">
                                        {{ props.row.denumire_banca }}
                                    </q-td>
                                    <q-td key="swift" :props="props" :sortable="true"
                                        style="white-space:pre-wrap; word-wrap:break-word">
                                        {{ props.row.swift }}
                                    </q-td>
                                    <q-td key="iban" :props="props" :sortable="true"
                                        style="white-space:pre-wrap; word-wrap:break-word">
                                        {{ props.row.iban }}
                                    </q-td>
                                  
                                    
                                    <q-td key="appid" :props="props" :sortable="false">
                                        <q-icon :name="'edit'" :class="'text-blue cursor-pointer pencil'"                                        
                                            style="font-size: 1.2rem; margin-left: auto;"                                            
                                            @click="onPressEditRowCont(props.row)">
                                            <q-tooltip>
                                                <span>Editeaza cont</span>
                                            </q-tooltip>
                                        </q-icon>
                                        <q-icon name="delete_forever" class="text-red cursor-pointer" style="font-size: 1.3rem;" @click="askIfDeleteCont(props.row)">
                                            <q-tooltip>
                                                <span>Sterge cont</span>
                                            </q-tooltip>
                                        </q-icon>
                                    </q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </div>
                </div>
            </div>
        </div>

        <q-dialog v-model="dialogUploadFile" position="top">
            <q-card style="width: 350px">
                <q-linear-progress :value="0.6" color="pink" />

                <q-card-section class="column no-wrap">
                    <div class="text-weight-bold">Incarca fisier nou</div>
                    <div class="text-grey" style="margin-top: 4px; margin-bottom: 10px;"><q-input counter outlined v-model="uploadScanTitle" label="Titlu document (minim 4 caractere)" dense maxlength="200" /></div>
                    <input v-show="uploadScanTitle.length>3" type="file" name="fileToUpload" id="fileToUpload" ref="myFileToUpload" @change="prepareForUpload($event)" style="margin-bottom: 10px;" />
                    <q-btn :disable="!(uploadScanTitle.length>3)" v-show="checkIfFileIsSelected" icon="cloud_upload" color="white" text-color="black" label="Salveaza pe server" @click="uploadFile()" :loading="uploadingFileToServer" style="margin-bottom: 5px;" />
                </q-card-section>
            </q-card>
        </q-dialog>

        <q-dialog v-model="visibleDialogPartenerCont" persistent >
            <q-card style="min-width: 60vw; max-width: 80vw;">
                <q-bar>
                    <q-icon name="add_box" />
                    <div>Adaugare cont bancar partener</div>

                    <q-space />

                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>

                <q-card-section>
                    <EditPartenerCont :frenunt="renuntEditarePartenerCont" :fonsave="getConturi" :appid_partener="partener.appid" :appid="appid_cont"/>
                </q-card-section>
            </q-card>
        </q-dialog>
    </div>
</template>

<script lang="ts" src="./WorkOnPartener.ts" />

<style scoped>
    .q-field--with-bottom {
        padding-bottom: 20px;
    }

    .q-field__native{
        font-family: "Nunito Sans";
        font-weight: bolder;
    }

    .mousechange:hover {
        cursor: pointer;
    }
</style>
