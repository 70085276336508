import {defineComponent, inject} from 'vue';
import User from '@/store/User';
import {APP_INFORMATION, APP_NAME} from '@/config';
import {getModule} from 'vuex-module-decorators';
import IUser from '@/types/IUser';
import { abbreviateNumber } from '@/modules/Utils';
import EventSourceNrBadge from '@/store/EventSourceNrBadge';
import IEventSourceNrBadge from '@/types/IEventSourceNrBadge';

export default defineComponent({
  name: 'MenuApp',

  setup() {
    const emitter = inject("emitter") as any;
    return {
      emitter
    }
  },

  data() {
    return{
      userStore: getModule(User),
      eventSourceNrBadgeStore: getModule(EventSourceNrBadge),
      aboutPopupOpen: false
    }
  },

  methods: {
    goToView(pRouteViewName: string){      
      this.$router.push({ name: pRouteViewName }) 
    },

    goToViewWithParam(pRouteViewName: string, pRouteViewParam: number){      
      this.$router.push({ name: pRouteViewName, params: { is_all: pRouteViewParam } }) 
    },

    goToViewWithParamString(pRouteViewName: string, pRouteViewParam: string){      
      this.$router.push({ name: pRouteViewName, params: { param: pRouteViewParam } }) 
    },

    goToViewPosturi(){
      this.$router.push({ name: 'HRMSPosition', params: { positionId: 12 } })
    },

    goToViewWithParamIsDialogPopup(pRouteViewName: string, pRouteViewParam: boolean){      
      this.$router.push({ name: pRouteViewName, params: { is_popupdialog: pRouteViewParam ? 1 : 0 , param: ' '}})
    },

    abbreviateNumber(value: number):string {
      return abbreviateNumber(value);
    },

    openLink() {
      window.open("http://192.168.125.9:5000/?launchApp=SYNO.SDS.Drive.Application");
    }
  },

  computed: {
    APP_NAME(): string{
      return APP_NAME;
    },
    
    user(): IUser {
      return this.userStore.User;
    },

    eventSourceNrBadge(): IEventSourceNrBadge{
      return this.eventSourceNrBadgeStore.EventSourceNrBadge;
    },

    APP_INFORMATION(): any{
      return APP_INFORMATION;
    }
  }
});
