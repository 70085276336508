import store from './index';
import {Module, VuexModule, Mutation, Action} from 'vuex-module-decorators';
import INomCateg from '@/types/INomCateg';
import INomTipDoc from '@/types/INomTipDoc';
import INomRegistru from '@/types/INomRegistru';
import INomCategActive from '@/types/INomCategActive';
import INomTipActive from '@/types/INomTipActive';
import ILabelValue from '@/types/ILabelValue';
// import IOrganizatie from '@/types/IOrganizatie';
import ITari from '@/types/ITari';
import IConturi from '@/types/IConturi';
import IOrgUnit from "@/types/IOrgUnit";
import ITipOrgUnit from "@/types/ITipOrgUnit";
import INomJob from "@/types/INomJob";
import IStatusLabel from '@/types/IStatusLabel';
import ILabelValueNumber from '@/types/ILabelValueNumber';
import IPersoana from '@/types/IPersoana';

@Module({ namespaced: true, dynamic: true, store, name: 'nomenclatoareStore'})
export default class Nomenclatoare extends VuexModule {
  
  public currentPageTitle:string = '';
  public NomCategorii: INomCateg[] = [];
  public nomCategIsOK:boolean = false;
  public NomTipDoc: INomTipDoc[] = [];
  public nomTipDocIsOK:boolean = false;
  public NomRegistre: INomRegistru[] = [];
  public nomRegistreIsOK:boolean = false;
  public NomCategoriiActive: INomCategActive[] = [];
  public nomCategActiveIsOK:boolean = false;
  public NomTipActive: INomTipActive[] = [];
  public nomTipActiveIsOK:boolean = false;
  // public NomOrganizatii: IOrganizatie[] = [];
  // public nomOrganizatiiIsOK:boolean = false;
  public NomOrgUnits: IOrgUnit[] = [];
  public nomOrgUnitIsOK: boolean = false;
  public NomOrgUnitsTypes: ITipOrgUnit[] = [];
  public nomOrgUnitsTypesIsOK: boolean = false;
  public NomJobs: INomJob[] = [];
  public nomJobIsOKs:boolean = false;
  public NomPersoaneUtilizatoare: IPersoana[] = [];
  public NomPersoaneUtilizatoareIsOK: boolean = false;
  public Tari: ITari[] = [];
  public TariIsOK:boolean = false;
  public Conturi: IConturi[] = [];
  public ConturiIsOK:boolean = false;

  public NomCategSolicitant : ILabelValue[]=[
    {label:'Institutie Publica', value: 'pu'},
    {label:'Persoana Fizica', value: 'pf'},
    {label:'Persoana Juridica', value: 'pj'}
  ]

  public NomTipInregistrare : ILabelValue[]=[
    {label:'Intrare', value: 'intrare'},
    {label:'Iesire', value: 'iesire'},
    {label:'Intern', value: 'intern'}
  ]

  public StareGeneral: IStatusLabel[] = [
    {status: 'init', label: 'Initiat'}, 
    {status: 'initiat', label: 'Initiat'}, 
    {status: 'registered', label: 'Inregistrat'}, 
    {status: 'assigned', label: 'Repartizat'}, 
    {status: 'pending', label: 'In lucru'}, 
    {status: 'advised', label: 'Avizat'}, 
    {status: 'approved', label: 'Aprobat'}, 
    {status: 'verified', label: 'Verificat'}, 
    {status: 'responsed', label: 'Raspuns'}, 
    {status: 'finalized', label: 'Finalizat'}, 
    {status: 'archived', label: 'Arhivat'},
    {status: 'inapproval', label: 'In aprobare'},
    {status: 'rejected', label: 'Respins'},
    {status: 'cancellation', label: 'Anulat'},
    // {status: 'consumed', label: 'Consumat'},
    {status: 'expired', label: 'Expirat'}
  ]

  public StareTask: IStatusLabel[] = [
    {status: 'activ', label: 'Actiune solicitata'},
    {status: 'inactiv', label: 'Actiune viitoare'},
    {status: 'finalized', label: 'Finalizat'},
    {status: 'rejected', label: 'Respins'}
    // {status: 'assigned', label: 'Repartizat'},
    // {status: 'pending', label: 'In aprobare'},
    // {status: 'advised', label: 'Avizat'},
    // {status: 'approved', label: 'Aprobat'},
    // {status: 'verified', label: 'Verificat'},
    // {status: 'responsed', label: 'Raspuns'},
    // {status: 'archived', label: 'Arhivat'},
  ]

  public TipClient: ILabelValueNumber[] = [
    {label: 'Persoana Fizica', value: 1},
    {label: 'Persoana Juridica', value: 2}
  ]

  public MyImgProfileString64: string = '';

  @Mutation
  public SET_NOMPERSOANE_UTILIZATOARE(puseri: IPersoana[]) {
    this.NomPersoaneUtilizatoare = JSON.parse(JSON.stringify(puseri));
    this.NomPersoaneUtilizatoareIsOK=true;
  }
  
  @Action
  public set_nompersoane_utilizatoare(puseri: IPersoana[]) {
    this.context.commit('SET_NOMPERSOANE_UTILIZATOARE', puseri);
  }

  @Mutation
  public SET_CURRENTPAGETITLE(ptitle: string) {
    this.currentPageTitle = JSON.parse(JSON.stringify(ptitle));
  }

  @Action
  public set_currentpagetitle(ptitle: string) {
    this.context.commit('SET_CURRENTPAGETITLE', ptitle);
  }

  @Mutation
  public SET_NOMCATEGORII(pcategorii: INomCateg[]) {
    this.NomCategorii = JSON.parse(JSON.stringify(pcategorii));
    this.nomCategIsOK=true;
  }

  @Action
  public set_nomcategorii(pcategorii: INomCateg[]) {
    this.context.commit('SET_NOMCATEGORII', pcategorii);
  }

  @Mutation
  public SET_NOMTIPDOC(ptipdoc: INomTipDoc[]) {
    this.NomTipDoc = JSON.parse(JSON.stringify(ptipdoc));
    this.nomTipDocIsOK=true;
  }

  @Action
  public set_nomtipdoc(ptipdoc: INomTipDoc[]) {
    this.context.commit('SET_NOMTIPDOC', ptipdoc);
  }

  @Mutation
  public SET_NOMREGISTRE(pregistre: INomRegistru[]) {
    this.NomRegistre = JSON.parse(JSON.stringify(pregistre));
    this.nomRegistreIsOK=true;
  }

  @Action
  public set_nomregistre(pregistre: INomRegistru[]) {
    this.context.commit('SET_NOMREGISTRE', pregistre);
  }

  @Mutation
  public SET_NOMCATEGORIIACTIVE(pcategoriiactive: INomCategActive[]) {
    this.NomCategoriiActive = JSON.parse(JSON.stringify(pcategoriiactive));
    this.nomCategActiveIsOK = true;
  }

  @Action
  public set_nomcategorii_active(pcategoriiactive: INomCategActive[]) {
    this.context.commit('SET_NOMCATEGORIIACTIVE', pcategoriiactive);
  }

  @Mutation
  public SET_NOMTIPACTIVE(ptipactive: INomTipActive[]) {
    this.NomTipActive = JSON.parse(JSON.stringify(ptipactive));
    this.nomTipActiveIsOK = true;
  }

  @Action
  public set_nomtip_active(ptipactive: INomTipActive[]) {
    this.context.commit('SET_NOMTIPACTIVE', ptipactive);
  }

  @Mutation
  public SETTARI(pTari: ITari[]) {
    this.Tari = JSON.parse(JSON.stringify(pTari));
    this.TariIsOK = true;
  }

  @Action
  public set_tari(pTari: ITari[]) {
    this.context.commit('SETTARI', pTari);
  }

  @Mutation
  public SETCONTURI(pConturi: IConturi[]) {
    this.Conturi = JSON.parse(JSON.stringify(pConturi));
    this.ConturiIsOK = true;
  }

  @Action
  public set_conturi(pConturi: IConturi[]) {
    this.context.commit('SETCONTURI', pConturi);
  }

  // @Mutation
  // public SET_NOMORGANIZATII(porganizatii: IOrganizatie[]) {
  //   this.NomOrganizatii = JSON.parse(JSON.stringify(porganizatii));
  //   this.nomOrganizatiiIsOK=true;
  // }

  // @Action
  // public set_nomorganizatii(porganizatii: IOrganizatie[]) {
  //   this.context.commit('SET_NOMORGANIZATII', porganizatii);
  // }

  @Mutation
  public SET_NOMORGUNITS(orgUnits: IOrgUnit[]) {
    this.NomOrgUnits = JSON.parse(JSON.stringify(orgUnits));
    this.nomOrgUnitIsOK=true;
  }

  @Action
  public set_nomorg_units(porgUnits: IOrgUnit[]) {
    this.context.commit('SET_NOMORGUNITS', porgUnits);
  }

  @Mutation
  public SET_NOMORGUNITSTYPES(orgUnitsTypes: ITipOrgUnit[]) {
    this.NomOrgUnitsTypes = JSON.parse(JSON.stringify(orgUnitsTypes));
    this.nomOrgUnitsTypesIsOK=true;
  }

  @Action
  public set_nom_org_units_types(porgUnitTypes: ITipOrgUnit[]) {
    this.context.commit('SET_NOMORGUNITSTYPES', porgUnitTypes);
  }

  @Mutation
  public SET_NOM_JOBS(jobs: INomJob[]) {
    this.NomJobs = JSON.parse(JSON.stringify(jobs));
    this.nomJobIsOKs=true;
  }

  @Action
  public set_nom_jobs(jobs: INomJob[]) {
    this.context.commit('SET_NOM_JOBS', jobs);
  }   
}
