<template>
    <div>
        <h4>Loading Component...</h4>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'LoadingComponent'
});
</script>

<style scoped />
