<template>
  <div class="q-pa-md">
    <q-spinner-gears
      v-if="loadingData"
      color="primary"
      size="4em"
      class="absolute-center"
    />
    <div v-else>
      <q-form ref="myForm">
        <div class="well well-sm">
          <div class="panel">
            <div class="q-pa-md">
              <div style="display: flex; align-items: center; margin-bottom: 15px;">
                <div
                  v-bind:class="{
                    column: $q.platform.is.mobile,
                    row: $q.platform.is.desktop,
                  }"
                >
                  <span class="text__title--medium">Cereri de avans</span>
                </div>
              </div>

              <div style="display: flex;align-items: start;">
                <div style="width: 65%; display: flex; flex-direction: row;">
                  <div style="display: flex;flex-direction: column;width: 100%;">
                    <div class="row">
                      <span class="col-4 form__label">Id</span>
                      <span class="col-8 form__label__black">{{cerere.id_cerere}}</span>
                    </div>
                    
                    <div class="row">
                      <span class="col-4 form__label">Pentru deplasare</span>
                      <span class="col-8 form__label__black">{{cerere.denumire_pentru}}</span>              
                    </div>                    

                    <div class="row">
                        <span class="col-4 form__label">De la data</span>
                        <div class="col-8 row" v-if="cerere.data_start != null">
                            <span class="form__label__black">
                                {{displayData(cerere.data_start)}}
                            </span>
                        </div>
                    </div>            

                    <div class="row">
                        <span class="col-4 form__label">Pana la data</span>
                        <div class="col-8 row" v-if="cerere.data_end != null">                
                            <span class="form__label__black">
                                {{displayData(cerere.data_end)}}
                            </span>
                        </div>
                    </div>

                    <div class="row">
                        <span class="col-4 form__label">Tara</span>
                        <span class="col-8 form__label__black">
                            {{cerere.denumire_tara}}
                        </span>
                    </div>

                    <div class="row">
                        <span class="col-4 form__label">Oras</span>
                        <span class="col-8 form__label__black">
                            {{cerere.denumire_localitate}}
                        </span>
                    </div>

                    <div class="row">
                        <span class="col-4 form__label">Mijloc de transport</span>
                        <span class="col-8 form__label__black">
                            {{getDenMijlocTransport(cerere.id_mijl_transport as number)}}
                        </span>
                    </div>

                    <div class="row">
                        <span class="col-4 form__label">Detalii / scop</span>
                        <span class="col-8 form__label__black">
                            {{cerere.denumire_pentru}}
                        </span>
                    </div>
                  </div>
                </div>
                <div style="width: 35%; display: flex; flex-direction: row; justify-content: center; align-items:center;">
                  <div style="display: flex; flex-direction: column">
                    <div 
                        v-bind:class="{
                        column: $q.platform.is.mobile,
                        row: $q.platform.is.desktop,
                        }"
                    >
                      <div style="display: flex;align-items: center;justify-content: center;width: 100%;">
                        <div>
                          <span class="text__title--medium">Documente atasate</span>
                          <div
                              v-show="
                                  cerere.scan_documents != null &&
                                  cerere.scan_documents.length == 0 &&
                                  !uploadingFileToServer
                              "
                              class="text__label--thin"
                              style="width: 100%; text-align: center"
                          >
                            Nimic atasat
                          </div>
                        </div>
                        <!-- <div v-if="user.auth_for_upload_scan == 'y'">
                          <q-btn 
                            flat
                            color="primary"
                            icon="add_box"
                            no-caps
                            size="lg"
                            :disable="loadingData"
                            @click="openFormUploadFile"
                            v-if="cerere.stare === 'initiat'"                            
                          >
                            <q-tooltip
                              transition-show="rotate"
                              transition-hide="rotate"
                            >
                              Incarca document nou
                            </q-tooltip>
                          </q-btn>
                        </div> -->
                      </div>

                      <div style="display: flex;align-items: center;justify-content: center;width: 100%;">
                        <div>
                          <q-spinner
                            v-if="uploadingFileToServer"
                            color="primary"
                            size="3em"
                            :thickness="10"
                          />
                          <table
                            v-else
                            class="table table-bordered table-condensed table-responsive"
                            style="width: 100%; max-height: 50vh"
                          >
                            <tbody>
                              <tr
                                  v-for="scan in cerere.scan_documents"
                                  v-bind:key="scan.appid"
                              >
                              <!-- v-if="user.is_admin == 'y'" -->
                                <!-- <td v-if="cerere.stare === 'initiat'">
                                  <span                                    
                                    class="material-icons cursor-pointer"
                                    style="color: red; font-size: 1.3rem;"
                                    @click="askIfRemoveScan(scan)"
                                    >delete_forever</span
                                  >
                                </td> -->
                                <td>
                                  {{ scan.file_ext }}
                                </td>
                                <td>
                                  {{ humanReadableBytes(scan.file_size) }}
                                </td>
                                <td>
                                  <div class="desktop-only" style="max-width: 30vw">
                                    {{ scan.description }}
                                  </div>
                                  <div class="mobile-only">
                                      {{ scan.description }}
                                  </div>
                                </td>
                                <td>
                                  <q-btn
                                    round
                                    color="black"
                                    icon="cloud_download"
                                    size="xs"
                                  />
                                  <q-menu
                                    fit
                                    transition-show="scale"
                                    transition-hide="scale"
                                  >
                                    <DialogOpenScan :appid="scan.appid" type_scan="Cereri"/>
                                  </q-menu>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             
              <div style="display: flex; align-items: center">
                <div
                  v-bind:class="{
                    column: $q.platform.is.mobile,
                    row: $q.platform.is.desktop,
                  }"
                >
                  <span class="text__title--medium" style="margin-bottom: 15px;">Solicit avans pentru</span>
                </div>
              </div>

              <div style="display: flex;align-items: center;justify-content: center;">
                <div class="row">
                  <div style="width:400px; display: center;">
                    <div class="row">
                      <span class="col-8 form__label">Cheltuieli de transport</span> 
                      <span class="col-4 form__label__black">{{cerere.valoare_transport}}</span>
                    </div>

                    <div class="row">
                      <span class="col-8 form__label">Diurna</span>
                      <span class="col-4 form__label__black">{{cerere.valoare_diurna}}</span>
                    </div>

                    <div class="row">
                      <span class="col-8 form__label">Cazare</span>
                      <span class="col-4 form__label__black">{{cerere.valoare_cazare}}</span>
                    </div> 
                  </div>          
                </div> 
              </div>  
            </div>

            <div clas="row" style="margin-top: 5px;margin-bottom: 15px;" v-if="cerere.stare !== 'initiat'" >
                <TaskList :appid="appid" :task="task" :onState="onState" :isSuccess="isSuccess"/>
            </div>
          </div>          
          <br />
          <div class="absolute-bottom-right q-pa-sm" style="margin-top: 15px;margin-right: 10px;">          
            <q-btn
              v-if="cerere.appid !== 0"
              label="DESCARCA CERERE"
              color="brown"
              @click="onDownload"
              style="margin-left: 5px;"
              size="sm"
            />

            <q-btn label="Renunt" type="reset" size="sm" flat @click="onCancel" style="margin-left: 5px;"/>
          </div>
        </div>
      </q-form>
    </div>
  </div>
</template>

<script lang="ts" src="./EditCerereDetail.ts" />

<style scoped>
.q-field--with-bottom {
  padding-bottom: 20px;
}

.q-field__native {
  font-family: "Nunito Sans";
  font-weight: bolder;
}
</style>