import { createI18n } from 'vue-i18n';
import pluralRules from './pluralization';
import numberFormats from './numbers';
import datetimeFormats from './datetime.js';
import en from './locales/en.json';
import ro from './locales/ro.json';
import hu from './locales/hu.json';

export default createI18n({
    locale: 'ro',
    fallbackLocale: 'ro',
    globalInjection: true,
    legacy: false,
    messages: { en, ro, hu },
    pluralRules,
    numberFormats,
    datetimeFormats
})