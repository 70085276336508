import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import IScan from '@/types/IScan';

interface TAjaxResponseGetRepartizari {
  status: string;
  message: string;
  rows: IScan[];
}

interface TAjaxResponseGetInfoScan {
  status: string;
  message: string;
  scan: IScan;
}

interface TAjaxResponseRepartizariHistoric{
  status: string;
  message: string;
  rows: string;
}

export class ServiceRepartizari {
  public static async getRepartizari(appidDoc:number, id_registru: number): Promise<TAjaxResponseGetRepartizari> {    
    const response = await axios.get(`${CONFIG_ENV.URL_REPARTIZARE.getRepartizari}/${appidDoc}/${id_registru}`);    
    return response.data;
  }

  public static async getRepartizariHistoric(appidDoc:number, id_registru: number): Promise<TAjaxResponseRepartizariHistoric> {
    const response = await axios.get(`${CONFIG_ENV.URL_REPARTIZARE.getRepartizariHistoric}/${appidDoc}/${id_registru}`);
    return response.data;
  }
}