<template>
    <div class="q-pa-md">
        <q-table
            dense
            :rows-per-page-options="[0]"
            :rows="tableDataOrgUnits"
            :columns="columns"
            :visible-columns="visibleColumns"
            row-key="id"
            :filter="filterOrgUnits"
            :loading="loadingData"
            :pagination.sync="myPagination"
        >
             <template v-slot:top>
                <span style="font-family: 'PT Sans Narrow', sans-serif; font-size: 0.8em;">{{title}}</span>
                <q-space />
                <q-input dense debounce="300" color="primary" v-model="filterOrgUnits">
                    <template v-slot:append>
                        <q-icon name="search" />
                    </template>
                </q-input>
            </template>

            <template v-slot:body="props">
                <q-tr :props="props" @click="selectHandler(props.row)">
                    <q-td key="organization_unit_descriere" :props="props" :sortable="true">
                        <span style="font-family: 'Nunito Sans', sans-serif; font-size: 0.8em;">{{ props.row.organization_unit_descriere }}</span>
                    </q-td>
                    <q-td key="tip_org_unit_id" :props="props" :sortable="true">
                        <span style="font-family: 'Nunito Sans', sans-serif; font-size: 0.8em;">{{ getDenTip(props.row.tip_org_unit_id) }}</span>
                    </q-td>
                    <q-td key="parent_organization_unit_descriere" :props="props" :sortable="true">
                        <span style="font-family: 'Nunito Sans', sans-serif; font-size: 0.8em;">{{ props.row.parent_organization_unit_descriere }}</span>
                    </q-td>
                    <q-td key="parent_tip_org_unit_id" :props="props" :sortable="true">
                        <span style="font-family: 'Nunito Sans', sans-serif; font-size: 0.8em;">{{ getDenTip(props.row.parent_tip_org_unit_id) }}</span>
                    </q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>

<script lang="ts" src="./ComboOrgUnit.ts" />

<style scoped />