import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import {getModule} from 'vuex-module-decorators';
import Nomenclatoare from '@/store/Nomenclatoare';

interface TAjaxResponseNomTipActive {
  status: string;
  message: string;
  rows: string;
  //rows: iNomCategActive[];
}

interface TAjaxResponseEditTipActive {
  status: string;
  message: string;
}

export class ServiceNomTipActive {
  public static async getNomTipActive(): Promise<TAjaxResponseNomTipActive> {
    const response = await axios.get(`${CONFIG_ENV.URL_ASSETS.tip_actives}`);
    return response.data;
  }

  public static async putNewStatusNomTipActive(appid:number, pNewStatus:string): Promise<TAjaxResponseEditTipActive> {
    const response = await axios.put(`${CONFIG_ENV.URL_ASSETS.tip_active}/status/${appid}/${pNewStatus}`);
    return response.data;
  }

  public static async putNewStatusConfidentalityNomTipActive(appid:number, pNewStatus:string): Promise<TAjaxResponseEditTipActive> {
    const response = await axios.put(`${CONFIG_ENV.URL_ASSETS.tip_active}/confidentiality/${appid}/${pNewStatus}`);
    return response.data;
  }

  public static async deleteNomTipActive(pappid: number): Promise<TAjaxResponseEditTipActive> {
    const response = await axios.delete(`${CONFIG_ENV.URL_ASSETS.tip_active}/${pappid}`);
    return response.data;
  }

  public static async postNomTipActive(appid: number, id_categ_active: number, den_lang_1: string, den_lang_2: string): Promise<TAjaxResponseEditTipActive> {
    const params = new URLSearchParams();
    params.set('appid', appid.toString());
    params.set('id_categ_active', id_categ_active.toString());
    params.set('den_lang_1', den_lang_1);
    params.set('den_lang_2', den_lang_2);
    const response = await axios.post(`${CONFIG_ENV.URL_ASSETS.tip_active}`, params);
    return response.data;
  }

  public static async updateStoreNomTipActivesFromDB() {
    const nomenclatoareStore = getModule(Nomenclatoare);
    this.getNomTipActive().then((presponse) => {
      if (presponse.status === 'success') {
        nomenclatoareStore.set_nomtip_active(JSON.parse(presponse.rows));
      }
    })
  }
}
