<template>
  <!-- @submit="onSubmit" -->
  <div class="q-pa-md">
    <q-form ref="myForm">
      <div class="row" v-if="(is_all == 1)">
         <span class="col-4 form__label">Angajat</span>
         <q-select
            :readonly="(timeRequest.appid != 0)"
            name="select-type"
            outlined
            use-input
            input-debounce="300"
            @filter="filterPersoane"
            class="col-8"
            v-model="timeRequest.marca"
            :options="optionsPersoane"
            option-value="marca"
            :option-label="(vl) => ((vl.marca != 0 && vl != 0) ? vl.nume_prenume : '')"
            :rules="[(val) => (val && val != 0) || 'Alegeti persoana']"
            :input-style="{ fontFamily: 'Nunito Sans', fontWeight: 'bolder', color: 'black' }"
            emit-value
            map-options
            lazy-rules
            dense
            style="max-height: 12vh;;">
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  No results
                </q-item-section>
              </q-item>
            </template>
            <template v-slot:option="{ itemProps, opt }">
              <q-item v-bind="itemProps">
                <q-item-section>
                  <div class="row">
                    <div class="col col-10_Item">{{opt.marca}}</div>
                    <div class="col col-40_Item">{{opt.nume_prenume}}</div>
                    <div class="col col-50_Item">{{opt.organization_unit_descriere}}</div>                          
                  </div>                 
                </q-item-section>
              </q-item>
            </template>
          </q-select>
      </div>

      <div class="row">
        <span class="col-4 form__label">Tip cerere</span>
        <q-select class="col-8" outlined v-model="selectedNomConcediiParent" 
          :options="optionsTimeRequestParent"
          @update:model-value="onValueChangeTimeRequestParent"
          :style="$q.platform.is.mobile ? 'max-height: 12vh; width: 100%' : 'max-height: 12vh;'"
          option-value="appid"
          option-label="tip_concediu"
          emit-value
          map-options
          lazy-rules
          dense            
          :input-style="{ fontFamily: 'Nunito Sans',fontWeight: 'bolder', color: 'black' }"
          :rules="[ val => val && val > 0 || 'Alegeti tipul de cerere!']"
        />
      </div>

      <div class="row" v-if="isVisibleFlagPlanAnualRegular"> <!-- numai pentru tip concediu de odihna este valabil! -->
        <span :class="$q.platform.is.mobile ? '' : 'col-4 form__label'"></span>
        <q-toggle
          class="col-8" 
          outlined
          v-model="stareFlagPlanAnualRegular"
          checked-icon="lock"
          color="blue"
          label="Regular/Anual"
          unchecked-icon="lock_open"
          emit-value
          map-options
          lazy-rules
          dense
          style="padding-bottom:20px;"
          @update:model-value="onValueChangeTimeRequestStatusPlanRegularOrAnual"
          :disable="!isAllowedPlanAnual"
        />
      </div>

      <div class="row" v-if="isVisibleNomConcediiChild">
        <span class="col-4 form__label">Tip concediu</span>
        <q-select class="col-8" outlined v-model="selectedNomConcediiChild" 
          :options="optionsTimeRequestChild" 
          :style="!isVisibleNomConcediiChild ? ($q.platform.is.mobile ? 'max-height: 12vh;padding-bottom:20px; width: 100%;' : 'max-height: 12vh;padding-bottom:20px;') :  ($q.platform.is.mobile ? 'max-height: 12vh; width:100%' : 'max-height: 12vh;')"
          option-value="appid"
          option-label="tip_concediu"
          emit-value
          map-options
          lazy-rules
          dense            
          :input-style="{ fontFamily: 'Nunito Sans',fontWeight: 'bolder', color: 'black' }"
          :rules="isVisibleNomConcediiChild ? [ val => val && val > 0 || 'Alegeti tipul de concediu!'] : undefined"
        />
      </div>

      <div class="row">
        <span class="col-4 form__label">Prima zi de concediu</span>
        <q-input class="col-8" outlined v-model="timeRequest.start_date" dense mask="##.##.####"
          :style="$q.platform.is.mobile ? 'width: 100%; max-height: 12vh;;' : 'width: 200px; max-height: 12vh;;'"
          :input-style="{ fontFamily: 'Nunito Sans',fontWeight: 'bolder', color: 'black' }"
          :rules="[
            (val) => (val && val.length > 0) || 'Completati prima zi de concediu',
          ]"
        >
          <q-popup-proxy ref="qDateProxy1" transition-show="scale" transition-hide="scale">
            <q-date minimal v-model="timeRequest.start_date" mask="DD.MM.YYYY" 
              @update:model-value="onChangeStartDate" :locale="myLocale" />
          </q-popup-proxy>
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer"></q-icon>
          </template>
        </q-input>
      </div>

      <div class="row">
        <span class="col-4 form__label">Ultima zi de concediu</span>
        <q-input class="col-8" outlined v-model="timeRequest.end_date" dense mask="##.##.####"  
          :style="$q.platform.is.mobile ? 'width: 100%; max-height: 12vh;;' : 'width: 200px; max-height: 12vh;;'"
          :input-style="{ fontFamily: 'Nunito Sans',fontWeight: 'bolder', color: 'black' }"
          :rules="[
            (val) => (val && val.length > 0) || 'Completati ultima zi de concediu!',
          ]"
        >
          <q-popup-proxy ref="qDateProxy2" transition-show="scale" transition-hide="scale">
            <q-date minimal v-model="timeRequest.end_date" mask="DD.MM.YYYY" 
              @update:model-value="onChangeEndDate" :locale="myLocale" />
          </q-popup-proxy>
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer"></q-icon>
          </template>
        </q-input>
      </div>

      <div class="row">
        <div class="col-4"></div>
        <div class="col-8">
          <q-linear-progress v-if="seCalculeazaNrZileLucratoare" indeterminate color="warning" class="q-mt-sm" />
          <span v-if="SePoateAfisaNrZileLucratoare" class="text__label--thin">{{timeRequest.nr_working_days}} zile lucratoare</span>
        </div>
      </div>

      <div class="row">
        <span class="col-4 form__label">Observatie</span>
        <q-input
            class="col-8"
            outlined
            :style="$q.platform.is.mobile ? 'max-height: 12vh; width: 100%' : 'max-height: 12vh;'"
            v-model="timeRequest.observatie"
            type="textarea"
            ref="refDescriere"
            lazy-rules
            counter
            maxlength="200"
            rows="3"
            dense
            :input-style="{ fontFamily: 'Nunito Sans',fontWeight: 'bolder', color: 'black' }"
            :rules="!stareFlagPlanAnualRegular ? [ val => val && val.length > 0 || 'Completati observatia!'] : []"
        />        
      </div>      

      <div class="row" v-if="timeRequest.appid != 0">
        <div style="display: flex;justify-content: flex-start; flex-direction: column; width: 100%;">
          <div style="display: flex;flex-direction:row;align-items: center;justify-content: flex-start;">
            <div v-if="user.auth_for_upload_scan == 'y'">
              <q-btn flat color="primary" icon="add_box" no-caps @click="openFormUploadFile">
                <q-tooltip
                    transition-show="rotate"
                    transition-hide="rotate"
                >
                  Incarca document nou
                </q-tooltip>
              </q-btn>
            </div>
            <span class="text__title--medium" style="font-weight:400;">Documente</span>
          </div>
          <div v-show="scans.length==0" class="text__label--thin" >Puteti atasa documente relative la aceasta cerere.</div>

          <q-spinner
            v-if="uploadingFileToServer"
            color="primary"
            size="3em"
            :thickness="10"
          />
          <table
              v-else
              class="table table-bordered table-condensed table-responsive"
              style="width: 100%; max-height: 50vh"
            >
            <tbody>
            <tr v-for="scan in scans" v-bind:key="scan.appid">
              <td v-if="timeRequest.stare === 'initiat'">
                <span class="material-icons cursor-pointer" style="color: red; font-size: 1.3rem;" @click="askIfRemoveScan(scan)">delete_forever</span>
              </td>
              <td>
                {{ scan.file_ext }}
              </td>
              <td>
                {{humanReadableBytes(scan.file_size)}}
              </td>
              <td>
                <div class="desktop-only" style="max-width: 30vw">
                  {{ scan.description }}
                </div>
                <div class="mobile-only">
                    {{ scan.description }}
                </div>
              </td>
              <td>
                  <q-btn
                    round
                    color="black"
                    icon="cloud_download"
                    size="xs"
                  />
                  <q-menu
                    fit
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <DialogOpenScan :appid="scan.appid" type_scan="TimeRequest"/>
                  </q-menu>
                </td>
            </tr>
            </tbody>
          </table>
        </div>  
      </div>
    </q-form>
    <br />
    <div :class="$q.platform.is.mobile ? 'column absolute-bottom-center q-pa-sm q-gutter-sm' : 'absolute-bottom-right q-pa-sm'">
      <q-btn v-if="timeRequest.appid != 0"                     
        label="Vizualizeaza cererea"
        color="brown-5"
        icon="file_copy"
        @click="onPreviewCerere"            
        style="margin-right: 5px;"
        size="sm"
      />
      <q-btn size="sm" label="Salveaza" type="submit" color="primary" :disable="loadingData || !requestIsFilled" @click="onSave"/>
      <q-btn v-if="timeRequest.appid != 0 && timeRequest.stare === 'initiat' && !stareFlagPlanAnualRegular"                     
        label="TRIMITE PE FLUX"
        color="green"  
        @click="onSendApproved"            
        style="margin-left: 5px;"
        size="sm"
      />
      <q-btn
        label="Renunt"
        type="reset"
        flat
        @click="onCancel"
        style="margin-left: 5px"
        size="sm"
      />
    </div>

    <q-dialog v-model="dialogUploadFile" position="top">
      <q-card style="width: 350px">
        <q-linear-progress :value="0.6" color="pink" />

        <q-card-section class="column no-wrap">
          <div class="text-weight-bold">Incarca fisier nou</div>
          <div class="text-grey" style="margin-top: 4px; margin-bottom: 10px">
            <q-input
              counter
              outlined
              v-model="uploadScanTitle"
              label="Titlu document (minim 4 caractere)"
              dense
              maxlength="200"
            />
          </div>
          <input
            v-show="uploadScanTitle.length > 3"
            type="file"
            name="fileToUpload"
            id="fileToUpload"
            ref="myFileToUpload"
            @change="prepareForUpload($event)"
            style="margin-bottom: 10px"
          />
          <q-btn
            :disable="!(uploadScanTitle.length>3)"
            v-show="checkIfFileIsSelected"
            icon="cloud_upload"
            color="white"
            text-color="black"
            label="Salveaza pe server"
            @click="uploadFile()"
            :loading="uploadingFileToServer"
            style="margin-bottom: 5px"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog
      v-model="showPreviewFile"
      persistent
      :maximized="true"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card class="bg-white">
        <q-bar>
          <h5>{{titleForPreviewForm}}</h5>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip transition-show="rotate"
                       transition-hide="rotate">Close</q-tooltip>
          </q-btn>
        </q-bar>

        <q-card-section>
          <q-linear-progress v-if="downloadingFile" query track-color="orange" color="purple" class="q-mt-sm"/>
          <iframe v-if="isPDFFileType" id="iframe_preview_scan" type="application/pdf" width="100%" height="100%" style="width:100%; height:calc(100vh - 70px);overflow: auto;border: none;"></iframe>
          <img v-else id="iframe_preview_scan" width="100%" height="100%" style="width:100%; height:calc(100vh - 70px);overflow: auto;border: none;display: block;"/>          
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="confirmVizualizareCererea" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="file_copy" color="primary" text-color="white" />
          <span class="q-ml-sm">Doriti sa salvati cererea?</span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn size="sm" label="Da" color="primary" v-close-popup @click="onSaveCerere"/>
          <q-btn size="sm" flat label="Nu" v-close-popup @click="onCancelPreview"/>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script lang="ts" src="./EditTimeRequest.ts" />

<style scoped>
  .col-10_Item {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .col-40_Item {
      flex: 0 0 40%;
      max-width: 40%;
  }
  .col-50_Item {
      flex: 0 0 50%;
      max-width: 50%;
  }
</style>
