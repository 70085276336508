import INomJob from "@/types/INomJob";
import axios from "axios";
import {CONFIG_ENV} from "@/config";
import 'url-search-params-polyfill';

interface TAjaxResponseNomJob {
    status: string;
    message: string;
    rows: INomJob[];
}

interface TAjaxResponseEditJob {
    status: string;
    message: string;
}

interface TAjaxResponseSimple {
    status: string;
    message: string;
}

export class ServiceNomJob {
    public static async getNomJobs(): Promise<TAjaxResponseNomJob> {
        const response = await axios.get(`${CONFIG_ENV.URL_ASSETS.jobs}`);
        return response.data;
    }

    public static async deleteNomJob(id: number): Promise<TAjaxResponseEditJob> {
        const response = await axios.delete(`${CONFIG_ENV.URL_ASSETS.deleteJob}/${id}`);
        return response.data;
    }
    
    public static async updateJob(id_org:number,job_title:string, organization_rank: number): Promise<TAjaxResponseSimple> {
        const params = new URLSearchParams();
        params.set('job_title', job_title);
        params.set('organization_rank', organization_rank.toString());
        const response = await axios.post(`${CONFIG_ENV.URL_ASSETS.editJob}/${id_org}`, params);
        return response.data;
    }
}