import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import IAdrese from '@/types/IAdrese';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type IAdreseNull = OrNull<IAdrese>;

interface TAjaxResponseAdrese{
  status: string;
  message: string;
  rows: string;
}

interface TAjaxResponseEditAdresa {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetAdresa {
  status: string;
  message: string;
  adresa: IAdreseNull;
}

export class ServiceAdrese {
  public static async getAdrese(): Promise<TAjaxResponseAdrese> {
    const response = await axios.get(`${CONFIG_ENV.URL_ADRESE.adrese}`);
    return response.data;
  }

  public static async deleteAdresa(appid: number): Promise<TAjaxResponseEditAdresa> {    
    const response = await axios.delete(`${CONFIG_ENV.URL_ADRESE.adresa}/${appid}`);
    return response.data;
  }

  public static async postAdresa(adresa: IAdrese): Promise<TAjaxResponseEditAdresa> {
    const response = await axios.post(`${CONFIG_ENV.URL_ADRESE.adresa}`, adresa);
    return response.data;
  }

  public static async getAdresa(appid: number): Promise<TAjaxResponseGetAdresa> {
    const response = await axios.get(`${CONFIG_ENV.URL_ADRESE.adresa}/${appid}`);
    return response.data;
  }

  public static async getAdreseFilter(idTara: number, idJudet: number, idLocalitate: number): Promise<TAjaxResponseAdrese> {
    const response = await axios.get(`${CONFIG_ENV.URL_ADRESE.adrese_filter}/${idTara}/${idJudet}/${idLocalitate}`);
    return response.data;
  }
}