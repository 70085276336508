import {defineComponent} from 'vue';
import {ServiceHRAdminPrezenta} from '@/modules/ServiceHRAdminPrezenta';
import {TTimeAccount} from '@/types/TTimeAccount';

export default defineComponent({
    name: 'HeaderTimeAccountsMarca',

    props: {
        marca: {
            type: Number,
            required: true,
            default: 0,
            readonly: true
        },
        year: {
            type: Number,
            required: true,
            default: 0,
            readonly: true
        }
    },

    data() {
        return {
            loadingData: false,
            timeAccounts: [] as TTimeAccount[]
        }
    },

    methods: {
        getTimeAccounts(){
            const vueInst=this;
            vueInst.loadingData=true;
            vueInst.timeAccounts=[];
            ServiceHRAdminPrezenta.getAllTimeAccountsForMarca(vueInst.marca, vueInst.year).then((presponse)=>{
                vueInst.loadingData=false;
                if(presponse.status==='success'){
                    vueInst.timeAccounts=JSON.parse(JSON.stringify(presponse.rows));
                }
            });
        }
    },

    mounted() {
        const vueInst = this;
        vueInst.loadingData=true;
        vueInst.getTimeAccounts();
    }
});
