<template>
    <div class="q-pa-md">
        <q-table
            dense
            :rows-per-page-options="[0]"
            :rows="tableDataDeplasari"
            :columns="columns"
            :visible-columns="visibleColumns"
            row-key="appid"
            :filter="filterDeplasari"
            :loading="loadingData"
            :pagination.sync="myPagination" 
        >
            <template v-slot:top>
                <span style="font-family: 'PT Sans Narrow', sans-serif; font-size: 0.8em;">{{title}}</span>
                <q-space />
                <q-input dense debounce="300" color="primary" v-model="filterDeplasari">
                    <template v-slot:append>
                        <q-icon name="search" />
                    </template>
                </q-input>
            </template>

            <template v-slot:body="props">
                <q-tr :props="props" @click="selectHandler(props.row)">
                    <q-td key="denumire_pentru" :props="props" :sortable="true" class="cursor-pointer">
                        <span style="font-family: 'Nunito Sans', sans-serif; font-size: 0.8em;">{{ props.row.denumire_pentru }}</span>
                    </q-td>
                    <q-td key="tip_deplasare" :props="props" :sortable="true" class="cursor-pointer">
                        <span style="font-family: 'Nunito Sans', sans-serif; font-size: 0.9em;font-weight: bold;" >{{ props.row.tip_deplasare }}</span>
                    </q-td>
                    <q-td key="localitate" :props="props" :sortable="true" class="cursor-pointer">
                        <span style="font-family: 'Nunito Sans', sans-serif; font-size: 0.9em;font-weight: bold;" >{{ props.row.localitate }}</span>
                    </q-td>
                    <q-td key="data_start" :props="props" :sortable="true" class="cursor-pointer">
                        <span style="font-family: 'Nunito Sans', sans-serif; font-size: 0.9em;font-weight: bold;" >{{ displayData(props.row.data_start) }}</span>
                    </q-td>
                    <q-td key="data_end" :props="props" :sortable="true" class="cursor-pointer">
                        <span style="font-family: 'Nunito Sans', sans-serif; font-size: 0.9em;font-weight: bold;" >{{ displayData(props.row.data_end) }}</span>
                    </q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>

<script lang="ts" src="./ComboDeplasari.ts" />

<style scoped />