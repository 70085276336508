import { getModule } from 'vuex-module-decorators';
import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import INomRegistru from '@/types/INomRegistru';
import Nomenclatoare from '@/store/Nomenclatoare';

interface TAjaxResponseNomRegistru {
  status: string;
  message: string;
  rows: INomRegistru[];
}

interface TAjaxResponseEditRegistru {
  status: string;
  message: string;
}

interface TAjaxResponseGetNomRegistru {
  status: string;
  message: string;
  registru: INomRegistru;
}

export class ServiceRegistre {
  public static async getNomRegistre(): Promise<TAjaxResponseNomRegistru> {
    const response = await axios.get(`${CONFIG_ENV.URL_NOM.registre}`);
    return response.data;
  }

  public static async getNomRegistru(appid: number): Promise<TAjaxResponseGetNomRegistru> {
    const response = await axios.get(`${CONFIG_ENV.URL_NOM.registru}/${appid}`);
    return response.data;
  }

  public static async deleteNomRegistru(appid: number): Promise<TAjaxResponseEditRegistru> {
    const response = await axios.delete(`${CONFIG_ENV.URL_NOM.registru}/${appid}`);
    return response.data;
  }

  public static async putNewStatusStampila(appid:number,pNewStatus:string): Promise<TAjaxResponseEditRegistru> {
    const response = await axios.put(`${CONFIG_ENV.URL_NOM.registru_stampila}/${appid}/${pNewStatus}`);
    return response.data;
  }

  public static async putNewStatus(appid:number,pNewStatus:string): Promise<TAjaxResponseEditRegistru> {
    const response = await axios.put(`${CONFIG_ENV.URL_NOM.registru}/${appid}/${pNewStatus}`);
    return response.data;
  }

  public static async putNewStatusPortal(appid:number,pNewStatus:string): Promise<TAjaxResponseEditRegistru> {
    const response = await axios.put(`${CONFIG_ENV.URL_NOM.registru_portal}/${appid}/${pNewStatus}`);
    return response.data;
  }

  public static async postNomRegistru(appid: number, den_registru_lang_1: string, den_registru_lang_2: string, an_permis_pt_inreg:number, nr_de_start: number): Promise<TAjaxResponseEditRegistru> {
    const params = new URLSearchParams();
    params.set('appid', appid.toString());
    params.set('den_registru_lang_1', den_registru_lang_1);
    params.set('den_registru_lang_2', den_registru_lang_2);
    params.set('an_permis_pt_inreg', an_permis_pt_inreg.toString());
    params.set('nr_de_start', nr_de_start.toString());
    const response = await axios.post(`${CONFIG_ENV.URL_NOM.registru}`, params);
    return response.data;
  }

  public static async updateStoreNomRegistreFromDB() {
    const nomenclatoareStore = getModule(Nomenclatoare);
    this.getNomRegistre().then((presponse) => {
      if (presponse.status === 'success') {
        nomenclatoareStore.set_nomregistre(JSON.parse(JSON.stringify(presponse.rows)));
      }
    })
  }
}
