<template>
  <div class="q-pa-md">
    <q-spinner-gears
      v-if="loadingData"
      color="primary"
      size="4em"
      class="absolute-center"
    />
    <div v-else>
      <q-form ref="myForm">
        <div class="well well-sm">
          <div class="panel">
            <div class="q-pa-md">     
              <div style="display: flex; align-items: center; margin-bottom: 15px">
                <div
                  v-bind:class="{
                    column: $q.platform.is.mobile,
                    row: $q.platform.is.desktop,
                  }"
                >
                  <span class="text__title--medium">Referat</span>
                </div>
              </div>

              <div class="row">
                <span class="col-4 form__label">Id</span>
                <span class="col-8 form__label__black">{{referat.id_referat}}</span>
              </div>
                            
              <div class="row">
                <span class="col-4 form__label">Scop / Titlu achizitie</span>
                <span class="col-8 form__label__black">{{referat.denumire}}</span>
              </div>

              <div class="row">
                <span class="col-4 form__label">Motivare descriere detaliata</span>
                <span class="col-8 form__label__black">{{referat.descriere}}</span>              
              </div>

              <div class="row">
                <span class="col-4 form__label">Stare</span>
                <span class="col-8 form__label__black">{{getDenStatus(referat.stare as string)}}</span>              
              </div>              
            </div>

            <div clas="row" style="margin-top: 15px;">
              <div class="q-pa-xs">
                <q-table
                    dense
                    :rows-per-page-options="[0]"
                    :rows="tableDataReferateServicii"
                    :columns="columns"
                    :visible-columns="visibleColumns"
                    row-key="appid"
                    :filter="filterReferateServicii"
                    :loading="loadingData"
                    :pagination.sync="myPagination"
                  >
                  <template v-slot:top>
                    <span class="text__title--medium">Servicii / Articole necesare</span>
                    <q-space />
                    <!--test-->
                    <q-input
                      dense
                      debounce="300"
                      color="primary"
                      v-model="filterReferateServicii"
                    >
                      <template v-slot:append>
                        <q-icon name="search" />
                      </template>
                    </q-input>
                  </template>

                  <template v-slot:body="props">
                    <q-tr :props="props">
                      <q-td key="id_referat_servici" :props="props">
                        {{ props.row.id_referat_servici }}
                      </q-td>
                      <q-td key="id_tip" :props="props" :sortable="true">
                        {{ tipDenumire(props.row.id_tip) }}
                      </q-td>
                      <q-td key="denumire" :props="props" :sortable="true" style="white-space:pre-wrap; word-wrap:break-word">
                        {{ props.row.denumire }}
                      </q-td>
                      <q-td key="valoare" :props="props" :sortable="true">
                        {{ props.row.valoare }}&nbsp;RON
                      </q-td>                    
                    </q-tr>
                  </template>
                </q-table>
              </div>
            </div>

            <div clas="row" style="margin-top: 5px;margin-bottom: 15px;" v-if="referat.stare !== 'initiat'">
                <TaskList :appid="appid" :task="task" :onState="onState" :isSuccess="isSuccess"/>
            </div>
          </div>          
          <br />
          <div class="absolute-bottom-right q-pa-sm" style="margin-top: 15px;margin-right: 10px;">      
            <q-btn
              v-if="referat.appid !== 0"
              label="DESCARCA REFERAT"
              color="brown"
              size="sm"
              @click="onDownload"
              style="margin-left: 5px;"
            />

            <q-btn label="Renunt" type="reset" size="sm" flat @click="onCancel" style="margin-left: 5px;"/>
          </div>
        </div>
      </q-form>
    </div>
  </div>
</template>

<script lang="ts" src="./EditReferatDetail.ts" />

<style scoped>
.q-field--with-bottom {
  padding-bottom: 20px;
}

.q-field__native {
  font-family: "Nunito Sans";
  font-weight: bolder;
}
</style>