import axios from "axios";
import {CONFIG_ENV} from "@/config";
import 'url-search-params-polyfill';
import IPontajProiect from "@/types/IPontajProiect";

interface TAjaxResponsePontajProiect {
    status: string;
    message: string;
    rows: IPontajProiect[];
}

interface TAjaxResponseProiect {
    status: string;
    message: string;
    proiect: IPontajProiect;
}

interface TAjaxResponseEditPontajProiect {
    status: string;
    message: string;
}

interface TAjaxResponseSimple {
    status: string;
    message: string;
    id: number;
}

export class ServicePontajProiect {
    public static async getPontajProiecte(is_all: number, searchParams:any): Promise<TAjaxResponsePontajProiect> {
        const response = await axios.post(`${is_all == 0 ? CONFIG_ENV.URL_PONTAJ_PROIECT.pontajeProiect : CONFIG_ENV.URL_PONTAJ_PROIECT.pontajeProiecte}`, {searchParams});
        return response.data;
    }

    public static async getPontajProiect(id: number): Promise<TAjaxResponseProiect> {
        const response = await axios.get(`${CONFIG_ENV.URL_PONTAJ_PROIECT.getPontajProiect}/${id}`);
        return response.data;
    }

    public static async deletePontajProiect(id: number): Promise<TAjaxResponseEditPontajProiect> {
        const response = await axios.delete(`${CONFIG_ENV.URL_PONTAJ_PROIECT.deletePontajProiect}/${id}`);
        return response.data;
    }

    public static async updatePontajProiect(id_pontaj:number, user_id:number, proiect_id: number, descriere_proiect: string, pontaj_date: string, efort: number): Promise<TAjaxResponseSimple> {
        const params = new URLSearchParams();
        params.set('user_id', user_id.toString());
        params.set('proiect_id', proiect_id.toString());
        params.set('descriere_proiect', descriere_proiect);
        params.set('pontaj_date', pontaj_date);
        params.set('efort', efort.toString());
        
        const response = await axios.post(`${CONFIG_ENV.URL_PONTAJ_PROIECT.editPontajProiect}/${id_pontaj}`, params);
        return response.data;
    }
}
