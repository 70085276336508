import {defineComponent, inject, ref} from 'vue';
import {ServiceHRAdminPrezenta} from '@/modules/ServiceHRAdminPrezenta';
import User from '@/store/User';
import Nomenclatoare from '@/store/Nomenclatoare';
import {getModule} from 'vuex-module-decorators';
import EditTimeRequest from '@/components/HR/EditTimeRequest/EditTimeRequest.vue';
import HeaderTimeAccountsMarca from '@/components/HR/HeaderTimeAccountsMarca/HeaderTimeAccountsMarca.vue';
import IConcediu from '@/types/IConcediu';
import {TTimeAccount} from "@/types/TTimeAccount";
import IPersoana from "@/types/IPersoana";
import IStatusLabel from '@/types/IStatusLabel';
import moment from 'moment';
import {dateToStringDDMonYYYY, getRowNumberTable} from '@/modules/Utils';
import { ServiceNomUsers } from '@/modules/ServiceNomUsers';

interface IConcediuExtended extends IConcediu{ 
    denumire_stare: string
}

const myForm = ref({} as any);
const childHeaderMyTimesRequests=ref(null);

export default defineComponent({
    name: 'AdminEmployeeTimeRequest',

    props: {
        marca: {
            type: Number,
            required: true,
            default: 0,
            readonly: true
        },
        year: {
            type: Number,
            required: true,
            default: 0,
            readonly: true
        },
        closeWindow: {
            type: Function,
            default: undefined
        }
    },

    components: {
        EditTimeRequest, HeaderTimeAccountsMarca
    },

    setup() {
        const emitter = inject("emitter") as any;
        return {
            emitter
        }
    },

    data() {
        return {
            loadingData: false,
            showDialogChildComponent: false,
            titleDynamicComponent: '',
            nameChildDynamicComponent: '',
            selectedAppIdRequest: 0,
            myTimeAccounts : [] as TTimeAccount[],
            filterConcedii: '',
            person:{} as IPersoana,
            selectedTimeRequest:{} as IConcediu,
            userStore: getModule(User),
            nomenclatoareStore: getModule(Nomenclatoare),
            visibleColumns: ['appid', 'tip_concediu', 'nr_working_days', 'start_date', 'end_date', 'denumire_stare', 'track_date_status', 'observatie', 'nr_recall_days', 'recall_date'],
            columns: [
                { name: 'appid', label: 'Id Cerere', field: 'appid', align: 'left', sortable: true },
                { name: 'tip_concediu', label: 'Tip Cerere', field: 'tip_concediu', align: 'left', sortable: true, style: 'max-width: 300px', headerStyle: 'max-width: 300px' },
                { name: 'nr_working_days', label: 'Nr.Zile', field: 'nr_working_days', align: 'left', sortable: true },
                { name: 'start_date', label: 'Prima zi', field: 'start_date', align: 'left', sortable: true },
                { name: 'end_date', label: 'Ultima zi', field: 'end_date', align: 'left', sortable: true },
                { name: 'denumire_stare', label: 'Stare', field: 'denumire_stare', align: 'left', sortable: true },
                { name: 'track_date_status', label: 'Data aprobare', field: 'track_date_status', align: 'left', sortable: true },
                { name: 'observatie', label: 'Observatie', field: 'observatie', align: 'left', sortable: true, style: 'max-width: 200px', headerStyle: 'max-width: 200px' },
                { name: 'nr_recall_days', label: 'Nr zile rechem.', field: 'nr_recall_days', align: 'left', sortable: true },
                { name: 'recall_date', label: 'Data rechemare', field: 'recall_date', align: 'center', sortable: true }
            ],
            myPagination: {
                rowsPerPage: getRowNumberTable()
            },
            tableDataConcedii: [] as IConcediuExtended[]
        }
    },

    methods: {
        goToView(pRouteViewName: string ) {
            this.$router.push({ name: pRouteViewName })
        },

        onOpenFormNewRequest(){
            //console.log('onOpenFormNewRequest')
            this.showDialogChildComponent=true;
            this.titleDynamicComponent='Creaza cerere noua'
            this.nameChildDynamicComponent='EditTimeRequest';
        },

        saveNewRequest(){
            const vueInst = this;
            vueInst.showDialogChildComponent=false;
            vueInst.nameChildDynamicComponent='';
            vueInst.loadingData=true;
            // vueInst.tableDataConcedii=[];
            vueInst.getDataTableDocumente();
            //HeaderTimeAccountsMarca.methods?.getTimeAccounts();            
            // @ts-ignore
            this.$refs.childHeaderMyTimesRequests?.getTimeAccounts();
        },

        cancelNewRequest(){
            const vueInst = this;
            vueInst.showDialogChildComponent=false;
            vueInst.nameChildDynamicComponent='';
        },

        openTimeRequest(pTimeRequest: IConcediu) {
            const vueInst = this;
            vueInst.selectedTimeRequest={...pTimeRequest};
        },

        approveRequest(){
            const vueInst = this;
        },

        rejectRequest(){
            const vueInst = this;
        },

        getPersonByMarca() {
            const vueInst = this;
            vueInst.loadingData=true;
            ServiceNomUsers.getUserByMarca(vueInst.marca).then((response)=>{
                if(response.status=='success'){
                    vueInst.person=response.persoana;
                }
                vueInst.loadingData=false;
            })
        },

        getDataTableDocumente() {
            const vueInst = this;
            vueInst.loadingData=true;
            vueInst.tableDataConcedii=[];
            ServiceHRAdminPrezenta.getAllTimeRequestsForMarca(vueInst.marca).then((response)=>{
                if(response.status=='success'){
                    response.rows.forEach(document=>{
                        vueInst.tableDataConcedii.push({                            
                            ...document,
                            denumire_stare: vueInst.getDenStatus(document.stare, document.flag)
                        })
                    })
                }
                vueInst.loadingData=false;
            })
        },

        getDenStatus(pStatus: string, pFlag: number): string{
            const vueInst=this;
            let result = '' as string;
            const Status = vueInst.optionsStare.find( stare =>{
              return stare.status == pStatus;
            });
            if(Status){
                if ((pFlag & 1) != 0) {
                    result=Status.label + ' Anual';
                } else {
                    result=Status.label;
                }
            }
            return result;
        },
      
        displayStatusColor(pStare:string) {      
            switch(pStare) { 
              case 'initiat': { 
                return 'app__status--concediu--black'
              }
              case 'pending': { 
                 return 'app__status--concediu--orange'
              } 
              case 'assigned': { 
                return 'app__status--concediu--gray'
              }
              case 'finalized': { 
                return 'app__status--concediu--blue'
              }
              case 'approved': { 
                return 'app__status--concediu--green'
              } 
              case 'inapproval': {
                return 'app__status--concediu--orange'
              }
              case 'rejected': {
                return 'app__status--concediu--red'
              }
              default: { 
                return 'app__status--deplasari--orange'
              } 
            }
        },

        displayData(pdata:string) :string{
            if(pdata){
              const day = moment(pdata.substring(0,10), 'YYYY-MM-DD');
              return dateToStringDDMonYYYY(day);
            }else{ 
              return '';
            }
        },

        displayDataRO(pdata:string) :string{
            if(pdata){
              const day = moment(pdata.substring(0,10), 'DD.MM.YYYY');
              return dateToStringDDMonYYYY(day);
            }else{ 
              return '';
            }
        },

        // getTimeAccounts() {
        //     const vueInst = this;
        //     vueInst.loadingData=true;
        //     vueInst.tableDataConcedii=[];
        //     ServiceHRAdminPrezenta.getAllTimeAccountsForMarca(vueInst.marca).then((response)=>{
        //         if(response.status=='success'){
        //             response.rows.forEach(document=>{
        //                 vueInst.myTimeAccounts.push({
        //                     year: document.year,
        //                     marca: document.marca,
        //                     timeCode: document.timeCode,
        //                     timeName: document.timeName,
        //                     nrDaysPerYear: document.nrDaysPerYear,
        //                     nrDaysConsumed: document.nrDaysConsumed,
        //                     nrDaysAvailable: document.nrDaysAvailable
        //                 })
        //             })
        //         }
        //         vueInst.loadingData=false;
        //     })
        // }
    },

    computed: {
        optionsStare() {      
          const stareArray = this.nomenclatoareStore.StareGeneral as IStatusLabel[];
          return stareArray;
        }
    },

    mounted() {
        const vueInst = this;
        vueInst.loadingData=true;
        // vueInst.tableDataConcedii=[];
        vueInst.getPersonByMarca();
        vueInst.getDataTableDocumente();
        // vueInst.getTimeAccounts();
    }
});
