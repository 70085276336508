import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import {getModule} from 'vuex-module-decorators';
import Nomenclatoare from '@/store/Nomenclatoare';
import INomTipDoc from '@/types/INomTipDoc';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type INomTipDocNull = OrNull<INomTipDoc>;

interface TAjaxResponseNomTipDoc {
  status: string;
  message: string;
  rows: INomTipDoc[];
}

interface TAjaxResponseEditNomTipDoc {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetNomTipDoc {
  status: string;
  message: string;
  nomtipdoc: INomTipDocNull;
}

export class ServiceNomTipDoc {
  public static async getNomTipDoc(): Promise<TAjaxResponseNomTipDoc> {
    const response = await axios.get(`${CONFIG_ENV.URL_NOM.tip_docs}`);
    return response.data;
  }

  public static async putNewStatusNomTipDoc(appid:number,pNewStatus:string): Promise<TAjaxResponseEditNomTipDoc> {
    const response = await axios.put(`${CONFIG_ENV.URL_NOM.tip_doc}/status/${appid}/${pNewStatus}`);
    return response.data;
  }

  public static async putNewStatusPortal(appid:number, pNewStatus:boolean): Promise<TAjaxResponseEditNomTipDoc> {
    const response = await axios.put(`${CONFIG_ENV.URL_NOM.tip_doc}/portal/${appid}/${pNewStatus}`);
    return response.data;
  }

  public static async putNewStatusConfidentalityNomTipDoc(appid:number,pNewStatus:string): Promise<TAjaxResponseEditNomTipDoc> {
    const response = await axios.put(`${CONFIG_ENV.URL_NOM.tip_doc}/confidentiality/${appid}/${pNewStatus}`);
    return response.data;
  }

  public static async deleteNomTipDoc(pappid: number): Promise<TAjaxResponseEditNomTipDoc> {
    const response = await axios.delete(`${CONFIG_ENV.URL_NOM.tip_doc}/${pappid}`);
    return response.data;
  }

  public static async postNomTipDoc(appid: number, id_categ: number, den_lang_1: string, den_lang_2: string): Promise<TAjaxResponseEditNomTipDoc> {
    const params = new URLSearchParams();
    params.set('appid', appid.toString());
    params.set('id_categ', id_categ.toString());
    params.set('den_lang_1', den_lang_1);
    params.set('den_lang_2', den_lang_2);
    const response = await axios.post(`${CONFIG_ENV.URL_NOM.tip_doc}`, params);
    return response.data;
  }

  public static async updateStoreNomTipDocsFromDB() {
    const nomenclatoareStore = getModule(Nomenclatoare);
    this.getNomTipDoc().then((presponse) => {
      if (presponse.status === 'success') {
        nomenclatoareStore.set_nomtipdoc(presponse.rows);
      }
    })
  }

  public static async getTipDoc(appid: number): Promise<TAjaxResponseGetNomTipDoc> {
    const response = await axios.get(`${CONFIG_ENV.URL_NOM.tip_doc}/${appid}`);
    return response.data;
  }  

  public static async postTipDocRepartizare(nomTipDoc: INomTipDoc): Promise<TAjaxResponseEditNomTipDoc> {
    const response = await axios.post(`${CONFIG_ENV.URL_NOM.tip_doc_repartizare}`, nomTipDoc);
    return response.data;
  }

  public static async postTipDocTermenRaspuns(nomTipDoc: INomTipDoc): Promise<TAjaxResponseEditNomTipDoc> {
    const response = await axios.post(`${CONFIG_ENV.URL_NOM.tip_doc_termen_raspuns}`, nomTipDoc);
    return response.data;
  }

  public static async postTipDocTermenArhivare(nomTipDoc: INomTipDoc): Promise<TAjaxResponseEditNomTipDoc> {
    const response = await axios.post(`${CONFIG_ENV.URL_NOM.tip_doc_termen_arhivare}`, nomTipDoc);
    return response.data;
  }
}
