<template>
    <div class="q-pa-sm">
        <q-spinner-gears v-if="loadingFacturaData" color="primary" size="4em" class="absolute-center" />
        <div v-else>
            <div class="well well-sm">
                <div class="panel">
                    <div class="panel-body" style="display:flex; align-items:center;">
                        <div style="min-width: 90px; min-height: 90px;">
                            <img :src="getLinkToQrCode" />
                        </div>
                        <div style="display: flex;flex-direction: column">
                            <div v-bind:class="{ 'column': $q.platform.is.mobile, 'row': $q.platform.is.desktop }">
                                <span class="text__title--medium">Seria.</span>
                                <span class="text__title--medium" style='padding-left: 5px;'>{{ factura.seria }}</span>
                            </div>
                            <div v-bind:class="{ 'column': $q.platform.is.mobile, 'row': $q.platform.is.desktop }">
                                <span class="text__title--medium">Nr.</span>
                                <span class="text__title--medium" style='padding-left: 5px;'>{{ factura.nr }}</span>
                            </div>
                            <q-btn color="white" text-color="black" icon-right="print" label="Tipareste!"
                                :disable="loadingPdfForDownload" no-caps :loading="loadingPdfForDownload"
                                @click="prepareCoverPdfForDownload">
                                <template v-slot:loading>
                                    <q-spinner-gears class="on-right" />
                                    Pregatire...
                                </template>
                            </q-btn>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-7" style="padding-right: 5px;">
                        <table class="table table-nocondensed">
                            <tbody>
                                <tr>
                                    <td class="text__title--medium">
                                        <div style="display: flex;">
                                            <span>Status:</span>
                                            <span class="material-icons" style="margin-left: auto;">create</span>
                                        </div>
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{getDenStatus(factura.stare)}}
                                        <q-popup-edit v-model="factura.stare" :validate="val => val.length > 0">
                                            <template v-slot="scope">
                                                <q-select outlined v-model="scope.value" :options="optionsStatus"
                                                    style="min-width:300px;" option-value="value" option-label="label"
                                                    emit-value map-options lazy-rules dense autofocus
                                                    hint="Modificare stare"
                                                    :input-style="{ fontFamily: 'Nunito Sans', fontWeight: 'bolder', color: 'black' }"
                                                    :rules="[val => val && val.length > 0 || 'Alegeti tipul de stare!']">
                                                    <template v-slot:after>
                                                        <q-btn flat dense color="negative" icon="cancel"
                                                            @click.stop="scope.cancel" />
                                                        <q-btn flat dense color="positive" icon="check_circle"
                                                            @click.stop="tryUpdateStatus(scope)"
                                                            :disable="scope.validate(scope.value) === false || scope.initialValue === scope.value" />
                                                    </template>
                                                </q-select>
                                            </template>
                                        </q-popup-edit>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text__title--medium">
                                        Furnizor:
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{ furnizor.denumire, furnizor.adresa }}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text__title--medium">
                                        <div style="display: flex;">
                                            <span>Cont Furnizor:</span>
                                        </div>
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{ contFurnizor.iban }}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text__title--medium">
                                        Cumparator:
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{ cumparator.denumire, cumparator.adresa }}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text__title--medium">
                                        <div style="display: flex;">
                                            <span>Cont Cumparator:</span>
                                        </div>
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{ contCumparator.iban }}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text__title--medium">
                                        <div style="display: flex;">
                                            <span>Moneda:</span>
                                        </div>
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{ factura.moneda }}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text__title--medium">
                                        Data eliberare:
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{ displayData(factura.data_eliberare as string) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text__title--medium">
                                        Data termen:
                                    </td>
                                    <td class="text__input--medium bg-white">
                                        {{ displayData(dataTermen)}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="col-md-5"
                        style="padding-left:2em;display: flex;flex-direction:column; justify-content: center; align-items: flex-start;">
                        <div>
                            <span>Total TVA: </span>
                            <span> {{ totalTvaFormated }}</span>
                        </div>
                        <div>
                            <span>Total NET: </span>
                            <span>{{ totalNetFormated }}</span>
                        </div>
                        <div>
                            <span>Total Factura: </span>
                            <span>{{ totalFacturaFormated }}</span>
                        </div>
                    </div>


                </div>
                <div class="row">
                    <div
                        style="display: flex;align-items: center;justify-content: center; width: 100%;border-bottom: 1px solid black;">
                    </div>

                    <div style="display: flex;align-items: left;justify-content: left; width: 100%;">
                        <div style="margin-top: 5px;">
                            <span class="text__title--medium">Articole/servicii</span>
                        </div>
                        <div>
                            <q-btn flat color="primary" icon="add_box" no-caps :disable="loadingFacturaData"
                                @click="openFormFacturaArticol">
                                <q-tooltip transition-show="rotate" transition-hide="rotate">
                                    Adaugare articol sau serviciu nou
                                </q-tooltip>
                            </q-btn>
                        </div>
                        <br />
                    </div>
                    <div style="display: flex;justify-content: center; width: 100%">
                        <q-spinner v-if="loadingFacturaArticole" color="primary" size="3em" :thickness="10" />
                        <q-table dense :rows-per-page-options="[0]" :rows="facturaArticole"
                            :columns="facturaArticoleListColumns" :visible-columns="visibleFacturaArticoleListColumns"
                            row-key="appid" :filter="filterFacturaArticoleList" :loading="loadingFacturaArticole"
                            :pagination.sync="myPagination" v-else
                            class="table table-bordered table-condensed table-responsive">
                            <template v-slot:top>
                                <q-space />
                                <!--test-->
                                <q-input dense debounce="300" color="primary" v-model="filterFacturaArticoleList">
                                    <template v-slot:append>
                                        <q-icon name="search" />
                                    </template>
                                </q-input>
                            </template>

                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="appid" :props="props" :sortable="false">
                                        <q-icon :name="'edit'" :class="'text-blue cursor-pointer pencil'"
                                            style="font-size: 1.2rem; margin-left: auto;"
                                            @click="onPressEditRowFacturaArticol(props.row)">
                                            <q-tooltip>
                                                <span>Editeaza</span>
                                            </q-tooltip>
                                        </q-icon>
                                        <q-icon :name="'delete'" :class="'text-red cursor-pointer pencil'"
                                            style="font-size: 1.2rem; margin-left: auto;"
                                            @click="(askIfRemoveFacturaArticol(props.row))">
                                            <q-tooltip>
                                                <span>Editeaza</span>
                                            </q-tooltip>
                                        </q-icon>
                                    </q-td>
                                    <q-td key="denumire" :props="props" :sortable="true"
                                        style="white-space:pre-wrap; word-wrap:break-word; max-width: 150pt;">
                                        {{ props.row.denumire }}
                                    </q-td>
                                    <q-td key="unitate_masura" :props="props" :sortable="true"
                                        style="white-space:pre-wrap; word-wrap:break-word">
                                        {{ props.row.unitate_masura }}
                                    </q-td>
                                    <q-td key="cantitate" :props="props" :sortable="true"
                                        style="white-space:pre-wrap; word-wrap:break-word">
                                        {{ props.row.cantitate }}
                                    </q-td>
                                    <q-td key="pret_fara_tva" :props="props" :sortable="true"
                                        style="white-space:pre-wrap; word-wrap:break-word">
                                        {{ props.row.pret_fara_tva }}
                                    </q-td>
                                    <q-td key="tva" :props="props" :sortable="true"
                                        style="white-space:pre-wrap; word-wrap:break-word">
                                        {{ props.row.tva + ' %' }}
                                    </q-td>

                                </q-tr>
                            </template>
                        </q-table>
                    </div>
                </div>
            </div>
        </div>

        <q-dialog v-model="visibleDialogFacturaArticole" persistent>
            <q-card style="min-width: 60vw; max-width: 80vw;">
                <q-bar>
                    <q-icon name="add_box" />
                    <div>Adaugare articol sau serviciu</div>

                    <q-space />

                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>

                <q-card-section>
                    <EditArticolFactura :frenunt="renuntEditareFacturaArticol" :fonsave="getFacturaArticole"
                        :appid_factura="factura.appid" :appid="appid_factura_articol" />
                </q-card-section>
            </q-card>
        </q-dialog>

    </div>
</template>

<script lang="ts" src="./WorkOnFactura.ts" />

<style scoped>
.q-field--with-bottom {
    padding-bottom: 20px;
}

.q-field__native {
    font-family: "Nunito Sans";
    font-weight: bolder;
}

.mousechange:hover {
    cursor: pointer;
}
</style>
