<template>
    <div class="q-pa-md">
        <q-table
            dense
            :rows-per-page-options="[0]"
            :rows="tableDataNomUsers"
            :columns="columns"
            :visible-columns="visibleColumns"
            row-key="marca"
            :filter="filterNomUsers"
            :loading="loadingData"
            :pagination.sync="myPagination"
        >
            <template v-slot:top>
                <div style="width: 100%">
                    <div class="row">
                        <span style="font-family: 'PT Sans Narrow', sans-serif; font-size: 0.8em;">{{title}}</span>
                        <q-space />
                        <q-input dense debounce="300" color="primary" v-model="filterNomUsers">
                            <template v-slot:append>
                                <q-icon name="search" />
                            </template>
                        </q-input>
                    </div>
                    <div class="row" style="width: 150px; margin-top: 5px; margin-bottom: 5px; display: flex; justify-content: flex-start;">
                        <span style="font-family: 'PT Sans Narrow', sans-serif; font-size: 0.9em;">Arata si cele inactive</span>
                        <q-space />
                        <q-toggle
                            dense
                            v-model="celeInactive"
                            checked-icon="lock"
                            debounce="300" color="primary"
                            unchecked-icon="lock_open"
                            false-value="Nu"
                            true-value="Da"
                            @click="changeStatus"
                        />
                    </div>
                </div>
            </template>

            <template v-slot:body="props">
                <q-tr :props="props" @click="selectHandler(props.row)" :style="props.row.is_activ == 'n' ? 'background-color: #dddddd' : ''">
                    <q-td key="nume_prenume" :props="props" :sortable="true">
                        <span style="font-family: 'Nunito Sans', sans-serif; font-size: 0.8em;">{{ props.row.nume_prenume }}</span>
                    </q-td>
                    <!-- <q-td key="lastName" :props="props" :sortable="true">
                        <span style="font-family: 'Nunito Sans', sans-serif; font-size: 0.8em;">{{ props.row.lastName }}</span>
                    </q-td>
                    <q-td key="firstName" :props="props" :sortable="true">
                        <span style="font-family: 'Nunito Sans', sans-serif; font-size: 0.8em;">{{ props.row.firstName }}</span>
                    </q-td> -->
                    <q-td key="organization_unit_descriere" :props="props" :sortable="true">
                        <span style="font-family: 'Nunito Sans', sans-serif; font-size: 0.8em;">{{ props.row.organization_unit_descriere }}</span>
                    </q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>

<script lang="ts" src="./ComboUser.ts" />

<style scoped />