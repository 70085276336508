import {defineComponent, defineAsyncComponent, inject, ref} from 'vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';
import {getModule} from 'vuex-module-decorators';
import axios from 'axios';
import Notify from 'quasar/src/plugins/Notify.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
import moment from 'moment';
import {CONFIG_ENV, APP_NAME, APP_NAME_TITLE, APP_INFORMATION} from '@/config';
import {ServiceNomUsers} from './modules/ServiceNomUsers';
import User from '@/store/User';
import Nomenclatoare from '@/store/Nomenclatoare';
import IUser from '@/types/IUser';
import MenuApp from './components/MenuApp/MenuApp.vue';
import {ServiceNomCateg} from '@/modules/ServiceNomCateg';
import {ServiceNomTipDoc} from '@/modules/ServiceNomTipDoc';
import {ServiceRegistre} from '@/modules/ServiceRegistre';
import {ServiceNomCategActive} from '@/modules/ServiceNomCategActive';
import {ServiceNomTipActive} from '@/modules/ServiceNomTipActive';
import {ServiceNomJob} from '@/modules/ServiceNomJob';
import {ServiceDocument} from '@/modules/ServiceDocument';
import {ServiceHrOrg} from '@/modules/ServiceHrOrg';
import {ServiceTari} from './modules/ServiceTari';
import {ServiceConturi} from './modules/ServiceConturi';
import WorkOnDocument from '@/components/WorkOnDocument/WorkOnDocument.vue';
import WorkOnAsset from '@/views/Asset/WorkOnAsset.vue';
import EditDecontDetail from './views/Deconturi/EditDecontDetail.vue';
import EditDeplasareDetail from './views/Deplasari/EditDeplasareDetail.vue';
import EditProiectDetail from './views/Proiect/EditProiectDetail.vue';
import EditProiectTaskDetail from './views/Proiect/EditProiectTaskDetail.vue';
import EditDocumentCetateniDetail from './views/DocumenteCetateni/EditDocumentCetateniDetail.vue';
import EditCerereDetail from './views/Cerere/EditCerereDetail.vue';
import EditReferatDetail from './views/Referat/EditReferatDetail.vue';
import EditPontajPrezentaDetail from './views/PontajPrezenta/EditPontajPrezentaDetail.vue';
import EditPontajProiectDetail from './views/PontajProiect/EditPontajProiectDetail.vue';
import EditTimeRequestDetail from './components/HR/EditTimeRequest/EditTimeRequestDetail.vue';
import EditCertificateUrbanismDetail from './views/Documente/EditCertificateUrbanismDetail.vue';
import EditServiciiDeTransportDetail from './views/Documente/EditServiciiDeTransportDetail.vue';
import {dateToStringDDMonYYYYRo, dateToStringDDMMYYYY} from '@/modules/Utils';
import WorkOnPartener from '@/views/Parteneri/WorkOnPartener.vue';
import WorkOnFactura from '@/views/Facturi/WorkOnFactura.vue';
import AdminEmployeeTimeRequest from "@/components/HR/AdminEmployeeTimeRequest/AdminEmployeeTimeRequest.vue";
import {ServiceUser} from "@/modules/ServiceUser";
import IDocumentFastSearch from './types/IDocumentFastSearch';
import { abbreviateNumber } from '@/modules/Utils';
import EventSourceNrBadge from './store/EventSourceNrBadge';
import IEventSourceNrBadge from './types/IEventSourceNrBadge';
import Company from './store/Company';
import ICompanyDrept from './types/ICompanyDrept';
import useMeta from 'quasar/src/composables/use-meta.js';;
import { useI18n } from 'vue-i18n';
import Tr from "@/i18n/translation";
import setCssVar from 'quasar/src/utils/set-css-var.js';;

const myForm = ref({} as any);

const DialogEditGrantCompanyDefault = defineAsyncComponent({
    loader: () => import('@/components/EditGrant/EditGrantCompanyDefault.vue'),
    loadingComponent: LoadingComponent,
    errorComponent: ErrorComponent,
    // The error component will be displayed if a timeout is
    // provided and exceeded. Default: Infinity.
    timeout: 3000,
    delay: 200
});

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// axios.defaults.headers.common['X-CSRF-TOKEN'] = '1t6bh0lwh72pv.2k9aurnh5tk44';

interface IDocumentFastSearchExtended extends IDocumentFastSearch{ 
    den_tip_doc: string,
    den_registru: string,
    ref_den_registru: string
}

const metaData = {
    title: (['APP_BRANDTEAM'].includes(APP_NAME) ? 'Brandteam' : 'Odocs')
}

export default defineComponent({
    name: 'App',

    components: {
        MenuApp, WorkOnDocument, WorkOnAsset, EditDecontDetail, EditDeplasareDetail, EditProiectDetail, 
        EditProiectTaskDetail, EditDocumentCetateniDetail, EditCerereDetail, EditReferatDetail, WorkOnFactura, WorkOnPartener,
        EditPontajPrezentaDetail, EditPontajProiectDetail, EditTimeRequestDetail, AdminEmployeeTimeRequest, 
        DialogEditGrantCompanyDefault, EditCertificateUrbanismDetail, EditServiciiDeTransportDetail
    },

    setup() {
        const emitter = inject("emitter") as any;
        useMeta(metaData);
        const { t, locale } = useI18n();
        
        return {
            emitter,
            myForm,
            t, locale
        }
    },

    data() {
        return {
            leftMenuOpen: false,
            rightMenuOpen: false,
            showPreviewFile: false,
            downloadingFile: false,
            inputFastSearch: ref(''),
            nr_of_recordsFastSearch: 0,
            loadingResultForFastSearch: false,
            refOptionsFastSearch: false,
            titleForPreviewForm: '',
            withFinalizeButton: 'no',
            withRepartizareButton: 'no',
            urlBlobForPreviewFile: '',
            nrRanduriTabel: 10,
            appidDocToWorkOnIt: 0,
            appidAssetToWorkOnIt: 0,
            appidDecontToWorkOnIt: 0,
            appidDeplasareToWorkOnIt: 0,            
            appidProiectToWorkOnIt: 0,
            appidProiectTaskToWorkOnIt: 0,            
            appidDocumentCetateniToWorkOnIt: 0,
            appidCerereToWorkOnIt: 0,
            appidReferatToWorkOnIt: 0,
            appidPontajPrezentaWorkOnIt: 0,
            appidPontajProiectToWorkOnIt: 0,
            appidTimeRequestWorkOnIt: 0,
            appidCertificateUrbanismWorkOnIt: 0,
            appidServiciiTransportWorkOnIt: 0,
            marcaTimeRequestsWorkOnIt: 0,
            appidFacturaToWorkOnIt: 0,
            appidPartenerToWorkOnIt: 0,
            yearTimeRequestsWorkOnIt: 0,
            task: {},
            optionsFastSearch: [] as IDocumentFastSearchExtended[],
            userStore: getModule(User),
            companyStore: getModule(Company),
            nomenclatoareStore: getModule(Nomenclatoare),
            div_el: undefined as HTMLDivElement | undefined,
            iframe: null,
            mesajSeLoadingNomenclatoare: '',
            actionName: '',
            isFullScreen: false,
            isStatePasswordReset: false,
            is_all: 0,
            isPDFFileType: false,
            eventSourceNrBadgeStore: getModule(EventSourceNrBadge),
            accesFrom: '',
            visibleDialogEditGrantCompanyDefault: false,
            refreshIntervalId: null as any,
            dialogOpen: false,
            dialogOpenReference: false,
            appidDocToWorkOnItReference: 0,
            withFinalizeButtonReference: 'no',
            withRepartizareButtonReference: 'no',
            taskReference: {},
            isFullScreenReference: false,
            accesFromReference: '',
            loadingData: false,
            aboutPopupOpen: false,
            processEnvMode: process.env.NODE_ENV,
            currentLocale: '',
            isADUserLogged: false
        };
    },

    methods: {
        getAppUser($q) {
            const vueInst = this;
            // const $q = useQuasar()
            const isAuthenticatedUser = localStorage.getItem('statusAuth') || 'no';
            
            if (isAuthenticatedUser == 'yes' && window.location.pathname.indexOf('login') == -1 && window.location.pathname.indexOf('change_password') == -1) {
                vueInst.checkToken($q);
            } else {
                if(window.location.pathname.indexOf('change_password') == -1) {
                    $q.loading.hide();
                    vueInst.$router.push({name: 'Login'});
                }else{
                    $q.loading.hide();
                }
            }
        },

        getUserFromAD($q){
            const vueInst = this;
            // const $q = useQuasar()

            $q.loading.show({
                message: 'Se verifica utilizatorul...',
                html: true
            })

            ServiceNomUsers.getCurrentUserFromAD().then(presponse=>{
                vueInst.mesajSeLoadingNomenclatoare = this.$t('app.text_load_nomenclature');
                // console.log('%cSe incarca userul din baza de date', "color: blue;font-size:18px;");
                // console.log('presult=%o', presult);
                if(presponse.status=='success'){
                    vueInst.isADUserLogged = true;
                    console.log('%cS-a incarcat userul din baza de date', "color: blue;font-size:18px;");
                    vueInst.nomenclatoareStore.set_currentpagetitle('');
                    vueInst.userStore.set_user(presponse.user);
                    vueInst.userStore.set_status_session(true);
                    
                    localStorage.setItem('token', presponse.user.csrf_token);
                    localStorage.setItem('statusAuth', 'yes');
                    localStorage.setItem('userid', presponse.user.userid.toString());

                    if (localStorage.getItem('nrRanduriTabel')) {
                        vueInst.nrRanduriTabel = Number(localStorage.getItem("nrRanduriTabel"));
                    } else {
                        vueInst.nrRanduriTabel = 10;
                        localStorage.setItem('nrRanduriTabel', vueInst.nrRanduriTabel.toString());
                    }
                    
                    vueInst.getNomenclatoareAndGoToRequestedComponent($q);

                    if (Number(vueInst.userStore.User?.userid) != 0){
                        vueInst.startSSE();
                    } 
                } else {
                    vueInst.mesajSeLoadingNomenclatoare = '';
                    $q.loading.hide();
                }
            }).catch((error) => {
                $q.loading.hide();
                if (!error.response) {
                   vueInst.mesajSeLoadingNomenclatoare = 'Serverul nu raspunde! Apasati F5 pentru a incerca din nou!'
                } else {
                    vueInst.mesajSeLoadingNomenclatoare = 'Eroare: ' + error.response.data.message;
                }
            });
        },

        checkToken($q){
            const vueInst = this;
            // const $q = useQuasar()

            $q.loading.show({
                message: 'Se verifica utilizatorul...',
                html: true
            })
            
            ServiceUser.checkToken().then((presult) => {
                vueInst.mesajSeLoadingNomenclatoare = this.$t('app.text_load_nomenclature');
                // console.log('%cSe incarca userul din baza de date', "color: blue;font-size:18px;");
                // console.log('presult=%o', presult);
                if (presult.status == 'success') {
                    console.log('%cS-a incarcat userul din baza de date', "color: blue;font-size:18px;");
                    vueInst.nomenclatoareStore.set_currentpagetitle('');
                    vueInst.userStore.set_user(presult.user);
                    vueInst.userStore.set_status_session(true);

                    localStorage.setItem('token', presult.user.csrf_token);
                    localStorage.setItem('statusAuth', 'yes');
                    localStorage.setItem('userid', presult.user.userid.toString());

                    if (localStorage.getItem('nrRanduriTabel')) {
                        vueInst.nrRanduriTabel = Number(localStorage.getItem("nrRanduriTabel"));
                    } else {
                        vueInst.nrRanduriTabel = 10;
                        localStorage.setItem('nrRanduriTabel', vueInst.nrRanduriTabel.toString());
                    }

                    vueInst.getNomenclatoareAndGoToRequestedComponent($q);

                    if (Number(vueInst.userStore.User?.userid) != 0){
                        vueInst.startSSE();
                    } 
                } else {
                    console.log('%cUser-ul nu a reusit sa auntentifica cu token in aplicatie...', "color: red;font-size:18px;");
                    vueInst.nomenclatoareStore.set_currentpagetitle('');
                    vueInst.userStore.set_status_session(false);
                    vueInst.userStore.set_csrf_token('');
                    vueInst.userStore.set_user({} as IUser);
                    localStorage.setItem('token', '');
                    localStorage.setItem('statusAuth', 'no');
                    localStorage.setItem('userid', '');

                    vueInst.mesajSeLoadingNomenclatoare = '';
                    $q.loading.hide();
                    vueInst.$router.push({name: 'Login'});
                }
            }).catch((error) => {
                $q.loading.hide();
                if (!error.response) {
                   vueInst.mesajSeLoadingNomenclatoare = 'Serverul nu raspunde! Apasati F5 pentru a incerca din nou!'
                } else {
                    vueInst.mesajSeLoadingNomenclatoare = 'Eroare: ' + error.response.data.message;
                }
            });
        },

        openPreviewForm(pAppId: number, pUrlToGetFile: string) {
            const vueInst = this;

            fetch(`${pUrlToGetFile}/${pAppId}`, {
                headers: 
                    (['APP_DEV', 'APP_URBANESQ'].includes(APP_NAME)) ? 
                        {
                            odocsuserid: vueInst.userStore.User.userid.toString(),
                            odocsusertoken: vueInst.userStore.User.csrf_token
                        } : 
                        {
                            Odocsuserid: vueInst.userStore.User.userid.toString(),
                            Odocsusertoken: vueInst.userStore.User.csrf_token
                        }
             })
            .then(resp => resp.blob())
            .then(blob => {
                let isError = false;
                blob.text().then(text => {
                    if (text.includes('Eroare:')) {
                        Notify.create({
                            color: 'red',
                            textColor: 'white',
                            type: 'negative',
                            message: text,
                            position: 'top',
                            timeout: 3500,
                            html: true
                        });
        
                        isError = true;
                    }
                });

                setTimeout(() => {
                    if (isError == false) {
                        vueInst.urlBlobForPreviewFile = window.URL.createObjectURL(blob);
                        vueInst.isPDFFileType = blob.type == 'application/pdf';

                        if (vueInst.isPDFFileType) {
                            if (this.$q.platform.is.mobile) {
                                setTimeout(function () {                        
                                    vueInst.downloadingFile = false;
                                    const divPreview = window.document.getElementById('iframe_preview_scan') as HTMLIFrameElement
                                    if(divPreview){
                                        divPreview.src = '/' + 'pdf_viewer/web/viewer.html?file=' + vueInst.urlBlobForPreviewFile; 
                                    }
                                }, 500);
                            } else {// is desktop
                                setTimeout(function () {
                                    vueInst.downloadingFile = false;
                                    const divPreview = window.document.getElementById('iframe_preview_scan') as HTMLIFrameElement;
                                    if(divPreview){
                                        divPreview.src = vueInst.urlBlobForPreviewFile;
                                    }
                                }, 500);
                                // document.querySelector("iframe").src = vueInst.urlBlobForPreviewFile;

                                /*      ServiceDocument.getPdfCoverDocument(pappid).then((response) => {
                                        const url = window.URL.createObjectURL(response.data);
                                        // @ts-ignore
                                        document.querySelector("iframe").src = url;
                                    });*/
                            }
                        }
                    } else {                    
                        vueInst.showPreviewFile = false;                    
                    }
                }, 1000);
            })
            .catch((err) => {
                Notify.create({
                    color: 'red',
                    textColor: 'white',
                    type: 'negative',
                    message: err.message ? 'Eroare! Fisierul nu poate fi descarcat! ' + err.message : 'Eroare! Fisierul nu poate fi descarcat!',
                    position: 'top',
                    timeout: 3500,
                    html: true
                })
            });
        },

        openPreviewFormDocumentPDF(pappid: number) {
            const vueInst = this;
            const urlToGetFile = `${CONFIG_ENV.URL_DOCUMENT.downloadPDFQuadCode}/${pappid}`;            
            
            fetch(urlToGetFile, { 
                headers: 
                    (['APP_DEV', 'APP_URBANESQ'].includes(APP_NAME)) ? 
                        {
                            odocsuserid: vueInst.userStore.User.userid.toString(),
                            odocsusertoken: vueInst.userStore.User.csrf_token
                        } : 
                        {
                            Odocsuserid: vueInst.userStore.User.userid.toString(),
                            Odocsusertoken: vueInst.userStore.User.csrf_token
                        }
             })
            .then(resp => resp.blob())
            .then(blob => {
                let isError = false;
                blob.text().then(text => {
                    if (text.includes('Eroare:')) {
                        Notify.create({
                            color: 'red',
                            textColor: 'white',
                            type: 'negative',
                            message: text,
                            position: 'top',
                            timeout: 3500,
                            html: true
                        });
        
                        isError = true;
                        setTimeout(() => {
                            vueInst.showPreviewFile = false;
                        }, 1000);
                    }
                });

                if (isError == false) {
                    vueInst.urlBlobForPreviewFile = window.URL.createObjectURL(blob);
                    vueInst.isPDFFileType = blob.type == 'application/pdf';

                    if (vueInst.isPDFFileType) {
                        if (this.$q.platform.is.mobile) {
                            setTimeout(function () {
                                vueInst.downloadingFile = false;
                                const divPreview = window.document.getElementById('iframe_preview_scan') as HTMLIFrameElement
                                if(divPreview){
                                    divPreview.src = '/' + 'pdf_viewer/web/viewer.html?file=' + vueInst.urlBlobForPreviewFile; 
                                }
                            }, 500);
                        } else {// is desktop
                            setTimeout(function () {
                                vueInst.downloadingFile = false;
                                const divPreview = window.document.getElementById('iframe_preview_scan') as HTMLIFrameElement;
                                if(divPreview){
                                    divPreview.src = vueInst.urlBlobForPreviewFile; 
                                }
                            }, 500);
                            // document.querySelector("iframe").src = vueInst.urlBlobForPreviewFile;

                            /*      ServiceDocument.getPdfCoverDocument(pappid).then((response) => {
                                    const url = window.URL.createObjectURL(response.data);
                                    // @ts-ignore
                                    document.querySelector("iframe").src = url;
                                });*/
                        }
                    } 
                }
            })
            .catch((err) => {
                Notify.create({
                    color: 'red',
                    textColor: 'white',
                    type: 'negative',
                    message: err.message ? 'Eroare! Fisierul nu poate fi descarcat! ' + err.message : 'Eroare! Fisierul nu poate fi descarcat!',
                    position: 'top',
                    timeout: 3500,
                    html: true
                })
            });
        },

        getNomenclatoareAndGoToRequestedComponent($q: any) {
            const vueInst = this;
            console.log('%cgetNomenclatoareAndGoToRequestedComponent<-------------------------', "color: red;font-size:16px;");

            const willUpdateStoreNomCategFromDB = new Promise(
                (resolve, reject) => {
                    console.log('%cwillUpdateStoreNomCategFromDB from app.ts', "color: red;font-size:16px;")
                    const nomenclatoareStore = getModule(Nomenclatoare);
                    ServiceNomCateg.getNomCateg().then((presponse) => {
                        if (presponse.status === 'success') {
                            nomenclatoareStore.set_nomcategorii(JSON.parse(presponse.rows));
                            resolve('success');
                        } else {
                            reject('error');
                        }
                    });
                });
            const willUpdateStoreNomTipDocsFromDB = new Promise(
                (resolve, reject) => {
                    const nomenclatoareStore = getModule(Nomenclatoare);
                    ServiceNomTipDoc.getNomTipDoc().then((presponse) => {
                        if (presponse.status === 'success') {
                            nomenclatoareStore.set_nomtipdoc(presponse.rows);
                            resolve('success');
                        } else {
                            reject('error');
                        }
                    });
                });
            const willUpdateStoreNomCategActiveFromDB = new Promise(
                (resolve, reject) => {
                    console.log('%cwillUpdateStoreNomCategActiveFromDB from app.ts', "color: red;font-size:16px;")
                    const nomenclatoareStore = getModule(Nomenclatoare);
                    ServiceNomCategActive.getNomCategActive().then((presponse) => {
                        if (presponse.status === 'success') {
                            nomenclatoareStore.set_nomcategorii_active(JSON.parse(presponse.rows));
                            resolve('success');
                        } else {
                            reject('error');
                        }
                    });
                });
            const willUpdateStoreNomTipActivesFromDB = new Promise(
                (resolve, reject) => {
                    const nomenclatoareStore = getModule(Nomenclatoare);
                    ServiceNomTipActive.getNomTipActive().then((presponse) => {
                        if (presponse.status === 'success') {
                            nomenclatoareStore.set_nomtip_active(JSON.parse(presponse.rows));
                            resolve('success');
                        } else {
                            reject('error');
                        }
                    });
                });
            const willUpdateStoreNomJobsFromDB = new Promise(
                (resolve, reject) => {
                    const nomenclatoareStore = getModule(Nomenclatoare);
                    ServiceNomJob.getNomJobs().then((presponse) => {
                        if (presponse.status === 'success') {
                            nomenclatoareStore.set_nom_jobs(presponse.rows);
                            resolve('success');
                        }
                        else {
                            reject('error');
                        }
                    });
                });
            const willUpdateStoreTariFromDB = new Promise(
                (resolve, reject) => {
                    const nomenclatoareStore = getModule(Nomenclatoare);
                    ServiceTari.getTari().then((presponse) => {
                        if (presponse.status === 'success') {
                            nomenclatoareStore.set_tari(JSON.parse(presponse.rows));
                            resolve('success');
                        }
                        else {
                            reject('error');
                        }
                    });
                });
            const willUpdateStoreNomOrgUnitsFromDB = new Promise(
                (resolve, reject) => {
                    const nomenclatoareStore = getModule(Nomenclatoare);
                    ServiceHrOrg.getNomOrgUnits().then((presponse) => {
                        if (presponse.status === 'success') {
                            nomenclatoareStore.set_nomorg_units(presponse.rows);
                            resolve('success');
                        }
                        else {
                            reject('error');
                        }
                    });
                });
            const willUpdateStoreNomOrgUnitsTypeFromDB = new Promise(
                (resolve, reject) => {
                    const nomenclatoareStore = getModule(Nomenclatoare);
                    ServiceHrOrg.getNomOrgUnitsTypes().then((presponse) => {
                        if (presponse.status === 'success') {
                            nomenclatoareStore.set_nom_org_units_types(presponse.rows);
                            resolve('success');
                        }
                        else {
                            reject('error');
                        }
                    });
                });
            const willUpdateStoreNomRegistreFromDB = new Promise(
                (resolve, reject) => {
                    const nomenclatoareStore = getModule(Nomenclatoare);
                    ServiceRegistre.getNomRegistre().then((presponse) => {
                        if (presponse.status === 'success') {
                            nomenclatoareStore.set_nomregistre(JSON.parse(JSON.stringify(presponse.rows)));
                            resolve('success');
                        }
                        else {
                            reject('error');
                        }
                    });
                });
            const willUpdatePersoaneUtilizatoareDB = new Promise(
                (resolve, reject) => {
                    const nomenclatoareStore = getModule(Nomenclatoare);
                    ServiceNomUsers.getNomPersoaneUtilizatoare().then((presponse) => {
                        if (presponse.status === 'success') {
                            nomenclatoareStore.set_nompersoane_utilizatoare(JSON.parse(JSON.stringify(presponse.rows)));
                            resolve('success');
                        }
                        else {
                            reject('error');
                        }
                    });
                });
            const willUpdateStoreConturiFromDB = new Promise(
                (resolve, reject) => {
                    const nomenclatoareStore = getModule(Nomenclatoare);
                    ServiceConturi.getConturi().then((presponse) => {
                        if (presponse.status === 'success') {
                            nomenclatoareStore.set_conturi(JSON.parse(presponse.rows));
                            resolve('success');
                        }
                        else {
                            reject('error');
                        }
                    });
                });
            //ServiceNomCateg.updateStoreNomCategFromDB();
            //ServiceNomTipDoc.updateStoreNomTipDocsFromDB();
            Promise.all([willUpdateStoreNomCategFromDB, willUpdateStoreNomTipDocsFromDB,
                willUpdateStoreNomCategActiveFromDB, willUpdateStoreNomTipActivesFromDB, willUpdateStoreNomJobsFromDB,
                willUpdateStoreNomRegistreFromDB, /*willUpdateStoreNomOrganizatiiFromDB,*/
                willUpdateStoreNomOrgUnitsFromDB, willUpdateStoreNomOrgUnitsTypeFromDB, willUpdatePersoaneUtilizatoareDB,
                willUpdateStoreTariFromDB, willUpdateStoreConturiFromDB]) //, willUpdateStoreNomFluxCategoryFromDB
            .then(result => {
                console.log('Promise.all cu succes!');
                //console.log(result); //["success", "success", "success", "success"]
                const appidToView = this.$router.currentRoute.value.params.appid;
                const currentRouteName = this.$router.currentRoute.value.name;
                if(currentRouteName === 'ViewDocument') {
                    vueInst.$router.push({name: 'ViewDocument', params: { appid: appidToView }}).catch(err => {console.log('already there')});
                }else{
                    vueInst.$router.push({name: 'Home'});
                }

                $q.loading.hide();
                setTimeout(() => {
                    vueInst.mesajSeLoadingNomenclatoare = (!vueInst.statusNomenclatoare && vueInst.userStore.User.is_activ == 'y') ? 'Nomenclatoarele nu au putut fi incarcate! Apasati F5 pentru a incerca din nou!' : '';
                }, 2000);
            })
            .catch(error => console.log(`Error in executing getNomenclatoareAndGoToRequestedComponent ${error}`));
        },

        putFocusBackToFastSearch(){
            const vueInst = this;
            // @ts-ignore
            vueInst.$refs.refInputFastSearch.focus();
        },

        setNrRanduriTabel(scope:any){
            const vueInst = this;
            if(scope.value){
                vueInst.nrRanduriTabel = Number(scope.value);
                localStorage.setItem('nrRanduriTabel', vueInst.nrRanduriTabel.toString());
                vueInst.$q.notify({
                    color: 'teal',
                    textColor: 'white',
                    type: 'positive',
                    message: "S-a salvat cu succes!",
                    position: 'top',
                    timeout: 1000,
                    html: true
                });
                scope.set();
            }
        },

        getDocumentFromFastSearch(params: any){
            const vueInst = this;
            vueInst.refOptionsFastSearch = false;
            // console.log('getDocumentFromFastSearch vueInst.inputFastSearch=%o params=%o',vueInst.inputFastSearch,params);
            vueInst.dialogOpen = true;
            vueInst.actionName='openDocumentDialog';
            vueInst.appidDocToWorkOnIt = params.appid;
            //document.getElementById('span_pt_simulate_click')?.click();
        },

        getOptionsDocumentsFastSearch(pInputSearch: string){
            const vueInst = this;
            // console.log('getOptionsDocumentsFastSearch=',pInputSearch)
            vueInst.loadingResultForFastSearch = true;            
            vueInst.refOptionsFastSearch = true;
            vueInst.loadingData = true;
            
            if(pInputSearch.length>0){
                this.optionsFastSearch = [];
                ServiceDocument.fastSearch(pInputSearch).then(presponse=>{
                    vueInst.loadingData = false;
                    if(presponse.status=='success'){
                        vueInst.nr_of_recordsFastSearch = presponse.nr_of_records;
                        vueInst.optionsFastSearch = presponse.rows.map(pDocument=>{
                        const day = moment(pDocument.data_doc.substring(0,10), 'YYYY-MM-DD');
                        const dataDocToDisplayInFastSearch = dateToStringDDMonYYYYRo(day);
                        const ref_dataDocToDisplayInFastSearch = pDocument.ref_data_doc != null ? dateToStringDDMonYYYYRo(moment(pDocument.ref_data_doc.substring(0,10), 'YYYY-MM-DD')) : '';
                        const tmpTipDoc = vueInst.nomenclatoareStore.NomTipDoc.find(ptipdoc=>ptipdoc.appid==pDocument.id_tip_document);
                        const tmpRegistru = vueInst.nomenclatoareStore.NomRegistre.find(pregistru=>pregistru.appid==pDocument.id_registru);
                        const tmpRefRegistru = vueInst.nomenclatoareStore.NomRegistre.find(pregistru=>pregistru.appid==pDocument.ref_id_registru);
                        return{
                            appid: Number(pDocument.appid),
                            id_doc: Number(pDocument.id_doc),
                            data_doc: dataDocToDisplayInFastSearch,
                            id_categ: pDocument.id_categ,
                            id_tip_document: pDocument.id_tip_document,
                            id_registru: pDocument.id_registru,                            
                            tip_request: pDocument.tip_request,
                            nume_denumire: pDocument.nume_denumire,                            
                            ref_nr_doc: pDocument.ref_nr_doc,
                            ref_data_doc: ref_dataDocToDisplayInFastSearch,
                            ref_id_registru: pDocument.ref_id_registru,
                            appid_referinta: pDocument.appid_referinta,
                            den_tip_doc: (tmpTipDoc?tmpTipDoc.den_lang_1:''),
                            den_registru: (tmpRegistru?tmpRegistru.den_registru_lang_1:''),
                            ref_den_registru: (tmpRefRegistru?tmpRefRegistru.den_registru_lang_1:'')
                        }});
                    }
                    vueInst.refOptionsFastSearch = vueInst.optionsFastSearch.length > 0;
                    vueInst.loadingResultForFastSearch = false;
                    vueInst.putFocusBackToFastSearch();
                });
            } else {
                vueInst.refOptionsFastSearch = false;
                vueInst.loadingResultForFastSearch = false;
                vueInst.loadingData = false;
            }
        },

        clearInputFastSearch(){
            this.inputFastSearch = '';
            this.loadingResultForFastSearch = false;
            this.refOptionsFastSearch = false;
            this.optionsFastSearch = [];
        },

        closeWindowDocument(){
            this.dialogOpen = false;
            this.appidDocToWorkOnIt = 0;

            this.emitter.emit('eventFromChildChanged', {action: 'Document', accesFrom: this.accesFrom});
          
            if (this.accesFrom === 'mydocuments') {
                this.emitter.emit('eventFromToDashboard', {action: 'MyDocument'});
            } else if (this.accesFrom === 'mydocumentspending') {
                this.emitter.emit('eventFromToDashboard', {action: 'PendingRegisteringDocument'});
            }
        },

        closeWindowDocumentReference(){
            this.dialogOpenReference = false;
            this.appidDocToWorkOnItReference = 0;

            this.actionName = 'openDocumentDialog';
        },
        
        closeWindowAsset(){
            this.dialogOpen = false;
            this.appidAssetToWorkOnIt = 0;
            this.emitter.emit('eventFromChildChanged', {action: 'Asset', accesFrom: ''});
        },

        closeWindowDecont(){
            this.dialogOpen = false;
            this.appidDecontToWorkOnIt = 0;
            this.emitter.emit('eventFromChildChanged', {action: 'Decont', accesFrom: ''});
            this.emitter.emit('eventFromToDashboard', {action: 'ActiuniTaskuri'});
        },

        closeWindowDeplasare(){
            this.dialogOpen = false;
            this.appidDeplasareToWorkOnIt = 0;
            this.emitter.emit('eventFromChildChanged', {action: 'Deplasare', accesFrom: ''});
            this.emitter.emit('eventFromToDashboard', {action: 'ActiuniTaskuri'});
        },
        
        closeWindowProiect(){
            this.dialogOpen = false;
            this.appidProiectToWorkOnIt = 0;
            this.emitter.emit('eventFromChildChanged', {action: 'Proiect', accesFrom: ''});
        },

        closeWindowProiectTask(){
            this.dialogOpen = false;
            this.appidProiectTaskToWorkOnIt = 0;
            this.emitter.emit('eventFromChildChanged', {action: 'ProiectTask', accesFrom: ''});
            this.emitter.emit('eventFromToDashboard', {action: 'ProiectTask'});
        },

        closeWindowDocumentCetateni(){
            this.dialogOpen = false;
            this.appidDocumentCetateniToWorkOnIt = 0;
            this.emitter.emit('eventFromChildChanged', {action: 'DocumentCetateni', accesFrom: ''}); 
        },

        closeWindowCerere(){
            this.dialogOpen = false;
            this.appidCerereToWorkOnIt = 0;
            this.emitter.emit('eventFromChildChanged', {action: 'Cerere', accesFrom: ''});
            this.emitter.emit('eventFromToDashboard', {action: 'ActiuniTaskuri'});           
        },

        closeWindowReferat(){
            this.dialogOpen = false;
            this.appidReferatToWorkOnIt = 0;
            this.emitter.emit('eventFromChildChanged', {action: 'Referat', accesFrom: ''});
            this.emitter.emit('eventFromToDashboard', {action: 'ActiuniTaskuri'});
        },

        closeWindowPontajPrezenta() {
            this.dialogOpen = false;            
            this.appidPontajPrezentaWorkOnIt = 0;
            this.emitter.emit('eventFromChildChanged', {action: 'PontajPrezenta', accesFrom: ''});
            this.emitter.emit('eventFromToDashboard', {action: 'ActiuniTaskuri'});
        },        

        closeWindowPontajProiect() {
            this.dialogOpen = false;            
            this.appidPontajProiectToWorkOnIt = 0;
            this.emitter.emit('eventFromChildChanged', {action: 'PontajProiect', accesFrom: ''});
            this.emitter.emit('eventFromToDashboard', {action: 'ActiuniTaskuri'});
        },

        closeWindowTimeRequest() {
            this.dialogOpen = false;
            this.appidTimeRequestWorkOnIt = 0;
            this.emitter.emit('eventFromChildChanged', {action: 'TimeRequest', accesFrom: ''});
            this.emitter.emit('eventFromToDashboard', {action: 'ActiuniTaskuri'});
        },

        closeWindowPartener(){
            this.dialogOpen = false;
            this.appidAssetToWorkOnIt = 0;
            this.emitter.emit('eventFromChildChanged', {action: 'Partener', accesFrom: ''});
        },

        closeWindowFactura(){
            this.dialogOpen = false;
            this.appidAssetToWorkOnIt = 0;
            this.emitter.emit('eventFromChildChanged', {action: 'Factura', accesFrom: ''});
        },

        closeWindowCertificateUrbanism() {
            this.dialogOpen = false;            
            this.appidCertificateUrbanismWorkOnIt = 0;            
            this.emitter.emit('eventFromChildChanged', {action: 'CertificateUrbanism', accesFrom: ''});
            this.emitter.emit('eventFromToDashboard', {action: 'ActiuniTaskuri'});
        },

        closeWindowServiciiTransport() {
            this.dialogOpen = false;            
            this.appidServiciiTransportWorkOnIt = 0;            
            this.emitter.emit('eventFromChildChanged', {action: 'ServiciiTransport', accesFrom: ''});
            this.emitter.emit('eventFromToDashboard', {action: 'ActiuniTaskuri'});
        },

        closeWindowPersonTimeRequest() {
            this.dialogOpen = false;
            this.marcaTimeRequestsWorkOnIt = 0;
            this.yearTimeRequestsWorkOnIt = 0;
        },

        openChangePassword() {
            this.$router.push({ name: 'ChangePassword', params: { userid: this.user.userid } });
        },

        openChangeCompany() {
            const vueInst = this;
            vueInst.visibleDialogEditGrantCompanyDefault = true;
        },

        closeWindowCompany() {
            const vueInst = this;
            vueInst.visibleDialogEditGrantCompanyDefault = false;
        },

        updateStoreCompanyDefault() {
            const vueInst = this;
            vueInst.visibleDialogEditGrantCompanyDefault = false;

            // const currentPage = this.$router.currentRoute.value;
            vueInst.$router.go(0);
        },
        
        logOut() {
            const vueInst = this;
            ServiceUser.logOut((vueInst.user as IUser).userid.toString()).then(response=>{
                if(response.status=='success'){                      
                    console.log('%cUser-ul a iesit din aplicatie...', "color: blue;font-size:18px;");
                    vueInst.nomenclatoareStore.set_currentpagetitle('');
                    vueInst.userStore.set_status_session(false);
                    vueInst.userStore.set_csrf_token('');
                    vueInst.userStore.set_user({} as IUser);
                    localStorage.setItem('token', '');
                    localStorage.setItem('statusAuth', 'no');
                    localStorage.setItem('userid', '');
                    localStorage.setItem('userlocale', '');
                    
                    vueInst.$router.push({ name: 'Login' });
                }
            });
        },

        onClickOpenMenuLeftOrRedirectLink() {
            const vueInst = this;
            if (!['APP_BRANDTEAM'].includes(APP_NAME)) { 
                vueInst.leftMenuOpen = true;
                vueInst.rightMenuOpen = false;
            } else { 
            	vueInst.leftMenuOpen = false;
            	vueInst.rightMenuOpen = false;
                window.open("https://eubt-uat.demo.milav.eu/");
            }
        },

        onClickOpenOrCloseMenuRight(state : boolean) {
            const vueInst = this;

            vueInst.leftMenuOpen = false;
            vueInst.rightMenuOpen = ((['APP_BRANDTEAM'].includes(APP_NAME))) ? state : false;
        },

        goToView(pRouteViewName: string){      
            this.$router.push({ name: pRouteViewName }) 
        },

        goToViewWithParam(pRouteViewName: string, pRouteViewParam: number){      
            this.$router.push({ name: pRouteViewName, params: { is_all: pRouteViewParam } }) 
        },

        goToViewWithParamString(pRouteViewName: string, pRouteViewParam: string){      
            this.$router.push({ name: pRouteViewName, params: { param: pRouteViewParam } }) 
        },

        abbreviateNumber(value: number):string {
            return abbreviateNumber(value);
        },

        startSSE(){
            const vueInst = this;

            ServiceDocument.getNrServerSentEvent().then(response=>{
                if(response.status=='success'){
                    var eventSourceNrBadgeStore = {
                        nrOfMyDocuments: response.nr_of_my_doc,
                        nrOfPendingToRegister: response.nr_of_doc_to_finalize,
                        nrDocumenteDeRepartizate: response.nr_of_doc_de_repartizat,
                        nrAllAllocatedDocs: response.nr_of_all_doc_allocated,
                        nrOfTask: response.nr_of_all_tasks,
                        nrOfTaskIstoric: response.nr_of_all_tasks_istoric,
                        nrDocumenteNeFinalizate: response.nr_of_all_doc_nefinalizate,
                        nrProjectTask: response.nr_project_task,
                        nrAssetEvenimente: response.nr_of_asset_notifications
                    } as IEventSourceNrBadge;
                    vueInst.eventSourceNrBadgeStore.set_eventsourcenrbadge(eventSourceNrBadgeStore);
                    // console.log('%cSeverSentEvent NrBadge...', "color: blue;font-size:14px;", vueInst.eventSourceNrBadgeStore.EventSourceNrBadge);
                }
            });
            
            if (this.refreshIntervalId == null) {
                vueInst.refreshIntervalId = setInterval(() => {
                    ServiceDocument.getNrServerSentEvent().then(response=>{
                        if(response.status=='success'){
                            var eventSourceNrBadgeStore = {
                                nrOfMyDocuments: response.nr_of_my_doc,
                                nrOfPendingToRegister: response.nr_of_doc_to_finalize,
                                nrDocumenteDeRepartizate: response.nr_of_doc_de_repartizat,
                                nrAllAllocatedDocs: response.nr_of_all_doc_allocated,
                                nrOfTask: response.nr_of_all_tasks,
                                nrOfTaskIstoric: response.nr_of_all_tasks_istoric,
                                nrDocumenteNeFinalizate: response.nr_of_all_doc_nefinalizate,
                                nrProjectTask: response.nr_project_task,
                                nrAssetEvenimente: response.nr_of_asset_notifications
                            } as IEventSourceNrBadge;
                            vueInst.eventSourceNrBadgeStore.set_eventsourcenrbadge(eventSourceNrBadgeStore);
                            // console.log('%cSeverSentEvent NrBadge...', "color: blue;font-size:14px;", vueInst.eventSourceNrBadgeStore.EventSourceNrBadge);
                        }
                    });
                }, 30000);
            }

            // if (!!window.EventSource && Number(vueInst.userStore.User?.userid)!=0) {
            //     const source = new EventSource(`${URL_API}/search/sse`);
            //     if (source.onmessage == null) {
            //         source.onmessage = (event) => {
            //             const jdata = JSON.parse(event.data);
                        
            //             var eventSourceNrBadgeStore = {
            //                 nrOfMyDocuments: Number(1*jdata.nr_of_my_doc),
            //                 nrOfPendingToRegister: Number(1*jdata.nr_of_doc_to_finalize),
            //                 nrDocumenteDeRepartizate: Number(1*jdata.nr_of_doc_de_repartizat),
            //                 nrAllAllocatedDocs: Number(1*jdata.nr_of_all_doc_allocated),
            //                 nrOfTask: Number(1*jdata.nr_of_all_tasks),
            //                 nrOfTaskIstoric: Number(1*jdata.nr_of_all_tasks_istoric),
            //                 nrDocumenteNeFinalizate: Number(1*jdata.nr_of_all_doc_nefinalizate),
            //                 nrProjectTask: Number(1*jdata.nr_project_task),
            //                 nrAssetEvenimente: Number(1*jdata.nr_of_asset_notifications)
            //             } as IEventSourceNrBadge;
            //             vueInst.eventSourceNrBadgeStore.set_eventsourcenrbadge(eventSourceNrBadgeStore);
            //         };
            //     }
            // }
        },

        displayData(pdata:string) :string{
            if(pdata){        
              const day = moment(pdata.substring(0,10), 'YYYY-MM-DD');
              return dateToStringDDMMYYYY(day);
            }else{ 
              return '';
            }
        },

        async switchLanguage() {
            const vueInst = this;
            const newLocale = vueInst.currentLocale;
             
            await Tr.switchLanguage(newLocale);
            //Aici mai trebuie apelat si backendul update session user-locale!!! $_SESSION["user_locale"]
            
            try {
                await vueInst.$router.replace({ params: { locale: newLocale } });
            } catch(e) {
                console.log(e);
                vueInst.$router.push("/");
            }
        },

        translatedLanguage(option) {
            return option ? this.$t(`locale.${option}`) : option;
        }
    },

    computed: {
        APP_NAME(): string{
            return APP_NAME;
        },

        APP_NAME_TITLE(): string{
            return APP_NAME_TITLE;
        },

        sessionIsValid(): boolean{
            return this.userStore.sessionIsValid
        },

        user(): IUser{
            return this.userStore.User;
        },

        company(): ICompanyDrept{
            return this.companyStore.Company;
        },

        statusNomenclatoare(): boolean{
            return (this.nomenclatoareStore.nomTipDocIsOK &&
                this.nomenclatoareStore.nomCategIsOK &&
                this.nomenclatoareStore.nomTipActiveIsOK &&
                this.nomenclatoareStore.nomCategActiveIsOK &&
                this.nomenclatoareStore.nomRegistreIsOK /*&&
                this.nomenclatoareStore.nomOrgIsOK*/) || this.isStatePasswordReset;
        },
        
        currentPageTitle(): String{
            return this.nomenclatoareStore.currentPageTitle;
        },        

        widthForDrawerDialog(): number{
            let result = 0;
            if(this.$q.platform.is.mobile){
                result = window.innerWidth;
            }
            else{
                if(screen.availWidth > 1500){ 
                    result = 1200;
                }
                else{
                    if(screen.availWidth > 1000){ 
                        result = 1000;
                    }
                    else{
                        if(screen.availWidth > 800){
                            result = 600;
                        }else{
                            result = window.innerWidth - 100;
                        }
                    }
                }
            }
            return result;
        },

        widthForDrawerDialogFullScreen(): number{            
            return screen.availWidth - 1; // window.innerWidth;
        },

        eventSourceNrBadge(): IEventSourceNrBadge{
            return this.eventSourceNrBadgeStore.EventSourceNrBadge;
        },

        APP_INFORMATION(): any{
            return APP_INFORMATION;
        },

        supportedLocales() {
            return Tr.supportedLocales;
        } 
    },

    async mounted() {
        const vueInst = this;
        const $q = useQuasar();

        if (['APP_BRANDTEAM'].includes(APP_NAME)){
            setCssVar('primary', '#8055A5')   
        };  

        vueInst.userStore.set_screenwidth(screen.availWidth);
        vueInst.userStore.set_screenheight(screen.availHeight);
        vueInst.nomenclatoareStore.set_currentpagetitle('');

        var localeStorage = localStorage.getItem('userlocale');
        vueInst.currentLocale = ((localeStorage == null || localeStorage == '') ? Tr.defaultLocale : localeStorage) as string;
        
        await Tr.switchLanguage(vueInst.currentLocale);
        if (['APP_DEV', 'APP_HR', 'APP_CV', 'APP_CJCV', 'APP_BRANDTEAM', 'APP_MAGIC', 'APP_URBANESQ', 'APP_MEAT', 'APP_MULTICOMPANY'].includes(APP_NAME)){
            vueInst.getAppUser($q); 
        } else if (['APP_SOPHORE'].includes(APP_NAME)){ //'APP_HR'
            vueInst.getUserFromAD($q);   
        }        
            
        vueInst.emitter.on('eventToPreview', (params: any) => {
            // console.log('in app.ts eventToPreview with params=%o', params);
            vueInst.titleForPreviewForm = params.title;
            vueInst.downloadingFile = true;
            vueInst.showPreviewFile = true;
            vueInst.openPreviewForm(params.appid, params.url_to_get_file);
        });

        vueInst.emitter.on('eventToPreviewDocumentPDF', (params: any) => {
            // console.log('in app.ts eventToPreviewDocumentPDF with params=%o', params);
            vueInst.titleForPreviewForm = 'Coperta';
            vueInst.downloadingFile = true;
            vueInst.showPreviewFile = true;
            vueInst.openPreviewFormDocumentPDF(params.appid);
        });

        vueInst.emitter.on('eventToOpenDocument', (params: any) => {
            this.actionName = params.action;
            // console.log('in app.ts eventToOpenDocument with params=%o', params);
            switch (params.action) {
                case 'openDocumentDialog':
                    vueInst.dialogOpen = true;
                    vueInst.appidDocToWorkOnIt = params.appid;
                    vueInst.withFinalizeButton = params.with_finalize;
                    vueInst.withRepartizareButton = params.with_repartizare_buton;
                    vueInst.task = params.task;
                    vueInst.isFullScreen = params.isFullScreen;
                    vueInst.accesFrom = params.accesFrom;
                break;
                case 'openDocumentDialogReference': 
                    vueInst.dialogOpenReference = true;
                    vueInst.appidDocToWorkOnItReference = params.appid;
                    vueInst.withFinalizeButtonReference = params.with_finalize;
                    vueInst.withRepartizareButtonReference = params.with_repartizare_buton;
                    vueInst.taskReference = params.task;
                    vueInst.isFullScreenReference = params.isFullScreen;
                    vueInst.accesFromReference = params.accesFrom;
                break;
            }
        });
       
        vueInst.emitter.on('eventToOpenAsset', (params: any) => {
            this.actionName = params.action;
            // console.log('in app.ts eventToOpenAsset with params=%o', params);
            switch (params.action) {        
                case 'openAsset': 
                    vueInst.dialogOpen = true;
                    vueInst.appidAssetToWorkOnIt = params.appid;
                break;
            }
        });

        vueInst.emitter.on('eventToOpenDecont', (params: any) => {
            this.actionName = params.action;
            // console.log('in app.ts eventToOpenDecont with params=%o', params);
            switch (params.action) {        
                case 'openDecontDialog': 
                    vueInst.dialogOpen = true;                
                    // console.log('PARAMS', params)
                    vueInst.appidDecontToWorkOnIt = params.appid;
                    vueInst.task = params.task;
                break;
            }
        });

        vueInst.emitter.on('eventToOpenDeplasare', (params: any) => {
            this.actionName = params.action;
            // console.log('in app.ts eventToOpenDeplasare with params=%o', params);
            switch (params.action) {
                //case 'openDeplasare':  
                case 'openDeplasareDialog':                
                    vueInst.dialogOpen = true;
                    // console.log('PARAMS', params)
                    vueInst.appidDeplasareToWorkOnIt = params.appid;
                    vueInst.task = params.task;
                    //vueInst.isFullScreen = params.isFullScreen;
                break;
            }
        });

        vueInst.emitter.on('eventToOpenProiect', (params: any) => {
            this.actionName = params.action;
            // console.log('in app.ts eventToOpenProiect with params=%o', params);
            switch (params.action) {        
                case 'openProiectDialog': 
                    vueInst.dialogOpen = true;
                    vueInst.appidProiectToWorkOnIt = params.appid;
                break;
            }
        });

        vueInst.emitter.on('eventToOpenProiectTask', (params: any) => {
            this.actionName = params.action;
            // console.log('in app.ts eventToOpenProiectTask with params=%o', params);
            switch (params.action) {        
                case 'openProiectTaskDialog': 
                    vueInst.dialogOpen = true;
                    vueInst.appidProiectTaskToWorkOnIt = params.appid;
                    vueInst.is_all = params.is_all;
                break;
            }
        });

        vueInst.emitter.on('eventToOpenDocumentCetateni', (params: any) => {
            this.actionName = params.action;
            // console.log('in app.ts eventToOpenDocumentCetateni with params=%o', params);
            switch (params.action) {        
                case 'openDocumentCetateniDialog': 
                    vueInst.dialogOpen = true;
                    vueInst.appidDocumentCetateniToWorkOnIt = params.appid;
                    vueInst.withFinalizeButton = params.with_finalize;
                    //vueInst.withRepartizareButton = params.with_repartizare_buton;
                break;
            }
        });

        vueInst.emitter.on('eventToOpenCerere', (params: any) => {
            this.actionName = params.action;
            // console.log('in app.ts eventToOpenCerere with params=%o', params);
            switch (params.action) {        
                case 'openCerereDialog': 
                    vueInst.dialogOpen = true;                
                    // console.log('PARAMS', params)
                    vueInst.appidCerereToWorkOnIt = params.appid;
                    vueInst.task = params.task;
                break;
            }
        });

        vueInst.emitter.on('eventToOpenReferat', (params: any) => {
            this.actionName = params.action;
            // console.log('in app.ts eventToOpenReferat with params=%o', params);
            switch (params.action) {        
                case 'openReferatDialog': 
                    vueInst.dialogOpen = true;
                    vueInst.appidReferatToWorkOnIt = params.appid;
                    vueInst.task = params.task;
                break;
            }
        });

        vueInst.emitter.on('eventToOpenPontajPrezenta', (params: any) => {
            this.actionName = params.action;
            // console.log('in app.ts eventToOpenPontajPrezenta with params=%o', params);
            switch (params.action) {                 
                case 'openPontajPrezentaDialog':                
                    vueInst.dialogOpen = true;
                    vueInst.appidPontajPrezentaWorkOnIt = params.appid;
                    vueInst.task = params.task;
                    vueInst.isFullScreen = params.isFullScreen;
                break;
            }
        });

        vueInst.emitter.on('eventToOpenPontajProiect', (params: any) => {
            this.actionName = params.action;
            // console.log('in app.ts eventToOpenPontajProiect with params=%o', params);
            switch (params.action) {
                case 'openPontajProiectDialog':
                    vueInst.dialogOpen = true;
                    vueInst.appidPontajProiectToWorkOnIt = params.appid;
                    vueInst.task = params.task;
                    vueInst.isFullScreen = params.isFullScreen;
                break;
            }
        });

        vueInst.emitter.on('eventToOpenTimeRequest', (params: any) => {
            this.actionName = params.action;
            // console.log('in app.ts eventToOpenTimeRequest with params=%o', params);
            switch (params.action) {
                case 'openTimeRequestDialog':
                    vueInst.dialogOpen = true;
                    vueInst.appidTimeRequestWorkOnIt = params.appid;
                    vueInst.task = params.task;
                    vueInst.isFullScreen = params.isFullScreen;
                break;
            }
        });

        vueInst.emitter.on('eventToOpenPersonTimeRequest', (params: any) => {
            this.actionName = params.action;
            //console.log('in app.ts eventToOpenPersonTimeRequest with params=%o', params);
            switch (params.action) {
                case 'openPersonTimeRequest':
                    vueInst.dialogOpen = true;
                    vueInst.marcaTimeRequestsWorkOnIt = params.marca;
                    vueInst.yearTimeRequestsWorkOnIt = params.year;
                    //vueInst.task = params.task;
                    //vueInst.isFullScreen = params.isFullScreen;
                break;
            }
        });

        vueInst.emitter.on('eventToOpenPartener', (params: any) => {
            this.actionName = params.action;
            // console.log('in app.ts eventToOpenAsset with params=%o', params);
            switch (params.action) {        
                case 'openPartener': 
                    vueInst.dialogOpen = true;
                    vueInst.appidPartenerToWorkOnIt = params.appid;
                break;
            }
        });
        
        vueInst.emitter.on('eventToOpenFactura', (params: any) => {
            this.actionName = params.action;
            // console.log('in app.ts eventToOpenAsset with params=%o', params);
            switch (params.action) {        
                case 'openFactura': 
                    vueInst.dialogOpen = true;
                    vueInst.appidFacturaToWorkOnIt = params.appid;
                break;
            }
        });
        
        vueInst.emitter.on('eventToOpenCertificateUrbanism', (params: any) => {
            this.actionName = params.action;
            // console.log('in app.ts eventToOpenCertificateUrbanism with params=%o', params);
            switch (params.action) {
                case 'openCertificateUrbanismDialog':
                    vueInst.dialogOpen = true;
                    vueInst.appidCertificateUrbanismWorkOnIt = params.appid;
                    vueInst.task = params.task;
                    vueInst.isFullScreen = params.isFullScreen;
                break;
            }
        });
        
        vueInst.emitter.on('eventToOpenServiciiTransport', (params: any) => {
            this.actionName = params.action;
            // console.log('in app.ts eventToOpenServiciiTransport with params=%o', params);
            switch (params.action) {
                case 'openServiciiTransportDialog':
                    vueInst.dialogOpen = true;
                    vueInst.appidServiciiTransportWorkOnIt = params.appid;
                    vueInst.task = params.task;
                    vueInst.isFullScreen = params.isFullScreen;
                break;
            }            
        });

        vueInst.emitter.on('eventFromLoggedChange', (params: any) => {
            vueInst.startSSE();
        });
    },

    beforeUnmount() { //beforeDestroy
        this.emitter.off('eventToPreview');
        this.emitter.off('eventToOpenDocument');
        this.emitter.off('eventFromLoggedChange');

        this.emitter.off('eventToOpenAsset');
        this.emitter.off('eventToOpenDecont');  
        this.emitter.off('eventToOpenDeplasare');  
        this.emitter.off('eventToOpenProiect');
        this.emitter.off('eventToOpenProiectTask');
        this.emitter.off('eventToOpenDocumentCetateni');
        this.emitter.off('eventToOpenCerere');
        this.emitter.off('eventToOpenReferat');
        this.emitter.off('eventToOpenPontajPrezenta');
        this.emitter.off('eventToOpenPontajProiect');   
        this.emitter.off('eventToOpenTimeRequest');
        this.emitter.off('eventToOpenPersonTimeRequest');
        this.emitter.off('eventToOpenPartener');
        this.emitter.off('eventToOpenFactura');
        this.emitter.off('eventToOpenCertificateUrbanism');
        this.emitter.off('eventToOpenServiciiTransport');
    }
});
