import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import IJudete from '@/types/IJudete';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type IJudeteNull = OrNull<IJudete>;

interface TAjaxResponseJudete {
  status: string;
  message: string;
  rows: string;
  //rows: iJudete[];
}

interface TAjaxResponseEditJudet {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetJudet {
  status: string;
  message: string;
  judet: IJudeteNull;
}

export class ServiceJudete {
  public static async getJudete(idtara: number): Promise<TAjaxResponseJudete> {
    const response = await axios.get(`${CONFIG_ENV.URL_JUDETE.judete}/${idtara}`);
    return response.data;
  }

  public static async deleteJudet(appid: number): Promise<TAjaxResponseEditJudet> {    
    const response = await axios.delete(`${CONFIG_ENV.URL_JUDETE.judet}/${appid}`);
    return response.data;
  }

  public static async postJudet(judet: IJudete): Promise<TAjaxResponseEditJudet> {
    const response = await axios.post(`${CONFIG_ENV.URL_JUDETE.judet}`, judet);
    return response.data;
  }

  public static async getJudet(appid: number): Promise<TAjaxResponseGetJudet> {
    const response = await axios.get(`${CONFIG_ENV.URL_ADRESE.adresa}/${appid}`);
    return response.data;
  }
}