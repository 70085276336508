import axios from "axios";
import {CONFIG_ENV} from "@/config";
import 'url-search-params-polyfill';
import INomFlux from "@/types/INomFlux";
import IFluxCategory from '@/types/IFluxCategory';
import IFluxType from "@/types/IFluxType";
import INomFluxStep from "@/types/INomFluxStep";
import INomTipResponsabil from "@/types/INomTipResponsabil";
import INomActionOnStep from "@/types/INomActionOnStep";
import INomResponsabilLevel from "@/types/INomResponsabilLevel";
import ITask from '@/types/ITask';
import ICreateTask from '@/types/ICreateTask';
import IActionOnStepTask from '@/types/IActionOnStepTask';
import INomFluxClone from "@/types/INomFluxClone";

type OrNull<T> = { [K in keyof T]: T[K] | null }
type INomFluxOrNull = OrNull<INomFlux>;


interface TAjaxResponseTask {
    status: string;
    message: string;    
}

interface TAjaxResponseSimple {
    status: string;
    message: string;
    id: number;
}

interface TAjaxResponseTaskWithStep {
    status: string;
    message: string;
    id: number;
    rows: IActionOnStepTask;
}

interface TAjaxResponseNomFlux {
    status: string;
    message: string;
    rows: INomFlux[];
}

interface TAjaxResponseFluxCategory {
    status: string;
    message: string;
    rows: IFluxCategory[];
}

interface TAjaxResponseTasks {
    status: string;
    message: string;
    rows: ITask[];
}

interface TAjaxResponseFluxType {
    status: string;
    message: string;
    rows: IFluxType[];
}

interface TAjaxResponseNomFluxStep {
    status: string;
    message: string;
    rows: INomFluxStep[];
}

interface TAjaxResponseNomFluxStepTipResponsabil {
    status: string;
    message: string;
    rows: INomTipResponsabil[];
}

interface TAjaxResponseNomFluxStepActionOnStep {
    status: string;
    message: string;
    rows: INomActionOnStep[];
}

interface TAjaxResponseNomFluxStepResponsabilLevel {
    status: string;
    message: string;
    rows: INomResponsabilLevel[];
}

interface TAjaxResponseNrOfTasks {
    status: string;
    message: string;
    nr_of_tasks: number;
}

export class ServiceNomFlux {
    public static async getNomFlux(stare: number): Promise<TAjaxResponseNomFlux> {
        const response = await axios.get(`${CONFIG_ENV.URL_FLUX.nom_flux}/${stare}`);
        return response.data;
    }

    public static async deleteNomFlux(nomFluxId: number): Promise<TAjaxResponseSimple> {
        const response = await axios.delete(`${CONFIG_ENV.URL_FLUX.delete_flux}/${nomFluxId}`);
        return response.data;
    }

    public static async insertUpdateFlux(flux : INomFlux): Promise<TAjaxResponseSimple> {
        const response = await axios.post(`${CONFIG_ENV.URL_FLUX.nom_flux_insert_update}`, flux);
        return response.data;
    }

    public static async cloneFlux(flux : INomFluxClone): Promise<TAjaxResponseSimple> {
        const response = await axios.post(`${CONFIG_ENV.URL_FLUX.nom_flux_clone}`, flux);
        return response.data;
    }

    public static async getNomFluxCategory(stare: number): Promise<TAjaxResponseFluxCategory> {
        const response = await axios.get(`${CONFIG_ENV.URL_FLUX.nom_flux_category}/${stare}`);
        return response.data;
    }

    public static async getTasks(): Promise<TAjaxResponseTasks> {
        const response = await axios.get(`${CONFIG_ENV.URL_FLUX.nom_flux_tasks}`);
        return response.data;
    }

    public static async postCreateTask(task: ICreateTask): Promise<TAjaxResponseTask> {        
        const response = await axios.post(`${CONFIG_ENV.URL_FLUX.create_task}`, task);
        return response.data;
    }

    public static async getTasksIstoric(): Promise<TAjaxResponseTasks> {
        const response = await axios.get(`${CONFIG_ENV.URL_FLUX.nom_flux_tasks_istoric}`);
        return response.data;
    }

    public static async getEntityTasks(entity_id: number, entity_type: string): Promise<TAjaxResponseTasks> {
        const response = await axios.get(`${CONFIG_ENV.URL_FLUX.get_entity_tasks}/${entity_id}/${entity_type}`);
        return response.data;
    }

    public static async approveTask(task_id: number, action_performed: string): Promise<TAjaxResponseTaskWithStep> {
        const response = await axios.post(`${CONFIG_ENV.URL_FLUX.approve}/${task_id}/${action_performed}`);
        return response.data;
    }

    public static async rejectTask(task_id: number, action_performed: string, observatie: string): Promise<TAjaxResponseSimple> {
        const response = await axios.post(`${CONFIG_ENV.URL_FLUX.reject}/${task_id}/${action_performed}`, observatie);
        return response.data;
    }

    public static async getNumberTasks(): Promise<TAjaxResponseNrOfTasks> {
        const response = await axios.get(`${CONFIG_ENV.URL_FLUX.nom_number_tasks}`);
        return response.data;
    }

    public static async getNumberTasksIstoric(): Promise<TAjaxResponseNrOfTasks> {
        const response = await axios.get(`${CONFIG_ENV.URL_FLUX.nom_number_tasks_istoric}`);
        return response.data;
    }

    public static async getNomFluxType(stare: number): Promise<TAjaxResponseFluxType> {
        const response = await axios.get(`${CONFIG_ENV.URL_FLUX.nom_flux_type}/${stare}`);
        return response.data;
    }

    public static async getNomFluxSteps(fluxId: number): Promise<TAjaxResponseNomFluxStep> {
        const response = await axios.get(`${CONFIG_ENV.URL_FLUX.nom_flux_step_by_flux_id}/${fluxId}`);
        return response.data;
    }

    public static async getNomFluxStepTipResponsabil(): Promise<TAjaxResponseNomFluxStepTipResponsabil> {
        const response = await axios.get(`${CONFIG_ENV.URL_FLUX.nom_flux_step_tip_responsabil}`);
        return response.data;
    }
    public static async getNomFluxStepActionOnStep(): Promise<TAjaxResponseNomFluxStepActionOnStep> {
        const response = await axios.get(`${CONFIG_ENV.URL_FLUX.nom_flux_step_action_on_step}`);
        return response.data;
    }
    public static async getNomFluxStepResponsabilLevel(): Promise<TAjaxResponseNomFluxStepResponsabilLevel> {
        const response = await axios.get(`${CONFIG_ENV.URL_FLUX.nom_flux_step_responsabil_level}`);
        return response.data;
    }

    public static async deleteStep(stepId:number): Promise<TAjaxResponseSimple> {
        const response = await axios.delete(`${CONFIG_ENV.URL_FLUX.nom_flux_step_delete}/${stepId}`);
        return response.data;
    }

    public static async updateFluxStep(id_step:number,
        flux_id:number,
        descriere_flux_step: string,
        tip_responsabil:string,
        responsabil_level: string,
        org_unit_id: Number,
        user_id: string,
        termen: number,
        action_on_step: string): Promise<TAjaxResponseSimple> {

        const params = new URLSearchParams();
        params.set('id_step', id_step.toString());
        params.set('flux_id', flux_id.toString());
        params.set('descriere_flux_step', descriere_flux_step);
        params.set('tip_responsabil', tip_responsabil);
        params.set('responsabil_level', responsabil_level);
        params.set('org_unit_id', org_unit_id?.toString());
        params.set('user_id', user_id);
        params.set('termen', termen.toString());
        params.set('action_on_step', action_on_step);

        const response = await axios.post(`${CONFIG_ENV.URL_FLUX.nom_flux_step_update}/${id_step}`, params);
        return response.data;
    }
}
