import {defineComponent, defineAsyncComponent, ref, inject} from 'vue';
import {getModule} from 'vuex-module-decorators';
import INomDeconturi from '@/types/INomDeconturi';
import INomDeconturiDet from '@/types/INomDeconturiDet';
import {ServiceNomDeconturi} from '@/modules/ServiceNomDeconturi';
import {CONFIG_ENV} from '@/config';
import Nomenclatoare from '@/store/Nomenclatoare';
import moment from 'moment';
import LoadingComponent from '@/components/LoadingComponent.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';
import {dateToStringDDMonYYYY, getRowNumberTable} from '@/modules/Utils';
import IScanNomDeconturi from '@/types/IScanNomDeconturi';
import User from '@/store/User';
import IUser from '@/types/IUser';
import {humanReadableBytes} from '@/modules/Utils';
import {FileUploadService} from 'v-file-upload';
import Notify from 'quasar/src/plugins/Notify.js';;
import IStatusLabel from '@/types/IStatusLabel';
import { ServiceNomFlux } from '@/modules/ServiceNomFlux';
import ICreateTask from '@/types/ICreateTask';
import IActionOnStepTask from '@/types/IActionOnStepTask';
import { ServiceNomTipMijlocDeTransport } from '@/modules/ServiceNomTipMijlocDeTransport';
import INomTipMijlocDeTransport from '@/types/INomTipMijlocDeTransport';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type INomDeconturiNull = OrNull<INomDeconturi>;
type INomDeconturiDetNull = OrNull<INomDeconturiDet>;

interface INomDeconturiExtended extends INomDeconturiNull{ 
  scan_documents:IScanNomDeconturi[]|null
}

interface INomDeconturiDetExtended extends INomDeconturiDetNull{ 
  scan_documents_det:IScanNomDeconturi[]|null
}

const EditDecontDet = defineAsyncComponent({
  loader: () => import('@/views/Deconturi/EditDecontDet.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

const DialogOpenScan = defineAsyncComponent({
  loader: () => import('@/components/DialogOpenScan/DialogOpenScan.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

const TaskList = defineAsyncComponent({
  loader: () => import('@/components/Tasks/TaskList.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

const myForm = ref({} as any);

export default defineComponent({
  name: 'EditDecontDetail',

  components: {
    DialogOpenScan, EditDecontDet, TaskList
  },

  props: {
	  appid: {
      type: Number,
      required: true,
      default: 0,
      readonly: true
    },
    closeWindow: {
      type: Function,
      default: undefined
    },
    task: {
      type: Object,
      task_id: 0,
      task_type: '',
      action_on_step: {} as IActionOnStepTask
    }
  },

  setup() {   
    const emitter = inject("emitter") as any; 
    return {
      emitter,
      myForm
    };
  },

  data() {
    return {
      decont: {
        appid: 0,
        id_decont: null,
        appid_deplasare: null,
        detalii: null,
        data_start: null,
        data_end: null,
        id_tara: null,
        id_judet: null,
        id_localitate: null,
        id_mijl_transport: null,
        descriere: null,            
        track_user: null,
        track_date: null,
        stare: null,
        valoare: null,
        responsabil: null, 
        denumire_tara: null,
        denumire_judet: null,
        denumire_localitate: null,
        localitate: null,
        denumire_pentru_deplasare: null,
        angajati: null,
        appid_tip_deplasare: null,
        tip_deplasare: null
      } as INomDeconturiExtended,
      myLocale: CONFIG_ENV.myLocale,      
      nomenclatoareStore: getModule(Nomenclatoare),
      loadingData: false,      
      uploadScanTitle: '',            
      urlForUpload: CONFIG_ENV.URL_DECONT_SCAN.uploadScanDecont,
      uploadingFileToServer: false,
      headers: {} as any,
      userStore: getModule(User),
      filesSelectedForUpload: [] as any,
      dialogUploadFile: false,
      loadingPdfForDownload: false,
      filterDecontDet: '',
      visibleDialogEditDecontDet: false,
      myPagination: {
        rowsPerPage: getRowNumberTable()
      },
      tableDataDecontDet: [] as INomDeconturiDetExtended[],
      visibleDecontDetColumns: ['nr_document', 'data', 'descriere', 'denumire_cont', 'valoare', 'nume_furnizor', 'angajat', 'atasament', 'appid'],
      visibleDecontDetColumnsNotAction: ['nr_document', 'data', 'descriere', 'denumire_cont', 'valoare', 'nume_furnizor', 'angajat', 'atasament'],
      decontDetColumns: [
        { name: 'nr_document', label: 'Numar document', field: 'nr_document', align: 'left', sortable: true },
        { name: 'data', label: 'Data', field: 'data', align: 'left', sortable: true },
        { name: 'descriere', label: 'Descriere', field: 'descriere', align: 'left', sortable: true, style: 'max-width: 200px', headerStyle: 'max-width: 200px' },
        { name: 'denumire_cont', label: 'Categorie cheltuiala', field: 'denumire_cont', align: 'left', sortable: true, style: 'max-width: 200px', headerStyle: 'max-width: 200px' },
        { name: 'valoare', label: 'Valoare RON cu TVA', field: 'valoare', align: 'left', sortable: true, sort: (a: string, b: string) => parseInt(a, 10) - parseInt(b, 10) },
        { name: 'nume_furnizor', label: 'Furnizor', field: 'nume_furnizor', align: 'left', sortable: true },
        { name: 'angajat', label: 'Angajat', field: 'angajat', align: 'left', sortable: true },        
        { name: 'atasament', label: 'Atasamente', field: 'atasament', align: 'left', sortable: true },
        { name: 'appid', label: 'Actiuni', field: 'appid', align: 'left', sortable: false }
      ],
      appidDecontDet: 0,
      isSuccess: false,
      nomTipMijlocDeTransport: [] as INomTipMijlocDeTransport[]
    }
  },

  methods: {
    onSave() {
      const vueInst = this;           
      vueInst.myForm.validate().then((success: any) => {
        if (success) {
           ServiceNomDeconturi.postNomDecont(vueInst.decont as INomDeconturi).then((presponse) => {            
             if ( presponse.status === 'success' ) {
               vueInst.$q.notify({
                 color: 'teal',
                 textColor: 'white',
                 type: 'positive',
                 message: presponse.message,
                 position: 'top',
                 timeout: 1000,
                 html: true
               });               
               vueInst.closeWindow?.();
             }
           });
         } else {
           // oh no, user has filled in
           // at least one invalid value
         }
      })
    },

    onCancel() { 
      const vueInst = this;
      // console.log('cancel');
      vueInst.closeWindow?.();
    },

    onSendApproved() {
      const vueInst = this;         
      if(vueInst.decont.stare === 'initiat'){
        const createTask = {
          appid: vueInst.decont.appid,  
          flux_type: 'Decont',
          entity_type: 'DECONT',
          appid_nom_tip_entitate: null,
          appid_nom_tip_entitate_other: null
        } as ICreateTask;
        
        ServiceNomFlux.postCreateTask(createTask).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });            
            vueInst.closeWindow?.();
          }
        });
      }
    },

    getDecont() {
      const vueInst=this;
      ServiceNomDeconturi.getNomDecont(vueInst.appid).then((presponse)=>{
        vueInst.loadingData = false;
        if(presponse.status==='success'){
          vueInst.decont = JSON.parse(JSON.stringify(presponse.decont))[0];
  
          vueInst.getScanDocuments();
          vueInst.refreshDataTableDeconturiDet(true); 
          vueInst.isSuccess = true;
        }
      });
    },

    addRowDecontDet() {
      if (this.decont.stare === 'initiat') {
        this.appidDecontDet = 0;
        this.visibleDialogEditDecontDet = true;
      }
    },

    renuntEditareDecontDet() {        
      const vueInst = this;
      vueInst.appidDecontDet = 0;
      vueInst.visibleDialogEditDecontDet = false;      
      vueInst.refreshDataTableDeconturiDet(false);      
    },

    askIfEditThisDecontDet(pDecontDet: INomDeconturiDet) {      
      const vueInst = this;
      if (this.decont.stare === 'initiat') {
        vueInst.appidDecontDet = pDecontDet.appid;
        vueInst.visibleDialogEditDecontDet = true;
      }
    },

    askIfDeleteDecontDetThis(pDecontDet: INomDeconturiDet) {
      const vueInst = this;
      if (this.decont.stare === 'initiat') {        
        this.$q.dialog({
          title: 'Confirmare',
          message: 'Stergere nr document: ' + pDecontDet.nr_document + ' / ' + this.displayData(pDecontDet.data),
          cancel: true,
          persistent: true,
          html: true
        }).onOk(() => {
          ServiceNomDeconturi.deleteDecontDet(pDecontDet.appid).then(presponse=>{
            if(presponse.status==='success'){            
              vueInst.refreshDataTableDeconturiDet(false);

              vueInst.$q.notify({
                color: 'teal',
                textColor: 'white',
                type: 'positive',
                message: presponse.message,
                position: 'top',
                timeout: 1000,
                html: true
              });
            }
          })
        });
      }
    },

    refreshDataTableDeconturiDet(isLoadDecont: boolean){
      const vueInst = this;
      vueInst.visibleDialogEditDecontDet = false;
      ServiceNomDeconturi.getNomDeconturiDet(Number(vueInst.decont.id_decont)).then((presponse)=>{   
        vueInst.loadingData=false;   
        if (presponse.status === 'success'){
          this.tableDataDecontDet = JSON.parse(presponse.rows) as INomDeconturiDetExtended[];

          if (!isLoadDecont){
            this.decont.valoare = vueInst.sumValoareDocument;
          }
          
          vueInst.getDecontDetScanDocuments();
        }
      });
    },

    openFormUploadFile() {
      this.filesSelectedForUpload = [];
      this.uploadScanTitle = '';
      this.dialogUploadFile = true;      
    },

    getScanDocuments(){
      const vueInst = this;
      vueInst.uploadingFileToServer = true;
      ServiceNomDeconturi.getScansDeconturi(Number(vueInst.appid)).then((presponse)=>{
        vueInst.loadingData = false;
        vueInst.uploadingFileToServer = false;
        if(presponse.status==='success'){
          vueInst.decont.scan_documents = JSON.parse(JSON.stringify(presponse.rows));
        }
      });
    },

    getDecontDetScanDocuments(){
      const vueInst = this;
      // vueInst.uploadingFileToServer = true;

      setTimeout(() => {
        // vueInst.loadingData = false;
        // vueInst.uploadingFileToServer = false;
        vueInst.tableDataDecontDet.forEach(deconDet => {
          deconDet.scan_documents_det = [];          
          ServiceNomDeconturi.getScansDeconturiDet(Number(deconDet.appid)).then((presponse)=>{            
            if(presponse.status==='success'){
              deconDet.scan_documents_det = JSON.parse(JSON.stringify(presponse.rows));               
            }
          });
        });          
      }, 500)      
    },
  
    askIfRemoveScan(pscan:IScanNomDeconturi){
      const vueInst = this;
      vueInst.$q.dialog({
        title: 'Confirm',
        message: `Stergeti fisierul reprezentand ${pscan.description}?`,
        cancel: true,
        persistent: true,
        html: true
      }).onOk(() => {
        ServiceNomDeconturi.deleteScan(pscan.appid, { userid: vueInst.user.userid, token: vueInst.user.csrf_token }).then(presponse=>{
          if(presponse.status==='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
  
            this.getScanDocuments();
          }
        });
      })
    },

    prepareForUpload(event:any){      
      this.filesSelectedForUpload = event.target.files[0];      
    },

    uploadFile() {
      const vueInst = this;      
         
      if (vueInst.uploadScanTitle.length == 0) {
        Notify.create({
          color: 'orange',
          textColor: 'white',
          type: 'warning',
          message: 'Trebuie sa completati titlu documentului!',
          position: 'top',
          timeout: 3500,
          html: true
        });
      } else if (vueInst.checkIfFileIsSelected()) {
        vueInst.uploadingFileToServer = true;      
      
        const fileUpload = new FileUploadService(
          this.urlForUpload,
          this.headers,
          this.onProgress
        );
        
        fileUpload.upload(vueInst.filesSelectedForUpload as File, { appidDecont: vueInst.appid, description: vueInst.uploadScanTitle, userid: vueInst.user.userid, token: vueInst.user.csrf_token})
          .then((e: any) => {
            vueInst.dialogUploadFile = false;          
            vueInst.getScanDocuments()
            if(e.target && e.target.response.status && e.target.response.status=='error'){
              Notify.create({
                color: 'red',
                textColor: 'white',
                type: 'negative',
                message: e.target.response.message,
                position: 'top',
                timeout: 3500,
                html: true
              })
            }else{
              vueInst.$q.notify({
                color: 'teal',
                textColor: 'white',
                type: 'positive',
                message: e.target.response.message,
                position: 'top',
                timeout: 1000,
                html: true
              });
              
              ServiceNomDeconturi.getScansDeconturi(Number(vueInst.appid)).then(
                presponse=>{
                  vueInst.decont.scan_documents = JSON.parse(JSON.stringify(presponse.rows))
                }
              )            
            }
          })
          .catch((e: any) => {
          })
      } else {
        Notify.create({
          color: 'orange',
          textColor: 'white',
          type: 'warning',
          message: 'Trebuie sa selectati fisierul!',
          position: 'top',
          timeout: 3500,
          html: true
        });
      }
    },

    resetDecont() {
      this.decont = {
        appid: 0,
        id_decont: null,
        appid_deplasare: null,
        detalii: null,
        data_start: null,
        data_end: null,
        id_tara: null,
        id_judet: null,
        id_localitate: null,
        id_mijl_transport: null,
        descriere: null,            
        track_user: null,
        track_date: null,
        stare: null,
        valoare: null,
        responsabil: null, 
        denumire_tara: null,
        denumire_judet: null,
        denumire_localitate: null,
        localitate: null,
        denumire_pentru_deplasare: null,
        angajati: null,
        appid_tip_deplasare: null,
        tip_deplasare: null
      } as INomDeconturiExtended;
      // this.tasks = [];
    },
    
    humanReadableBytes(nrPfBytes:number): string{
      return humanReadableBytes(nrPfBytes);
    },

    checkIfFileIsSelected():boolean{
      return this.filesSelectedForUpload != undefined && this.filesSelectedForUpload.length != 0;
    },

    onProgress(event:any) {
      // Handdle the progress
    },
    
    displayData(pdata:string) :string{
      if(pdata){
        const day = moment(pdata.substring(0,10), 'YYYY-MM-DD');
        return dateToStringDDMonYYYY(day);
      }else{ 
        return '';
      }
    },

    getDenMijlocTransport(pIdMijlTrans: number): string{
      const vueInst = this;
      let result = '';
      const MijlTransport = vueInst.optionsTipMijlTransport.find( mijlTransport => {
        return mijlTransport.appid == pIdMijlTrans;
      });
      if(MijlTransport){
        result = MijlTransport.denumire;
      }
      return result;
    },

    getNomTipMijlocDeTransport() {
      const vueInst = this;
      ServiceNomTipMijlocDeTransport.getNomTipMijlocDeTransport().then((presponse)=>{      
        if (presponse.status === 'success'){
          vueInst.nomTipMijlocDeTransport = JSON.parse(presponse.rows) as INomTipMijlocDeTransport[];
        }
      });
    },

    getDenStatus(pStatus: string): string{
      const vueInst=this;
      let result = '' as string;
      const Status = vueInst.optionsStare.find( stare =>{
        return stare.status == pStatus;
      });
      if(Status){
        result=Status.label;
      }
      return result;
    },

    displayStatusColor(pStare:string) {      
      switch(pStare) { 
        case 'initiat': {
          return 'app__status--deconturi--black'
        }
        case 'pending': { 
           return 'app__status--deconturi--orange'
        } 
        case 'assigned': { 
          return 'app__status--deconturi--gray'
        }
        case 'finalized': { 
          return 'app__status--deconturi--blue'
        }
        case 'approved': { 
          return 'app__status--deconturi--green'
        } 
        case 'inapproval': {
          return 'app__status--deconturi--orange'
        }
        case 'rejected': {
          return 'app__status--deconturi--red'
        }
        default: { 
          return 'app__status--deconturi--orange'
        } 
      }
    }, 

    onState(operator: string){
      switch(operator) {
        case 'Refresh':
          this.getDecont();
          break;
        case 'Close':
          this.resetDecont();
          this.closeWindow?.();
          break;
      }
    }
  },

  computed: {
    user(): IUser {
      return this.userStore.User;
    },

    optionsTipMijlTransport(): INomTipMijlocDeTransport[] {
      return this.nomTipMijlocDeTransport;
    },

    optionsStare() {      
      const stareArray = this.nomenclatoareStore.StareGeneral as IStatusLabel[];
      return stareArray;
    },
    
    sumValoareDocument(): number {
      if (this.tableDataDecontDet.length > 0) {
        return this.tableDataDecontDet.map(a => Number(a.valoare)).reduce(function(a, b)
        {
          return a + b;
        });
      }
      else return 0;
    }
  },

  mounted() {
    const vueInst = this;
    vueInst.nomenclatoareStore.set_currentpagetitle('Inregistrare decont');
    
    vueInst.getNomTipMijlocDeTransport();
  },
  
  watch: {
    appid: {
      immediate: true,
      handler (newVal: number, oldVal: number) {
        const vueInst = this;        
        vueInst.resetDecont();    
        // vueInst.scan_documents = [];
        vueInst.loadingData = true;
        if(newVal != 0 && vueInst.appid != 0) { 
          vueInst.getDecont();    
        }
      }
    }
  }
});