import { getModule } from 'vuex-module-decorators';
import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import Nomenclatoare from '@/store/Nomenclatoare';

interface TAjaxResponseConturi {
  status: string;
  message: string;
  rows: string;
}

export class ServiceConturi {
  public static async getConturi(): Promise<TAjaxResponseConturi> {
    const response = await axios.get(`${CONFIG_ENV.URL_CONTURI.conturi}`);
    return response.data;
  }

  public static async updateStoreConturiFromDB() {
    const nomenclatoareStore = getModule(Nomenclatoare);
    this.getConturi().then((presponse) => {
      if (presponse.status === 'success') {
        nomenclatoareStore.set_conturi(JSON.parse(presponse.rows));
      }
    })
  }
}