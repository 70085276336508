<template>
  <div class="q-pa-md">
    <q-spinner-gears
      v-if="loadingData"
      color="primary"
      size="4em"
      class="absolute-center"
    />
    <div v-else>
      <q-form ref="myForm">
        <div class="well well-sm">
          <div class="panel">          
            <div class="q-pa-md">
              <div style="display: flex; align-items: center; margin-bottom: 15px">
                <div
                  v-bind:class="{
                    column: $q.platform.is.mobile,
                    row: $q.platform.is.desktop,
                  }"
                >
                  <span class="text__title--medium">Cerere concediu - Actiune solicitata</span>
                </div>

                <div class="absolute-right q-pa-md">
                  <q-btn v-if="timeRequest.appid != 0 && timeRequest.stare != 'cancellation'"
                    label="Vizualizeaza cererea"
                    color="brown-5"
                    icon="file_copy"
                    @click="onPreviewCerereCO"            
                    style="margin-right: 5px;"
                    size="sm"
                  />
                  <q-btn v-if="(timeRequest.appid != 0 && timeRequest.nr_recall_days != 0 && timeRequest.nr_recall_days != null && timeRequest.stare != 'cancellation')"                     
                    label="Vizualizeaza rechemarea"
                    color="brown-5"
                    icon="file_copy"
                    @click="onPreviewCerereRechemare"            
                    style="margin-right: 5px;"
                    size="sm"
                  />
                </div>
              </div>

              <div style="display: flex;align-items: start;">
                <div style="width: 65%; display: flex; flex-direction: row;">
                  <div style="display: flex;flex-direction: column;width: 100%;">
                    <div class="row">
                      <span class="col-4 form__label">Id</span>
                      <span class="col-8 form__label__black">{{timeRequest.appid}}</span>
                    </div>

                    <div class="row">
                      <span class="col-4 form__label">Angajat</span>
                      <span class="col-8 form__label__black">{{timeRequest.last_name}} {{timeRequest.first_name}}</span>
                    </div>

                    <div class="row">
                      <span class="col-4 form__label">Tip cerere</span>
                      <span class="col-8 form__label__black">{{timeRequest.tip_concediu}}</span>
                    </div>

                    <div class="row" v-if="timeRequest.nr_days_recalculated != 0 && (Number(timeRequest.flag) & 8) != 0">
                      <span class="col-4 form__label">Nr zile / Drept / Recalculat</span>
                      <span class="col-8 form__label__black">{{timeRequest.nr_working_days}} / {{timeRequest.nr_zile_drept}} / {{ timeRequest.nr_days_recalculated }}</span>              
                    </div>
                    <div class="row" v-else>
                      <span class="col-4 form__label">Nr zile / Drept</span>
                      <span class="col-8 form__label__black">{{timeRequest.nr_working_days}} / {{timeRequest.nr_zile_drept}}</span>              
                    </div>

                    <div class="row">
                      <span class="col-4 form__label">Prima zi</span>
                      <span class="col-8 form__label__black">{{timeRequest.start_date}}</span>              
                    </div>

                    <div class="row">
                      <span class="col-4 form__label">Ultima zi</span>
                      <span class="col-8 form__label__black">{{timeRequest.end_date}}</span>
                    </div>

                    <div class="row">
                      <span class="col-4 form__label">Stare</span>
                      <span class="col-8 form__label__black">{{timeRequest.denumire_stare}}</span>
                    </div>

                    <div class="row">
                      <span class="col-4 form__label">Observatie</span>
                      <span class="col-8 form__label__black">{{timeRequest.observatie}}</span>
                    </div> 

                    <div class="row" v-if="Number(timeRequest.recall_date?.length) > 0 && timeRequest.recall_date != null">
                      <span class="col-4 form__label">Data rechemare</span>
                      <span class="col-8 form__label__black">
                        <div style="min-width: 15vw;">
                          <span v-for="(recalldate, index) in timeRequest.recall_date" v-bind:key="index">
                            {{ displayDataRO(recalldate) + (index != (timeRequest.recall_date?.length - 1) ? ", " : "") }}
                          </span>
                        </div>
                      </span>
                    </div>
                    
                    <div class="row" v-if="timeRequest.nume_prenume_rechemat != null">
                      <span class="col-4 form__label">Utilizator rechemat</span>
                      <span class="col-8 form__label__black">{{timeRequest.nume_prenume_rechemat}}</span>
                    </div> 

                    <div class="row" v-if="timeRequest.description_recall != null">
                      <span class="col-4 form__label">Motiv</span>
                      <span class="col-8 form__label__black">{{timeRequest.description_recall}}</span>
                    </div>                    
                  </div>
                </div>
                <div style="width: 35%; display: flex; flex-direction: row; justify-content: center; align-items:center;">
                  <div style="display: flex; flex-direction: column">
                    <div v-bind:class="{ column: $q.platform.is.mobile, row: $q.platform.is.desktop }">
                      <div style="display: flex;align-items: center;justify-content: center;width: 100%;">
                        <div>
                          <span class="text__title--medium">Documente atasate</span>
                          <div
                              v-show="
                                  timeRequest.scansTimeRequests != null &&
                                  timeRequest.scansTimeRequests.length == 0 &&
                                  !uploadingFileToServer
                              "
                              class="text__label--thin"
                              style="width: 100%; text-align: center"
                          >
                            Nimic atasat
                          </div>
                        </div>
                        <div v-if="user.auth_for_upload_scan == 'y'">
                          <q-btn 
                            flat
                            color="primary"
                            icon="add_box"
                            no-caps
                            size="lg"
                            :disable="loadingData"
                            @click="openFormUploadFile"                           
                          >
                            <q-tooltip
                              transition-show="rotate"
                              transition-hide="rotate"
                            >
                              Incarca document nou
                            </q-tooltip>
                          </q-btn>
                        </div>
                      </div>

                      <div style="display: flex;align-items: center;justify-content: center;width: 100%;">
                        <div>
                          <q-spinner
                            v-if="uploadingFileToServer"
                            color="primary"
                            size="3em"
                            :thickness="10"
                          />
                          <table
                            v-else
                            class="table table-bordered table-condensed table-responsive"
                            style="width: 100%; max-height: 50vh"
                          >
                            <tbody>
                              <tr
                                  v-for="scan in timeRequest.scansTimeRequests"
                                  v-bind:key="scan.appid"
                              >
                                <td v-if="timeRequest.stare === 'initiat' || user.is_admin === 'y'">
                                  <span                                    
                                    class="material-icons cursor-pointer"
                                    style="color: red; font-size: 1.3rem;"
                                    @click="askIfRemoveScan(scan)"
                                    >delete_forever</span
                                  >
                                </td>
                                <td>
                                  {{ scan.file_ext }}
                                </td>
                                <td>
                                  {{ humanReadableBytes(scan.file_size) }}
                                </td>
                                <td>
                                  <div class="desktop-only" style="max-width: 30vw">
                                    {{ scan.description }}
                                  </div>
                                  <div class="mobile-only">
                                      {{ scan.description }}
                                  </div>
                                </td>
                                <td>
                                  <q-btn
                                    round
                                    color="black"
                                    icon="cloud_download"
                                    size="xs"
                                  />
                                  <q-menu
                                    fit
                                    transition-show="scale"
                                    transition-hide="scale"
                                  >
                                    <DialogOpenScan :appid="scan.appid" type_scan="TimeRequest"/>
                                  </q-menu>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div clas="row" style="margin-top: 5px;margin-bottom: 15px;" v-if="timeRequest.stare !== 'initiat'">
                <TaskList :appid="appid" :task="task" :onState="onState" :isSuccess="isSuccess"/>
            </div>
          </div>      
        </div>
      </q-form>

      <q-dialog
        v-model="showPreviewFile"
        persistent
        :maximized="true"
        transition-show="slide-up"
        transition-hide="slide-down"
      >
        <q-card class="bg-white">
          <q-bar>
            <h5>{{titleForPreviewForm}}</h5>
            <q-space />
            <q-btn dense flat icon="close" v-close-popup>
              <q-tooltip transition-show="rotate"
                        transition-hide="rotate">Close</q-tooltip>
            </q-btn>
          </q-bar>

          <q-card-section>
            <q-linear-progress v-if="downloadingFile" query track-color="orange" color="purple" class="q-mt-sm"/>            
            <iframe v-if="isPDFFileType" id="iframe_preview_scan" type="application/pdf" width="100%" height="100%" style="width:100%; height:calc(100vh - 70px);overflow: auto;border: none;"></iframe>
            <img v-else id="iframe_preview_scan" width="100%" height="100%" style="width:100%; height:calc(100vh - 70px);overflow: auto;border: none;display: block;"/>
          </q-card-section>
        </q-card>
      </q-dialog>

      <q-dialog v-model="dialogUploadFile" position="top">
        <q-card style="width: 350px">
          <q-linear-progress :value="0.6" color="pink" />

          <q-card-section class="column no-wrap">
            <div class="text-weight-bold">Incarca fisier nou</div>
            <div class="text-grey" style="margin-top: 4px; margin-bottom: 10px">
              <q-input
                counter
                outlined
                v-model="uploadScanTitle"
                label="Titlu document (minim 4 caractere)"
                dense
                maxlength="200"
              />
            </div>
            <input
              v-show="uploadScanTitle.length > 3"
              type="file"
              name="fileToUpload"
              id="fileToUpload"
              ref="myFileToUpload"
              @change="prepareForUpload($event)"
              style="margin-bottom: 10px"
            />
            <q-btn
              :disable="!(uploadScanTitle.length>3)"
              v-show="checkIfFileIsSelected"
              icon="cloud_upload"
              color="white"
              text-color="black"
              label="Salveaza pe server"
              @click="uploadFile()"
              :loading="uploadingFileToServer"
              style="margin-bottom: 5px"
            />
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>

<script lang="ts" src="./EditTimeRequestDetail.ts" />

<style scoped>
.q-field--with-bottom {
  padding-bottom: 20px;
}

.q-field__native {
  font-family: "Nunito Sans";
  font-weight: bolder;
}
</style>