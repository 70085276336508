<template>
  <div class="q-pa-md">
    <q-spinner-gears
      v-if="loadingData"
      color="primary"
      size="4em"
      class="absolute-center"
    />
    <div v-else>
      <q-form ref="myForm">
        <div class="well well-sm">
          <div class="panel">
            <div class="q-pa-md">
              <div style="display: flex; align-items: center; margin-bottom: 15px">
                <div
                  v-bind:class="{
                    column: $q.platform.is.mobile,
                    row: $q.platform.is.desktop,
                  }"
                >
                  <span class="text__title--medium">Ordin de deplasare</span>
                </div>
              </div>

              <div style="display: flex;align-items: start;">
                <div style="width: 65%; display: flex; flex-direction: row;">
                  <div style="display: flex;flex-direction: column;width: 100%;">
                    <div class="row">
                      <span class="col-4 form__label">Id</span>
                      <span class="col-8 form__label__black">{{deplasare.id_deplasare}}</span>
                    </div>

                    <div class="row">
                      <span class="col-4 form__label">Scop deplasare</span>
                      <span class="col-8 form__label__black">{{deplasare.denumire_pentru}}</span>
                    </div>

                    <div class="row">
                      <span class="col-4 form__label">Detalii</span>
                      <span class="col-8 form__label__black">{{deplasare.detalii}}</span>              
                    </div>

                    <div class="row">
                      <span class="col-4 form__label">De la data</span>
                      <span class="col-8 form__label__black">{{deplasare.data_start}}</span>              
                    </div>

                    <div class="row">
                      <span class="col-4 form__label">Pana la data</span>
                      <span class="col-8 form__label__black">{{deplasare.data_end}}</span>
                    </div>

                    <div class="row">
                      <span class="col-4 form__label">Tip deplasare</span>
                      <span class="col-8 form__label__black">{{deplasare.tip_deplasare}}</span>
                    </div>

                    <div class="row" v-if="deplasare.appid_tip_deplasare == 3"> <!-- Strainatate -->
                      <span class="col-4 form__label">Tara</span>
                      <span class="col-8 form__label__black">{{getDenTara(deplasare.id_tara as number)}}</span>              
                    </div>

                    <div class="row" v-if="deplasare.appid_tip_deplasare == 2 || deplasare.appid_tip_deplasare == 3"> <!-- Extra judetean sau Strainatate -->
                      <span class="col-4 form__label">Judet</span>
                      <span class="col-8 form__label__black">{{getDenJudet(deplasare.id_tara as number, deplasare.id_judet as number)}}</span>              
                    </div>

                    <div class="row">
                      <span class="col-4 form__label">Localitate</span>
                      <span class="col-8 form__label__black">{{getDenLocalitate(deplasare.id_tara as number, deplasare.id_judet as number, deplasare.id_localitate as number)}}</span>              
                    </div>

                    <div class="row">
                      <span class="col-4 form__label">Mijloc de transport</span>
                      <span class="col-8 form__label__black">{{getDenMijlocTransport(deplasare.id_mijl_transport as number)}}</span>                       
                    </div>
                    
                    <div class="row"
                      style="display: flex; flex-direction: row; margin-bottom:1vh;" 
                      v-if="deplasare.appid != 0"         
                    >
                      <span class="col-4 form__label">Personal</span> 
                      <div class="col-8">
                        <div>
                          <br />

                          <div style="display: flex; align-items: center; justify-content: right;">
                            <q-spinner
                              v-if="loadingData"
                              color="primary"
                              size="3em"
                              :thickness="10"
                            />
                            <table
                              v-else
                              class="table table-bordered table-condensed table-responsive"
                              style="width: 100%; max-height: 50vh"
                            >
                              <tbody>
                                <tr
                                  v-for="persoana in deplasare.persoane"
                                  v-bind:key="persoana.appid as number"
                                >                          
                                  <td>
                                    {{ persoana.persoana }}
                                  </td>
                                  <td>
                                    {{ persoana.den_org_unit }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: 35%; display: flex; flex-direction: row; justify-content: center; align-items:center;">
                  <div style="display: flex; flex-direction: column">
                    <div v-bind:class="{ column: $q.platform.is.mobile, row: $q.platform.is.desktop }">
                      <div style="display: flex;align-items: center;justify-content: center;width: 100%;">
                        <div>
                          <span class="text__title--medium">Documente atasate</span>
                          <div
                              v-show="
                                  deplasare.scan_documents != null &&
                                  deplasare.scan_documents.length == 0 &&
                                  !uploadingFileToServer
                              "
                              class="text__label--thin"
                              style="width: 100%; text-align: center"
                          >
                            Nimic atasat
                          </div>
                        </div>
                        <!-- <div v-if="user.auth_for_upload_scan == 'y'">
                          <q-btn 
                            flat
                            color="primary"
                            icon="add_box"
                            no-caps
                            size="lg"
                            :disable="loadingData"
                            @click="openFormUploadFile"
                            v-if="deplasare.stare === 'initiat'"
                          >
                            <q-tooltip
                              transition-show="rotate"
                              transition-hide="rotate"
                            >
                              Incarca document nou
                            </q-tooltip>
                          </q-btn>
                        </div> -->
                      </div>

                      <div style="display: flex;align-items: center;justify-content: center;width: 100%;">
                        <div>
                          <q-spinner
                            v-if="uploadingFileToServer"
                            color="primary"
                            size="3em"
                            :thickness="10"
                          />
                          <table
                            v-else
                            class="table table-bordered table-condensed table-responsive"
                            style="width: 100%; max-height: 50vh"
                          >
                            <tbody>
                              <tr
                                  v-for="scan in deplasare.scan_documents"
                                  v-bind:key="scan.appid"
                              >
                              <!-- v-if="user.is_admin == 'y'" -->
                                <!-- <td v-if="deplasare.stare === 'initiat'">
                                  <span                                    
                                    class="material-icons cursor-pointer"
                                    style="color: red; font-size: 1.3rem;"
                                    @click="askIfRemoveScan(scan)"
                                    >delete_forever</span
                                  >
                                </td> -->
                                <td>
                                  {{ scan.file_ext }}
                                </td>
                                <td>
                                  {{ humanReadableBytes(scan.file_size) }}
                                </td>
                                <td>
                                  <div class="desktop-only" style="max-width: 30vw">
                                    {{ scan.description }}
                                  </div>
                                  <div class="mobile-only">
                                      {{ scan.description }}
                                  </div>
                                </td>
                                <td>
                                  <q-btn
                                    round
                                    color="black"
                                    icon="cloud_download"
                                    size="xs"
                                  />
                                  <q-menu
                                    fit
                                    transition-show="scale"
                                    transition-hide="scale"
                                  >
                                    <DialogOpenScan :appid="scan.appid" type_scan="NomDeplasari"/>
                                  </q-menu>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div clas="row" style="margin-top: 5px;margin-bottom: 15px;" v-if="deplasare.stare !== 'initiat'">
                <TaskList :appid="appid" :task="task" :onState="onState" :isSuccess="isSuccess"/>
            </div>
            <div v-if="['APP_BRANDTEAM'].includes(APP_NAME)" class="row"  style="margin: 5px;">
                    <div
                        style="display: flex;align-items: center;justify-content: center; width: 100%;border-bottom: 1px solid black;">
                    </div>

                    <div style="display: flex;align-items: left;justify-content: left; width: 100%;">
                        <div style="margin-top: 5px;">
                            <span class="text__title--medium">Foi de parcurs</span>
                        </div>
                        <br />
                    </div>
                    <div style="display: flex;justify-content: center; width: 100%">
                        <q-spinner v-if="loadingFoieParcurs" color="primary" size="3em" :thickness="10" />
                        <q-table dense :rows-per-page-options="[0]" :rows="tableDataFoiParcurs"
                            :columns="foaieParcursListColumns" :visible-columns="visibleFoiParcursListColumns"
                            row-key="appid" :filter="filterFoiParcursList" :loading="loadingFoiParcurs"
                            :pagination.sync="myPagination" v-else
                            class="table table-bordered table-condensed table-responsive">
                            <template v-slot:top>
                                <q-space />
                                <q-input dense debounce="300" color="primary" v-model="filterFoiParcursList">
                                    <template v-slot:append>
                                        <q-icon name="search" />
                                    </template>
                                </q-input>
                            </template>

                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="nume_user" :props="props" :sortable="true"
                                        style="white-space:pre-wrap; word-wrap:break-word; max-width: 150pt;">
                                        {{ props.row.nume_user }}
                                    </q-td>
                                    <q-td key="data_start" :props="props" :sortable="true"
                                        style="white-space:pre-wrap; word-wrap:break-word">
                                        {{ props.row.data_start }}
                                    </q-td>
                                    <q-td key="data_end" :props="props" :sortable="true"
                                        style="white-space:pre-wrap; word-wrap:break-word">
                                        {{ props.row.data_end }}
                                    </q-td>
                                    <q-td key="denumire_pentru" :props="props" :sortable="true"
                                        style="white-space:pre-wrap; word-wrap:break-word">
                                        {{ props.row.denumire_pentru }}
                                    </q-td>
                                    <q-td key="adresa" :props="props" :sortable="true"
                                        style="white-space:pre-wrap; word-wrap:break-word">
                                        {{ props.row.adresa }}
                                    </q-td>
                                    <q-td key="distanta" :props="props" :sortable="true"
                                        style="white-space:pre-wrap; word-wrap:break-word">
                                        {{ props.row.distanta + ' km' }}
                                    </q-td>
                                    <q-td key="appid" :props="props" :sortable="false">
                                        <q-icon :name="'file_download'" :class="'text-blue cursor-pointer pencil'"
                                            style="font-size: 1.2rem; margin-left: auto;"
                                            @click="(getFoaieParcursPDF(props.row))">
                                            <q-tooltip>
                                                <span>Descarca foaia de parcurs</span>
                                            </q-tooltip>
                                        </q-icon>
                                    </q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </div>
            </div>
          </div>      
        </div>
      </q-form>
      <q-dialog
        v-model="showPreviewFile"
        persistent
        :maximized="true"
        transition-show="slide-up"
        transition-hide="slide-down"
      >
        <q-card class="bg-white">
          <q-bar>
            <h5>{{titleForPreviewForm}}</h5>
            <q-space />
            <q-btn dense flat icon="close" v-close-popup>
              <q-tooltip transition-show="rotate"
                        transition-hide="rotate">Close</q-tooltip>
            </q-btn>
          </q-bar>

          <q-card-section>
            <q-linear-progress v-if="downloadingFile" query track-color="orange" color="purple" class="q-mt-sm"/>            
            <iframe v-if="isPDFFileType" id="iframe_preview_scan" type="application/pdf" width="100%" height="100%" style="width:100%; height:calc(100vh - 70px);overflow: auto;border: none;"></iframe>
            <img v-else id="iframe_preview_scan" width="100%" height="100%" style="width:100%; height:calc(100vh - 70px);overflow: auto;border: none;display: block;"/>
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>

<script lang="ts" src="./EditDeplasareDetail.ts" />

<style scoped>
.q-field--with-bottom {
  padding-bottom: 20px;
}

.q-field__native {
  font-family: "Nunito Sans";
  font-weight: bolder;
}
</style>