<template>
    <div>
        ErrorComponent
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'ErrorComponent'
});
</script>

<style scoped />