<template>
<div class="q-pa-md" style="min-width: 500px;">
    <span class="text__title--medium">Modificare tip document</span>
    <br />
    <br />
    <div class="row">
        <span class="col-2 form__label">Categorie</span>
        <q-select class="col-10" outlined v-model="id_categ" :options="optionsCategorii" style="max-height: 7vh;;"            
            option-value="appid"
            option-label="den_lang_1"
            emit-value
            map-options
            lazy-rules
            dense
            @update:model-value="onValueChangeTipCategorie"
            :input-style="{ fontFamily: 'Nunito Sans',fontWeight: 'bolder', color: 'black' }"
            :rules="[ val => val && val > 0 || 'Alegeti categoria documentului!']"
        />
    </div>

    <div class="row">
        <span class="col-2 form__label">Tip</span>
        <q-select class="col-10" outlined v-model="id_tip_doc" :options="filteredOptionTipDoc" style="max-height: 7vh;;"
            option-value="appid"
            option-label="den_lang_1"
            emit-value
            map-options
            lazy-rules
            dense
            use-input
            input-debounce="300"
            @filter="filterTipDocument"
            @update:model-value="onValueChangeTipDocument"
            :input-style="{ fontFamily: 'Nunito Sans',fontWeight: 'bolder', color: 'black' }"
            :rules="[ val => val && val > 0 || 'Alegeti tipul documentului!']"
        />
    </div>
    <br />
    <div class="absolute-bottom-right q-pa-sm">        
        <q-btn label="Salveaza" size="sm" color="primary" @click="modificaTipDocument"/>
        <q-btn label="Renunt" size="sm" type="reset" flat @click="renuntModificareTipDoc" style="margin-left: 5px;"/>
    </div>
</div>
</template>

<script lang="ts" src="./ChangeTipDocument.ts" />

<style scoped />