import {defineAsyncComponent, defineComponent, inject, ref} from 'vue';
import {getModule} from 'vuex-module-decorators';
import {CONFIG_ENV} from '@/config';
import Nomenclatoare from '@/store/Nomenclatoare';
import LoadingComponent from "@/components/LoadingComponent.vue";
import ErrorComponent from "@/components/ErrorComponent.vue";
import {dateToStringDDMonYYYY} from '@/modules/Utils';
import moment from 'moment';
import {ServicePontajProiect} from "@/modules/ServicePontajProiect";
import IUser from "@/types/IUser";
import User from '@/store/User';
import IActionOnStepTask from '@/types/IActionOnStepTask';
import IPontajProiect from '@/types/IPontajProiect';

const TaskList = defineAsyncComponent({
    loader: () => import('@/components/Tasks/TaskList.vue'),
    loadingComponent: LoadingComponent,
    errorComponent: ErrorComponent,
    // The error component will be displayed if a timeout is
    // provided and exceeded. Default: Infinity.
    timeout: 3000,
    delay: 200
  });

const myForm = ref({} as any);

export default defineComponent({
    name: 'EditPontajProiectDetail',

    components: {
        TaskList
    },
    
    props: {
        appid: {
            type: Number,
            required: true,
            default: 0,
            readonly: true
        },
        closeWindow: {
            type: Function,
            default: undefined
        },
        task: {
            type: Object,
            task_id: 0,
            task_type: '',
            action_on_step: {} as IActionOnStepTask
        }        
    },

    setup() {
        const emitter = inject("emitter") as any;
        return {
            emitter,
            myForm
        }
    },

    data() {
        return {
            isElementShow: false,
            proiect: {
                id: 0,
                user_id: null,
                proiect_id: null,
                descriere_proiect: '',
                pontaj_date: '',
                efort: 0,                
                stare: '',
                track_date: ''
            } as IPontajProiect,
            myLocale: CONFIG_ENV.myLocale,
            userStore: getModule(User),
            nomenclatoareStore: getModule(Nomenclatoare),
            loadingData: false,
            isSuccess: false      
        }
    },

    methods: {        
        displayData(pdata:string) :string{
            if(pdata){
              const day = moment(pdata.substring(0,10), 'YYYY-MM-DD');
              return dateToStringDDMonYYYY(day);
            }else{ 
              return '';
            }
        },

        getProiect() {
            const vueInst = this;
            ServicePontajProiect.getPontajProiect(Number(vueInst.proiect.id)).then((presponse) => {
                vueInst.loadingData = false;
                if (presponse.status === 'success') {
                    const userId = presponse.proiect?.user_id;
                    vueInst.proiect = { ...presponse.proiect, user_id: userId };
                    vueInst.isElementShow = vueInst.proiect.stare === 'initiat';
                    vueInst.proiect.pontaj_date = vueInst.proiect.pontaj_date == null ? '' : vueInst.displayData(vueInst.proiect.pontaj_date);
                    vueInst.isSuccess = true               
                }
            })
        },        

        onState(operator: string){
            switch(operator) {
              case 'Refresh':
                this.getProiect();
                break;
              case 'Close':          
                this.closeWindow?.();
                break;
            }
        }, 
    },

    computed: {
        user(): IUser {
            return this.userStore.User;
        },
    },

    mounted() {
        const vueInst = this;
        vueInst.nomenclatoareStore.set_currentpagetitle('Pontaj proiect');

        // if (this.appid != 0) {
        //     this.proiect.id = this.appid;
        //     this.loadingData = true;
        //     this.getProiect();
        // }
    },

    watch: {
        appid: {
            immediate: true,
            handler (newVal: number, oldVal: number) {        
                const vueInst = this;
                vueInst.loadingData = true;                
                if (newVal != 0 && vueInst.appid != 0) {
                    vueInst.proiect.id = vueInst.appid;
                    vueInst.getProiect();
                }
            },            
        },
    },
});