import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import IPartener from '@/types/IPartener';
import IScanParteneri from '@/types/IScanParteneri';

interface TAjaxResponseSimple {
  status: string;
  message: string;
}

interface TAjaxResponseParteneri {
  status: string;
  message: string;
  rows: IPartener[];
}

interface TAjaxResponseGetPartener {
  status: string;
  message: string;
  partener: IPartener
}


interface TAjaxSimpleResponse {
  status: string;
  message: string;
}

interface TAjaxResponseGetScans {
  status: string;
  message: string;
  nr_of_records: number,
  rows: IScanParteneri[];
}

interface TAjaxResponseGetInfoScan {
  status: string;
  message: string;
  scan: IScanParteneri;
}

export class ServiceParteneri {
  public static async getParteneri(): Promise<TAjaxResponseParteneri> {
    const response = await axios.get(`${CONFIG_ENV.URL_PARTENERI.parteneri}/parteneri`);
    return response.data;
  }

  public static async getPartener(appid: number): Promise<TAjaxResponseGetPartener> {
    const response = await axios.get(`${CONFIG_ENV.URL_PARTENERI.parteneri}/partener/${appid}`);
    return response.data;
  }

  public static async deletePartener(appid: number): Promise<TAjaxResponseSimple> {
    const response = await axios.delete(`${CONFIG_ENV.URL_PARTENERI.parteneri}/partener/${appid}`);
    return response.data;
  }

  public static async postPartener(partener: IPartener): Promise<TAjaxResponseSimple> {
    const response = await axios.post(`${CONFIG_ENV.URL_PARTENERI.parteneri}/partener`, partener);
    return response.data;
  }

  public static async putStatusIsActive(appid:number, status: string): Promise<TAjaxResponseSimple> {
    const response = await axios.put(`${CONFIG_ENV.URL_PARTENERI.parteneri}/partener/is_active/${appid}/${status}`);
    return response.data;
  }

  public static async getScans(appidPartener:number): Promise<TAjaxResponseGetScans> {
    const response = await axios.get(`${CONFIG_ENV.URL_PARTENERI_SCAN.getScans}/${appidPartener}`);
    return response.data;
  }

  public static async getInfoScan(appid:number): Promise<TAjaxResponseGetInfoScan> {
    const response = await axios.get(`${CONFIG_ENV.URL_PARTENERI_SCAN.getInfoScan}/${appid}`);
    return response.data;
  }

  public static async deleteScan(appid:number, headers: any): Promise<TAjaxSimpleResponse> {
    const response = await axios.delete(`${CONFIG_ENV.URL_PARTENERI_SCAN.getInfoScan}/${appid}`, { headers });
    return response.data;
  }

}
