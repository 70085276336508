import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import IFacturaArticole from '@/types/IFacturaArticole';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type IFacturaArticoleNull = OrNull<IFacturaArticole>;

interface TAjaxResponseFacturaArticole {
  status: string;
  message: string;
  rows: IFacturaArticole[];
}

interface TAjaxResponseEditFacturaArticol {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetFacturaArticol {
  status: string;
  message: string;
  facturaArticol: IFacturaArticoleNull;
}

interface TAjaxSimpleResponse {
    status: string;
    message: string;
}

export class ServiceFacturaArticole {
  public static async getFacturaArticole(appid: number): Promise<TAjaxResponseFacturaArticole> {
    const response = await axios.get(`${CONFIG_ENV.URL_FACTURI.factura_articole}/factura_articole/${appid}`);
    return response.data;
  }

  public static async getFacturaArticol(appid: number): Promise<TAjaxResponseGetFacturaArticol> {
    const response = await axios.get(`${CONFIG_ENV.URL_FACTURI.factura_articole}/factura_articol/${appid}`);
    return response.data;
  }
  
  public static async postFacturaArticol(pFacturaArticol: IFacturaArticole): Promise<TAjaxResponseEditFacturaArticol> {
    const response = await axios.post(`${CONFIG_ENV.URL_FACTURI.factura_articole}/factura_articol`, pFacturaArticol);
    return response.data;
  }

  public static async deleteFacturaArticol(appid: number): Promise<TAjaxSimpleResponse> {
    const response = await axios.delete(`${CONFIG_ENV.URL_FACTURI.factura_articole}/factura_articol/${appid}`);
    return response.data;
  }
}
