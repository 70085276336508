import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import ILocalitati from '@/types/ILocalitati';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type ILocalitatiNull = OrNull<ILocalitati>;

interface TAjaxResponseLocalitati {
  status: string;
  message: string;
  rows: string;
  //rows: iLocalitati[];
}

interface TAjaxResponseEditLocalitate {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetLocalitate {
  status: string;
  message: string;
  localitate: ILocalitatiNull;
}

export class ServiceLocalitati {
  public static async getLocalitati(idTara: number, idJudet: number): Promise<TAjaxResponseLocalitati> {
    const response = await axios.get(`${CONFIG_ENV.URL_LOCALITATI.localitati}/${idTara}/${idJudet}`);
    return response.data;
  } 
  
  public static async deleteLocalitate(appid: number): Promise<TAjaxResponseEditLocalitate> {    
    const response = await axios.delete(`${CONFIG_ENV.URL_LOCALITATI.localitate}/${appid}`);
    return response.data;
  }

  public static async postLocalitate(localitate: ILocalitati): Promise<TAjaxResponseEditLocalitate> {
    const response = await axios.post(`${CONFIG_ENV.URL_LOCALITATI.localitate}`, localitate);
    return response.data;
  }

  public static async getLocalitate(appid: number): Promise<TAjaxResponseGetLocalitate> {
    const response = await axios.get(`${CONFIG_ENV.URL_LOCALITATI.localitate}/${appid}`);
    return response.data;
  }
}