import {defineComponent, ref} from 'vue';
import {getModule} from 'vuex-module-decorators';
import {CONFIG_ENV} from '@/config';
import {ServiceExpeditii} from '@/modules/ServiceExpeditii';
import IExpeditie from '@/types/IExpeditie';
import IUser from '@/types/IUser';
import User from '@/store/User';

type OrNull<T> = { [K in keyof T]: T[K] | null }

const myForm = ref({} as any);
const qDateProxy1 = ref(null);

export default defineComponent({
  name: 'ExpeditiiDocument',

  props: {
	  appid: {
      type: Number,
      required: true,
      default: 0,
      readonly: true
    },
    isAllowOperator: {
      type: Boolean,
      required: true,
      default: false,
      readonly: true
    }
  },

  setup() {    
    return {
      myForm,
      qDateProxy1
    };
  },

  data() {
    return {
      loadingExpeditii: false,
      savingExpedition: false,
      myLocale: CONFIG_ENV.myLocale,
      newExpeditie: {
        appid: 0,
        appid_doc: 0,
        awb_nr: '',
        awb_data: '',
        awb_detalii: ''
      } as IExpeditie,
      arrExpeditii: [] as IExpeditie[],
      userStore: getModule(User),
      thumbStyle: {
        right: '4px',
        borderRadius: '5px',
        backgroundColor: '#027be3',
        width: '5px',
        opacity: 0.75
      } as any,
      barStyle: {
        right: '2px',
        borderRadius: '9px',
        backgroundColor: '#027be3',
        width: '9px',
        opacity: 0.2,
      } as any
    }
  },

  methods: {
    resetExpeditie(){
      this.newExpeditie={
        appid: 0,
        appid_doc: 0,
        awb_nr: '',
        awb_data: '',
        awb_detalii: ''
      }
    },

    onCancel() {
      const vueInst = this;
      vueInst.resetExpeditie();
      // @ts-ignore
      vueInst.$refs.refNewExpeditie.hide();
    },

    getExpeditii(){
      const vueInst=this;
      ServiceExpeditii.getExpeditii(String(vueInst.appid)).then(presponse=>{
        vueInst.loadingExpeditii=false;
        if(presponse.status==='success'){
          vueInst.arrExpeditii=JSON.parse(JSON.stringify(presponse.rows));
        }
      });
    },

    saveNewExpeditie(){
      const vueInst=this;
      vueInst.savingExpedition=true;
      ServiceExpeditii.addNewExpeditie(String(vueInst.appid),vueInst.newExpeditie).then(presponse=>{
        vueInst.savingExpedition=false;
        if(presponse.status==='success'){
          vueInst.$q.notify({
            color: 'teal',
            textColor: 'white',
            type: 'positive',
            message: presponse.message,
            position: 'top',
            timeout: 1000,
            html: true
          });
          vueInst.resetExpeditie();
          // @ts-ignore
          vueInst.$refs.refNewExpeditie.hide();
          vueInst.getExpeditii();
        }
      });
    },

    removeExpeditie(pexpeditie:IExpeditie, pindex:number){
      const vueInst=this;
      vueInst.$q.dialog({
        title: 'Confirm',
        message: `Stergeti expeditia ${pexpeditie.awb_nr}/${pexpeditie.awb_data}?`,
        cancel: true,
        persistent: true,
        html: true
      }).onOk(() => {
        ServiceExpeditii.removeExpeditie(pexpeditie.appid).then(presponse=>{
          vueInst.savingExpedition=false;
          if(presponse.status==='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            vueInst.getExpeditii();
          }
        });
      })
    }
  },

  computed: {
    user(): IUser {
      return this.userStore.User;
    }
  },

  watch: {
    appid: {
      immediate: true,
      handler (newVal: string, oldVal: string) {
        //console.log('onAppidChanged in WorkOnDocument')
        const vueInst = this;
        vueInst.arrExpeditii = [];
        if(Number(newVal) !=0 && Number(vueInst.appid) != 0) {
          vueInst.loadingExpeditii=true;
          vueInst.getExpeditii();
        }
      }
    }
    // appid: function (newVal: string, oldVal: string) {
    //   //console.log('onAppidChanged in WorkOnDocument')
    //   const vueInst = this;
    //   vueInst.arrExpeditii = [];
    //   if(Number(newVal) !=0 && Number(vueInst.appid) != 0) {
    //     vueInst.loadingExpeditii=true;
    //     vueInst.getExpeditii();
    //   }
    // }
  }
});