import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import TPersonWeekStatus from '@/types/TPersonWeekStatus';
import IWeekInfo from '@/types/IWeekInfo';
import IConcediu from "@/types/IConcediu";
import {TTimeAccount} from "@/types/TTimeAccount";
import moment from 'moment';
import {dateToStringYYYYMMDDStandard} from '@/modules/Utils';
import ISearchParamsHRAdminPrezenta from '@/types/ISearchParamsHRAdminPrezenta';

interface TAjaxSimpleResponse {
  status: string;
  message: string;
}

interface TAjaxResponsePersonsWeekStatus {
  status: string;
  message: string;
  rows: TPersonWeekStatus[];
}

interface TAjaxResponseWeekInfo {
  status: string;
  message: string;
  weekInfo: IWeekInfo;
}

interface TAjaxResponseMarcaRequests {
  status: string;
  message: string;
  totalNrOfPages: number;
  currentPage: number;
  nrRecordsPerPage: number;
  totalRecords: number;
  rows: IConcediu[];
}

interface TAjaxResponseTimeAccounts {
  status: string;
  message: string;
  rows: TTimeAccount[];
}

interface TAjaxResponseTimeRequest {
  status: string;
  message: string;
  timeRequest: IConcediu;
}

interface TAjaxResponseEditConcediu{
  status: string;
  message: string;
  appid: number;
}

interface IDateParam {
  start_date: string,
  end_date: string,    
}

export class ServiceHRAdminPrezenta {  
  public static async getWeekInfo(start_date: Date, end_date: Date): Promise<TAjaxResponseWeekInfo> {
    const param = {} as IDateParam;
    param.start_date = dateToStringYYYYMMDDStandard(moment(start_date, 'YYYY-MM-DD'));
    param.end_date = dateToStringYYYYMMDDStandard(moment(end_date, 'YYYY-MM-DD'));

    const response = await axios.post(`${CONFIG_ENV.URL_HR_ADMIN_PREZENTA.getWeekInfo}`, {param});
    return response.data;
  }

  public static async getPersonsWeekStatus(is_all: number, start_date: Date, end_date: Date): Promise<TAjaxResponsePersonsWeekStatus> {
    const param = {} as IDateParam;
    param.start_date = dateToStringYYYYMMDDStandard(moment(start_date, 'YYYY-MM-DD'));
    param.end_date = dateToStringYYYYMMDDStandard(moment(end_date, 'YYYY-MM-DD'));

    const response = await axios.post(`${CONFIG_ENV.URL_HR_ADMIN_PREZENTA.getPersonsWeekStatus}/${is_all}`, {param});
    return response.data;
  }

  public static async getAllTimeRequestsForMarca(marca:number): Promise<TAjaxResponseMarcaRequests> {
    const response = await axios.get(`${CONFIG_ENV.URL_HR_ADMIN_PREZENTA.AllRequestsMarca}/${marca}`);
    return response.data;
  }

  public static async getAllTimeAccountsForMarca(marca:number, year:number): Promise<TAjaxResponseTimeAccounts> {
    const response = await axios.get(`${CONFIG_ENV.URL_HR_ADMIN_PREZENTA.TimeAccountsMarca}/${marca}/${year}`);
    return response.data;
  }

  public static async getTimeRequest(appid:number): Promise<TAjaxResponseTimeRequest> {
    const response = await axios.get(`${CONFIG_ENV.URL_HR_ADMIN_PREZENTA.getTimeRequest}/${appid}`);
    return response.data;
  }

  public static async approveTimeRequest(appid:number): Promise<TAjaxResponseTimeRequest> {
    const response = await axios.get(`${CONFIG_ENV.URL_HR_ADMIN_PREZENTA.approveTimeRequest}/${appid}`);
    return response.data;
  }

  public static async rejectTimeRequest(appid:number): Promise<TAjaxResponseTimeRequest> {
    const response = await axios.get(`${CONFIG_ENV.URL_HR_ADMIN_PREZENTA.rejectTimeRequest}/${appid}`);
    return response.data;
  }

  public static async getPersonsAllStatus(is_all: number, nrRanduriTabel: number, pageNumber: number, searchParams: ISearchParamsHRAdminPrezenta): Promise<TAjaxResponseMarcaRequests> {
    const response = await axios.post(`${CONFIG_ENV.URL_HR_ADMIN_PREZENTA.getPersonsAllStatus}/${is_all}/${nrRanduriTabel}/${pageNumber}`, {searchParams});
    return response.data;
  }

  public static async postRecallCO(concediu: IConcediu): Promise<TAjaxResponseEditConcediu> {
    const response = await axios.post(`${CONFIG_ENV.URL_HR_ADMIN_PREZENTA.recallCO}`, concediu);
    return response.data;
  }

  public static async postRescheduleCO(concediu: IConcediu): Promise<TAjaxResponseEditConcediu> {
    const response = await axios.post(`${CONFIG_ENV.URL_HR_ADMIN_PREZENTA.rescheduleCO}`, concediu);
    return response.data;
  }

  public static async cancellationTimeRequest(appid: number, observatie: string): Promise<TAjaxSimpleResponse> {
    const response = await axios.post(`${CONFIG_ENV.URL_HR_ADMIN_PREZENTA.cancellationTimeRequest}/${appid}`, observatie);
    return response.data;
  }
}
