<template>
  <div class="q-pa-sm">
    <q-spinner-gears
      v-if="loadingDocumentCetateniData"
      color="primary"
      size="4em"
      class="absolute-center"
    />

    <div v-else>
      <div class="well well-sm">
        <div class="panel">
          <div class="panel">
            <div class="panel-body" style="display: flex; align-items: center">
              <div style="min-width: 90px; min-height: 90px">
                <img :src="getLinkToQrCode" />
              </div>
              <div style="display: flex; flex-direction: column">
                <div
                  v-bind:class="{
                    column: $q.platform.is.mobile,
                    row: $q.platform.is.desktop,
                  }"
                >
                  <span class="text__title--medium">Numar intrare&nbsp;</span>
                  <span class="text__title--medium"
                    >{{ documentcetateni.id_doc }} /
                    {{ documentcetateni.data_doc }}</span
                  >
                </div>
                <q-btn
                  color="white"
                  text-color="black"
                  icon-right="print"
                  label="Tipareste!"
                  :disable="loadingPdfForDownload"
                  no-caps
                  :loading="loadingPdfForDownload"
                  @click="prepareCoverPdfForDownload"
                >
                  <template v-slot:loading>
                    <q-spinner-gears class="on-right" />
                    Pregatire...
                  </template>
                </q-btn>
              </div>
              <div
                style="
                  margin-left: auto;
                  min-height: 90px;
                  display: flex;
                  align-items: center;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    min-width: 350px;
                  "
                >
                  <div class="row">
                    <div class="col-6">
                      <span class="text__title--medium"
                        >Termen raspuns:&nbsp;</span
                      >
                    </div>
                    <div class="col-6">
                      <span class="text__title--medium__black">{{
                        documentcetateni.termen == "Fara termen"
                          ? documentcetateni.termen
                          : displayData(documentcetateni.termen_date as string)
                      }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <span class="text__title--medium">Timp ramas:&nbsp;</span>
                    </div>
                    <div class="col-6">
                      <span class="text__title--medium__black"
                        >{{
                          documentcetateni.termen == "Fara termen"
                            ? "-"
                            : documentcetateni.termen_zi
                        }}&nbsp;zile</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style="
              display: flex;
              align-items: center;
              paddingleft: 25px;
              paddingright: 15px;
              paddingbottom: 15px;
            "
          >
            <div style="width: 65%; display: flex; flex-direction: row">
              <div style="display: flex; flex-direction: column; width: 100%;margin-left:15px;">
                <div
                  v-bind:class="{
                    column: $q.platform.is.mobile,
                    row: $q.platform.is.desktop,
                  }"
                  style="margin-bottom: 5px"
                >
                  <span class="col-3 text__title--medium">Sursa:&nbsp;</span>
                  <span
                    class="col-9 text__title--medium"
                    style="color: #2f6cb7"
                    >{{ documentcetateni.sursa }}</span
                  >
                </div>
                <div
                  v-bind:class="{
                    column: $q.platform.is.mobile,
                    row: $q.platform.is.desktop,
                  }"
                  style="margin-top: 5px; margin-bottom: 5px"
                >
                  <span class="col-3 text__title--medium"
                    >Tip document:&nbsp;</span
                  >
                  <span
                    class="col-9 text__title--medium"
                    style="color: #2f6cb7"
                    >{{
                      getDenTipDocument(documentcetateni.id_tip_document as number)
                    }}</span
                  >
                </div>
                <div
                  v-bind:class="{
                    column: $q.platform.is.mobile,
                    row: $q.platform.is.desktop,
                  }"
                  style="margin-top: 5px; margin-bottom: 5px"
                >
                  <span class="col-3 text__title--medium"
                    >Nume prenume:&nbsp;</span
                  >
                  <span
                    class="col-9 text__title--medium"
                    style="color: #2f6cb7"
                    >{{ documentcetateni.nume_denumire }}</span
                  >
                </div>
                <div
                  v-bind:class="{
                    column: $q.platform.is.mobile,
                    row: $q.platform.is.desktop,
                  }"
                  style="margin-top: 5px; margin-bottom: 5px"
                >
                  <span class="col-3 text__title--medium"
                    >CNP / CUI:&nbsp;</span
                  >
                  <span
                    class="col-9 text__title--medium"
                    style="color: #2f6cb7"
                    >{{ documentcetateni.cui }}</span
                  >
                </div>
                <div
                  v-bind:class="{
                    column: $q.platform.is.mobile,
                    row: $q.platform.is.desktop,
                  }"
                  style="margin-top: 5px; margin-bottom: 5px"
                >
                  <span class="col-3 text__title--medium">Email:&nbsp;</span>
                  <span
                    class="col-9 text__title--medium"
                    style="color: #2f6cb7"
                    >{{ documentcetateni.email_solicitant }}</span
                  >
                </div>
                <div
                  v-bind:class="{
                    column: $q.platform.is.mobile,
                    row: $q.platform.is.desktop,
                  }"
                  style="margin-top: 5px; margin-bottom: 5px"
                >
                  <span class="col-3 text__title--medium">Adresa:&nbsp;</span>
                  <span
                    class="col-9 text__title--medium"
                    style="color: #2f6cb7"
                    >{{ documentcetateni.adresa }}</span
                  >
                </div>
                <div
                  v-bind:class="{
                    column: $q.platform.is.mobile,
                    row: $q.platform.is.desktop,
                  }"
                  style="margin-top: 5px; margin-bottom: 5px"
                >
                  <span class="col-3 text__title--medium">Titlu:&nbsp;</span>
                  <span
                    class="col-9 text__title--medium"
                    style="color: #2f6cb7"
                    >{{ documentcetateni.titlu }}</span
                  >
                </div>
                <div
                  v-bind:class="{
                    column: $q.platform.is.mobile,
                    row: $q.platform.is.desktop,
                  }"
                  style="margin-top: 5px; margin-bottom: 5px"
                >
                  <span class="col-3 text__title--medium"
                    >Descriere:&nbsp;</span
                  >
                  <span
                    class="col-9 text__title--medium"
                    style="color: #2f6cb7"
                    >{{ documentcetateni.descriere }}</span
                  >
                </div>
                <div
                  v-bind:class="{
                    column: $q.platform.is.mobile,
                    row: $q.platform.is.desktop,
                  }"
                  style="margin-top: 5px; margin-bottom: 5px"
                >
                  <span class="col-3 text__title--medium"
                    >Numar file:&nbsp;</span
                  >
                  <span
                    class="col-9 text__title--medium"
                    style="color: #2f6cb7"
                    >{{ documentcetateni.nr_file }}</span
                  >
                </div>
                <div
                  v-bind:class="{
                    column: $q.platform.is.mobile,
                    row: $q.platform.is.desktop,
                  }"
                  style="margin-top: 5px; margin-bottom: 5px"
                >
                  <span class="col-3 text__title--medium"
                    >Repartizat la:&nbsp;</span
                  >
                  <!-- <span
                    class="col-9 text__title--medium"
                    style="color: #2f6cb7"
                    >{{ documentcetateni.repartizat }}</span
                  > -->
                  <span
                    class="col-9 text__title--medium"
                    style="color: #2f6cb7"
                    >
                    <div style="display: flex; align-items: center;flex-wrap: wrap;"
                      v-bind:class="{ 'lista_departamente_de_repartizat-mobile': $q.platform.is.mobile, 'lista_departamente_de_repartizat-desktop': $q.platform.is.desktop }"
                    >
                      <div v-for="(repartizare,index) in repartizari.filter((item) => item.appid_org_destinatar != null)" class="departament_repartizat" v-bind:key="index">
                          <div>{{repartizare.den_org_destinatar}}</div>
                      </div>
                      <div v-for="(repartizare,index) in repartizari.filter((item) => item.appid_user_destinatar != null)" class="departament_repartizat" v-bind:key="index">
                          <div>{{repartizare.nume_prenume_user_destinatar}}</div>
                      </div>
                    </div>
                  </span
                  >
                </div>
              </div>
            </div>
            <div style="width: 35%; display: flex; flex-direction: row">
              <div style="display: flex; flex-direction: column">
                <div
                  v-bind:class="{
                    column: $q.platform.is.mobile,
                    row: $q.platform.is.desktop,
                  }"
                >
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      width: 100%;
                    "
                  >
                    <div>
                      <span class="text__title--medium">Documente atasate</span>
                      <div
                        v-show="
                          documentcetateni.scans != null &&
                          documentcetateni.scans.length == 0 &&
                          !uploadingFileToServer
                        "
                        class="text__label--thin"
                        style="width: 100%; text-align: center"
                      >
                        Nimic atasat
                      </div>
                    </div>
                    <div v-if="user.auth_for_upload_scan == 'y'">
                      <q-btn
                        flat
                        color="primary"
                        icon="add_box"
                        no-caps
                        :disable="loadingDocumentCetateniData"
                        @click="openFormUploadFile"
                      >
                        <q-tooltip
                          transition-show="rotate"
                          transition-hide="rotate"
                        >
                          Incarca document nou
                        </q-tooltip>
                      </q-btn>
                    </div>
                  </div>

                  <div style="display: flex; align-items:center; justify-content: center; width:100%;">
                    <div>
                    <q-spinner
                      v-if="uploadingFileToServer"
                      color="primary"
                      size="3em"
                      :thickness="10"
                    />
                    <table
                      v-else
                      class="
                        table table-bordered table-condensed table-responsive
                      "
                      style="width: 100%; max-height: 50vh"
                    >
                      <tbody>
                        <tr
                          v-for="scan in documentcetateni.scans"
                          v-bind:key="scan.appid"
                        >
                          <td>
                            <span
                              v-if="user.is_admin == 'y' || user.auth_for_deletedocument == 'y'"
                              class="material-icons cursor-pointer"
                              style="color: red; font-size: 1.3rem"
                              @click="askIfRemoveScan(scan)"
                              >delete_forever</span
                            >
                          </td>
                          <td>
                            {{ scan.file_ext }}
                          </td>
                          <td>
                            {{ humanReadableBytes(scan.file_size) }}
                          </td>
                          <td>
                            <div class="desktop-only" style="max-width: 30vw">
                              {{ scan.description }}
                            </div>
                            <div class="mobile-only">
                              {{ scan.description }}
                            </div>
                          </td>
                          <td>
                            <q-btn
                              round
                              color="black"
                              icon="cloud_download"
                              size="xs"
                            />
                            <q-menu
                              fit
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <DialogOpenScan :appid="scan.appid" type_scan="Document"/>
                            </q-menu>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div clas="row" style="margin-top: 15px;">
            <div>
              <div class="q-pa-sm">
                <q-btn
                  size="sm"
                  label="Preiau in lucru"
                  style="margin-left: 10px; color: gray"
                />
                <q-btn
                  size="sm"
                  label="Creaza document iesire"
                  style="margin-left: 15px; color: gray"
                />
                <q-btn
                  size="sm"
                  label="Prelucreaza"
                  style="margin-left: 15px; color: gray"
                />
                <q-btn
                  size="sm"
                  label="Ataseaza"
                  style="margin-left: 15px; color: gray"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <q-dialog v-model="dialogUploadFile" position="top">
      <q-card style="width: 350px">
        <q-linear-progress :value="0.6" color="pink" />

        <q-card-section class="column no-wrap">
          <div class="text-weight-bold">Incarca fisier nou</div>
          <div class="text-grey" style="margin-top: 4px; margin-bottom: 10px">
            <q-input
              counter
              outlined
              v-model="uploadScanTitle"
              label="Titlu document (minim 4 caractere)"
              dense
              maxlength="200"
            />
          </div>
          <input
            v-show="uploadScanTitle.length > 3"
            type="file"
            name="fileToUpload"
            id="fileToUpload"
            ref="myFileToUpload"
            @change="prepareForUpload($event)"
            style="margin-bottom: 10px"
          />
          <q-btn
            :disable="!(uploadScanTitle.length>3)"
            v-show="checkIfFileIsSelected"
            icon="cloud_upload"
            color="white"
            text-color="black"
            label="Salveaza pe server"
            @click="uploadFile()"
            :loading="uploadingFileToServer"
            style="margin-bottom: 5px;"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script lang="ts" src="./EditDocumentCetateniDetail.ts" />

<style scoped>
.q-field--with-bottom {
  padding-bottom: 20px;
}

.q-field__native {
  font-family: "Nunito Sans";
  font-weight: bolder;
}
</style>