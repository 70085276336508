import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import IConcediu from '@/types/IConcediu';
import IScanTimeRequest from "@/types/IScanTimeRequest";
import ISearchParamsMyTimesRequests from '@/types/ISearchParamsMyTimesRequests';

interface TAjaxSimpleResponse {
	status: string;
	message: string;
}

interface TAjaxSimpleResponseWithId {
	status: string;
	message: string;
	appid: number;
}

interface TAjaxResponseMyRequests {
	status: string;
	message: string;
	totalNrOfPages: number;
	currentPage: number;
	nrRecordsPerPage: number;
	totalRecords: number;
	rows: IConcediu[];
}

interface TAjaxResponsScans {
	status: string;
	message: string;
	nr_of_records: number,
	rows: IScanTimeRequest[];
}

interface TAjaxResponseGetInfoScanTimeRequest {
	status: string;
	message: string;
	scan: IScanTimeRequest;
}

interface TAjaxResponseTimeRequest {
	status: string;
	message: string;
	timeRequest: IConcediu;
}

interface TAjaxNrZileLucratoareResponse {
	status: string;
	message: string;
	nrZileLucratoare:number;
}

export class ServiceTimesRequests {
	public static async getAllTimeRequests(is_all: number, nrRanduriTabel: number, pageNumber: number, searchParams: ISearchParamsMyTimesRequests): Promise<TAjaxResponseMyRequests> {
		const response = await axios.post(`${is_all == 0 ? CONFIG_ENV.URL_TIME_REQUESTS.myRequests : CONFIG_ENV.URL_TIME_REQUESTS.allRequests}/${nrRanduriTabel}/${pageNumber}`, {searchParams});
		return response.data;
	}

	public static async getTimeRequest(appid:number): Promise<TAjaxResponseTimeRequest> {
		const response = await axios.get(`${CONFIG_ENV.URL_TIME_REQUESTS.requests}/${appid}`);
		return response.data;
	}

	public static async deleteTimeRequest(appid:number): Promise<TAjaxResponseTimeRequest> {
		const response = await axios.delete(`${CONFIG_ENV.URL_TIME_REQUESTS.requests}/${appid}`);
		return response.data;
	}

	public static async getScans(appid:number): Promise<TAjaxResponsScans> {
		const response = await axios.get(`${CONFIG_ENV.URL_TIME_REQUESTS_SCAN.scans}/${appid}`);
		return response.data;
	}

	public static async getInfoScan(appid:number): Promise<TAjaxResponseGetInfoScanTimeRequest> {
		const response = await axios.get(`${CONFIG_ENV.URL_TIME_REQUESTS_SCAN.getInfoScan}/${appid}`);
		return response.data;
	}

	public static async deleteScan(appid:number, headers: any): Promise<TAjaxSimpleResponse> {
		const response = await axios.delete(`${CONFIG_ENV.URL_TIME_REQUESTS_SCAN.deleteScan}/${appid}`, { headers });
		return response.data;
	}

	public static async postRequest(appid:number, anulAferent:number, startDate:string, endDate:string, appidNomConcediu: number, timeCode:string, marca:number, flag: number, observatie:string): Promise<TAjaxSimpleResponseWithId> {
		const params = new URLSearchParams();
		params.set('appid', appid.toString());
		params.set('anulAferent', anulAferent.toString());
		params.set('startDate', startDate);
		params.set('endDate', endDate);
		params.set('appidNomConcediu', appidNomConcediu.toString());
		params.set('timeCode', timeCode);
		params.set('marca', marca.toString());
		params.set('flag', flag.toString());
		params.set('observatie', observatie);
		const response = await axios.post(`${CONFIG_ENV.URL_TIME_REQUESTS.requests}`,params);
		return response.data;
	}

	public static async getWorkingDays(startDate: string, endDate: string, timeCode: string): Promise<TAjaxNrZileLucratoareResponse> {
		const params = new URLSearchParams();
		params.set('startDate', startDate);
		params.set('endDate', endDate);
		params.set('timeCode', timeCode);
		const response = await axios.post(`${CONFIG_ENV.URL_TIME_REQUESTS.getWorkingDays}`,params);
		return response.data;
	}

	public static async updateStatusPlanReqularAnual(appid:number, marca: number, flag:number): Promise<TAjaxSimpleResponse> {
		const params = new URLSearchParams();
		params.set('appid', appid.toString());
		params.set('marca', marca.toString());
		params.set('flag', flag.toString());
		const response = await axios.post(`${CONFIG_ENV.URL_TIME_REQUESTS.updateStatusPlanReqularAnual}`,params);
		return response.data;
	}

	public static async postApproveAllTimeRequest(): Promise<TAjaxSimpleResponse> {
		const response = await axios.post(`${CONFIG_ENV.URL_TIME_REQUESTS.sendAprobarePlanAnualConcediu}`);
		return response.data;
	}
}