import {defineComponent} from 'vue';
import INomDeplasari from '@/types/INomDeplasari';
import {ServiceNomDeplasari} from '@/modules/ServiceNomDeplasari';
import moment from 'moment';
import {dateToStringDDMMYYYY} from '@/modules/Utils';
import {getRowNumberTable} from '@/modules/Utils';

export default defineComponent({
  name: 'ComboDeplasari',

  props: {
    selectHandler: {
      type: Function,
      default: () => {}
    },    
    title: {
      type: String,
      required: true,
      default: 'Deplasari',
      readonly: true
    },
    is_all: {
      type: Number,
      required: true,
      default: 0,
      readonly: true
    }  
  },

  data() {
    return {
      loadingData: false,
      filterDeplasari: '',
      myPagination: {
        rowsPerPage: getRowNumberTable()
      },
      visibleColumns: ['denumire_pentru', 'tip_deplasare', 'localitate', 'data_start', 'data_end'],
      columns: [
        { name: 'appid', label: 'appid', field: 'appid' },
        { name: 'id_deplasare', label: 'Numar deplasare', field: 'id_deplasare' },
        { name: 'denumire_pentru', label: 'Denumire', field: 'denumire_pentru', align: 'left', sortable: true},
        { name: 'tip_deplasare', label: 'Tip Deplasare', field: 'tip_deplasare', align: 'left', sortable: true},
        { name: 'localitate', label: 'Localitate', field: 'localitate', align: 'left', sortable: true },
        { name: 'data_start', label: 'De la', field: 'data_start', align: 'left', sortable: true },
        { name: 'data_end', label: 'Pana la', field: 'data_end', align: 'left', sortable: true },
      ],
      tableDataDeplasari: [] as INomDeplasari[]
    }
  },

  methods: {
    displayData(pdata:string) :string{
      if(pdata){
        const day = moment(pdata.substring(0,10), 'YYYY-MM-DD');
        return dateToStringDDMMYYYY(day);
      }else{ 
        return '';
      }
    },
  },

  mounted() {
    const vueInst = this;
    vueInst.loadingData = true;
    ServiceNomDeplasari.getNomDeplasariCombo(vueInst.is_all).then((presponse)=>{        
      if (presponse.status === 'success'){        
        vueInst.tableDataDeplasari = JSON.parse(presponse.rows); //JSON.parse(JSON.stringify(presponse.rows));
      }
      vueInst.loadingData = false;
    });
  }
});
