import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import IFactura from '@/types/IFactura';

interface TAjaxResponseSimple {
  status: string;
  message: string;
}

interface TAjaxResponseFacturi {
  status: string;
  message: string;
  rows: IFactura[];
}

interface TAjaxResponseGetFactura {
  status: string;
  message: string;
  factura: IFactura
}

interface TAjaxSimpleResponse {
  status: string;
  message: string;
}

export class ServiceFacturi {
  public static async getFacturi(): Promise<TAjaxResponseFacturi> {
    const response = await axios.get(`${CONFIG_ENV.URL_FACTURI.facturi}/facturi`);
    return response.data;
  }

  public static async getFactura(appid: number): Promise<TAjaxResponseGetFactura> {
    const response = await axios.get(`${CONFIG_ENV.URL_FACTURI.facturi}/factura/${appid}`);
    return response.data;
  }

  public static async deleteFactura(appid: number): Promise<TAjaxResponseSimple> {
    const response = await axios.delete(`${CONFIG_ENV.URL_FACTURI.facturi}/factura/${appid}`);
    return response.data;
  }

  public static async postFactura(factura: IFactura): Promise<TAjaxResponseSimple> {
    const response = await axios.post(`${CONFIG_ENV.URL_FACTURI.facturi}/factura`, factura);
    return response.data;
  }

  public static async updateFactura(appid:number, tipupdate:string, ptext: string): Promise<TAjaxSimpleResponse> {
    const params = new URLSearchParams();
    params.set('new_value', ptext);
    const response = await axios.post(`${CONFIG_ENV.URL_FACTURI.facturi}/factura/update/${appid}/${tipupdate}`, params);
    return response.data;
  }
}
