<template>
    <div class="q-pa-sm">
        <q-spinner-gears
                v-if="loadingProiectTaskData"
                color="primary"
                size="4em"
                class="absolute-center"
        />

        <div v-else>
           <div class="well well-sm">
             <div class="panel">
                <!-- <div style="width:65%;display: flex;flex-direction: column;"> -->
                <div style="display:flex; align-items:center;paddingLeft: 15px;paddingRight: 15px;paddingTop: 15px;">                        
                 <div style="width:70%;display: flex;flex-direction: row;">
                         <div>
                    <div class="row">
                        <div style="min-width: 90px; min-height: 90px;paddingRight: 5px;">
                                <img :src="getLinkToQrCode" />
                        </div>
                        <div style="display: flex;flex-direction: column">
                         <div v-bind:class="{ 'column': $q.platform.is.mobile, 'row': $q.platform.is.desktop }">
                                <span class="text__title--semismall_bold__black" style='paddingTop: 10px;'>PROIECT:</span>                                
                                <span class="text__title--semismall_bold__black" style='paddingTop: 10px;paddingLeft: 5px;'>{{ proiectTaskDetail.denumire }}</span>                        
                                <span class="text__title--semismall_bold__black" style='paddingTop: 10px;paddingLeft: 15px;'>Etapa proiect:&nbsp;</span>                           
                                <span class="text__title--semismall_bold__black" style='paddingTop: 10px;paddingLeft: 5px;'>{{ proiectTaskDetail.denumire_etapa }}</span>                           
                        </div>

                         <div v-bind:class="{ 'column': $q.platform.is.mobile, 'row': $q.platform.is.desktop }">
                                <span class="text__title--small_normal__black">ID {{ proiectTaskDetail.id_proiect }} / {{ proiectTaskDetail.track_date }}</span>                           
                         </div>

                         <div v-bind:class="{ 'column': $q.platform.is.mobile, 'row': $q.platform.is.desktop }">
                                <span class="text__title--small_normal">Start:</span>
                                <span class="text__title--small_bold__black" style='paddingLeft: 5px;'>{{ proiectTaskDetail.data_start }}</span>

                                <span class="text__title--small_normal" style='paddingLeft: 15px;'>End:</span>
                                <span class="text__title--small_bold__black" style='paddingLeft: 5px;'>{{ proiectTaskDetail.data_end }}</span>

                                <span class="text__title--small_normal" style='paddingLeft: 15px;'>Total zile:</span>
                                <span class="text__title--small_bold__black" style='paddingLeft: 5px;'>{{ proiectTaskDetail.durata }}</span>
                         </div>

                         <div v-bind:class="{ 'column': $q.platform.is.mobile, 'row': $q.platform.is.desktop }">
                                <span class="text__title--small_normal">Creat de:</span>
                                <span class="text__title--small_bold__black" style='paddingLeft: 5px;'>{{ proiectTaskDetail.denumire_user_created }}</span>

                                <span class="text__title--small_normal" style='paddingLeft: 15px;'>Manager Proiect:</span>
                                <span class="text__title--small_bold__black" style='paddingLeft: 5px;'>{{ proiectTaskDetail.responsabil }}</span>
                         </div>                                                                 
                       </div>                      
                     </div> 
                     <div class="row">
                        <div class="row" style="display:flex; paddingLeft: 15px;paddingRight: 15px;paddingBottom: 15px; max-width: 55vw;">                  
                         <div style="display: flex;flex-direction: column">
                           <div v-bind:class="{ 'column': $q.platform.is.mobile, 'row': $q.platform.is.desktop }">
                                <span class="text__title--normal__black">Descriere task:&nbsp;</span>                           
                                <span class="text__title--normal">{{ proiectTaskDetail.descriere_task }}</span>                           
                           </div>
                         </div>
                        </div>  
                     </div> 
                     <div class="row">
                        <div class="row" style="display:flex; paddingLeft: 15px;paddingRight: 15px;max-width: 55vw;">                  
                         <div style="display: flex;flex-direction: column">
                           <div v-bind:class="{ 'column': $q.platform.is.mobile, 'row': $q.platform.is.desktop }">
                                <span class="text__title--normal__black">Termen task:&nbsp;</span>                           
                                <span class="text__title--normal">{{ proiectTaskDetail.termen_task }}</span>                           
                           </div>
                         </div>
                        </div>  
                     </div>     
                     <!-- <div class="row">
                        <div class="row" style="display:flex; paddingLeft: 15px;paddingRight: 15px;max-width: 55vw;">                  
                         <div style="display: flex;flex-direction: column">
                           <div v-bind:class="{ 'column': $q.platform.is.mobile, 'row': $q.platform.is.desktop }">
                                <span class="text__title--normal__black">Etapa proiect:&nbsp;</span>                           
                                <span class="text__title--normal">{{ proiectTaskDetail.denumire_etapa }}</span>                           
                           </div>
                         </div>
                        </div>  
                     </div>    -->
                     <!-- <div class="row">
                        <div class="row" style="display:flex; paddingLeft: 15px;paddingRight: 15px;max-width: 55vw;">                  
                         <div style="display: flex;flex-direction: column">
                           <div v-bind:class="{ 'column': $q.platform.is.mobile, 'row': $q.platform.is.desktop }">
                                <span class="text__title--normal__black">Responsabil:&nbsp;</span>                           
                                <span class="text__title--normal">{{ proiectTaskDetail.responsabil_etapa }}</span>                           
                           </div>
                         </div>
                        </div>  
                     </div>    -->
                     <!-- <div class="row">
                        <div class="row" style="display:flex; paddingLeft: 15px;paddingRight: 15px;max-width: 55vw;">                  
                         <div style="display: flex;flex-direction: column">
                           <div v-bind:class="{ 'column': $q.platform.is.mobile, 'row': $q.platform.is.desktop }">
                                <span class="text__title--normal__black">Termen:&nbsp;</span>                           
                                <span class="text__title--normal">{{ proiectTaskDetail.termen }}</span>                           
                           </div>
                         </div>
                        </div>  
                     </div>    -->
                     <!-- <div class="row">
                        <div class="row" style="display:flex; paddingLeft: 15px;paddingRight: 15px;max-width: 55vw;">                  
                         <div style="display: flex;flex-direction: column">
                           <div v-bind:class="{ 'column': $q.platform.is.mobile, 'row': $q.platform.is.desktop }">
                                <span class="text__title--normal__black">Task:&nbsp;</span>                           
                                <span class="text__title--normal">{{ proiectTaskDetail.denumire_task }}</span>                           
                           </div>
                         </div>
                        </div>  
                     </div>    -->
                     <!-- <div class="row">
                        <div class="row" style="display:flex; paddingLeft: 15px;paddingRight: 15px;max-width: 55vw;">                  
                         <div style="display: flex;flex-direction: column">
                           <div v-bind:class="{ 'column': $q.platform.is.mobile, 'row': $q.platform.is.desktop }">
                                <span class="text__title--normal__black">Termen task:&nbsp;</span>                           
                                <span class="text__title--normal">{{ proiectTaskDetail.termen_task }}</span>                           
                           </div>
                         </div>
                        </div>  
                     </div>    -->
                     <div class="row">
                        <div class="row" style="display:flex; paddingLeft: 15px;paddingRight: 15px;max-width: 55vw;">                  
                         <div style="display: flex;flex-direction: column">
                           <div v-bind:class="{ 'column': $q.platform.is.mobile, 'row': $q.platform.is.desktop }">
                                <span class="text__title--normal__black">Efort estimat (ore):&nbsp;</span>                           
                                <span class="text__title--normal">{{ proiectTaskDetail.estimare_ora_task }}</span>                           
                                <span class="text__title--normal__black" style="padding-left: 5px; padding-right: 5px;">/</span>
                                <span class="text__title--normal__black">Efort consumat (ore):&nbsp;</span>                           
                                <span class="text__title--normal">{{ proiectTaskDetail.ora_consumate }}</span>                           
                           </div>
                         </div>
                        </div>  
                     </div>   
                </div>          
                </div>
                 <div style="width:25%;display: flex;flex-direction: row;">
                    <div>
                        <div class="row" style="display: flex;flex-direction: row;justify-content: center; align-items:center;"> 
                                <div v-bind:class="{ column: $q.platform.is.mobile, row: $q.platform.is.desktop }">
                                        <span class="text__title--normal__black">Stare:</span>
                                        <span v-bind:class="displayStatusColor(proiectTaskDetail.stare as string)" style="paddingLeft: 10px;font-family: Nunito Sans;font-size: 1rem;font-weight: bold;">{{ getDenStatus(proiectTaskDetail.stare as string) }}</span>
                                </div>                                
                        </div> 
                        <div class="row" style="display: flex;flex-direction: row;justify-content: center; align-items:center;">
                                <div v-bind:class="{ column: $q.platform.is.mobile, row: $q.platform.is.desktop }">        
                                        <span class="text__title--normal__black">Progres:</span>
                                        <span v-bind:class="displayProgres(proiectTaskDetail.progres as number)" style="paddingLeft: 10px;font-family: Nunito Sans;font-size: 1rem;font-weight: bold;">{{ proiectTaskDetail.progres }}%</span>
                                </div>                                
                        </div> 

                        <!-- <div class="row">
                           <div style="display: flex;flex-direction: row;justify-content: center; align-items:center;width:100%">
                                <div style="display: flex;flex-direction: row">                        
                                        <div v-bind:class="{ column: $q.platform.is.mobile, row: $q.platform.is.desktop }">
                                        <div style="display: flex;align-items: center;justify-content: center; width: 100%;">
                                                <div>
                                                        <span class="text__title--normal">Documente proiect</span>
                                                        <div v-show="proiectTaskDetail.scan_documents != null && proiectTaskDetail.scan_documents.length==0 && !uploadingFileToServer" class="text__label--thin" style="width: 100%; text-align: center;">Nimic atasat</div>                                                        
                                                </div>                                                
                                        </div>

                                        <div style="display: flex;align-items: center;justify-content: center;width: 100%;">
                                                <div>
                                                <q-spinner
                                                        v-if="uploadingFileToServer"
                                                        color="primary"
                                                        size="3em"
                                                        :thickness="10"
                                                />
                                                <table v-else class="table table-bordered table-condensed table-responsive" style="width: 100%; max-height: 50vh;">
                                                <tbody>
                                                        <tr v-for="scan_document in proiectTaskDetail.scan_documents" v-bind:key="scan_document.appid">
                                                        <td>
                                                                {{scan_document.file_ext}}
                                                        </td>
                                                        <td>
                                                                {{humanReadableBytes(scan_document.file_size)}}
                                                        </td>
                                                        <td>
                                                                <div class="desktop-only" style="max-width:30vw;">{{scan_document.description}}</div>
                                                                <div class="mobile-only">{{scan_document.description}}</div>
                                                        </td>
                                                        <td>
                                                                <q-btn round color="black" icon="cloud_download" size="xs"/>
                                                                <q-menu fit
                                                                transition-show="scale"
                                                                transition-hide="scale"
                                                                >
                                                                <DialogOpenScan :appid="scan_document.appid" type_scan="Proiecte"/>
                                                                </q-menu>
                                                        </td>
                                                        </tr>
                                                </tbody>
                                                </table>
                                                </div>
                                        </div>
                                        </div>                            
                                </div>                      
                             </div>
                        </div> -->

                        <!-- <div class="row">
                           <div style="display: flex;flex-direction: row;justify-content: center; align-items:center;width:100%">
                                <div style="display: flex;flex-direction: row">                        
                                        <div v-bind:class="{ column: $q.platform.is.mobile, row: $q.platform.is.desktop }">
                                        <div style="display: flex;align-items: center;justify-content: center; width: 100%;">
                                                <div>
                                                        <span class="text__title--normal">Documente proiect etapa</span>
                                                        <div v-show="proiectTaskDetail.scan_documents_etapa != null && proiectTaskDetail.scan_documents_etapa.length==0 && !uploadingFileToServer" class="text__label--thin" style="width: 100%; text-align: center;">Nimic atasat</div>                                                        
                                                </div>
                                        </div>

                                        <div style="display: flex;align-items: center;justify-content: center;width: 100%;">
                                                <div>
                                                <q-spinner
                                                        v-if="uploadingFileToServer"
                                                        color="primary"
                                                        size="3em"
                                                        :thickness="10"
                                                />
                                                <table v-else class="table table-bordered table-condensed table-responsive" style="width: 100%; max-height: 50vh;">
                                                <tbody>
                                                        <tr v-for="scan_document in proiectTaskDetail.scan_documents_etapa" v-bind:key="scan_document.appid">                                                        
                                                        <td>
                                                                {{scan_document.file_ext}}
                                                        </td>
                                                        <td>
                                                                {{humanReadableBytes(scan_document.file_size)}}
                                                        </td>
                                                        <td>
                                                                <div class="desktop-only" style="max-width:30vw;">{{scan_document.description}}</div>
                                                                <div class="mobile-only">{{scan_document.description}}</div>
                                                        </td>
                                                        <td>
                                                                <q-btn round color="black" icon="cloud_download" size="xs"/>
                                                                <q-menu fit
                                                                transition-show="scale"
                                                                transition-hide="scale"
                                                                >
                                                                <DialogOpenScan :appid="scan_document.appid" type_scan="Proiecte_Etapa"/>
                                                                </q-menu>
                                                        </td>
                                                        </tr>
                                                </tbody>
                                                </table>
                                                </div>
                                        </div>
                                        </div>                            
                                </div>                      
                             </div>
                        </div> -->

                        <div class="row">
                           <div style="display: flex;flex-direction: row;justify-content: center; align-items:center;width:100%">
                                <div style="display: flex;flex-direction: row;justify-content: center;">                        
                                        <div v-bind:class="{ column: $q.platform.is.mobile, row: $q.platform.is.desktop }">
                                        <div style="display: flex;align-items: center;justify-content: center; width: 100%;">
                                                <div>
                                                        <span class="text__title--normal">Documente task</span>
                                                        <div v-show="proiectTaskDetail.scan_documents_task != null && proiectTaskDetail.scan_documents_task.length==0 && !uploadingFileToServer" class="text__label--thin" style="width: 100%; text-align: center;">Nimic atasat</div>                                                        
                                                </div>
                                        </div>                                        

                                        <div style="display: flex;align-items: center;justify-content: center;width: 100%;">
                                                <div>
                                                <q-spinner
                                                        v-if="uploadingFileToServer"
                                                        color="primary"
                                                        size="3em"
                                                        :thickness="10"
                                                />
                                                <table v-else class="table table-bordered table-condensed table-responsive" style="width: 100%; max-height: 50vh;">
                                                <tbody>
                                                        <tr v-for="scan_document in proiectTaskDetail.scan_documents_task" v-bind:key="scan_document.appid">                                                        
                                                        <td>
                                                                {{scan_document.file_ext}}
                                                        </td>
                                                        <td>
                                                                {{humanReadableBytes(scan_document.file_size)}}
                                                        </td>
                                                        <td>
                                                                <div class="desktop-only" style="max-width:30vw;">{{scan_document.description}}</div>
                                                                <div class="mobile-only">{{scan_document.description}}</div>
                                                        </td>
                                                        <td>
                                                                <q-btn round color="black" icon="cloud_download" size="xs"/>
                                                                <q-menu fit
                                                                transition-show="scale"
                                                                transition-hide="scale"
                                                                >
                                                                <DialogOpenScan :appid="scan_document.appid" type_scan="Proiecte_Task"/>
                                                                </q-menu>
                                                        </td>
                                                        </tr>
                                                </tbody>
                                                </table>
                                                </div>
                                        </div>
                                        </div>                            
                                </div>                      
                           </div>
                        </div>
                     </div>
                 </div>                  
                </div>                 
             </div>

        <q-expansion-item
                v-model="expandedPontareTask"                
                :label="tableDataProiecteTaskuriPontari.length != 0 ? 'Pontare task '+ tableDataProiecteTaskuriPontari.length : 'Pontare task'"
                dense-toggle
                expand-separator
                header-class="text-primary"
                header-style="font-weight: bold;">
                <q-table
                        dense
                        :rows-per-page-options="[0]"
                        :rows="tableDataProiecteTaskuriPontari"
                        :columns="proiecteTaskuriPontariColumns"
                        :visible-columns="visibleProiecteTaskuriPontariColumns"
                        row-key="appid"
                        :filter="filterProiectTaskuriPontare"
                        :loading="loadingProiectTaskData"
                        :pagination.sync="myPagination"
                >
                        <template v-slot:top>
                                <q-btn flat color="primary" icon="add_box" no-caps size="lg" :disable="loadingProiectTaskData" @click="addRowProiecteTaskuriPontare" >
                                        <q-tooltip
                                                transition-show="rotate"
                                                transition-hide="rotate"
                                        >
                                        Adauga task pontare noua
                                        </q-tooltip>
                                </q-btn>                                
                                <q-space />
                                <!--test-->
                                <q-input dense debounce="300" color="primary" v-model="filterProiectTaskuriPontare">
                                <template v-slot:append>
                                        <q-icon name="search" />
                                </template>
                                </q-input>
                        </template>

                        <template v-slot:body="props">
                                <q-tr :props="props">
                                        <q-td key="id_proiect_task_pontare" :props="props">
                                                {{ props.row.id_proiect_task_pontare }}                        
                                        </q-td>                    
                                        <q-td key="denumire" :props="props" :sortable="true" style="white-space:pre-wrap; word-wrap:break-word">
                                                {{ props.row.denumire }}
                                        </q-td>
                                        <q-td key="data" :props="props" :sortable="true">                        
                                                {{ displayData(props.row.data) }}
                                        </q-td>
                                        <q-td key="ora_consumate" :props="props" :sortable="true">
                                                {{ props.row.ora_consumate }}
                                        </q-td> 
                                        <q-td key="progres_estimat_task" :props="props" :sortable="true"
                                                v-bind:class="displayProgres(props.row.progres_estimat_task)">
                                                {{ props.row.progres_estimat_task }}%
                                        </q-td>
                                        <q-td key="stare" :props="props" :sortable="true"
                                                v-bind:class="displayStatusColor(props.row.stare)">                                                                        
                                                {{ getDenStatus(props.row.stare) }}
                                        </q-td>
                                        <q-td key="id_proiect_task_pontare" :props="props">
                                                <q-icon name="edit" class="text-blue cursor-pointer" style="font-size: 1.2rem; margin-left: auto;" @click="openEditPontareTask(props.row)" />
                                        </q-td>                                       
                                </q-tr>                                                  
                        </template>
                </q-table>

                <q-dialog v-model="visibleDialogEditProiecteTaskuriPontari" persistent >
                        <q-card style="min-width: 60vw; max-width: 80vw;">
                                <q-bar>
                                <q-icon name="add_box" />
                                <div>Editare proiect task pontare</div>

                                <q-space />

                                <q-btn dense flat icon="close" v-close-popup @click="refreshDataTableProiecteTaskuriPontari">
                                        <q-tooltip>Close</q-tooltip>
                                </q-btn>
                                </q-bar>

                                <q-card-section>
                                        <EditProiectTaskPontare :foncancel="renuntEditareProiecteTaskuriPontare" :fonsave="getProiectTaskDetail" :appid="appid_pontare" :proiect_detail_info="proiect_detail_info" :is_all="is_all" :is_load_from_dashboard="true"/>
                                </q-card-section>
                        </q-card>
                </q-dialog>
        </q-expansion-item>

        <q-expansion-item
                v-model="expandedPontareNote"
                :label="tableDataProiecteTaskuriNotite.length != 0 ? 'Notite '+ tableDataProiecteTaskuriNotite.length : 'Notite'"
                dense-toggle
                expand-separator
                header-class="text-primary"
                header-style="font-weight: bold;">
                <q-table
                        dense
                        :rows-per-page-options="[0]"
                        :rows="tableDataProiecteTaskuriNotite"
                        :columns="proiecteTaskuriNotiteColumns"
                        :visible-columns="visibleProiecteTaskuriNotiteColumns"
                        row-key="appid"
                        :filter="filterProiectTaskuriNota"
                        :loading="loadingProiectTaskData"
                        :pagination.sync="myPagination"
                >
                        <template v-slot:top>
                                <q-btn flat color="primary" icon="add_box" no-caps size="lg" :disable="loadingProiectTaskData" @click="addRowProiecteTaskuriNota" >
                                        <q-tooltip
                                                transition-show="rotate"
                                                transition-hide="rotate"
                                        >
                                        Adauga task nota noua
                                        </q-tooltip>
                                </q-btn>                                
                                <q-space />
                                <!--test-->
                                <q-input dense debounce="300" color="primary" v-model="filterProiectTaskuriNota">
                                <template v-slot:append>
                                        <q-icon name="search" />
                                </template>
                                </q-input>
                        </template>

                        <template v-slot:body="props">
                                <q-tr :props="props">
                                        <q-td key="appid" :props="props">
                                                {{ props.row.appid }}                        
                                        </q-td>
                                        <q-td key="track_date" :props="props" :sortable="true">                        
                                                {{ displayDataFull(props.row.track_date) }}
                                        </q-td>
                                        <q-td key="utilizator" :props="props" :sortable="true" style="white-space:pre-wrap; word-wrap:break-word">
                                                {{ props.row.utilizator }}
                                        </q-td>
                                        <q-td key="descriere" :props="props" :sortable="true" style="white-space:pre-wrap; word-wrap:break-word">
                                                {{ props.row.descriere }}
                                        </q-td>
                                        <q-td key="scans" :props="props" style="white-space:pre-wrap; word-wrap:break-word">
                                                <div style="max-width:30vw;" class="container--span__file_scan">
                                                <span v-for="scan in props.row.scans" v-bind:key="scan.appid" class="cursor-pointer span__file_scan">
                                                <span class="material-icons rotated90">attachment</span>
                                                        {{humanReadableBytes(scan.file_size)}}.{{scan.file_ext}}
                                                        <q-menu fit                                          
                                                        transition-show="scale"
                                                        transition-hide="scale"                                          
                                                        >
                                                        <DialogOpenScan :appid="scan.appid" type_scan="Proiecte_Task_Nota"/>
                                                        </q-menu>
                                                </span>
                                                </div>
                                        </q-td>
                                </q-tr>                                                  
                        </template>
                </q-table>

                <q-dialog v-model="visibleDialogEditProiecteTaskuriNotite" persistent >
                        <q-card style="min-width: 60vw; max-width: 80vw;">
                                <q-bar>
                                <q-icon name="add_box" />
                                <div>Editare proiect task nota</div>

                                <q-space />

                                <q-btn dense flat icon="close" v-close-popup @click="refreshDataTableProiecteTaskuriNotite">
                                        <q-tooltip>Close</q-tooltip>
                                </q-btn>
                                </q-bar>

                                <q-card-section>
                                        <EditProiectTaskNota :foncancel="renuntEditareProiecteTaskuriNota" :fonsave="refreshDataTableProiecteTaskuriNotite" :appid="appid_nota" :proiect_detail_info="proiect_detail_info" />
                                </q-card-section>
                        </q-card>
                </q-dialog>
        </q-expansion-item>
        
           </div>
        </div>
    </div>
</template>

<script lang="ts" src="./EditProiectTaskDetail.ts" />

<style scoped>
    .q-field--with-bottom {
        padding-bottom: 20px;
    }

    .q-field__native{
        font-family: "Nunito Sans";
        font-weight: bolder;
    }
</style>