<template>
  <div class="q-pa-md">
    <q-spinner-gears
      v-if="loadingData"
      color="primary"
      size="4em"
      class="absolute-center"
    />
    <div v-else>
      <q-form ref="myForm">
        <div class="well well-sm">
          <div class="panel">          
            <div class="q-pa-md">
              <div style="display: flex; align-items: center; margin-bottom: 15px">
                <div
                  v-bind:class="{
                    column: $q.platform.is.mobile,
                    row: $q.platform.is.desktop,
                  }"
                >
                  <span class="text__title--medium">Pontaj proiect</span>
                </div>
              </div>

              <div style="display: flex;align-items: start;">
                <div style="width: 65%; display: flex; flex-direction: row;">
                  <div style="display: flex;flex-direction: column;width: 100%;">
                    <div class="row">
                      <span class="col-4 form__label">Proiect</span>
                      <span class="col-8 form__label__black">{{proiect.proiect_id?.denumire}}</span>
                    </div>

                    <div class="row">
                      <span class="col-4 form__label">Descriere</span>
                      <span class="col-8 form__label__black">{{proiect.descriere_proiect}}</span>              
                    </div>

                    <div class="row">
                      <span class="col-4 form__label">La data</span>
                      <span class="col-8 form__label__black">{{proiect.pontaj_date}}</span>              
                    </div>

                    <div class="row">
                      <span class="col-4 form__label">Ore pontate</span>
                      <span class="col-8 form__label__black">{{proiect.efort}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div clas="row" style="margin-top: 5px;margin-bottom: 15px;" v-if="proiect.stare !== 'initiat'">
                <TaskList :appid="appid" :task="task" :onState="onState" :isSuccess="isSuccess"/>
            </div>
          </div>      
        </div>
      </q-form>
    </div>
  </div>
</template>

<script lang="ts" src="./EditPontajProiectDetail.ts" />

<style scoped>
.q-field--with-bottom {
  padding-bottom: 20px;
}

.q-field__native {
  font-family: "Nunito Sans";
  font-weight: bolder;
}
</style>