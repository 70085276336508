import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { style: {"font-family":"'PT Sans Narrow', sans-serif","font-size":"0.8em"} }
const _hoisted_3 = { style: {"font-family":"'Nunito Sans', sans-serif","font-size":"0.8em"} }
const _hoisted_4 = { style: {"font-family":"'Nunito Sans', sans-serif","font-size":"0.8em"} }
const _hoisted_5 = { style: {"font-family":"'Nunito Sans', sans-serif","font-size":"0.8em"} }
const _hoisted_6 = { style: {"font-family":"'Nunito Sans', sans-serif","font-size":"0.8em"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_table, {
      dense: "",
      "rows-per-page-options": [0],
      rows: _ctx.tableDataOrgUnits,
      columns: _ctx.columns,
      "visible-columns": _ctx.visibleColumns,
      "row-key": "id",
      filter: _ctx.filterOrgUnits,
      loading: _ctx.loadingData,
      pagination: _ctx.myPagination
    }, {
      top: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createVNode(_component_q_space),
        _createVNode(_component_q_input, {
          dense: "",
          debounce: "300",
          color: "primary",
          modelValue: _ctx.filterOrgUnits,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterOrgUnits) = $event))
        }, {
          append: _withCtx(() => [
            _createVNode(_component_q_icon, { name: "search" })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      body: _withCtx((props) => [
        _createVNode(_component_q_tr, {
          props: props,
          onClick: ($event: any) => (_ctx.selectHandler(props.row))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_td, {
              key: "organization_unit_descriere",
              props: props,
              sortable: true
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_3, _toDisplayString(props.row.organization_unit_descriere), 1)
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_td, {
              key: "tip_org_unit_id",
              props: props,
              sortable: true
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.getDenTip(props.row.tip_org_unit_id)), 1)
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_td, {
              key: "parent_organization_unit_descriere",
              props: props,
              sortable: true
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_5, _toDisplayString(props.row.parent_organization_unit_descriere), 1)
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_td, {
              key: "parent_tip_org_unit_id",
              props: props,
              sortable: true
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.getDenTip(props.row.parent_tip_org_unit_id)), 1)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 2
        }, 1032, ["props", "onClick"])
      ]),
      _: 1
    }, 8, ["rows", "columns", "visible-columns", "filter", "loading", "pagination"])
  ]))
}