import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';

interface TAjaxResponseNomConcedii{
  status: string;
  message: string;
  rows: string;
}

export class ServiceNomConcedii {
  public static async getNomConcedii(): Promise<TAjaxResponseNomConcedii> {
    const response = await axios.get(`${CONFIG_ENV.URL_NOMCONCEDIU.getNomConcediu}`);
    return response.data;
  }
}