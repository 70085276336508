import axios from "axios";
import {CONFIG_ENV} from "@/config";
import 'url-search-params-polyfill';
import IPontajPrezenta from "@/types/IPontajPrezenta";
import IScanPontajPrezenta from "@/types/IScanPontajPrezenta";
import IMonthInfo from '@/types/IMonthInfo';
import moment from 'moment';
import {dateToStringYYYYMMDDStandard} from '@/modules/Utils';
import TPersonMonthStatus from "@/types/TPersonMonthStatus";
import { TOvertimeAccount } from "@/types/TOvertimeAccount";
import ISearchParamsPontajPrezenta from "@/types/ISearchParamsPontajPrezenta";
import IMonths from "@/types/IMonths";
import ISearchParamsPontajPrezentaLunar from "@/types/ISearchParamsPontajPrezentaLunar";

// type OrNull<T> = { [K in keyof T]: T[K] | null }
// type IPontajPrezentaNull = OrNull<IPontajPrezenta>;

interface TAjaxResponsePontajPrezenta {
    status: string;
    message: string;
    totalNrOfPages: number;
    currentPage: number;
    nrRecordsPerPage: number;
    totalRecords: number;
    rows: IPontajPrezenta[];
}

interface TAjaxResponsePrezenta {
    status: string;
    message: string;
    prezenta: IPontajPrezenta;
}

interface TAjaxResponseEditPontajPrezenta {
    status: string;
    message: string;
}

interface TAjaxResponseSimple {
    status: string;
    message: string;
    id: number;
}

interface TAjaxResponseGetScansPontajePrezenta {
    status: string;
    message: string;
    nr_of_records: number,
    rows: IScanPontajPrezenta[];
}

interface TAjaxResponseGetInfoScanPontajPrezenta {
    status: string;
    message: string;
    scan: IScanPontajPrezenta;
}

interface TAjaxSimpleResponse {
    status: string;
    message: string;
}

interface TAjaxResponseMonthInfo {
    status: string;
    message: string;
    monthInfo: IMonthInfo;
}

interface TAjaxResponsePersonsMonthStatus {
    status: string;
    message: string;
    rows: TPersonMonthStatus[];
}

// interface IDateParam {
//     start_date: string,
//     end_date: string,
// }

interface TAjaxResponseOvertimeAccounts {
    status: string;
    message: string;
    overtimeAccount: TOvertimeAccount|null;
}

interface TAjaxResponseMonths {
    status: string;
    message: string;
    months: IMonths[];
}

export class ServicePontajPrezenta {
    // public static async startFlux(appid: number): Promise<TAjaxResponseEditPontajPrezenta> {
    //     const response = await axios.put(`${CONFIG_ENV.URL_PONTAJ_PREZENTA.start_flux}/${appid}`);
    //     return response.data;
    // }

    public static async getPontajPrezente(is_all: number, nrRanduriTabel: number, pageNumber: number, searchParams: ISearchParamsPontajPrezenta): Promise<TAjaxResponsePontajPrezenta> {
        const response = await axios.post(`${is_all == 0 ? CONFIG_ENV.URL_PONTAJ_PREZENTA.pontajePrezenta : CONFIG_ENV.URL_PONTAJ_PREZENTA.pontajePrezente}/${nrRanduriTabel}/${pageNumber}`, {searchParams});
        return response.data;
    }

    public static async getPontajPrezenta(id: number): Promise<TAjaxResponsePrezenta> {
        const response = await axios.get(`${CONFIG_ENV.URL_PONTAJ_PREZENTA.getPontajPrezenta}/${id}`);
        return response.data;
    }

    public static async deletePontajPrezenta(id: number): Promise<TAjaxResponseEditPontajPrezenta> {
        const response = await axios.delete(`${CONFIG_ENV.URL_PONTAJ_PREZENTA.deletePontajPrezenta}/${id}`);
        return response.data;
    }

    public static async updatePontajPrezenta(prezenta: IPontajPrezenta): Promise<TAjaxResponseSimple> {
        const response = await axios.post(`${CONFIG_ENV.URL_PONTAJ_PREZENTA.editPontajPrezenta}`, prezenta);
        return response.data;
    }

    public static async getScansPontaj(appid:number): Promise<TAjaxResponseGetScansPontajePrezenta> {
        const response = await axios.get(`${CONFIG_ENV.URL_PONTAJPREZENTA_SCAN.getScansPontajePrezenta}/${appid}`);
        return response.data;
    }

    public static async getInfoScan(appid:number): Promise<TAjaxResponseGetInfoScanPontajPrezenta> {
        const response = await axios.get(`${CONFIG_ENV.URL_PONTAJPREZENTA_SCAN.getInfoScan}/${appid}`);
        return response.data;
    }

    public static async deleteScan(appid:number, headers: any): Promise<TAjaxSimpleResponse> {
        const response = await axios.delete(`${CONFIG_ENV.URL_PONTAJPREZENTA_SCAN.getInfoScan}/${appid}`, { headers });
        return response.data;
    }    

    public static async getMonthInfo(month: Date): Promise<TAjaxResponseMonthInfo> { 
        const param = dateToStringYYYYMMDDStandard(moment(month, 'YYYY-MM-DD'));

        const response = await axios.post(`${CONFIG_ENV.URL_PONTAJ_PREZENTA.getMonthInfo}`, { param });
        return response.data;
    }

    public static async getPersonsMonthStatus(is_all: number, searchParams: ISearchParamsPontajPrezentaLunar): Promise<TAjaxResponsePersonsMonthStatus> {
        // const param = {} as IDateParam;
        // param.start_date = dateToStringYYYYMMDDStandard(moment(start_date, 'YYYY-MM-DD'));
        // param.end_date = dateToStringYYYYMMDDStandard(moment(end_date, 'YYYY-MM-DD'));
    
        const response = await axios.post(`${CONFIG_ENV.URL_PONTAJ_PREZENTA.getPersonsMonthStatus}/${is_all}`, {searchParams});
        return response.data;
    }

    public static async getOvertimeAccountForMarca(marca:number): Promise<TAjaxResponseOvertimeAccounts> {
        const response = await axios.get(`${CONFIG_ENV.URL_PONTAJ_PREZENTA.OvertimeAccountMarca}/${marca}`);
        return response.data;
    }

    public static async getMonths(): Promise<TAjaxResponseMonths> {
        const response = await axios.get(`${CONFIG_ENV.URL_PONTAJ_PREZENTA.getMonths}`);
        return response.data;
    }
}