<template>
  <div>
    <q-item clickable v-ripple @click="goToViewWithParamString('Dashboard', 'documente_repartizate')">
      <q-item-section avatar>
        <q-icon color="primary" name="dashboard" />
      </q-item-section>
      <q-item-section>
        <q-item-label>Dashboard</q-item-label>
        <q-item-label>
          <q-badge color="blue" transparent style="position: relative;">
            {{abbreviateNumber(eventSourceNrBadge.nrOfMyDocuments)}}
          </q-badge>
          <q-badge color="orange" transparent style="position: relative;margin-left:5px;">
            {{abbreviateNumber(eventSourceNrBadge.nrOfPendingToRegister)}}
          </q-badge>
          <q-badge color="red" transparent style="position: relative;margin-left:5px;">
            {{abbreviateNumber(eventSourceNrBadge.nrOfTask)}}
          </q-badge>
          <q-badge color="green" transparent style="position: relative;margin-left:5px;">
            {{abbreviateNumber(eventSourceNrBadge.nrOfTaskIstoric)}}
          </q-badge>
          <q-badge color="cyan" transparent style="position: relative;margin-left:5px;" v-if="user.auth_for_proiect=='y'">
            {{abbreviateNumber(eventSourceNrBadge.nrProjectTask)}}
          </q-badge>
          <q-badge color="purple" transparent style="position: relative;margin-left:5px;" v-if="['APP_DEV', 'APP_SOPHORE', 'APP_CV', 'APP_URBANESQ', 'APP_MEAT', 'APP_BRANDTEAM', 'APP_HR'].includes(APP_NAME)">
            {{abbreviateNumber(eventSourceNrBadge.nrAssetEvenimente)}}
          </q-badge>
        </q-item-label>
      </q-item-section>
    </q-item>

    <q-item v-if="user.is_admin=='y' || user.auth_for_search=='y'" clickable v-ripple @click="goToView('Search')">
      <q-item-section avatar>
        <q-icon color="primary" name="search" />
      </q-item-section>
      <q-item-section>
        <q-item-label>Cautare document</q-item-label>
      </q-item-section>
    </q-item>

    <q-item v-if="(user.is_admin=='y' || user.auth_for_document_portal=='y') && ['APP_DEV', 'APP_CV', 'APP_CJCV', 'APP_HR'].includes(APP_NAME)" 
      clickable v-ripple @click="goToView('DocumenteCetateni')">
      <q-item-section avatar>
        <q-icon color="primary" name="chrome_reader_mode" />
      </q-item-section>
      <q-item-section>
        <q-item-label>Solicitari cetateni</q-item-label>
      </q-item-section>
    </q-item>

    <div style="max-width: 350px">
      <q-list>
        <q-expansion-item
          switch-toggle-side        
          expand-separator
          label="Registratura"
          v-if="user.is_admin=='y' || user.auth_for_input_intrare=='y' || user.auth_for_input_exit=='y' || user.auth_for_input_intern=='y'"
        >
          <!-- <q-expansion-item
            switch-toggle-side        
            expand-separator          
            label="Inregistrare documente"
            v-if="user.auth_for_input_intrare=='y' || user.auth_for_input_exit=='y' || user.auth_for_input_intern=='y'"
          > -->
            <span style="color: #747474; margin-left: 15px">Inregistrare documente</span>            
            <br />

            <q-item v-if="user.is_admin=='y' || user.auth_for_input_intrare=='y'" clickable v-ripple @click="goToView('Intrare')">
              <q-item-section avatar>
                <q-icon color="primary" name="input" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Document intrare</q-item-label>
              </q-item-section>
            </q-item>

            <q-item v-if="user.is_admin=='y' || user.auth_for_input_exit=='y'" clickable v-ripple @click="goToView('Iesire')">
              <q-item-section avatar>                
                <q-icon color="primary" name="output" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Document iesire</q-item-label>
              </q-item-section>
            </q-item>

            <q-item v-if="user.is_admin=='y' || user.auth_for_input_intern=='y'" clickable v-ripple @click="goToView('Intern')">
              <q-item-section avatar>
                <q-icon color="primary" name="system_update_alt" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Document intern</q-item-label>
              </q-item-section>
            </q-item>
          <!-- </q-expansion-item> -->          

            <span v-if="user.is_admin=='y' || user.auth_todo_repartizare=='y' || user.auth_for_search=='y' || user.auth_for_document_nefinalizat=='y' || user.auth_for_reportregistryoffice=='y'" style="color: #747474; margin-left: 15px">Actiuni documente</span>
            <br />

            <q-item v-if="user.is_admin=='y' || user.auth_for_document_nefinalizat=='y'" clickable v-ripple @click="goToView('DocumentNefinalizat')">
              <q-item-section avatar>
                <q-icon color="primary" name="source" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Documente nefinalizate
                  <q-badge v-if="eventSourceNrBadge.nrDocumenteNeFinalizate>0" color="orange" transparent style="position: relative;top:-5px;left:5px;">
                    {{abbreviateNumber(eventSourceNrBadge.nrDocumenteNeFinalizate)}}
                  </q-badge>
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-item v-if="user.is_admin=='y' || user.auth_todo_repartizare=='y'" clickable v-ripple @click="goToView('DeRepartizat')">
              <q-item-section avatar>
                <q-icon color="primary" name="device_hub" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Repartizare documente
                  <q-badge v-if="eventSourceNrBadge.nrDocumenteDeRepartizate>0" color="red" transparent style="position: relative;top:-5px;left:5px;">
                    {{abbreviateNumber(eventSourceNrBadge.nrDocumenteDeRepartizate)}}
                  </q-badge>
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-item v-if="user.is_admin=='y' || user.auth_todo_repartizare=='y'" clickable v-ripple @click="goToView('ToateRepartizate')">
              <q-item-section avatar>
                <q-icon color="primary" name="layers" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Documente repartizate
                  <q-badge v-if="eventSourceNrBadge.nrAllAllocatedDocs>0" color="green" transparent style="position: relative;top:-5px;left:5px;">
                    {{abbreviateNumber(eventSourceNrBadge.nrAllAllocatedDocs)}}
                  </q-badge>
                </q-item-label>
              </q-item-section>
            </q-item>                      

            <q-item v-if="user.is_admin=='y' || user.auth_for_search=='y' || user.auth_for_reportregistryoffice=='y'" clickable v-ripple @click="goToView('Reports')">
              <q-item-section avatar>
                <q-icon color="primary" name="bubble_chart" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Rapoarte registratura</q-item-label>
              </q-item-section>
            </q-item>

            <q-item clickable v-ripple @click="goToView('StatusNumere')">
              <q-item-section avatar>
                <q-icon color="primary" name="bookmark_border" />
              </q-item-section>
              <q-item-section>
                <q-item-label>Status numere</q-item-label>
              </q-item-section>
            </q-item>
          <!-- </q-expansion-item> -->
        </q-expansion-item>

        <q-expansion-item
          switch-toggle-side        
          expand-separator          
          label="Servicii electronice"
          v-if="(user.is_admin=='y' || user.auth_for_certificaturbanism=='y' || user.auth_for_serviciidetransport=='y') && ['APP_DEV', 'APP_HR'].includes(APP_NAME)"
        >
          <q-item v-if="(user.is_admin=='y' || user.auth_for_certificaturbanism=='y')" clickable v-ripple @click="goToView('CertificateUrbanism')">
            <q-item-section avatar>
              <q-icon color="primary" name="web_asset" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Certificate urbanism</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="(user.is_admin=='y' || user.auth_for_serviciidetransport=='y')" clickable v-ripple @click="goToView('ServiciiDeTransport')">
            <q-item-section avatar>
              <q-icon color="primary" name="web_asset" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Autorizatii speciale transport</q-item-label>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-expansion-item
          switch-toggle-side        
          expand-separator          
          label="Active"
          v-if="(user.is_admin=='y' || user.auth_for_asset=='y') && ['APP_DEV', 'APP_SOPHORE', 'APP_CV', 'APP_URBANESQ', 'APP_MEAT', 'APP_BRANDTEAM', 'APP_HR'].includes(APP_NAME)"
        >
          <q-item clickable v-ripple @click="goToViewWithParamIsDialogPopup('EditAsset', false)">
            <q-item-section avatar>
              <q-icon color="primary" name="web_asset" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Activ nou</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParamString('Assets', ' ')">
            <q-item-section avatar>
              <q-icon color="primary" name="web_asset" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Lista active</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParam('AssetsNotificationsList', 1)">
            <q-item-section avatar>
              <q-icon color="primary" name="web_asset" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Evenimente</q-item-label>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-expansion-item
          switch-toggle-side 
          expand-separator 
          label="Financiar"
          v-if="(user.is_admin=='y' || user.auth_for_financiar=='y') && ['APP_DEV', 'APP_URBANESQ'].includes(APP_NAME)"
        >
          <q-item clickable v-ripple @click="goToView('Facturi')">
            <q-item-section avatar>
              <q-icon color="primary" name="receipt_long" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Facturi</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToView('Parteneri')">
            <q-item-section avatar>
              <q-icon color="primary" name="handshake" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Parteneri</q-item-label>
            </q-item-section>
          </q-item>

          <!-- <q-item clickable v-ripple >
            <q-item-section avatar>
              <q-icon color="primary" name="local_offer" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Oferte</q-item-label>
            </q-item-section>
          </q-item> -->

          <!-- <q-item clickable v-ripple>
            <q-item-section avatar>
              <q-icon color="primary" name="list_alt" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Comenzi</q-item-label>
            </q-item-section>
          </q-item> -->

          <!-- <q-item clickable v-ripple>
            <q-item-section avatar>
              <q-icon color="primary" name="categoryt" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Articole</q-item-label>
            </q-item-section>
          </q-item> -->

          <!-- <q-item clickable v-ripple>
            <q-item-section avatar>
              <q-icon color="primary" name="inventory_2" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Depozite</q-item-label>
            </q-item-section>
          </q-item> -->

          <q-item clickable v-ripple @click="goToView('CursValutar')">
            <q-item-section avatar>
              <q-icon color="primary" name="currency_exchange" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Curs valutar</q-item-label>
            </q-item-section>
          </q-item>

          <!-- <q-item clickable v-ripple>
            <q-item-section avatar>
              <q-icon color="primary" name="summarize" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Rapoarte</q-item-label>
            </q-item-section>
          </q-item> -->
        </q-expansion-item>

        <q-expansion-item
          switch-toggle-side        
          expand-separator          
          label="Operatiuni personal"
          v-if="(user.is_admin=='y' || user.auth_for_operatiunipersonal=='y')"
        > 
          <q-item clickable v-ripple @click="goToViewWithParam('PontajPrezenta', 0)">
            <q-item-section avatar>
              <q-icon color="primary" name="door_sliding" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Pontaj prezenta</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParam('PontajProiect', 0)">
            <q-item-section avatar>
              <q-icon color="primary" name="door_sliding" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Pontaj proiect</q-item-label>
            </q-item-section>
          </q-item>
          
          <q-item clickable v-ripple @click="goToViewWithParam('PontajTask', 0)">
            <q-item-section avatar>
              <q-icon color="primary" name="door_sliding" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Pontaj task</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParam('MyTimesRequests', 0)">
            <q-item-section avatar>
              <q-icon color="primary" name="date_range" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Concedii</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParam('NomDeplasari', 0)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Deplasari</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParam('NomDeconturi', 0)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Deconturi</q-item-label>
            </q-item-section>
          </q-item>          

          <q-item clickable v-ripple @click="goToViewWithParam('Referate', 0)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Referat</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParam('Cereri', 0)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Cereri de avans</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="['APP_DEV', 'APP_HR', 'APP_MAGIC'].includes(APP_NAME)" clickable v-ripple @click="goToView('Pontaje')">
            <q-item-section avatar>
              <q-icon color="primary" name="door_sliding" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Istoric acces</q-item-label>
            </q-item-section>
          </q-item>       
        </q-expansion-item>

        <q-expansion-item
          switch-toggle-side        
          expand-separator          
          label="Arhiva"
          v-if="user.is_admin=='y'"
        > 
          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToViewWithParam('Arhive', 0)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Arhivare</q-item-label>
            </q-item-section>
          </q-item>       
          <!-- <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToViewWithParam('Arhive', 0)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Iesiri</q-item-label>
            </q-item-section>
          </q-item> 
          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToViewWithParam('Arhive', 0)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Returnari</q-item-label>
            </q-item-section>
          </q-item> -->
        </q-expansion-item>

        <q-expansion-item
          switch-toggle-side        
          expand-separator          
          label="Management dezvoltare"
          v-if="(user.is_admin=='y' || user.auth_for_managementdezvoltare=='y' || user.auth_for_reportbianalytics=='y')"
        >
          <q-item clickable v-ripple @click="goToViewWithParam('Taskuri', 1)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Taskuri</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToView('Proiecte')">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Proiecte</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParam('PontajTask', 1)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Pontaje task</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParamString('ApiExternIntergation', 'bitbucket')" v-if="['APP_MAGIC'].includes(APP_NAME)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Cod sursa</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParamString('ApiExternIntergation', 'jira')" v-if="['APP_MAGIC'].includes(APP_NAME)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Service desk</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParamString('ApiExternIntergation', 'figma')" v-if="['APP_MAGIC'].includes(APP_NAME)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Proiectare UI</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParamString('ApiExternIntergation', 'mantis')" v-if="['APP_MAGIC'].includes(APP_NAME)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Mantis</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParamString('ApiExternIntergation', 'confluence')" v-if="['APP_MAGIC'].includes(APP_NAME)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Documentatii proiecte</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParamString('ApiExternIntergation', 'apipostman')" v-if="['APP_MAGIC'].includes(APP_NAME)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>API management</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParamString('ApiExternIntergation', 'chatgpt')" v-if="['APP_MAGIC'].includes(APP_NAME)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>AI code assistant</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToView('BIAnalytics')" v-if="(user.is_admin=='y' || user.auth_for_reportbianalytics=='y')">
            <q-item-section avatar>
              <q-icon color="primary" name="date_range" />
            </q-item-section>
            <q-item-section>
              <q-item-label>BI analytics</q-item-label>
            </q-item-section>
          </q-item>
          
          <q-item clickable v-ripple @click="openLink" v-if="['APP_MAGIC'].includes(APP_NAME)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Office</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToView('Documentator')" v-if="['APP_MAGIC'].includes(APP_NAME)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Documentare cod</q-item-label>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-expansion-item
          switch-toggle-side        
          expand-separator          
          label="Operatiuni conducere"
          v-if="(user.is_admin=='y' || user.auth_for_operatiuniconducere=='y' || user.auth_for_restrictionsholiday=='y')"
        >
          <q-item v-if="user.is_admin=='y' || user.auth_for_operatiuniconducere=='y'" clickable v-ripple @click="goToViewWithParam('AdminPrezenta', 0)">
            <q-item-section avatar>
              <q-icon color="primary" name="date_range" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Monitorizare Concedii</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y' || user.auth_for_operatiuniconducere=='y' || user.auth_for_restrictionsholiday=='y'" clickable v-ripple @click="goToViewWithParam('RestrictiiConcediu', 0)">
            <q-item-section avatar>
              <q-icon color="primary" name="date_range" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Restrictionari concediu</q-item-label>
            </q-item-section>
          </q-item>          
        </q-expansion-item>

        <q-expansion-item
          switch-toggle-side 
          expand-separator 
          label="HR"
          v-if="(user.is_admin=='y' || user.auth_for_operatiunihr=='y' || user.auth_for_angajari=='y' || user.auth_for_medicinamuncii=='y')"
        > 
          <q-item clickable v-ripple @click="goToViewWithParam('PontajPrezenta', 1)">
            <q-item-section avatar>
              <q-icon color="primary" name="door_sliding" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Pontaj prezenta</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParam('PontajProiect', 1)">
            <q-item-section avatar>
              <q-icon color="primary" name="door_sliding" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Pontaj proiect</q-item-label>
            </q-item-section>
          </q-item>

          <!-- <q-item clickable v-ripple @click="goToViewWithParam('Pontare task', 0)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Pontaje task</q-item-label>
            </q-item-section>
          </q-item> -->

          <q-item v-if="user.is_admin=='y' || user.auth_for_monitoringholiday=='y'" clickable v-ripple @click="goToViewWithParam('AdminPrezenta', 1)">
            <q-item-section avatar>
              <q-icon color="primary" name="date_range" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Monitorizare Concedii</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParam('MyTimesRequests', (user.is_admin=='y' || user.auth_for_timerequesthr=='y') ? 1 : 0)">
            <q-item-section avatar>
              <q-icon color="primary" name="date_range" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Concedii</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y' || user.auth_for_restrictionsholiday=='y'" clickable v-ripple @click="goToViewWithParam('RestrictiiConcediu', 1)">
            <q-item-section avatar>
              <q-icon color="primary" name="date_range" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Restrictionari concediu</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y' || user.auth_for_rightdaysholiday=='y'" clickable v-ripple @click="goToView('DrepturiCO')">
            <q-item-section avatar>
              <q-icon color="primary" name="date_range" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Drepturi CO</q-item-label>
            </q-item-section>
          </q-item>   

          <q-item v-if="user.is_admin=='y' || user.auth_for_officialdaysoff=='y'" clickable v-ripple @click="goToView('NomZiLibereOficial')">
            <q-item-section avatar>
              <q-icon color="primary" name="date_range" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Administrare zile libere</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParam('NomDeplasari', 1)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Deplasari</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParam('NomDeconturi', 1)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Deconturi</q-item-label>
            </q-item-section>
          </q-item>          

          <q-item clickable v-ripple @click="goToViewWithParam('Referate', 1)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Referat</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParam('Cereri', 1)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Cereri de avans</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToViewWithParam('Arhive', 1)">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Arhive</q-item-label>
            </q-item-section>
          </q-item>          
          
          <q-item clickable v-ripple @click="goToView('ReportsHR')">
            <q-item-section avatar>
              <q-icon color="primary" name="date_range" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Rapoarte</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y' || user.auth_for_medicinamuncii=='y'" clickable v-ripple @click="goToView('MedicinaMuncii')">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Medicina muncii</q-item-label>
            </q-item-section>
          </q-item>

          <!-- <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('OperatiuniRaportPersonal')">
            <q-item-section avatar>
              <q-icon color="primary" name="door_sliding" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Rapoarte</q-item-label>
            </q-item-section>
          </q-item> -->
        </q-expansion-item>

        <q-expansion-item
          switch-toggle-side        
          expand-separator          
          label="Operatiuni Acces"
          v-if="(user.is_admin=='y' || user.auth_for_operatiuniacces=='y') && ['APP_DEV', 'APP_HR', 'APP_MAGIC'].includes(APP_NAME)"
        >
          <q-item v-if="user.is_admin=='y' || user.auth_for_operatiuniacces=='y'" clickable v-ripple @click="goToView('Monitorizare')">
            <q-item-section avatar>
              <q-icon color="primary" name="door_sliding" />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{['APP_MAGIC'].includes(APP_NAME) ? 'Condica' : 'Monitorizare' }}</q-item-label>
            </q-item-section>
          </q-item>             
                    
          <q-item v-if="user.is_admin=='y' || user.auth_for_operatiuniacces=='y'" clickable v-ripple @click="goToView('AlocariEntitatiAcces')">
            <q-item-section avatar>
              <q-icon color="primary" name="assignment_ind" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Alocari acces</q-item-label>
            </q-item-section>
          </q-item>                    
        </q-expansion-item>

        <q-expansion-item
            switch-toggle-side
            expand-separator
            label="Organizatie"
            v-if="(user.is_admin=='y' )"
        >
          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('HRMSPositions')">
            <q-item-section avatar>
              <q-icon color="primary" name="toc" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Posturi</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('HRMSPositions')">
            <q-item-section avatar>
              <q-icon color="primary" name="manage_accounts" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Angajari</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y' " clickable v-ripple @click="goToView('HRMSPersonal')">
            <q-item-section avatar>
              <q-icon color="primary" name="contacts" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Gestiune Personal</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('HRMSOrganigrama')">
            <q-item-section avatar>
              <q-icon color="primary" name="corporate_fare" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Organigrama</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('HRMSPerformancePeople')">
            <q-item-section avatar>
              <q-icon color="primary" name="moving" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Evaluare performante</q-item-label>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-expansion-item
          switch-toggle-side        
          expand-separator          
          label="Roluri"
          v-if="(user.is_admin=='y' || user.auth_for_operatiunievenimente=='y' || user.auth_for_operatiuniclienti=='y')"
        >
          <q-item v-if="user.is_admin=='y' || user.auth_for_operatiuniclienti=='y'" clickable v-ripple @click="goToView('Clienti')">
            <q-item-section avatar>
              <q-icon color="primary" name="contacts" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Clienti</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y' || user.auth_for_operatiunievenimente=='y'" clickable v-ripple @click="goToView('Evenimente')">
            <q-item-section avatar>
              <q-icon color="primary" name="event" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Evenimente</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('CampaniiEmail')">
            <q-item-section avatar>
              <q-icon color="primary" name="campaign" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Campanii</q-item-label>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-expansion-item
          switch-toggle-side        
          expand-separator          
          label="Nomenclatoare"
          v-if="user.is_admin=='y' || user.auth_for_posturi == 'y'"
        >
          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomCateg')">
            <q-item-section avatar>
              <q-icon color="primary" name="insert_drive_file" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Categorii documente</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomTipDoc')">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Tipuri documente</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomRegistre')">
            <q-item-section avatar>
              <q-icon color="primary" name="chrome_reader_mode" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Registre</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y' && ['APP_DEV', 'APP_SOPHORE', 'APP_CV', 'APP_URBANESQ', 'APP_MEAT', 'APP_HR'].includes(APP_NAME)" clickable v-ripple @click="goToView('NomCategActive')">
            <q-item-section avatar>
              <q-icon color="primary" name="insert_drive_file" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Categorii active</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y' && ['APP_DEV', 'APP_SOPHORE', 'APP_CV', 'APP_URBANESQ', 'APP_MEAT', 'APP_HR'].includes(APP_NAME)" clickable v-ripple @click="goToView('NomTipActive')">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Tipuri active</q-item-label>
            </q-item-section>
          </q-item>
          
          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomJobs')">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Functii</q-item-label>
            </q-item-section>
          </q-item>

          <!-- <q-item v-if="(user.is_admin=='y' || user.auth_for_proiect=='y')" clickable v-ripple @click="goToView('Proiecte')">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Proiecte</q-item-label>
            </q-item-section>
          </q-item> -->

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomEntitateAcces')">
            <q-item-section avatar>
              <q-icon color="primary" :name="['APP_MAGIC'].includes(APP_NAME) ? 'class' : 'description'" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Entitati acces</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('Adrese')">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Adrese</q-item-label>
            </q-item-section>
          </q-item>           
          
          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomJudet')">
            <q-item-section avatar>
              <q-icon color="primary" name="map" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Judete</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomLocalitate')">
            <q-item-section avatar>
              <q-icon color="primary" name="map" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Localitati</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="(user.is_admin=='y') && ['APP_DEV', 'APP_URBANESQ'].includes(APP_NAME)" clickable v-ripple @click="goToView('NomUnitatiMasura')">
            <q-item-section avatar>
              <q-icon color="primary" name="straighten" />
            </q-item-section>
            <q-item-section>
              <q-item-label>UM</q-item-label>
            </q-item-section>
          </q-item>

          <!-- <q-item v-if="user.is_admin=='y'" clickable v-ripple>
            <q-item-section avatar>
              <q-icon color="primary" name="price_change" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Valute</q-item-label>
            </q-item-section>
          </q-item> -->

          <q-item v-if="user.is_admin=='y' || user.auth_for_posturi == 'y'" clickable v-ripple @click="goToView('Posturi')">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Posturi</q-item-label>
            </q-item-section>
          </q-item> 
        </q-expansion-item>

        <q-expansion-item
          switch-toggle-side        
          expand-separator          
          label="Locatii arhiva"
          v-if="(user.is_admin=='y' || user.auth_for_locatiiarhiva=='y')"
        >
          <q-item v-if="(user.is_admin=='y' || user.auth_for_locatiiarhiva=='y')" clickable v-ripple @click="goToView('NomCladiriArhiva')">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Cladiri</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="(user.is_admin=='y' || user.auth_for_locatiiarhiva=='y')" clickable v-ripple @click="goToView('NomCamereArhiva')">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Camere</q-item-label>
            </q-item-section>
          </q-item>
    
          <q-item v-if="(user.is_admin=='y' || user.auth_for_locatiiarhiva=='y')" clickable v-ripple @click="goToView('NomDulapuriArhiva')">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Dulapuri</q-item-label>
            </q-item-section>
          </q-item>
    
          <q-item v-if="(user.is_admin=='y' || user.auth_for_locatiiarhiva=='y')" clickable v-ripple @click="goToView('NomRafturiArhiva')">
            <q-item-section avatar>
              <q-icon color="primary" name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Rafturi</q-item-label>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-expansion-item
          switch-toggle-side        
          expand-separator          
          label="Administrare"
          v-if="user.is_admin=='y'"
        >          
          <!-- <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomDepartamente')">
            <q-item-section avatar>
              <q-icon color="primary" name="assignment_ind" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Departamente</q-item-label>
            </q-item-section>
          </q-item> -->

          <q-item v-if="user.is_admin=='y' && ['APP_DEV', 'APP_MEAT', 'APP_MULTICOMPANY'].includes(APP_NAME)" clickable v-ripple @click="goToView('NomCompanies')">
            <q-item-section avatar>
              <q-icon color="primary" name="business" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Companii</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomUsers')">
            <q-item-section avatar>
              <q-icon color="primary" name="people" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Utilizatori</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomFlux')">
            <q-item-section avatar>
              <q-icon color="primary" name="account_tree" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Fluxuri</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomOrgUnit')">
            <q-item-section avatar>
              <q-icon color="primary" name="assignment_ind" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Org Unit</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y' && ['APP_DEV', 'APP_URBANESQ'].includes(APP_NAME)" clickable v-ripple @click="goToView('SetariCompanie')">
            <q-item-section avatar>
              <q-icon color="primary" name="room_preferences" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Setări companie</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('ParamsTypeFiles')">
            <q-item-section avatar>
              <q-icon color="primary" name="attach_file" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Parametri atasamente</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('LogAdmin')">
            <q-item-section avatar>
              <q-icon color="primary" name="android" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Log</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="['APP_MAGIC'].includes(APP_NAME)" clickable v-ripple @click="aboutPopupOpen = true">
            <q-item-section avatar>
              <q-icon color="primary" name="info" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Info</q-item-label>
            </q-item-section>
          </q-item>
        </q-expansion-item>
      </q-list>
    </div>

    <q-dialog v-model="aboutPopupOpen" persistent
      transition-show="slide-down"
      transition-hide="slide-up">
      <q-card style="min-width: 60vw;max-width: 90vw;min-height: 30vw;max-height: 30vw;">
        <q-card-section>
          <div class="q-pa-md">
            <div class="row">
              <p style="margin-top:0pt; margin-bottom:0pt; line-height:115%; font-size:10pt;">LICENȚA DE UTILIZARE PLATFORMA ODOCS</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-bottom:0pt; line-height:115%; font-size:10pt;">&nbsp;</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-bottom:0pt; line-height:115%; font-size:10pt;">Cod Licență:</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-bottom:0pt; line-height:115%; font-size:10pt;">&nbsp;</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; font-size:10pt;"><strong>5915648210220231</strong></p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; font-size:10pt;">Denumire: ODOCS - Sistem de management al dezvoltarii aplicatiei Brandteam</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; font-size:10pt;">Issue date: 21022023</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; widows:0; orphans:0; font-size:10pt;">Users: Unlimited</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; widows:0; orphans:0; font-size:10pt;">&nbsp;</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; widows:0; orphans:0; font-size:10pt;"><strong>5915648210220231_29</strong></p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; font-size:10pt;">Denumire: ODOCS - BI</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; font-size:10pt;">Issue date: 13062023</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; widows:0; orphans:0; font-size:10pt;">Users: Unlimited</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; widows:0; orphans:0; font-size:10pt;">&nbsp;</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; widows:0; orphans:0; font-size:10pt;"><strong>5915648210220231_1615</strong><br>Denumire: ODOCS - Pachet Office</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; font-size:10pt;">Issue date: 13072023</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; widows:0; orphans:0; font-size:10pt;">Users: 10</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-bottom:0pt; line-height:115%; widows:0; orphans:0; font-size:10pt;">&nbsp;</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-bottom:0pt; line-height:115%; widows:0; orphans:0; font-size:10pt;">Versiune platforma Odocs: V{{ APP_INFORMATION.version }}</p>
            </div>
            <div class="absolute-bottom-right q-pa-sm">
              <q-btn
                label="Inchide"
                dense flat 
                icon="close" 
                v-close-popup
                size="sm"
                color="primary"
              />
            </div>
          </div> 
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script lang="ts" src="./MenuApp.ts" />

<style scoped />
