import {defineComponent} from 'vue';
import Nomenclatoare from '@/store/Nomenclatoare';
import {getModule} from 'vuex-module-decorators';
import INomTipDoc from '@/types/INomTipDoc';
import INomCateg from '@/types/INomCateg';
import {ServiceDocument} from '@/modules/ServiceDocument';
import User from '@/store/User';

export default defineComponent({
  name: 'ChangeTipDocument',

  props: {
	  appid: {
      type: Number,
      required: true,
      default: 0,
      readonly: true
    },
    prop_id_registru: {
      type: Number,
      required: true,
      default: 0,
      readonly: true
    },
    prop_id_categ: {
      type: Number,
      required: true,
      default: 0,
      readonly: true
    },
    prop_id_tip_doc: {
      type: Number,
      required: true,
      default: 0,
      readonly: true
    },
    fRenuntModificareTipDoc: {
      type: Function,
      default: undefined
    },
    fModificareTipDoc: { //(id_categ:number, id_tip_doc:number|null)=>void
      type: Function,
      default: undefined
    }
  },

  data() {
    return {
      id_categ: 0 as number,
      id_tip_doc: 0 as number | null,
      nomenclatoareStore: getModule(Nomenclatoare),
      userStore: getModule(User),
      nomTipDoc: [] as INomTipDoc[]
    }
  },

  methods: {
    modificaTipDocument(){
      const vueInst = this;
      if(vueInst.id_categ && vueInst.id_tip_doc) {
        ServiceDocument.modificaTipDocument(vueInst.appid, vueInst.id_categ, vueInst.id_tip_doc).then(presponse=>{
          // console.log('modificaTipDocument presponse=%o',presponse)
          if(presponse.status=='success') {
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });            
            vueInst.fModificareTipDoc?.(vueInst.id_categ, vueInst.id_tip_doc);
            //vueInst.fRenuntModificareTipDoc?.();
          }
        });
      }
    },

    renuntModificareTipDoc(){
      const vueInst = this;
      vueInst.fRenuntModificareTipDoc?.();
    },

    onValueChangeTipCategorie() {
      const vueInst = this;

      vueInst.id_tip_doc = null;

      vueInst.nomTipDoc = vueInst.nomenclatoareStore.NomTipDoc.filter((ptipdoc)=>{
        return ((ptipdoc.id_categ == vueInst.id_categ && vueInst.id_categ != null) || (vueInst.id_categ == null)) && ptipdoc.activ == 'y' && 
          (
            (vueInst.userStore.User.auth_toview_confidential == 'n' && ptipdoc.is_confidential == 'n') || 
            (vueInst.userStore.User.auth_toview_confidential == 'y') ||
            (vueInst.userStore.User.auth_toview_confidential_general == 'y')
          );
      });
    },

    filterTipDocument(value: string, update: Function) {
      const vueInst = this;
      
      if ((value === '' || value == null) && (vueInst.id_categ == null)) {
        update(() => {
          vueInst.nomTipDoc = vueInst.nomenclatoareStore.NomTipDoc.filter((ptipdoc)=>{
            return ptipdoc.activ == 'y' && 
              (
                (vueInst.userStore.User.auth_toview_confidential == 'n' && ptipdoc.is_confidential == 'n') || 
                (vueInst.userStore.User.auth_toview_confidential == 'y') ||
                (vueInst.userStore.User.auth_toview_confidential_general == 'y')
              );
          });
        });
      } if ((value === '' || value == null) && (vueInst.id_categ != null)) {
        update(() => {
          vueInst.nomTipDoc = vueInst.nomenclatoareStore.NomTipDoc.filter((ptipdoc)=>{
            return ptipdoc.id_categ == vueInst.id_categ && ptipdoc.activ == 'y' && 
              (
                (vueInst.userStore.User.auth_toview_confidential == 'n' && ptipdoc.is_confidential == 'n') || 
                (vueInst.userStore.User.auth_toview_confidential == 'y') ||
                (vueInst.userStore.User.auth_toview_confidential_general == 'y')
              );
          });
        });
      } else {
        update(() => {
          const valueLowerCase = value.toLowerCase();
          //this.localitati = this.localitatiNoFiltered.filter(f => f.denumire.toLowerCase().startsWith(valueLowerCase));
          if (vueInst.id_categ != null) {
            vueInst.nomTipDoc = vueInst.nomenclatoareStore.NomTipDoc.filter((ptipdoc)=>{
              return ptipdoc.id_categ == vueInst.id_categ && ptipdoc.activ == 'y' && 
                (
                  (vueInst.userStore.User.auth_toview_confidential == 'n' && ptipdoc.is_confidential == 'n') || 
                  (vueInst.userStore.User.auth_toview_confidential == 'y') ||
                  (vueInst.userStore.User.auth_toview_confidential_general == 'y')
                );
            }).filter(f => f.den_lang_1.toLowerCase().startsWith(valueLowerCase));
          } else {
            vueInst.nomTipDoc = vueInst.nomenclatoareStore.NomTipDoc.filter((ptipdoc)=>{
              return ptipdoc.activ == 'y' && 
                (
                  (vueInst.userStore.User.auth_toview_confidential == 'n' && ptipdoc.is_confidential == 'n') || 
                  (vueInst.userStore.User.auth_toview_confidential == 'y') ||
                  (vueInst.userStore.User.auth_toview_confidential_general == 'y')
                );
            }).filter(f => f.den_lang_1.toLowerCase().startsWith(valueLowerCase));
          }
        }); 
      }
    },

    onValueChangeTipDocument(idTipDocument: number) {
      const vueInst = this;
      
      if (idTipDocument != null) {
        vueInst.id_categ = vueInst.nomenclatoareStore.NomTipDoc.find(f => f.appid == idTipDocument)?.id_categ as number;
      }
    },
  },

  computed: {
    optionsCategorii(): INomCateg[] {
      const categoriiActive=this.nomenclatoareStore.NomCategorii.filter(pcategorie=>{
        return pcategorie.activ=='y'
      })
      return categoriiActive;
    },

    optionsTipDoc(): INomTipDoc[] {
      const onlyActiveTipDoc=this.nomenclatoareStore.NomTipDoc.filter(
        pTipDoc=>{
          return pTipDoc.activ=='y';
        }
      );
      return onlyActiveTipDoc;
    },

    filteredOptionTipDoc(): INomTipDoc[] {
      return this.nomTipDoc;
    }    
  },

  mounted() {
    this.id_categ = this.prop_id_categ;
    this.onValueChangeTipCategorie();
    this.id_tip_doc = this.prop_id_tip_doc;
  }
});
