import {defineComponent} from 'vue';
import Nomenclatoare from '@/store/Nomenclatoare';
import {getModule} from 'vuex-module-decorators';
import IPersoana from '@/types/IPersoana';
import {getRowNumberTable} from '@/modules/Utils';

export default defineComponent({
  name: 'ComboUser',

  props: {    
    selectHandler: {
      type: Function,
      default: () => {}
    },
    title: {
      type: String,
      required: true,
      default: 'Org Unit',
      readonly: true
    }
  },

  data() {
    return {
      loadingData: false,
      filterNomUsers: '',
      nomenclatoareStore: getModule(Nomenclatoare),
      myPagination: {
        rowsPerPage: getRowNumberTable()
      },
      visibleColumns: ['nume_prenume', 'organization_unit_descriere'],
      columns: [
        { name: 'nume_prenume', label: 'Nume prenume', field: 'nume_prenume', align: 'left', sortable: true },
        // { name: 'lastName', label: 'Nume', field: 'lastName', align: 'left', sortable: true },
        // { name: 'firstName', label: 'Prenume', field: 'firstName', align: 'left', sortable: true},
        { name: 'organization_unit_descriere', label: 'Org Unit', field: 'organization_unit_descriere', align: 'left', sortable: true },
        // { name: 'den_org', label: 'loc de munca', field: 'den_org', align: 'left', sortable: true },
      ],      
      allPersoane: [] as IPersoana[],
      celeInactive: 'Nu',
      tableDataNomUsers: [] as IPersoana[] 
    }
  },

  methods: {    
    changeStatus() {
      const vueInst = this;      
      vueInst.tableDataNomUsers = vueInst.celeInactive !== 'Da' ? vueInst.allPersoane.filter(persoana => persoana.is_activ == 'y') : vueInst.allPersoane;
    }
  },

  mounted() {
    const vueInst = this;
    vueInst.loadingData = true;    
    vueInst.allPersoane = vueInst.nomenclatoareStore.NomPersoaneUtilizatoare;
    vueInst.tableDataNomUsers = vueInst.celeInactive !== 'Da' ? vueInst.allPersoane.filter(persoana => persoana.is_activ == 'y') : vueInst.allPersoane;
    vueInst.loadingData = false;
  },
});
