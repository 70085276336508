import {defineComponent, defineAsyncComponent, inject, ref} from 'vue';
import {getModule} from 'vuex-module-decorators';
import IConcediu from '@/types/IConcediu';
import {ServiceTimesRequests} from '@/modules/ServiceTimesRequests';
import {CONFIG_ENV, APP_NAME} from '@/config';
import {FileUploadService} from "v-file-upload";
import Nomenclatoare from '@/store/Nomenclatoare';
import User from '@/store/User';
import IUser from '@/types/IUser';
import {humanReadableBytes} from '@/modules/Utils';
import moment from 'moment';
import LoadingComponent from '@/components/LoadingComponent.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';
import {dateToStringDDMonYYYY} from '@/modules/Utils';
import IActionOnStepTask from '@/types/IActionOnStepTask';
import Notify from 'quasar/src/plugins/Notify.js';;
import IStatusLabel from '@/types/IStatusLabel';
import IScanTimeRequest from '@/types/IScanTimeRequest';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type IConcediuNull = OrNull<IConcediu>;

interface IConcediuExtended extends IConcediuNull {
  denumire_stare: string
}

const DialogOpenScan = defineAsyncComponent({
  loader: () => import('@/components/DialogOpenScan/DialogOpenScan.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

const TaskList = defineAsyncComponent({
  loader: () => import('@/components/Tasks/TaskList.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

const myForm = ref({} as any);
const myForm1 = ref({} as any);
const qDateProxy1 = ref(null);
const qDateProxy2 = ref(null);

export default defineComponent({
  name: 'EditTimeRequestDetail',

  components: {
    DialogOpenScan, TaskList
  },

  props: {    
	  appid: {
      type: Number,
      required: true,
      default: 0,
      readonly: true
    },
    closeWindow: {
      type: Function,
      default: undefined
    },
    task: {
      type: Object,
      task_id: 0,
      task_type: '',
      action_on_step: {} as IActionOnStepTask
    }
  },

  setup() { 
    const emitter = inject("emitter") as any;     
    return {
      emitter,
      myForm,
      myForm1,
      qDateProxy1,
      qDateProxy2
    }  
  },

  data() {    
    return {
      timeRequest: {} as IConcediuExtended,
      myLocale: CONFIG_ENV.myLocale,      
      nomenclatoareStore: getModule(Nomenclatoare),
      loadingData: false,
      dialogUploadFile: false,
      filesSelectedForUpload: [] as any,
      uploadScanTitle: '',
      urlForUpload: CONFIG_ENV.URL_TIME_REQUESTS_SCAN.uploadScan,
      headers: {},
      uploadingFileToServer: false,      
      userStore: getModule(User),            
      loadingPdfForDownload: false,
      isSuccess: false,
      showPreviewFile: false,
      titleForPreviewForm: '',
      downloadingFile: false,
      urlBlobForPreviewFile: '',
      isPDFFileType: false
    }
  },

  methods: {        
    displayData(pdata:string) :string{
      if(pdata){
        const day = moment(pdata.substring(0,10), 'YYYY-MM-DD');
        return dateToStringDDMonYYYY(day);
      }else{ 
        return '';
      }
    },

    getTimeRequest() {
      const vueInst=this;      
      ServiceTimesRequests.getTimeRequest(Number(vueInst.timeRequest.appid)).then((presponse)=>{
        vueInst.loadingData = false;
        if(presponse.status==='success'){
          vueInst.timeRequest = presponse.timeRequest as IConcediuExtended;

          vueInst.timeRequest.start_date = vueInst.timeRequest.start_date == null ? null : vueInst.displayData(vueInst.timeRequest.start_date);
          vueInst.timeRequest.end_date = vueInst.timeRequest.end_date == null ? null : vueInst.displayData(vueInst.timeRequest.end_date);
          vueInst.timeRequest.denumire_stare = vueInst.getDenStatus(vueInst.timeRequest.stare as string, vueInst.timeRequest.flag as number);
          // console.log('A', vueInst.timeRequest.scansTimeRequests);
          vueInst.getScanDocuments();
          vueInst.isSuccess = true;
        }
      })
    },

    getScanDocuments(){
      const vueInst = this;
      vueInst.uploadingFileToServer = true;
      ServiceTimesRequests.getScans(Number(vueInst.timeRequest.appid)).then((presponse)=>{
        vueInst.loadingData = false;
        vueInst.uploadingFileToServer = false;
        if(presponse.status==='success'){
          vueInst.timeRequest.scansTimeRequests = JSON.parse(JSON.stringify(presponse.rows));          
        }
      });
    },

    prepareForUpload(event:any){
      this.filesSelectedForUpload = event.target.files[0];
    },

    humanReadableBytes(nrPfBytes:number): string{
      return humanReadableBytes(nrPfBytes);
    },

    onState(operator: string){
      switch(operator) {
        case 'Refresh':
          this.getTimeRequest();
          break;
        case 'Close':          
          this.closeWindow?.();
          break;
      }
    },

    onPreviewCerereCO() {
      const vueInst = this;         
      //if(vueInst.timeRequest.stare !== 'initiat'){
      vueInst.titleForPreviewForm = 'Vizualizare';
      vueInst.downloadingFile = true;
      vueInst.showPreviewFile = true;
      
      let urlToGetFile = `${CONFIG_ENV.URL_TIME_REQUESTS.downloadScanForPreviewFromAppCO}/${vueInst.timeRequest.appid}`;

      fetch(urlToGetFile, { 
        headers: 
          (['APP_DEV', 'APP_URBANESQ'].includes(APP_NAME)) ? 
            {
              odocsuserid: vueInst.userStore.User.userid.toString(),
              odocsusertoken: vueInst.userStore.User.csrf_token
            } : 
            {
              Odocsuserid: vueInst.userStore.User.userid.toString(),
              Odocsusertoken: vueInst.userStore.User.csrf_token
            }
       })
      .then(resp => resp.blob())
      .then(blob => {
        vueInst.urlBlobForPreviewFile = window.URL.createObjectURL(blob);
        vueInst.isPDFFileType = blob.type == 'application/pdf';

        if (vueInst.isPDFFileType) {
          if (this.$q.platform.is.mobile) {
            setTimeout(function () {
              vueInst.downloadingFile = false;
              const divPreview = window.document.getElementById('iframe_preview_scan') as HTMLIFrameElement
              if(divPreview){
                divPreview.src = '/' + 'pdf_viewer/web/viewer.html?file=' + vueInst.urlBlobForPreviewFile; 
              }
            }, 500);
          } else {// is desktop
            setTimeout(function () {
              vueInst.downloadingFile = false;
              const divPreview = window.document.getElementById('iframe_preview_scan') as HTMLIFrameElement;
              if(divPreview){
                divPreview.src = vueInst.urlBlobForPreviewFile; 
              }
            }, 500);
          }
        } else {
          blob.text().then(text => {
            Notify.create({
              color: 'red',
              textColor: 'white',
              type: 'negative',
              message: text,
              position: 'top',
              timeout: 3500,
              html: true
            });

            setTimeout(() => {
              vueInst.showPreviewFile = false;
            }, 1000);
          });
        }
      })
      .catch((err) => {
          Notify.create({
            color: 'red',
            textColor: 'white',
            type: 'negative',
            message: err.message ? 'Eroare! Fisierul nu poate fi descarcat! ' + err.message : 'Eroare! Fisierul nu poate fi descarcat!',
            position: 'top',
            timeout: 3500,
            html: true
          })
      });
    },

    onPreviewCerereRechemare() {
      const vueInst = this;         
      if(/*vueInst.timeRequest.stare !== 'initiat' &&*/ vueInst.timeRequest.nr_recall_days != 0 && vueInst.timeRequest.nr_recall_days != null){
        vueInst.titleForPreviewForm = 'Vizualizare';
        vueInst.downloadingFile = true;
        vueInst.showPreviewFile = true;
       
        let urlToGetFile = `${CONFIG_ENV.URL_TIME_REQUESTS.downloadScanForPreviewFromAppRechemare}/${vueInst.timeRequest.appid}`;        
        
        fetch(urlToGetFile, { 
          headers: 
            (['APP_DEV', 'APP_URBANESQ'].includes(APP_NAME)) ? 
              {
                odocsuserid: vueInst.userStore.User.userid.toString(),
                odocsusertoken: vueInst.userStore.User.csrf_token
              } : 
              {
                Odocsuserid: vueInst.userStore.User.userid.toString(),
                Odocsusertoken: vueInst.userStore.User.csrf_token
              }
         })
        .then(resp => resp.blob())
        .then(blob => {
          vueInst.urlBlobForPreviewFile = window.URL.createObjectURL(blob);
          vueInst.isPDFFileType = blob.type == 'application/pdf';

          if (vueInst.isPDFFileType) {
            if (this.$q.platform.is.mobile) {
              setTimeout(function () {
                vueInst.downloadingFile = false;
                const divPreview = window.document.getElementById('iframe_preview_scan') as HTMLIFrameElement
                if(divPreview){
                  divPreview.src = '/' + 'pdf_viewer/web/viewer.html?file=' + vueInst.urlBlobForPreviewFile; 
                }
              }, 500);
            } else {// is desktop
              setTimeout(function () {
                vueInst.downloadingFile = false;
                const divPreview = window.document.getElementById('iframe_preview_scan') as HTMLIFrameElement;
                if(divPreview){
                  divPreview.src = vueInst.urlBlobForPreviewFile; 
                }
              }, 500);
            }
          } else {
            blob.text().then(text => {
              Notify.create({
                color: 'red',
                textColor: 'white',
                type: 'negative',
                message: text,
                position: 'top',
                timeout: 3500,
                html: true
              });
  
              setTimeout(() => {
                vueInst.showPreviewFile = false;
              }, 1000);
            });
          }
        })
        .catch((err) => {
            Notify.create({
              color: 'red',
              textColor: 'white',
              type: 'negative',
              message: err.message ? 'Eroare! Fisierul nu poate fi descarcat! ' + err.message : 'Eroare! Fisierul nu poate fi descarcat!',
              position: 'top',
              timeout: 3500,
              html: true
            })
        });
      }
    },

    getDenStatus(pStatus: string, pFlag: number): string{
      const vueInst=this;
      let result = '' as string;
      const Status = vueInst.optionsStare.find( stare =>{
        return stare.status == pStatus;
      });
      if(Status){
        if ((pFlag & (1|2)) != 0) {
          result=Status.label + ' Anual';
        } else if ((pFlag & (4)) != 0) {
          result=Status.label + ' Reprogramat';
        } else {
          result=Status.label;
        }
      }
      return result;
    },

    checkIfFileIsSelected():boolean{
      return this.filesSelectedForUpload != undefined && this.filesSelectedForUpload.length != 0;
    },

    onProgress(event:any) {
      // Handdle the progress
    },

    uploadFile() {
      const vueInst = this;        
      
      if (vueInst.uploadScanTitle.length == 0) {
        Notify.create({
          color: 'orange',
          textColor: 'white',
          type: 'warning',
          message: 'Trebuie sa completati titlu documentului!',
          position: 'top',
          timeout: 3500,
          html: true
        });
      } else if (vueInst.checkIfFileIsSelected()) {
        vueInst.uploadingFileToServer = true;
      
        const fileUpload = new FileUploadService(
          this.urlForUpload,
          this.headers,
          this.onProgress
        );
        
        fileUpload.upload(vueInst.filesSelectedForUpload as File, { appidTimeRequest: vueInst.timeRequest.appid, description: vueInst.uploadScanTitle, userid: vueInst.user.userid, token: vueInst.user.csrf_token})
          .then((e: any) => {
            vueInst.dialogUploadFile = false;          
            vueInst.getScanDocuments();
            if(e.target && e.target.response.status && e.target.response.status=='error'){
              Notify.create({
                color: 'red',
                textColor: 'white',
                type: 'negative',
                message: e.target.response.message,
                position: 'top',
                timeout: 3500,
                html: true
              })
            }else{
              vueInst.$q.notify({
                color: 'teal',
                textColor: 'white',
                type: 'positive',
                message: e.target.response.message,
                position: 'top',
                timeout: 1000,
                html: true
              });
              
              ServiceTimesRequests.getScans(Number(vueInst.timeRequest.appid)).then(                
                presponse=>{
                  vueInst.timeRequest.scansTimeRequests = JSON.parse(JSON.stringify(presponse.rows))                
                }
              )
            }
          })
          .catch((e: any) => {
          })
      } else {
        Notify.create({
          color: 'orange',
          textColor: 'white',
          type: 'warning',
          message: 'Trebuie sa selectati fisierul!',
          position: 'top',
          timeout: 3500,
          html: true
        });
      }
    },

    askIfRemoveScan(pscan:IScanTimeRequest){
      const vueInst = this;
      vueInst.$q.dialog({
        title: 'Confirm',
        message: `Stergeti fisierul reprezentand ${pscan.description}?`,
        cancel: true,
        persistent: true,
        html: true
      }).onOk(() => {
        ServiceTimesRequests.deleteScan(pscan.appid, { userid: vueInst.user.userid, token: vueInst.user.csrf_token }).then(presponse=>{
          if(presponse.status==='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
  
            this.getScanDocuments();
          }
        });
      })
    },

    openFormUploadFile() {
      this.filesSelectedForUpload = [];
      this.uploadScanTitle = '';
      this.dialogUploadFile = true;
    },

    displayDataRO(pdata:string) :string{
      if(pdata){
        const day = moment(pdata.substring(0,10), 'DD.MM.YYYY');
        return dateToStringDDMonYYYY(day);
      }else{ 
        return '';
      }
    },
  },

  computed: {
    APP_NAME(): string{
      return APP_NAME;
    },
    
    user(): IUser {
      return this.userStore.User;
    },

    optionsStare() {      
      const stareArray = this.nomenclatoareStore.StareGeneral as IStatusLabel[];
      return stareArray;
    }
  },

  mounted() {
    const vueInst = this;
    vueInst.nomenclatoareStore.set_currentpagetitle('Cererile de concediu');
  },

  watch: {
    appid: {
      immediate: true,
      handler (newVal: number, oldVal: number) {        
        const vueInst = this;        
        // vueInst.scan_documents = [];
        vueInst.loadingData = true;
        if(newVal != 0 && vueInst.appid != 0) {                    
          vueInst.timeRequest.appid = vueInst.appid;            
          vueInst.getTimeRequest();    
        }
      }
    }
  }
});