import {defineComponent, defineAsyncComponent, inject, ref} from 'vue';
import IDocument from '@/types/IDocument';
import LoadingComponent from '@/components/LoadingComponent.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';
import Nomenclatoare from '@/store/Nomenclatoare';
import {ServiceDocumenteCetateni} from '@/modules/ServiceDocumenteCetateni';
import {ServiceRepartizari} from '@/modules/ServiceRepartizari';
import IRepartizare from '@/types/IRepartizare';
import {APP_NAME, CONFIG_ENV} from '@/config';
import {getModule} from 'vuex-module-decorators';
import moment from 'moment';
import {dateToStringDDMonYYYY} from '@/modules/Utils';
import User from '@/store/User';
import IUser from '@/types/IUser';
import Notify from 'quasar/src/plugins/Notify.js';;
import {FileUploadService} from 'v-file-upload';
import {humanReadableBytes} from '@/modules/Utils';
import IScan from '@/types/IScan';
import INomCateg from '@/types/INomCateg';
import INomTipDoc from '@/types/INomTipDoc';
import { ServiceDocument } from '@/modules/ServiceDocument';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type IDocumentNull = OrNull<IDocument>;

interface IDocumentExtended extends IDocumentNull{   
  adresa: string
}

const myForm = ref({} as any);

const DialogOpenScan = defineAsyncComponent({
  loader: () => import('@/components/DialogOpenScan/DialogOpenScan.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

export default defineComponent({
  name: 'EditDocumentCetateniDetail',

  components: {
    DialogOpenScan
  },

  props: {
    appid: {
      type: Number,
      required: true,
      default: 0,
      readonly: true
    },
    withFinalizeButton: {
      type: String,
      required: true,
      default: 'no',
      readonly: true
    },
    closeWindow: {
      type: Function,
      default: undefined
    },
  },

  setup() {  
    const emitter = inject("emitter") as any;
    return {
      emitter,
      myForm
    };
  },

  data() {
    return {
      documentcetateni: {
        appid: 0,
        id_registru: 0,
        id_categ: 0,
        id_tip_document: 0,
        id_doc: 0,
        tip_request: '',
        data_doc: '',
        tip_solicitant: '',
        nume_denumire: '',
        cui: '',
        titlu: '',
        descriere: '',
        nr_inreg: '',
        data_inreg: '',
        nr_file: 0,
        este_confidential: false,
        ref_nr_doc: '',
        ref_data_doc: '',
        ref_id_registru: null,
        track_creation_date: '',
        id_org_destinatar_init: 0,
        id_org_destinatar_curent: 0,
        sursa: '',
        status: '',
        termen_date: '',
        termen: '',
        termen_zi: 0,
        flux: '',
        email_solicitant: '',
        scans: [],
        repartizari: [],
        adresa: '',
        repartizat: '',
        id_tara: null,
        id_judet: null, 
        id_localitate: null, 
        strada: null,
        numar: null,
        cod_postal: null,
        este_repartizat: null,
        finalizat_de_inregistrat: null,
        track_creation_user: null,
        nume_prenume_reprezentant: null,
        nr_telefon_reprezentant: null,
        appid_referinta: null,
        id_org_expeditor: null,
        text_for_search: null,
        nu_se_repartizeaza: null,
        data_repartizare: null,
        marca_user_deponent: null,
        flag: 0,
        description_cancellation: null,
        userid_cancellation: null,
        data_cancellation: null,
        tip_doc_flag: null,
        appid_document_extended: null
      } as IDocumentExtended,      
      myLocale: CONFIG_ENV.myLocale,
      nomenclatoareStore: getModule(Nomenclatoare),      
      loadingDocumentCetateniData: false,
      uploadScanTitle: '',
      urlForUpload: CONFIG_ENV.URL_DOCUMENT_SCAN.uploadScan,
      uploadingFileToServer: false,
      headers: {} as any,
      userStore: getModule(User),
      filesSelectedForUpload: [] as any,
      dialogUploadFile: false,   
      loadingPdfForDownload: false,
      loadingRepartizari: false,
      repartizari: [] as IRepartizare[],
    }
  },

  methods: {
    getDocument() {
      const vueInst=this;
      ServiceDocumenteCetateni.getDocumentCetateniDetail(vueInst.appid).then((presponse)=>{
        vueInst.loadingDocumentCetateniData = false;
        if(presponse.status==='success'){     
          vueInst.documentcetateni = JSON.parse(JSON.stringify(presponse.document))[0];          
          vueInst.documentcetateni.data_doc = vueInst.documentcetateni.data_doc == null ? null : vueInst.displayData(vueInst.documentcetateni.data_doc);          

          vueInst.getScanDocuments();   
          vueInst.getRepartizari();       
        }
      })
    },

    displayData(pdata:string) :string{
      if(pdata){        
        const day = moment(pdata.substring(0,10), 'YYYY-MM-DD');
        return dateToStringDDMonYYYY(day);
      }else{ 
        return '';
      }
    },

    openFormUploadFile() {
      this.filesSelectedForUpload = [];
      this.uploadScanTitle = '';
      this.dialogUploadFile = true;      
    },

    getScanDocuments(){
      const vueInst = this;
      vueInst.uploadingFileToServer = true;
      ServiceDocument.getScans(vueInst.appid).then((presponse)=>{
        vueInst.loadingDocumentCetateniData = false;
        vueInst.uploadingFileToServer = false;
        if(presponse.status==='success'){          
          vueInst.documentcetateni.scans = JSON.parse(JSON.stringify(presponse.rows)) as IScan[];          
        }
      });
    },

    getRepartizari(){
      const vueInst=this;
      vueInst.uploadingFileToServer=true;      
      ServiceRepartizari.getRepartizari(vueInst.appid, vueInst.documentcetateni.id_registru as number).then((presponse)=>{        
        vueInst.loadingRepartizari=false;
        if(presponse.status==='success'){
          vueInst.repartizari=JSON.parse(JSON.stringify(presponse.rows));          
        }
      });
    },

    askIfRemoveScan(pscan:IScan){
      const vueInst = this;
      vueInst.$q.dialog({
        title: 'Confirm',
        message: `Stergeti fisierul reprezentand ${pscan.description}?`,
        cancel: true,
        persistent: true,
        html: true
      }).onOk(() => {
        ServiceDocument.deleteScan(pscan.appid, { userid: vueInst.user.userid, token: vueInst.user.csrf_token }).then(presponse=>{
          if(presponse.status==='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
  
            this.getScanDocuments();
          }
        });
      })
    },

    prepareForUpload(event:any){
      this.filesSelectedForUpload = event.target.files[0];
    },

    uploadFile() {
      const vueInst = this;      
        
      if (vueInst.uploadScanTitle.length == 0) {
        Notify.create({
          color: 'orange',
          textColor: 'white',
          type: 'warning',
          message: 'Trebuie sa completati titlu documentului!',
          position: 'top',
          timeout: 3500,
          html: true
        });
      } else if (vueInst.checkIfFileIsSelected()) {
        vueInst.uploadingFileToServer = true;
      
        const fileUpload = new FileUploadService(
          this.urlForUpload,
          this.headers,
          this.onProgress
        );        
        
        fileUpload.upload(vueInst.filesSelectedForUpload as File, { appidDoc: vueInst.appid, description: vueInst.uploadScanTitle, userid: vueInst.user.userid, token: vueInst.user.csrf_token})
          .then((e: any) => {
            vueInst.dialogUploadFile = false;          
            vueInst.getScanDocuments();
            if(e.target && e.target.response.status && e.target.response.status=='error'){
              Notify.create({
                color: 'red',
                textColor: 'white',
                type: 'negative',
                message: e.target.response.message,
                position: 'top',
                timeout: 3500,
                html: true
              })
            }else{
              vueInst.$q.notify({
                color: 'teal',
                textColor: 'white',
                type: 'positive',
                message: e.target.response.message,
                position: 'top',
                timeout: 1000,
                html: true
              });
            }
          })
          .catch((e: any) => {
          })
      } else {
        Notify.create({
          color: 'orange',
          textColor: 'white',
          type: 'warning',
          message: 'Trebuie sa selectati fisierul!',
          position: 'top',
          timeout: 3500,
          html: true
        });
      }
    },

    prepareCoverPdfForDownload(){      
      const vueInst = this;
      if (!['APP_HR'].includes(APP_NAME)) {
        vueInst.emitter.emit('eventToPreviewDocumentPDF', { action: 'show_preview_file', appid: vueInst.documentcetateni.appid });
      } else {
        vueInst.emitter.emit('eventToPreview', { action: 'show_preview_file', title: 'Coperta', appid: vueInst.documentcetateni.appid, url_to_get_file: `${CONFIG_ENV.URL_DOCUMENT.pdf_cover}` });
      }
    },

    resetDocumentCetateni() {
      this.documentcetateni = {
        appid: 0,
        id_registru: 0,
        id_categ: 0,
        id_tip_document: 0,
        id_doc: 0,
        tip_request: '',
        data_doc: '',
        tip_solicitant: '',
        nume_denumire: '',
        cui: '',
        titlu: '',
        descriere: '',
        nr_inreg: '',
        data_inreg: '',
        nr_file: 0,
        este_confidential: false,
        ref_nr_doc: '',
        ref_data_doc: '',
        ref_id_registru: null,
        track_creation_date: '',
        id_org_destinatar_init: 0,
        id_org_destinatar_curent: 0,
        sursa: '',
        status: '',
        termen_date: '',
        termen: '',
        termen_zi: 0,
        email_solicitant: '',
        scans: [],
        repartizari: [],
        adresa: '',        
        id_tara: null,
        id_judet: null, 
        id_localitate: null, 
        strada: null,
        numar: null,
        cod_postal: null,
        este_repartizat: null,
        finalizat_de_inregistrat: null,
        track_creation_user: null,
        nume_prenume_reprezentant: null,
        nr_telefon_reprezentant: null,
        appid_referinta: null,
        id_org_expeditor: null,
        text_for_search: null,
        nu_se_repartizeaza: null,
        data_repartizare: null,
        marca_user_deponent: null,
        flag: 0,
        description_cancellation: null,
        userid_cancellation: null,
        data_cancellation: null,
        tip_doc_flag: null,
        appid_document_extended: null
      } as IDocumentExtended;
    },

    humanReadableBytes(nrPfBytes:number): string{
      return humanReadableBytes(nrPfBytes);
    },    

    checkIfFileIsSelected():boolean{
      return this.filesSelectedForUpload != undefined && this.filesSelectedForUpload.length != 0;
    },

    onProgress(event:any) {
      // Handdle the progress
    },

    getDenTipCategorie(pIdCategoria: number): string{
      const vueInst=this;
      let result = '' as string;
      const Categoria = vueInst.nomenclatoareStore.NomCategorii.find( categoria =>{
        return categoria.appid == pIdCategoria;
      });
      if(Categoria){
        result=Categoria.den_lang_1;
      }
      return result;
    },

    getDenTipDocument(pIdTipDoc: number): string{
      const vueInst=this;
      let result = '' as string;
      const TipDoc = vueInst.nomenclatoareStore.NomTipDoc.find( tipdoc =>{
        return tipdoc.id_tip_doc == pIdTipDoc;
      });
      if(TipDoc){
        result=TipDoc.den_lang_1;
      }
      return result;
    },

    getDenRegistru(pIdRegistru: number): string{
      const vueInst=this;
      let result = '' as string;
      const Registru = vueInst.nomenclatoareStore.NomRegistre.find( registru =>{
        return registru.id_registru == pIdRegistru;
      });
      if(Registru){
        result=Registru.den_registru_lang_1;
      }
      return result;
    },
  },

  computed: {  
    APP_NAME(): string{
      return APP_NAME;
    },

    user(): IUser {
      return this.userStore.User;
    },

    getLinkToQrCode(): string {      
      return CONFIG_ENV.URL_DOCUMENT.qr_code + '/' + this.appid;
    },

    optionsCategorii(): INomCateg[] {
      const categoriiActive = this.nomenclatoareStore.NomCategorii.filter(pcategorie=>{
        return pcategorie.activ=='y'
      })
      return categoriiActive;
    },

    optionsTipDoc(): INomTipDoc[] {
      const onlyActiveTipDoc = this.nomenclatoareStore.NomTipDoc.filter(
        pTipDoc=>{
          return pTipDoc.activ=='y' && pTipDoc.id_categ == this.documentcetateni.id_categ;
        }
      );
      return onlyActiveTipDoc;
    },
  },

  watch: {
    appid: {
      immediate: true,
      handler (newVal: number, oldVal: number) {        
        const vueInst = this;                
        vueInst.resetDocumentCetateni();    
        // vueInst.scan_documents = [];
        vueInst.loadingDocumentCetateniData = true;
        if(newVal != 0 && vueInst.appid != 0) {           
          vueInst.getDocument();    
        }
      }
    }
  }
});
