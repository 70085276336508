<template>
  <div class="q-pa-sm">
    <div style="display: flex;align-items: center;">
      <span class="text-h6">{{person.nume_prenume}}</span> 
      <span class="text-subtitle2" style="margin-left:2rem;">{{person.organization_unit_descriere}} / {{person.job_title}}</span>
    </div>
    <HeaderTimeAccountsMarca :marca="marca" :year="year" ref="childHeaderMyTimesRequests" />
    <q-table
      dense
      :rows-per-page-options="[0]"
      :rows="tableDataConcedii"
      :columns="columns"
      :visible-columns="visibleColumns"
      row-key="appid"
      :filter="filterConcedii"
      :loading="loadingData"
      :pagination.sync="myPagination"
    >
      <template v-slot:top>
        <q-space />
        <q-input dense debounce="300" color="primary" v-model="filterConcedii">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="appid" :props="props">
            <span>{{ props.row.appid }}</span>
          </q-td>
          <q-td key="tip_concediu" :props="props" style="white-space:pre-wrap; word-wrap:break-word">
            {{ props.row.tip_concediu }}
          </q-td>
          <q-td key="nr_working_days" :props="props">
            {{ (props.row.nr_days_recalculated != 0 && (props.row.flag & 8) != 0) ? props.row.nr_working_days + " / " + props.row.nr_days_recalculated : props.row.nr_working_days }}
          </q-td>
          <q-td key="start_date" :props="props">
            {{ props.row.start_date }}
          </q-td>
          <q-td key="end_date" :props="props">
            {{ props.row.end_date }}
          </q-td>
          <q-td key="denumire_stare" :props="props"
            v-bind:class="displayStatusColor(props.row.stare)">
            {{ props.row.denumire_stare }}
          </q-td>
          <q-td key="track_date_status" :props="props">
            {{ props.row.track_date_status }}
          </q-td>
          <q-td key="observatie" :props="props" style="white-space:pre-wrap; word-wrap:break-word">
            {{ props.row.observatie }}
          </q-td>
          <q-td key="nr_recall_days" :props="props">
            {{ props.row.nr_recall_days }}
          </q-td>
          <q-td key="recall_date" :props="props">
            <div style="max-width:30vw;" class="container--span__file_scan">
              <div v-if="props.row.recall_date.length > 0" class="cursor-pointer">
                <div v-if="props.row.recall_date.length == 1">
                  <span>{{ displayDataRO(props.row.recall_date[0]) }}</span>
                </div>
                <q-badge color="blue" transparent v-else>
                  {{props.row.recall_date.length}}
                  <q-menu fit
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <div class="q-pa-md" style="min-width: 15vw;">
                      <span v-for="recalldate in props.row.recall_date" v-bind:key="recalldate" class="span__repartizare">
                        <span class="material-icons">share</span>
                        <div class="row" style="display: flex; max-width: 100px;width:100%; margin-left:5px;">
                          <div style="text-align:right;">{{ displayDataRO(recalldate) }}</div>
                        </div>
                      </span>
                    </div>
                  </q-menu>
                </q-badge>
              </div>
              <span v-else></span>
            </div>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script lang="ts" src="./AdminEmployeeTimeRequest.ts" />

<style scoped>
.span_status{
  background-color:#dde0e4;
  padding:0.2rem;
}
.span_data_concediu{
  margin: 0 1rem;
  padding:0.2rem;
}
</style>
