import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import IFoaieParcurs from '@/types/IFoaieParcurs';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type IFoaieParcursNull = OrNull<IFoaieParcurs>;

interface TAjaxResponseFoiParcurs {
  status: string;
  message: string;
  rows: string;
  //rows: iFoaieParcurs[];
}

interface TAjaxResponseEditFoaieParcurs {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetFoaieParcurs {
    status: string;
    message: string;
    foaieParcurs: IFoaieParcursNull;
}

interface TAjaxSimpleResponse {
  status: string;
  message: string;
}

export class ServiceFoiParcurs {

  public static async getFoiParcurs(appid: number): Promise<TAjaxResponseFoiParcurs> {    
    const response = await axios.get(`${CONFIG_ENV.URL_FOI_PARCURS.foiParcurs}/${appid}`);
    return response.data;
  }

  public static async postFoaieParcurs(pFoaieParcurs: IFoaieParcurs): Promise<TAjaxResponseEditFoaieParcurs> {
    const response = await axios.post(`${CONFIG_ENV.URL_FOI_PARCURS.foaieParcurs}`, pFoaieParcurs);
    return response.data;
  }

  public static async getFoaieParcurs(appid: number): Promise<TAjaxResponseGetFoaieParcurs> {
    const response = await axios.get(`${CONFIG_ENV.URL_FOI_PARCURS.foaieParcurs}/${appid}`);
    return response.data;
  }

  public static async deleteFoaieParcurs(appid:number): Promise<TAjaxSimpleResponse> {
    const response = await axios.delete(`${CONFIG_ENV.URL_FOI_PARCURS.foaieParcurs}/${appid}`);
    return response.data;
  }
}