import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import ISetariCompanie from '@/types/ISetariCompanie';

interface TAjaxResponseSimple {
  status: string;
  message: string;
}

interface TAjaxResponseGetSetariCompanie {
  status: string;
  message: string;
  setariCompanie: ISetariCompanie;
}

interface TAjaxResponseGetScans {
  status: string;
  message: string;
  nr_of_records: number,
  rows: ISetariCompanie[];
}

interface TAjaxResponseGetInfoScan {
  status: string;
  message: string;
  scan: ISetariCompanie;
}

interface TAjaxSimpleResponse {
  status: string;
  message: string;
}

export class ServiceSetariCompanie {
//   public static async get(): Promise<TAjaxResponseSetariCompanie> {
//     const response = await axios.get(`${CONFIG_ENV.URL_PARTENERI.parteneri}/parteneri`);
//     return response.data;
//   }

  public static async getSetariCompanie(): Promise<TAjaxResponseGetSetariCompanie> {
    const response = await axios.get(`${CONFIG_ENV.URL_SETARICOMPANIE.setariCompanie}/setari_companie`);
    return response.data;
  }

  public static async postSetariCompanie(setariCompanie: ISetariCompanie): Promise<TAjaxResponseSimple> {
    const response = await axios.post(`${CONFIG_ENV.URL_SETARICOMPANIE.setariCompanie}/setari_companie`, setariCompanie);
    return response.data;
  }

  public static async getScans(isDocument:string): Promise<TAjaxResponseGetScans> {
    const response = await axios.get(`${CONFIG_ENV.URL_SETARICOMPANIE_SCAN.getScans}/${isDocument}`);
    return response.data;
  }

  public static async getInfoScan(appid:number): Promise<TAjaxResponseGetInfoScan> {
    const response = await axios.get(`${CONFIG_ENV.URL_SETARICOMPANIE_SCAN.getInfoScan}/${appid}`);
    return response.data;
  }

  public static async deleteScan(appid:number, headers: any): Promise<TAjaxSimpleResponse> {
    const response = await axios.delete(`${CONFIG_ENV.URL_SETARICOMPANIE_SCAN.getInfoScan}/${appid}`, { headers });
    return response.data;
  }

  public static async deleteLogo(headers: any): Promise<TAjaxSimpleResponse> {
    const response = await axios.delete(`${CONFIG_ENV.URL_SETARICOMPANIE_SCAN.getInfoScanLogo}`, { headers });
    return response.data;
  }
  public static async getLogo(): Promise<any> {
    const response = await axios.get(CONFIG_ENV.URL_SETARICOMPANIE.logo);
    return response;
  }

}
