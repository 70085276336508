<template>
  <div class="q-pa-md">
    <q-spinner-gears
      v-if="loadingData"
      color="primary"
      size="4em"
      class="absolute-center"
    />
    <div v-else>
        <q-form ref="myForm">
            <div class="well well-sm">
                <div class="panel">
                    <div class="q-pa-md">
                        <div style="display: flex; align-items: center; margin-bottom: 15px">
                            <div style="display: flex; flex-direction: column">
                                <div
                                    v-bind:class="{
                                    column: $q.platform.is.mobile,
                                    row: $q.platform.is.desktop,
                                    }"
                                >
                                    <span class="text__title--medium">Editeaza decont</span>
                                    <span class="text__title--medium" style="paddingLeft: 50px"
                                    >Numar&nbsp;{{ decont.id_decont }}&nbsp;/&nbsp;{{
                                        displayData(decont.track_date as string) 
                                    }}</span
                                    >
                                </div>
                            </div>
                        </div>

                        <div style="display: flex;align-items: start;">
                            <div style="width: 65%; display: flex; flex-direction: row;">
                                <div
                                style="display: flex;flex-direction: column;width: 100%;">
                                    <div
                                        v-bind:class="{
                                        column: $q.platform.is.mobile,
                                        row: $q.platform.is.desktop,
                                        }"
                                    >
                                        <span class="col-3 form__label"
                                        >Ordin de deplasare:&nbsp;</span
                                        >
                                        <span
                                        class="col-9 form__label__black"
                                        >{{ decont.denumire_pentru_deplasare }}</span
                                        >
                                    </div>
                                    <div
                                        v-bind:class="{
                                            column: $q.platform.is.mobile,
                                            row: $q.platform.is.desktop,
                                        }">
                                        <span class="col-3 form__label"
                                            >Detalii:&nbsp;</span
                                        >
                                        <span
                                            class="col-9 form__label__black"
                                            >{{
                                            decont.detalii
                                            }}</span
                                        >
                                    </div>
                                    <div
                                        v-bind:class="{
                                            column: $q.platform.is.mobile,
                                            row: $q.platform.is.desktop,
                                        }">
                                        <span class="col-3 form__label"
                                            >Perioada:&nbsp;</span
                                        >
                                        <div class="col-9 form__label__black row" v-if="decont.data_start != null && decont.data_end != null">
                                                <div class="columns">
                                                    <span>
                                                        Data la&nbsp;{{displayData(decont.data_start)}}
                                                    </span>
                                                </div>
                                                <div class="columns">
                                                    <span style="margin-left: 10px;">
                                                        la&nbsp;{{displayData(decont.data_end)}}
                                                    </span>
                                                </div>
                                            </div>
                                    </div>
                                    <div
                                        v-bind:class="{
                                            column: $q.platform.is.mobile,
                                            row: $q.platform.is.desktop,
                                        }">
                                        <span class="col-3 form__label"
                                            >Tip deplasare:&nbsp;</span
                                        >
                                        <span
                                            class="col-9 form__label__black"                    
                                            >{{ decont.tip_deplasare }}</span
                                        >
                                    </div>
                                    <div
                                        v-bind:class="{
                                            column: $q.platform.is.mobile,
                                            row: $q.platform.is.desktop,
                                        }">
                                        <span class="col-3 form__label"
                                            >Locatie:&nbsp;</span
                                        >
                                        <span
                                            class="col-9 form__label__black"                    
                                            >{{ decont.localitate }}</span
                                        >
                                    </div>
                                    <div
                                        v-bind:class="{
                                            column: $q.platform.is.mobile,
                                            row: $q.platform.is.desktop,
                                        }">
                                    <span class="col-3 form__label">Mijloc de transport:&nbsp;</span>
                                    <span
                                        class="col-9 form__label__black"
                                        >{{ getDenMijlocTransport(decont.id_mijl_transport as number) }}</span
                                    >
                                    </div>
                                    <div
                                        v-bind:class="{
                                            column: $q.platform.is.mobile,
                                            row: $q.platform.is.desktop,
                                        }">
                                        <span class="col-3 form__label">Descriere:&nbsp;</span>
                                        <div
                                            class="col-9 form__label__black"
                                        >                            
                                            <q-input   
                                                v-if="decont.stare === 'initiat'"                                 
                                                outlined
                                                style="max-height: 12vh;max-width:80%;"
                                                v-model="decont.descriere"
                                                type="textarea"
                                                ref="refDescriere"                
                                                lazy-rules
                                                counter
                                                maxlength="500"
                                                rows="3"
                                                dense
                                                :input-style="{ fontFamily: 'Nunito Sans',fontWeight: 'bolder', color: 'black' }"
                                                :rules="[ val => val && val.length > 0 || 'Completati descrierea!']"
                                            />                                
                                            <span v-if="decont.stare !== 'initiat'"
                                                class="form__label__black"
                                            >{{ decont.descriere }}</span>
                                        </div>                                 
                                    </div>
                                    <div
                                        v-bind:class="{
                                            column: $q.platform.is.mobile,
                                            row: $q.platform.is.desktop,
                                        }"
                                        style="margin-top: 5px;"
                                        >
                                        <span class="col-3 text__title--medium">Valoare totala decont:&nbsp;</span>
                                        <div class="col-9 text__title--medium row">
                                                <div class="columns">
                                                    <span style="color: #2f6cb7">
                                                        {{decont.valoare}}&nbsp;RON
                                                    </span>
                                                </div>
                                                <div class="columns">
                                                    <span class="text__title--medium" style="margin-left: 80px;">
                                                        Stare decont:&nbsp;
                                                    </span>
                                                    <span style="margin-left: 20px;" v-bind:class="displayStatusColor(decont.stare as string)">
                                                        {{getDenStatus(decont.stare as string)}}
                                                    </span>
                                                </div>
                                            </div>
                                    </div>                
                                </div>
                            </div>
                            <div style="width: 35%; display: flex; flex-direction: row; justify-content: center; align-items:center;">
                                <div style="display: flex; flex-direction: column">
                                    <div 
                                        v-bind:class="{
                                        column: $q.platform.is.mobile,
                                        row: $q.platform.is.desktop,
                                        }"
                                    >
                                        <div style="display: flex; align-items: center; justify-content: center; width: 100%;">
                                        <div>
                                            <span class="text__title--medium">Documente atasate</span>
                                            <div
                                                v-show="
                                                    decont.scan_documents != null &&
                                                    decont.scan_documents.length == 0 &&
                                                    !uploadingFileToServer
                                                "
                                                class="text__label--thin"
                                                style="width: 100%; text-align: center"
                                            >
                                            Nimic atasat
                                            </div>
                                        </div>
                                        <div v-if="user.auth_for_upload_scan == 'y'">
                                            <q-btn 
                                                flat
                                                color="primary"
                                                icon="add_box"
                                                no-caps
                                                :disable="loadingData || !(decont.track_user == user.userid || user.auth_for_operatiunihr == 'y')"
                                                @click="openFormUploadFile"
                                                v-if="decont.stare === 'initiat'"                            
                                            >
                                            <q-tooltip
                                                transition-show="rotate"
                                                transition-hide="rotate"
                                            >
                                                Incarca document nou
                                            </q-tooltip>
                                            </q-btn>
                                        </div>
                                        </div>

                                        <div style="display: flex; align-items: center; justify-content: center; width: 100%;">
                                            <div>
                                            <q-spinner
                                                v-if="uploadingFileToServer"
                                                color="primary"
                                                size="3em"
                                                :thickness="10"
                                            />
                                            <table
                                                v-else
                                                class="
                                                table table-bordered table-condensed table-responsive
                                                "
                                                style="width: 100%; max-height: 50vh"
                                            >
                                                <tbody>
                                                <tr
                                                    v-for="scan in decont.scan_documents"
                                                    v-bind:key="scan.appid"
                                                >
                                                    <td v-if="user.is_admin == 'y' || user.auth_for_operatiunihr == 'y' || decont.track_user == user.userid">
                                                    <span                                                        
                                                        class="material-icons cursor-pointer"
                                                        style="color: red; font-size: 1.3rem;"
                                                        @click="askIfRemoveScan(scan)"
                                                        >delete_forever</span
                                                    >
                                                    </td>
                                                    <td>
                                                    {{ scan.file_ext }}
                                                    </td>
                                                    <td>
                                                    {{ humanReadableBytes(scan.file_size) }}
                                                    </td>
                                                    <td>
                                                    <div class="desktop-only" style="max-width: 30vw">
                                                        {{ scan.description }}
                                                    </div>
                                                    <div class="mobile-only">
                                                        {{ scan.description }}
                                                    </div>
                                                    </td>
                                                    <td>
                                                    <q-btn
                                                        round
                                                        color="black"
                                                        icon="cloud_download"
                                                        size="xs"
                                                    />
                                                    <q-menu
                                                        fit
                                                        transition-show="scale"
                                                        transition-hide="scale"
                                                    >
                                                        <DialogOpenScan :appid="scan.appid" type_scan="Decont"/>
                                                    </q-menu>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                    
                    </div>

                    <div clas="row" style="margin-top: 15px;">
                        <div class="q-pa-xs">                        
                            <q-table
                                dense
                                :rows-per-page-options="[0]"
                                :rows="tableDataDecontDet"
                                :columns="decontDetColumns"
                                :visible-columns="decont.stare === 'initiat' ? visibleDecontDetColumns : visibleDecontDetColumnsNotAction"
                                row-key="appid"
                                :filter="filterDecontDet"
                                :loading="loadingData"
                                :pagination.sync="myPagination"
                            >
                                <template v-slot:top>
                                    <span class="text__title--medium">Bonuri fiscale / facturi</span>
                                    <q-btn flat color="primary" icon="add_box" no-caps size="lg" 
                                        :disable="loadingData || !(decont.track_user == user.userid || user.auth_for_operatiunihr == 'y')" 
                                        @click="addRowDecontDet" v-if="decont.stare === 'initiat'">                                    
                                        <q-tooltip
                                            transition-show="rotate"
                                            transition-hide="rotate"
                                        >
                                            Adauga bonuri fiscale / facturi
                                        </q-tooltip>
                                    </q-btn>
                                    <q-space />
                                    <!--test-->
                                    <q-input dense debounce="300" color="primary" v-model="filterDecontDet">
                                    <template v-slot:append>
                                        <q-icon name="search" />
                                    </template>
                                    </q-input>
                                </template>

                                <template v-slot:body="props">
                                    <q-tr :props="props">
                                        <q-td key="nr_document" :props="props" :sortable="true">
                                            {{ props.row.nr_document }}                                        
                                        </q-td>
                                        <q-td key="data" :props="props" :sortable="true">
                                            {{ displayData(props.row.data) }}
                                        </q-td>
                                        <q-td key="descriere" :props="props" style="white-space:pre-wrap; word-wrap:break-word">
                                            {{ props.row.descriere }}
                                        </q-td> 
                                        <q-td key="denumire_cont" :props="props" :sortable="true" style="white-space:pre-wrap; word-wrap:break-word">
                                            {{ props.row.denumire_cont }}
                                        </q-td>      
                                        <q-td key="valoare" :props="props" :sortable="true">                        
                                            {{ props.row.valoare }}
                                        </q-td>
                                        <q-td key="nume_furnizor" :props="props" :sortable="true">                                                                        
                                            {{ props.row.nume_furnizor }}
                                        </q-td>
                                        <q-td key="angajat" :props="props" :sortable="true">                                                                        
                                            {{ props.row.angajat }}
                                        </q-td>
                                        <q-td key="atasament" :props="props" style="white-space:pre-wrap; word-wrap:break-word">
                                            <div style="max-width:30vw;" class="container--span__file_scan">
                                                <span v-for="scan in props.row.scan_documents_det" v-bind:key="scan.appid" class="cursor-pointer span__file_scan">
                                                    <span class="material-icons rotated90">attachment</span>
                                                        {{humanReadableBytes(scan.file_size)}}.{{scan.file_ext}}
                                                        <q-menu fit
                                                            transition-show="scale"
                                                            transition-hide="scale"                                            
                                                        >
                                                            <DialogOpenScan :appid="scan.appid" type_scan="Decont"/>
                                                        </q-menu>
                                                </span>
                                            </div>
                                        </q-td>
                                        <q-td key="appid" :props="props" v-if="(decont.stare === 'initiat' && decont.track_user == user.userid) || user.auth_for_operatiunihr == 'y'">                                        
                                            <q-icon name="edit" class="text-blue cursor-pointer" style="font-size: 1.2rem; margin-left: auto;margin-right:5px;" @click="askIfEditThisDecontDet(props.row)" />
                                            <q-icon name="delete_forever" class="text-red cursor-pointer" style="font-size: 1.3rem; margin-left: auto;" @click="askIfDeleteDecontDetThis(props.row)" />
                                        </q-td>
                                    </q-tr>                      
                                </template>
                            </q-table>

                            <q-dialog v-model="visibleDialogEditDecontDet" persistent >
                                <q-card style="min-width: 60vw; max-width: 80vw;">
                                    <q-bar>
                                        <q-icon name="add_box" />
                                        <div>Editare bonul fiscal / factura</div>

                                        <q-space />

                                        <q-btn dense flat icon="close" v-close-popup @click="refreshDataTableDeconturiDet(false)">
                                            <q-tooltip>Close</q-tooltip>
                                        </q-btn>
                                    </q-bar>

                                    <q-card-section>
                                        <EditDecontDet :foncancel="renuntEditareDecontDet" :fonsave="refreshDataTableDeconturiDet" :appid="appidDecontDet" :currentRowViewDecont="decont" />
                                    </q-card-section>
                                </q-card>
                            </q-dialog>
                        </div>
                    </div>

                    <div clas="row" style="margin-top: 5px;margin-bottom: 15px;" v-if="decont.stare !== 'initiat'">
                        <TaskList :appid="appid" :task="task" :onState="onState" :isSuccess="isSuccess"/>
                    </div>
                </div>            
            </div>

            <q-dialog v-model="dialogUploadFile" position="top">
                <q-card style="width: 350px">
                    <q-linear-progress :value="0.6" color="pink" />

                    <q-card-section class="column no-wrap">
                        <div class="text-weight-bold">Incarca fisier nou</div>
                        <div class="text-grey" style="margin-top: 4px; margin-bottom: 10px;"><q-input counter outlined v-model="uploadScanTitle" label="Titlu document (minim 4 caractere)" dense maxlength="200" /></div>
                        <input v-show="uploadScanTitle.length>3" type="file" name="fileToUpload" id="fileToUpload" ref="myFileToUpload" @change="prepareForUpload($event)" style="margin-bottom: 10px;" />
                        <q-btn :disable="!(uploadScanTitle.length>3)" v-show="checkIfFileIsSelected" icon="cloud_upload" color="white" text-color="black" label="Salveaza pe server" @click="uploadFile()" :loading="uploadingFileToServer" style="margin-bottom: 5px;" />
                    </q-card-section>
                </q-card>
            </q-dialog>        
        </q-form> 
        
        <!-- <br v-if="decont.stare === 'initiat'"> -->
        <div v-if="decont.stare === 'initiat'" class="row absolute-bottom-right q-pa-sm" style="margin-right: 30px;"> <!--margin-top: 15px;-->              
            <q-btn
                label="Salveaza"
                type="submit"
                color="primary"
                @click="onSave"                      
                style="margin-left: 5px;" 
                size="sm"
                :disable="!(decont.track_user == user.userid || user.auth_for_operatiunihr == 'y')"         
            />
            
            <q-btn v-if="(decont.appid != 0 && decont.stare === 'initiat')"
                label="TRIMITE PE FLUX"
                color="green"  
                @click="onSendApproved"                    
                style="margin-left: 5px;"
                size="sm"
                :disable="!(decont.track_user == user.userid || user.auth_for_operatiunihr == 'y')"
            />

            <q-btn label="Renunt" size="sm" type="reset" flat @click="onCancel" style="margin-left: 5px;"/>
        </div>
    </div>    
  </div>
</template>

<script lang="ts" src="./EditDecontDetail.ts" />

<style scoped>
.q-field--with-bottom {
  padding-bottom: 20px;
}

.q-field__native {
  font-family: "Nunito Sans";
  font-weight: bolder;
}
</style>