import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import ISetariCompanieConturi from '@/types/ISetariCompanieConturi';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type ISetariCompanieConturiNull = OrNull<ISetariCompanieConturi>;

interface TAjaxResponseSetariCompanieCont {
  status: string;
  message: string;
  rows: ISetariCompanieConturi[];
}

interface TAjaxResponseEditSetariCompanieCont {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetSetariCompanieCont {
  status: string;
  message: string;
  setariCompanieCont: ISetariCompanieConturiNull;
}

interface TAjaxSimpleResponse {
    status: string;
    message: string;
}

export class ServiceSetariCompanieConturi {
  public static async getSetariCompanieConturi(): Promise<TAjaxResponseSetariCompanieCont> {
    const response = await axios.get(`${CONFIG_ENV.URL_SETARICOMPANIE.setariCompanieConturi}/setari_companie_conturi`);
    return response.data;
  }

  public static async getSetariCompanieCont(appid: number): Promise<TAjaxResponseGetSetariCompanieCont> {
    const response = await axios.get(`${CONFIG_ENV.URL_SETARICOMPANIE.setariCompanieConturi}/setari_compnaie_cont/${appid}`);
    return response.data;
  }
  
  public static async postSetariCompanieCont(pPartenerCont: ISetariCompanieConturi): Promise<TAjaxResponseEditSetariCompanieCont> {
    const response = await axios.post(`${CONFIG_ENV.URL_SETARICOMPANIE.setariCompanieConturi}/setari_companie_cont`, pPartenerCont);
    return response.data;
  }

  public static async deleteSetariCompanieCont(appid: number): Promise<TAjaxSimpleResponse> {
    const response = await axios.delete(`${CONFIG_ENV.URL_SETARICOMPANIE.setariCompanieConturi}/setari_companie_cont/${appid}`);
    return response.data;
  }
}
