import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import INomDeplasari from '@/types/INomDeplasari';
import IScanNomDeplasari from '@/types/IScanNomDeplasari';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type INomDeplasariNull = OrNull<INomDeplasari>;

interface TAjaxResponseNomDeplasari {
  status: string;
  message: string;
  rows: string;
  //rows: iNomDeplasari[];
}

interface TAjaxResponseEditDeplasare {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetDeplasare {
    status: string;
    message: string;
    deplasare: INomDeplasariNull;
}

interface TAjaxResponseGetScansNomDeplasari {
  status: string;
  message: string;
  nr_of_records: number,
  rows: IScanNomDeplasari[];
}

interface TAjaxResponseGetInfoScanNomDeplasari {
  status: string;
  message: string;
  scan: IScanNomDeplasari;
}

interface TAjaxSimpleResponse {
  status: string;
  message: string;
}

export class ServiceNomDeplasari {
  // public static async getNomDeplasari(searchParams:any): Promise<TAjaxResponseNomDeplasari> {
  //   const response = await axios.post(`${CONFIG_ENV.URL_DEPLASARI.deplasari}`, searchParams);    
  //   return response.data;
  // }

  public static async getNomDeplasari(is_all: number): Promise<TAjaxResponseNomDeplasari> {    
    const response = await axios.get(`${CONFIG_ENV.URL_DEPLASARI.deplasari}/${is_all}`);
    return response.data;
  }

  public static async getNomDeplasariCombo(is_all: number): Promise<TAjaxResponseNomDeplasari> {    
    const response = await axios.get(`${CONFIG_ENV.URL_DEPLASARI.deplasaricombo}/${is_all}`);
    return response.data;
  }

  public static async postNomDeplasare(pDeplasare: INomDeplasari): Promise<TAjaxResponseEditDeplasare> {
    const response = await axios.post(`${CONFIG_ENV.URL_DEPLASARI.deplasare}`, pDeplasare);
    return response.data;
  }

  public static async getNomDeplasare(appid: number): Promise<TAjaxResponseGetDeplasare> {
    const response = await axios.get(`${CONFIG_ENV.URL_DEPLASARI.deplasare}/${appid}`);
    return response.data;
  }

  public static async getScansNomDeplasari(appid:number): Promise<TAjaxResponseGetScansNomDeplasari> {
    const response = await axios.get(`${CONFIG_ENV.URL_NOMDEPLASARE_SCAN.getScansNomDeplasari}/${appid}`);
    return response.data;
  }

  public static async getInfoScan(appid:number): Promise<TAjaxResponseGetInfoScanNomDeplasari> {
    const response = await axios.get(`${CONFIG_ENV.URL_NOMDEPLASARE_SCAN.getInfoScan}/${appid}`);
    return response.data;
  }

  public static async deleteScan(appid:number, headers: any): Promise<TAjaxSimpleResponse> {
    const response = await axios.delete(`${CONFIG_ENV.URL_NOMDEPLASARE_SCAN.getInfoScan}/${appid}`, { headers });
    return response.data;
  }
}