import { defineComponent, defineAsyncComponent, inject, ref } from 'vue';
import { getModule } from 'vuex-module-decorators';
import moment from 'moment';
import { CONFIG_ENV, APP_NAME } from '@/config';
import Nomenclatoare from '@/store/Nomenclatoare';
import User from '@/store/User';
import IUser from '@/types/IUser';
import { dateToStringDDMonYYYY, getRowNumberTable } from '@/modules/Utils';
import { humanReadableBytes } from '@/modules/Utils';
import { FileUploadService } from 'v-file-upload';
import Notify from 'quasar/src/plugins/Notify.js';;
import LoadingComponent from '@/components/LoadingComponent.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';
import IScanParteneri from '@/types/IScanParteneri';
import IPartener from '@/types/IPartener';
import IPartenerConturi from '@/types/IPartenerConturi';
import { ServiceParteneri } from '@/modules/ServiceParteneri';
import { ServiceParteneriConturi } from '@/modules/ServicePartenerConturi';
import ITari from '@/types/ITari';
import IJudete from '@/types/IJudete';
import ILocalitati from '@/types/ILocalitati';
import { ServiceTari } from '@/modules/ServiceTari';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type IPartenerConturiNull = OrNull<IPartenerConturi>;

interface IPartenerExtended extends IPartener {
  scan_documents: IScanParteneri[] | null,
}

const qDateProxy0 = ref(null);

const EditPartenerCont = defineAsyncComponent({
  loader: () => import('./EditPartenerCont.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,

  timeout: 3000,
  delay: 200
});

const DialogOpenScan = defineAsyncComponent({
  loader: () => import('@/components/DialogOpenScan/DialogOpenScan.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,

  timeout: 3000,
  delay: 200
});

export default defineComponent({
  name: 'WorkOnPartener',

  components: {
    DialogOpenScan, EditPartenerCont
  },

  props: {
    appid: {
      type: Number,
      required: true,
      default: 0,
      readonly: true
    },

    closeWindow: {
      type: Function,
      default: undefined
    }
  },

  setup() {
    const emitter = inject("emitter") as any;
    return {
      emitter,
      qDateProxy0,
      slide: ref(0),
      fullscreen: ref(false)
    }
  },

  data() {
    return {
      partener: {
        appid: 0,
        denumire: '',
        cui: null as number | null,
        tip: '',
        tip_firma: '',
        tip_organizatie: '',
        reg_comert: '',
        is_tva: 'n',
        is_activ: 'y',
        id_tara: 189 as number | null,
        id_judet: null as number | null,
        id_localitate: null as number | null,
        strada: '',
        numar: '',
        cod_postal: null as number | null,
        nume_prenume_contact: '',
        functia: '',
        numar_telefon_fix: '',
        numar_telefon_mobil: '',
        email: '',
        mentiuni: '',
        adresa: '',
        scan_documents: [] as IScanParteneri[],
      } as IPartenerExtended,
      loadingPartenerData: false,
      myLocale: CONFIG_ENV.myLocale,
      urlForUpload: CONFIG_ENV.URL_PARTENERI_SCAN.uploadScan,
      uploadingFileToServer: false,
      tari: [] as ITari[],
      judete: [] as IJudete[],
      judeteNoFiltered: [] as IJudete[],
      localitati: [] as ILocalitati[],
      loadingPartenerConturi: false,
      headers: {} as any,
      nomenclatoareStore: getModule(Nomenclatoare),
      userStore: getModule(User),
      filesSelectedForUpload: [] as any,
      dialogUploadFile: false,
      uploadScanTitle: '',
      isFinalizat: false,
      loadingPdfForDownload: false,
      isDocument: '',
      visibleDialogPartenerCont: false,
      partenerConturi: [] as IPartenerConturi[],
      appid_cont: 0,
      myPagination: {
        rowsPerPage: getRowNumberTable()
      },
      filterPartenerConturiList: '',
      visiblePartenerConturiListColumns: ['appid', 'denumire_banca', 'moneda', 'swift', 'iban'],
      partenerConturiListColumns: [
        { name: 'moneda', label: 'Moneda', field: '', align: 'left', sortable: true },
        { name: 'denumire_banca', label: 'Banca', field: 'denumire_banca', align: 'left', sortable: true, style: 'max-width: 200px', headerStyle: 'max-width: 200px' },
        { name: 'swift', label: 'SWIFT/BIC', field: 'swift', align: 'left', sortable: true, style: 'max-width: 200px', headerStyle: 'max-width: 200px' },
        { name: 'iban', label: 'IBAN', field: 'iban', align: 'left', sortable: true, style: 'max-width: 200px', headerStyle: 'max-width: 200px' },
        { name: 'appid', label: 'Actiuni', field: 'appid', align: 'left' }
      ],
    }
  },

  methods: {
    resetPartener() {
      this.partener = {
        appid: 0,
        denumire: '',
        cui: null as number | null,
        tip: '',
        is_tva: 'n',
        is_activ: 'y',
        id_tara: 189 as number | null,
        id_judet: null as number | null,
        id_localitate: null as number | null,
        strada: '',
        numar: '',
        cod_postal: null as number | null,
        nume_prenume_contact: '',
        functia: '',
        numar_telefon_fix: '',
        numar_telefon_mobil: '',
        email: '',
        scan_documents: [] as IScanParteneri[],
      } as IPartenerExtended;
    },

    getPartener() {

      const vueInst = this;
      ServiceParteneri.getPartener(Number(vueInst.appid)).then((presponse) => {
        vueInst.loadingPartenerData = false;
        if (presponse.status === 'success') {
          vueInst.partener = JSON.parse(JSON.stringify(presponse.partener));

          vueInst.getScanDocuments();
          vueInst.getConturi();
        }
      })
    },

    doNothing() {
    },

    openFormUploadFile(pIsDocument: string) {
      this.filesSelectedForUpload = [];
      this.uploadScanTitle = '';
      this.dialogUploadFile = true;
      this.isDocument = pIsDocument;
    },

    openFormAssetPlan() {
      this.appid_cont = 0;
      this.visibleDialogPartenerCont = true;
    },

    renuntEditarePartenerCont() {
      this.appid_cont = 0;
      this.visibleDialogPartenerCont = false;
      this.getConturi();
    },

    getConturi() {
      const vueInst = this;
      vueInst.loadingPartenerConturi = true;
      vueInst.visibleDialogPartenerCont = false;
      ServiceParteneriConturi.getPartenerConturi(Number(vueInst.appid)).then((presponse) => {
        vueInst.loadingPartenerData = false;
        vueInst.loadingPartenerConturi = false;
        if (presponse.status === 'success') {
          //vueInst.assetApp.notifications = JSON.parse(JSON.stringify(presponse.rows));
          vueInst.partenerConturi = JSON.parse(JSON.stringify(presponse.rows));
        }
      });
    },

    askIfRemoveScan(pscan: IScanParteneri) {
      const vueInst = this;
      vueInst.$q.dialog({
        title: 'Confirm',
        message: `Stergeti fisierul reprezentand ${pscan.description}?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        ServiceParteneri.deleteScan(pscan.appid, { userid: vueInst.user.userid, token: vueInst.user.csrf_token }).then(presponse => {
          if (presponse.status === 'success') {
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });

            this.getScanDocuments();
          }
        });
      })
    },

    prepareForUpload(event: any) {
      this.filesSelectedForUpload = event.target.files[0];
    },

    uploadFile() {
      const vueInst = this;

      if (vueInst.uploadScanTitle.length == 0) {
        Notify.create({
          color: 'orange',
          textColor: 'white',
          type: 'warning',
          message: 'Trebuie sa completati titlu documentului!',
          position: 'top',
          timeout: 3500,
          html: true
        });
      } else if (vueInst.checkIfFileIsSelected()) {
        vueInst.uploadingFileToServer = true;

        const fileUpload = new FileUploadService(
          this.urlForUpload,
          this.headers,
          this.onProgress
        );

        fileUpload.upload(vueInst.filesSelectedForUpload as File, { appidPartener: vueInst.appid, description: vueInst.uploadScanTitle, userid: vueInst.user.userid, token: vueInst.user.csrf_token })
          .then((e: any) => {
            vueInst.dialogUploadFile = false;
            vueInst.getScanDocuments();
            if (e.target && e.target.response.status && e.target.response.status == 'error') {
              Notify.create({
                color: 'red',
                textColor: 'white',
                type: 'negative',
                message: e.target.response.message,
                position: 'top',
                timeout: 3500,
                html: true
              })
            } else {
              vueInst.$q.notify({
                color: 'teal',
                textColor: 'white',
                type: 'positive',
                message: e.target.response.message,
                position: 'top',
                timeout: 1000,
                html: true
              });

              ServiceParteneri.getScans(Number(vueInst.appid)).then(
                presponse => {
                    vueInst.partener.scan_documents = JSON.parse(JSON.stringify(presponse.rows))
                }
              )
            }
          })
          .catch((e: any) => {
          })
      } else {
        Notify.create({
          color: 'orange',
          textColor: 'white',
          type: 'warning',
          message: 'Trebuie sa selectati fisierul!',
          position: 'top',
          timeout: 3500,
          html: true
        });
      }
    },

    onProgress(event: any) {
      // Handdle the progress
    },

    prepareCoverPdfForDownload() {
      const vueInst = this;
    },

    getScanDocuments(){
      const vueInst = this;
      vueInst.uploadingFileToServer = true;
      ServiceParteneri.getScans(Number(vueInst.appid)).then((presponse)=>{
        vueInst.loadingPartenerData = false;
        vueInst.uploadingFileToServer = false;
        if(presponse.status==='success'){
          vueInst.partener.scan_documents = JSON.parse(JSON.stringify(presponse.rows)) as IScanParteneri[];
        }
      });
    },

    getDenStatus(pStatusValue: string) {
      let result = '' as string;
      const Status = this.optionsStatus.find(statusValue => {
        return statusValue.status == pStatusValue;
      });

      if (Status) {
        result = Status.label;
      }

      return result;
    },

    getDenIsTva(pStatusValue: string) {
      let result = '' as string;
      const Status = this.optionsIsTva.find(statusValue => {
        return statusValue.status == pStatusValue;
      });

      if (Status) {
        result = Status.label;
      }

      return result;
    },

    getDenTip(pStatusValue: string) {
      let result = '' as string;
      const Status = this.optionsTip.find(statusValue => {
        return statusValue.status == pStatusValue;
      });

      if (Status) {
        result = Status.label;
      }

      return result;
    },

    getDenTipCategorie(pIdTipCategorie: number): string {
      const vueInst = this;
      let result = '' as string;
      const TipCategorie = vueInst.nomenclatoareStore.NomCategoriiActive.find(tipcategorie => {
        return tipcategorie.appid == pIdTipCategorie;
      });

      if (TipCategorie) {
        result = TipCategorie.den_lang_1;
      }

      return result;
    },

    getDenTipActive(pIdTipActive: number): string {
      const vueInst = this;
      let result = '' as string;
      const TipActive = vueInst.nomenclatoareStore.NomTipActive.find(tipactive => {
        return tipactive.appid == pIdTipActive;
      });

      if (TipActive) {
        result = TipActive.den_lang_1;
      }

      return result;
    },
    getDenTara() {
      const vueInst = this;
      ServiceTari.getTari().then((presponse) => {
        if (presponse.status === 'success') {
          vueInst.tari = JSON.parse(presponse.rows) as ITari[];

          vueInst.tari.unshift({
            appid: 0,
            id_tara: 0,
            cod_tara: '',
            denumire: ''
          });

          if (vueInst.partener.id_tara != null) {
            //vueInst.onValueChangeTara(vueInst.partener.id_tara as number);
          }
        }
      });
    },


    humanReadableBytes(nrPfBytes: number): string {
      return humanReadableBytes(nrPfBytes);
    },

    checkIfFileIsSelected(): boolean {
      return this.filesSelectedForUpload != undefined && this.filesSelectedForUpload.length != 0;
    },

    getLinkToImgAsset(galerie: string): string {
      return CONFIG_ENV.URL_ASSETS.imgForGallery + "/" + galerie;
    },

    onPressEditRowCont(ipartenerCont: IPartenerConturi) {
      const vueInst = this;
      vueInst.appid_cont = ipartenerCont.appid;
      vueInst.visibleDialogPartenerCont = true;
    },

    displayData(pdata: string): string {
      if (pdata) {
        const day = moment(pdata.substring(0, 10), 'YYYY-MM-DD');
        return dateToStringDDMonYYYY(day);
      } else {
        return '';
      }
    },

    askIfDeleteCont(cont: IPartenerConturi) {
      const vueInst = this;
      vueInst.$q.dialog({
        title: 'Confirmare',
        message: 'Stergeti cont ' + cont.moneda + ' ' + cont.denumire_banca + ' ' + cont.iban + ' ' + '?',
        cancel: true,
        persistent: true
      }).onOk(() => {
        ServiceParteneriConturi.deletePartenerCont(cont.appid).then(presponse => {
          if (presponse.status == 'success') {
            //vueInst.refre();
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
          }
        })
      });
    },

  },

  computed: {
    APP_NAME(): string {
      return APP_NAME;
    },

    optionsStatus() {
      const statusArray = [
        { status: 'y', label: 'Activ' },
        { status: 'n', label: 'Inactiv' },
      ];
      return statusArray;
    },

    optionsIsTva() {
      const isTvaArray = [
        { status: 'y', label: 'Da' },
        { status: 'n', label: 'Nu' },
      ];
      return isTvaArray;
    },

    optionsTip() {
      const tipArray = [
        { status: 'client', label: 'Client' },
        { status: 'furnizor', label: 'Furnizor' },
        { status: 'client$furnizor', label: 'Client si Furnizor' }
      ];
      return tipArray;
    },

    optionsTari(): ITari[] {
      return this.tari;
    },

    optionsJudete(): IJudete[] {
      return this.judete;
    },

    optionsLocalitati(): ILocalitati[] {
      return this.localitati;
    },

    user(): IUser {
      return this.userStore.User;
    },

  },

  watch: {
    appid: {
      immediate: true,
      handler(newVal: number, oldVal: number) {
        const vueInst = this;
        vueInst.resetPartener();
        vueInst.partenerConturi = [];
        vueInst.loadingPartenerData = true;
        if (newVal != 0 && vueInst.appid != 0) {
          vueInst.isFinalizat = false;
          vueInst.getPartener();
        }
      }
    }
  }
});