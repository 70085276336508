import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import IDocument from '@/types/IDocument';
import IDocumentFastSearch from '@/types/IDocumentFastSearch';
import IOrgUnit from '@/types/IOrgUnit';
import IScan from '@/types/IScan';
import IDocumentSearchParams from '@/types/IDocumentSearchParams';
import IPersoana from '@/types/IPersoana';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type IDocumentNull = OrNull<IDocument>;

interface TAjaxResponsePostDocument {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetDocument {
  status: string;
  message: string;
  document: IDocumentNull;
}

interface TAjaxResponseGetPdfCoverDocumentt {
  data: string;
}

interface TAjaxResponsePageWithDocuments {
  status: string;
  message: string;
  totalNrOfPages: number;
  currentPage:number;
  nrRecordsPerPage:number;
  totalRecords:number;
  rows: IDocumentNull[];
}

interface TAjaxResponseGetFastSearch {
  status: string;
  message: string;
  nr_of_records: number,
  rows: IDocumentFastSearch[];
}

interface TAjaxResponseFinInregDoc {
  status: string;
  message: string;
}

interface TAjaxResponseNrOfDoc {
  status: string;
  message: string;
  nr_of_doc: number;
}

interface TAjaxResponseDocumentForReference {
  status: string;
  message: string;
  rows: string;
}

interface TAjaxResponseGetScans {
  status: string;
  message: string;
  nr_of_records: number,
  rows: IScan[];
}

interface TAjaxResponseGetInfoScan {
  status: string;
  message: string;
  scan: IScan;
}

interface TAjaxSimpleResponse {
  status: string;
  message: string;
}

interface TAjaxResponseEventSourceNrBadge {
  status: string;
  message: string;
  nr_of_my_doc: number,
  nr_of_doc_to_finalize: number,
  nr_of_doc_de_repartizat: number,
  nr_of_all_doc_allocated: number,
  nr_of_all_tasks: number,
  nr_of_all_tasks_istoric: number,
  nr_of_all_doc_nefinalizate: number,
  nr_project_task: number,
  nr_of_asset_notifications: number
}

export class ServiceDocument {
  public static async postDocumentIntrare(pdocApp: IDocument): Promise<TAjaxResponsePostDocument> {
    const response = await axios.post(`${CONFIG_ENV.URL_DOCUMENT.doc_intrare}`, pdocApp);
    return response.data;
  }
  
  public static async postDocumentIesire(pdocApp: IDocument): Promise<TAjaxResponsePostDocument> {
    const response = await axios.post(`${CONFIG_ENV.URL_DOCUMENT.doc_iesire}`, pdocApp);
    return response.data;
  }

  public static async postDocumentIntern(pdocApp: IDocument): Promise<TAjaxResponsePostDocument> {
    const response = await axios.post(`${CONFIG_ENV.URL_DOCUMENT.doc_intern}`, pdocApp);
    return response.data;
  }

  public static async getDocument(appid: number): Promise<TAjaxResponseGetDocument> {
    const response = await axios.get(`${CONFIG_ENV.URL_DOCUMENT.document}/${appid}`);
    return response.data;
  }
  
  public static async finalizeazaInregistrareDocument(appid: number): Promise<TAjaxResponseFinInregDoc> {
    const response = await axios.put(`${CONFIG_ENV.URL_DOCUMENT.finalizeazaInregistrareDocument}/${appid}`);
    return response.data;
  }

  public static async updateDocument(appid:number, tipupdate:string, ptext: string): Promise<TAjaxResponseFinInregDoc> {
    const params = new URLSearchParams();
    params.set('new_value', ptext);
    const response = await axios.post(`${CONFIG_ENV.URL_DOCUMENT.update_document}/${appid}/${tipupdate}`, params);
    return response.data;
  }

  public static async updateAdresaDocument(document: IDocument): Promise<TAjaxResponsePostDocument> {
    const response = await axios.post(`${CONFIG_ENV.URL_DOCUMENT.update_adresadocument}`, document);
    return response.data;
  }

  public static async modificaTipDocument(appid:number, id_categ:number,id_tip_doc: number): Promise<TAjaxResponseFinInregDoc> {
    const response = await axios.put(`${CONFIG_ENV.URL_DOCUMENT.modifica_tip_document}/${appid}/${id_categ}/${id_tip_doc}`);
    return response.data;
  }

  public static async fastSearch(ptext: string): Promise<TAjaxResponseGetFastSearch> {
    const params = new URLSearchParams();
    params.set('fast_search_text', ptext);
    const response = await axios.post(`${CONFIG_ENV.URL_DOCUMENT.FastSearch}`, params);
    return response.data;
  }  

  public static async getPageWithDocuments(nrRanduriTabel: number, pageNumber: number, accesFrom: string, searchParams: IDocumentSearchParams): Promise<TAjaxResponsePageWithDocuments> {
    const response = await axios.post(`${CONFIG_ENV.URL_DOCUMENT.GetPageWithDocuments}/${nrRanduriTabel}/${pageNumber}`, {accesFrom, searchParams});
    return response.data;
  }

  public static async getPageWithDocumentsCertificateUrbanism(nrRanduriTabel: number, pageNumber: number, searchParams: IDocumentSearchParams): Promise<TAjaxResponsePageWithDocuments> {
    const response = await axios.post(`${CONFIG_ENV.URL_DOCUMENT.GetPageWithDocumentsCertificateUrbanism}/${nrRanduriTabel}/${pageNumber}`, {searchParams});
    return response.data;
  }

  public static async getPageWithDocumentsServiciiDeTransport(nrRanduriTabel: number, pageNumber: number, searchParams: IDocumentSearchParams): Promise<TAjaxResponsePageWithDocuments> {
    const response = await axios.post(`${CONFIG_ENV.URL_DOCUMENT.GetPageWithDocumentsServiciiDeTransport}/${nrRanduriTabel}/${pageNumber}`, {searchParams});
    return response.data;
  }

  public static async getNrOfMyDocuments(): Promise<TAjaxResponseNrOfDoc> {
    const response = await axios.get(`${CONFIG_ENV.URL_SEARCH.NrOfMyDocuments}`);
    return response.data;
  }

  public static async getNrOfPendingToRegister(): Promise<TAjaxResponseNrOfDoc> {
    const response = await axios.get(`${CONFIG_ENV.URL_SEARCH.NrOfMyDocumentsPendingRegistering}`);
    return response.data;
  }

  public static async getNrOfDocumentsDeRepartizat(): Promise<TAjaxResponseNrOfDoc> {
    const response = await axios.get(`${CONFIG_ENV.URL_SEARCH.NrOfDocumentsDeRepartizat}`);
    return response.data;
  }

  public static async getPdfCoverDocument(appid: number): Promise<TAjaxResponseGetPdfCoverDocumentt> {
    const response = await axios({
      url: `${CONFIG_ENV.URL_DOCUMENT.pdf_cover}/${appid}`,
      method: 'GET',
      responseType: 'blob', // important
    });
    return response;
  }

  public static async repartizeaza(appid: number, parrayOrgs:IOrgUnit[], parrayUsers:IPersoana[]): Promise<TAjaxResponseFinInregDoc> {
    const response = await axios.post(`${CONFIG_ENV.URL_DOCUMENT.Repartizeaza}/${appid}`, {orgUnit: parrayOrgs, users: parrayUsers});
    return response.data;
  }

  public static async nuSeRepartizeaza(appid: number, p_NuSeRepartizeaza:string): Promise<TAjaxResponseFinInregDoc> {
    const response = await axios.put(`${CONFIG_ENV.URL_DOCUMENT.NuSeRepartizeaza}/${appid}/${p_NuSeRepartizeaza}`);
    return response.data;
  }

  public static async stergeRepartizarea(appidDoc: number): Promise<TAjaxResponseFinInregDoc> {
    const response = await axios.delete(`${CONFIG_ENV.URL_DOCUMENT.Repartizeaza}/${appidDoc}`);
    return response.data;
  }

  public static async getNrDocumenteForReference(id_registru: number, id_doc: number): Promise<TAjaxResponseDocumentForReference> {
    const response = await axios.get(`${CONFIG_ENV.URL_DOCUMENT.documenteForReference}/${id_registru}/${id_doc}`);
    return response.data;
  }

  public static async getScans(appidDoc:number): Promise<TAjaxResponseGetScans> {
    const response = await axios.get(`${CONFIG_ENV.URL_DOCUMENT_SCAN.getScans}/${appidDoc}`);
    return response.data;
  }

  public static async getInfoScan(appid:number): Promise<TAjaxResponseGetInfoScan> {
    const response = await axios.get(`${CONFIG_ENV.URL_DOCUMENT_SCAN.getInfoScan}/${appid}`);
    return response.data;
  }

  public static async deleteScan(appid:number, headers: any): Promise<TAjaxSimpleResponse> {    
    const response = await axios.delete(`${CONFIG_ENV.URL_DOCUMENT_SCAN.getInfoScan}/${appid}`, { headers });
    return response.data;
  }

  public static async cancellationDocument(appid: number, description_cancellation: string): Promise<TAjaxSimpleResponse> {
    const response = await axios.post(`${CONFIG_ENV.URL_DOCUMENT.cancellationDocument}/${appid}`, description_cancellation);
    return response.data;
  }

  public static async getNrServerSentEvent(): Promise<TAjaxResponseEventSourceNrBadge> {
    const response = await axios.get(`${CONFIG_ENV.URL_SEARCH.ServerSentEvent}`);
    return response.data;
  }

  public static async stergeRepartizareOrgUnitUtilizator(appidDoc: number, appidRepartizare: number): Promise<TAjaxResponseFinInregDoc> {
    const response = await axios.delete(`${CONFIG_ENV.URL_DOCUMENT.DeleteRepartizare}/${appidDoc}/${appidRepartizare}`);
    return response.data;
  }

  public static async updateReferenceDocument(document: IDocument): Promise<TAjaxResponsePostDocument> {
    const response = await axios.post(`${CONFIG_ENV.URL_DOCUMENT.update_referencedocument}`, document);
    return response.data;
  }
}