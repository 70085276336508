import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import IDocument from '@/types/IDocument';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type IDocumentNull = OrNull<IDocument>;

interface IDocumentExtended extends IDocumentNull{ 
  flux: string
}

interface TAjaxResponseDocumenteCetateni {
  status: string;
  message: string;
  totalNrOfPages: number;
  currentPage:number;
  nrRecordsPerPage:number;
  totalRecords:number;
  rows: IDocumentExtended[];
}

interface TAjaxResponseGetDocumentCetateni {
  status: string;
  message: string;
  document: IDocumentExtended;
}

export class ServiceDocumenteCetateni {
  public static async getDocumenteCetateni(nrRanduriTabel:number, pageNumber:number, searchParams:any): Promise<TAjaxResponseDocumenteCetateni> {
    const response = await axios.post(`${CONFIG_ENV.URL_DOCUMENTE_CETATENI.documente_cetateni}/${nrRanduriTabel}/${pageNumber}`, searchParams);    
    return response.data;
  }  

  public static async getDocumentCetateniDetail(appid: number): Promise<TAjaxResponseGetDocumentCetateni> {
    const response = await axios.get(`${CONFIG_ENV.URL_DOCUMENTE_CETATENI.documentcetateni_detail}/${appid}`);
    return response.data;
  }
}
