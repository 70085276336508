import {defineComponent, defineAsyncComponent, inject, ref} from 'vue';
import {getModule} from 'vuex-module-decorators';
import IDocumenteServiciiDeTransport from '@/types/IDocumenteServiciiDeTransport';
import { ServiceDocumentExtended } from '@/modules/ServiceDocumentExtended';
import {CONFIG_ENV, APP_NAME} from '@/config';
import Nomenclatoare from '@/store/Nomenclatoare';
import User from '@/store/User';
import moment from 'moment';
import LoadingComponent from '@/components/LoadingComponent.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';
import {dateToStringDDMonYYYY} from '@/modules/Utils';
import IActionOnStepTask from '@/types/IActionOnStepTask';
import Notify from 'quasar/src/plugins/Notify.js';;
import IStatusLabel from '@/types/IStatusLabel';

// type OrNull<T> = { [K in keyof T]: T[K] | null }
// type IDocumenteServiciiDeTransportNull = OrNull<IDocumenteServiciiDeTransport>;

const TaskList = defineAsyncComponent({
  loader: () => import('@/components/Tasks/TaskList.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

const myForm = ref({} as any);
const myForm1 = ref({} as any);
const qDateProxy1 = ref(null);
const qDateProxy2 = ref(null);

export default defineComponent({
  name: 'EditServiciiDeTransportDetail',

  components: {
    TaskList
  },

  props: {    
	  appid: {
      type: Number,
      required: true,
      default: 0,
      readonly: true
    },
    closeWindow: {
      type: Function,
      default: undefined
    },
    task: {
      type: Object,
      task_id: 0,
      task_type: '',
      action_on_step: {} as IActionOnStepTask
    }
  },

  setup() { 
    const emitter = inject("emitter") as any;     
    return {
      emitter,
      myForm,
      myForm1,
      qDateProxy1,
      qDateProxy2
    }  
  },

  data() {    
    return {
      documenteServiciiDeTransport: {} as IDocumenteServiciiDeTransport,
      myLocale: CONFIG_ENV.myLocale,      
      nomenclatoareStore: getModule(Nomenclatoare),
      loadingData: false,
      userStore: getModule(User),
      isSuccess: false,
      showPreviewFile: false,
      titleForPreviewForm: '',
      downloadingFile: false,
      urlBlobForPreviewFile: '',
      isPDFFileType: false
    }
  },

  methods: {        
    displayData(pdata:string) :string{
      if(pdata){
        const day = moment(pdata.substring(0,10), 'YYYY-MM-DD');
        return dateToStringDDMonYYYY(day);
      }else{ 
        return '';
      }
    },

    getDocumenteServiciiDeTransport() {
      const vueInst = this;
      ServiceDocumentExtended.getDocumentServiciiDeTransport(Number(vueInst.documenteServiciiDeTransport.appid)).then((presponse)=>{
        vueInst.loadingData = false;
        if(presponse.status==='success'){
          vueInst.documenteServiciiDeTransport = presponse.documenteServiciiDeTransport as IDocumenteServiciiDeTransport;

          vueInst.isSuccess = true;
        }
      })
    },

    onState(operator: string){
      switch(operator) {
        case 'Refresh':
          this.getDocumenteServiciiDeTransport();
          break;
        case 'Close':          
          this.closeWindow?.();
          break;
      }
    },

    onPreviewCerere() {
      const vueInst = this;

      vueInst.titleForPreviewForm = 'Vizualizare';
      vueInst.downloadingFile = true;
      vueInst.showPreviewFile = true;
      
      let urlToGetFile = `${CONFIG_ENV.URL_DOCUMENTEXTENDED.downloadScanForPreviewFromAppDocServiciiDeTransport}/${vueInst.documenteServiciiDeTransport.appid}`;        

      fetch(urlToGetFile, { 
        headers: 
          (['APP_DEV', 'APP_URBANESQ'].includes(APP_NAME)) ? 
            {
              odocsuserid: vueInst.userStore.User.userid.toString(),
              odocsusertoken: vueInst.userStore.User.csrf_token
            } : 
            {
              Odocsuserid: vueInst.userStore.User.userid.toString(),
              Odocsusertoken: vueInst.userStore.User.csrf_token
            }
       })
      .then(resp => resp.blob())
      .then(blob => {
        vueInst.urlBlobForPreviewFile = window.URL.createObjectURL(blob);
        vueInst.isPDFFileType = blob.type == 'application/pdf';

        if (vueInst.isPDFFileType) {
          if (this.$q.platform.is.mobile) {
            setTimeout(function () {
              vueInst.downloadingFile = false;
              const divPreview = window.document.getElementById('iframe_preview_scan') as HTMLIFrameElement
              if(divPreview){
                divPreview.src = '/' + 'pdf_viewer/web/viewer.html?file=' + vueInst.urlBlobForPreviewFile; 
              }
            }, 500);
          } else {// is desktop
            setTimeout(function () {
              vueInst.downloadingFile = false;
              const divPreview = window.document.getElementById('iframe_preview_scan') as HTMLIFrameElement;
              if(divPreview){
                divPreview.src = vueInst.urlBlobForPreviewFile; 
              }
            }, 500);
          }
        } else {
          blob.text().then(text => {
            Notify.create({
              color: 'red',
              textColor: 'white',
              type: 'negative',
              message: text,
              position: 'top',
              timeout: 3500,
              html: true
            });

            setTimeout(() => {
              vueInst.showPreviewFile = false;
            }, 1000);
          });
        }
      })
      .catch((err) => {
          Notify.create({
            color: 'red',
            textColor: 'white',
            type: 'negative',
            message: err.message ? 'Eroare! Fisierul nu poate fi descarcat! ' + err.message : 'Eroare! Fisierul nu poate fi descarcat!',
            position: 'top',
            timeout: 3500,
            html: true
          })
      });
    },

    getDenStatus(pStatus: string): string{
      const vueInst=this;
      let result = '' as string;
      const Status = vueInst.optionsStatus.find(status =>{
        return status.status == pStatus;
      });
      if(Status){
        result=Status.label;
      }
      return result;
    },

    displayDataRO(pdata:Date) :string{
      if(pdata){
        const day = moment(pdata, 'YYYY-MM-DD');
        return dateToStringDDMonYYYY(day);
      }else{ 
        return '';
      }
    },
  },

  computed: {
    APP_NAME(): string{
      return APP_NAME;
    },

    optionsStatus() {
      const statusArray = [
        {status: 'init', label: 'Initiat'},
        {status: 'initiat', label: 'Initiat'},
        {status: 'pending', label: 'In lucru'}, 
        {status: 'inapproval', label: 'In aprobare'},
        {status: 'approved', label: 'Aprobat'},
        {status: 'authorizationissued', label: 'Autorizatie emisa'}
      ];

      return statusArray;
    }
  },

  mounted() {
    const vueInst = this;
    // vueInst.nomenclatoareStore.set_currentpagetitle('Servicii de transport');
  },

  watch: {
    appid: {
      immediate: true,
      handler (newVal: number, oldVal: number) {        
        const vueInst = this;        

        vueInst.loadingData = true;
        if(newVal != 0 && vueInst.appid != 0) {                    
          vueInst.documenteServiciiDeTransport.appid = vueInst.appid;            
          vueInst.getDocumenteServiciiDeTransport();
        }
      }
    }
  }
});