import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import INomDeplasariUsers from '@/types/INomDeplasariUsers';

// type OrNull<T> = { [K in keyof T]: T[K] | null }
// type INomDeplasariUsersNull = OrNull<INomDeplasariUsers>;

interface TAjaxResponseNomDeplasariUsers {
  status: string;
  message: string;
  rows: string;
}

interface TAjaxResponseEditDeplasareUser {
  status: string;
  message: string;
  appid: number;
}

export class ServiceNomDeplasariUsers {
  public static async getNomDeplasariUsers(appid: number): Promise<TAjaxResponseNomDeplasariUsers> {    
    const response = await axios.get(`${CONFIG_ENV.URL_DEPLASARI_USERS.deplasari_users}/${appid}`);
    return response.data;
  }

  public static async postNomDeplasareUser(pINomDeplasareUser: INomDeplasariUsers): Promise<TAjaxResponseEditDeplasareUser> {
    const response = await axios.post(`${CONFIG_ENV.URL_DEPLASARI_USERS.deplasare_user}`, pINomDeplasareUser);
    return response.data;
  }

  public static async deleteNomDeplasareUser(appid: number): Promise<TAjaxResponseEditDeplasareUser> {    
    const response = await axios.delete(`${CONFIG_ENV.URL_DEPLASARI_USERS.deplasare_user}/${appid}`);
    return response.data;
  }
}