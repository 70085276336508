import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import IDocument from '@/types/IDocument';
import IArhiva from '@/types/IArhiva';
import IArhivaSearchParams from '@/types/IArhivaSearchParams';
import IDocumentSearchParams from '@/types/IDocumentSearchParams';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type IArhivaNull = OrNull<IArhiva>;

interface TAjaxResponseDocumente {
  status: string;
  message: string;
  totalNrOfPages: number;
	currentPage: number;
	nrRecordsPerPage: number;
	totalRecords: number;
  rows: IDocument[];
}

interface TAjaxResponseArhive {
  status: string;
  message: string;
  totalNrOfPages: number;
	currentPage: number;
	nrRecordsPerPage: number;
	totalRecords: number;
  rows: IArhiva[];
}

interface TAjaxResponseEditDocument {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetArhive {
  status: string;
  message: string;
  arhiva: IArhivaNull;
}

export class ServiceArhive {
  public static async getDocumente(is_all: number, nrRanduriTabel: number, pageNumber: number, searchParams: IDocumentSearchParams): Promise<TAjaxResponseDocumente> {
    const response = await axios.post(`${CONFIG_ENV.URL_ARHIVE.arhiv_documente}/${is_all}/${nrRanduriTabel}/${pageNumber}`, {searchParams});
    return response.data;
  }

  public static async getArhive(is_all: number, nrRanduriTabel: number, pageNumber: number, searchParams: IArhivaSearchParams): Promise<TAjaxResponseArhive> {
    const response = await axios.post(`${CONFIG_ENV.URL_ARHIVE.arhiv_arhive}/${is_all}/${nrRanduriTabel}/${pageNumber}`, {searchParams});
    return response.data;
  }

  public static async postDocumentArhiva(document: IDocument): Promise<TAjaxResponseEditDocument> {
    const response = await axios.post(`${CONFIG_ENV.URL_ARHIVE.arhiva}`, document);
    return response.data;
  }

  public static async getArhiva(appid_document: number): Promise<TAjaxResponseGetArhive> {
    const response = await axios.get(`${CONFIG_ENV.URL_ARHIVE.arhiva}/${appid_document}`);
    return response.data;
  }
}