import {defineComponent, defineAsyncComponent, inject, ref} from 'vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';
import Nomenclatoare from '@/store/Nomenclatoare';
import {ServiceProiecte} from '@/modules/ServiceProiecte';
import {CONFIG_ENV, APP_NAME} from '@/config';
import {getModule} from 'vuex-module-decorators';
import moment from 'moment';
import {dateToStringDDMMYYYY, dateToStringDDMonYYYY, dateToStringDDMMYYYYHHMMSS, denPersoanaUtilizatorFromMarca, getRowNumberTable} from '@/modules/Utils';
import {humanReadableBytes} from '@/modules/Utils';
import IProiecteTaskuriPontari from '@/types/IProiecteTaskuriPontari';
import IProiecteTaskuriDetail from '@/types/IProiecteTaskuriDetail';
import IScanProiecte from '@/types/IScanProiecte';
import IProiecteTaskuriNotite from '@/types/IProiecteTaskuriNotite';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type IProiecteTaskuriDetailNull = OrNull<IProiecteTaskuriDetail>;

interface IProiecteTaskuriDetailExtended extends IProiecteTaskuriDetailNull{ 
  denumire_user_created: string,  
  scan_documents: IScanProiecte[]|null,
  scan_documents_etapa: IScanProiecte[]|null,
  scan_documents_task: IScanProiecte[]|null
}

interface IProiecteTaskuriNotiteExtended extends IProiecteTaskuriNotite{ 
  utilizator: string,  
  scans: IScanProiecte[]|null
}

const myForm = ref({} as any);
const qDateProxy1 = ref(null);
const qDateProxy2 = ref(null);

const EditProiectTaskPontare = defineAsyncComponent({
  loader: () => import('@/views/Proiect/EditProiectTaskPontare.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

const EditProiectTaskNota = defineAsyncComponent({
  loader: () => import('@/views/Proiect/EditProiectTaskNota.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

const DialogOpenScan = defineAsyncComponent({
  loader: () => import('@/components/DialogOpenScan/DialogOpenScan.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

export default defineComponent({
  name: 'EditProiectTaskDetail',

  components: {
    DialogOpenScan, EditProiectTaskPontare, EditProiectTaskNota
  },

  props: {
    appid: {
      type: Number,
      required: true,
      default: 0,
      readonly: true
    },
    closeWindow: {
      type: Function,
      default: undefined
    },
    is_all: {
      type: Number,
      required: true,
      default: 0,
      readonly: true
    },    
  },

  setup() {  
    const emitter = inject("emitter") as any;
    return {
      emitter,
      myForm,
      qDateProxy1,
      qDateProxy2
    };
  },

  data() {
    return {      
      proiectTaskDetail: {
        appid: 0,
        id_proiect: 0,  
        denumire: '',
        descriere: '',
        id_responsabil: 0,
        data_start: '',
        data_end: '',
        track_user: 0,
        track_date: '',
        durata: 0,
        responsabil: '',
        
        appid_etapa: 0,
        id_proiect_etapa: 0,  
        denumire_etapa: '',
        descriere_etapa: '',
        termen: '',
        id_responsabil_etapa: 0,
        estimare_ora: 0,
        track_user_etapa: 0,
        track_date_etapa: '',
        responsabil_etapa: '',

        appid_task: 0,
        id_proiect_task: 0,  
        denumire_task: '',
        descriere_task: '',
        termen_task: '',
        id_responsabil_task: 0,
        estimare_ora_task: 0,
        track_user_task: 0,
        track_date_task: '',
        responsabil_task: '',
        stare: '',
        progres: 0,
        ora_consumate: 0,
        progres_estimat_task: 0,

        denumire_user_created: '',
        scan_documents: [],
        scan_documents_etapa: [],
        scan_documents_task: []
      } as IProiecteTaskuriDetailExtended,
      myLocale: CONFIG_ENV.myLocale,
      nomenclatoareStore: getModule(Nomenclatoare),      
      loadingProiectTaskData: false,      
      urlForUpload: CONFIG_ENV.URL_PROIECT_SCAN.uploadScanProiect,
      uploadingFileToServer: false,
      headers: {} as any,
      filesSelectedForUpload: [] as any,
      dialogUploadFile: false,   
      loadingPdfForDownload: false,      
      filterProiectTaskuriPontare: '',          
      visibleDialogEditProiecteTaskuriPontari: false,
      myPagination: {
        rowsPerPage: getRowNumberTable()
      },      
      tableDataProiecteTaskuriPontari: [] as IProiecteTaskuriPontari[],      
      visibleProiecteTaskuriPontariColumns: ['id_proiect_task_pontare', 'denumire', 'data', 'ora_consumate', 'progres_estimat_task', 'stare', 'id_proiect_task_pontare'],      
      proiecteTaskuriPontariColumns: [
        { name: 'id_proiect_task_pontare', label: 'Id', field: 'id_proiect_task_pontare', align: 'left', sortable: true, sort: (a: string, b: string) => parseInt(a, 10) - parseInt(b, 10) },
        { name: 'denumire', label: 'Activitate', field: 'denumire', align: 'left', sortable: true, style: 'max-width: 200px', headerStyle: 'max-width: 200px' },                
        { name: 'data', label: 'Data', field: 'data', align: 'left', sortable: true },
        { name: 'ora_consumate', label: 'Ore', field: 'ora_consumate', align: 'left', sortable: true },        
        { name: 'progres_estimat_task', label: 'Progres estimat', field: 'progres_estimat_task', align: 'left', sortable: true, sort: (a: string, b: string) => parseInt(a, 10) - parseInt(b, 10) },
        { name: 'stare', label: 'Stare task', field: 'stare', align: 'left', sortable: true },
        { name: 'id_proiect_task_pontare', label: 'Actiuni', field: 'id_proiect_task_pontare', align: 'left', sortable: false }   
      ],
      appid_pontare: 0,
      proiect_detail_info: {
        appid: null as number|null,
        appid_etapa: null as number|null,
        appid_task: null as number|null,
        denumire: '',
        denumire_etapa: '',
        denumire_task: ''
      },
      
      expandedPontareTask: false,
      expandedPontareNote: false,

      filterProiectTaskuriNota: '',          
      visibleDialogEditProiecteTaskuriNotite: false,

      tableDataProiecteTaskuriNotite: [] as IProiecteTaskuriNotiteExtended[],      
      visibleProiecteTaskuriNotiteColumns: ['appid', 'track_date', 'utilizator', 'descriere', 'scans'],      
      proiecteTaskuriNotiteColumns: [
        { name: 'appid', label: 'Id', field: 'appid', align: 'left', sortable: true, sort: (a: string, b: string) => parseInt(a, 10) - parseInt(b, 10) },
        { name: 'track_date', label: 'Data', field: 'track_date', align: 'left', sortable: true },
        { name: 'utilizator', label: 'Utilizator', field: 'utilizator', align: 'left', sortable: true, style: 'max-width: 150px', headerStyle: 'max-width: 150px' },
        { name: 'descriere', label: 'Descriere', field: 'descriere', align: 'left', sortable: true, style: 'max-width: 400px', headerStyle: 'max-width: 400px' },
        { name: 'scans', label: 'Documente atasate', field: 'scans', align: 'left', style: 'max-width: 200px', headerStyle: 'max-width: 200px' }       
      ],
      appid_nota: 0,
    }
  },

  methods: {
    displayData(pdata:string) :string{
      if(pdata){        
        const day = moment(pdata.substring(0,10), 'YYYY-MM-DD');
        return dateToStringDDMonYYYY(day);
      }else{ 
        return '';
      }
    },

    displayDataNormal(pdata:string) :string{
      if(pdata){        
        const day = moment(pdata.substring(0,10), 'YYYY-MM-DD');
        return dateToStringDDMMYYYY(day);
      }else{ 
        return '';
      }
    },

    displayDataFull(pdata:string) :string{
      if(pdata){        
        const day = moment(pdata, 'YYYY-MM-DD HH:mm:ss');
        return dateToStringDDMMYYYYHHMMSS(day);
      }else{ 
        return '';
      }
    },

    getDenStatus(pStatus: string): string{
      const vueInst=this;
      let result = '' as string;
      const Status = vueInst.optionsStare.find( stare =>{
        return stare.status == pStatus;
      });
      if(Status){
        result=Status.label;
      }
      return result;
    },

    displayStatusColor(pStare:string) {
      switch(pStare) { 
        case 'neinceput': { 
           return 'app__status--proiecte--gray'
        } 
        case 'inlucru': { 
          return 'app__status--proiecte--orange'
        }
        case 'finalizat': { 
          return 'app__status--proiecte--green'
       } 
        default: { 
          return 'app__status--proiecte--gray'
        } 
     } 
    },

    displayProgres(pProgres:number) {
      if (pProgres < 30) {
        return 'app__status--proiecte--gray';
      } else if (pProgres >= 30 && pProgres < 60) {
        return 'app__status--proiecte--red';
      } else if (pProgres >= 60) {
        return 'app__status--proiecte--orange';
      } else {
        return 'app__status--proiecte--gray';
      }
    },

    getProiectTaskDetail() {
      const vueInst=this;
      ServiceProiecte.getProiectTaskDetail(vueInst.appid).then((presponse)=>{
        vueInst.loadingProiectTaskData = false;
        if(presponse.status==='success'){
          vueInst.proiectTaskDetail = JSON.parse(JSON.stringify(presponse.proiectTaskDetail))[0];
          vueInst.proiectTaskDetail.data_start = vueInst.proiectTaskDetail.data_start == null ? null : vueInst.displayData(vueInst.proiectTaskDetail.data_start);
          vueInst.proiectTaskDetail.data_end = vueInst.proiectTaskDetail.data_end == null ? null : vueInst.displayData(vueInst.proiectTaskDetail.data_end);
          vueInst.proiectTaskDetail.track_date = vueInst.proiectTaskDetail.track_date == null ? null : vueInst.displayDataNormal(vueInst.proiectTaskDetail.track_date);
          vueInst.proiectTaskDetail.termen = vueInst.proiectTaskDetail.termen == null ? null : vueInst.displayData(vueInst.proiectTaskDetail.termen);
          vueInst.proiectTaskDetail.termen_task = vueInst.proiectTaskDetail.termen_task == null ? null : vueInst.displayData(vueInst.proiectTaskDetail.termen_task);
          vueInst.proiectTaskDetail.denumire_user_created = denPersoanaUtilizatorFromMarca(Number(vueInst.proiectTaskDetail.track_user));

          // vueInst.getScanDocuments();
          // vueInst.getScanDocumentsEtapa();
          vueInst.getScanDocumentsTask(); 

          vueInst.refreshDataTableProiecteTaskuriPontari();
          vueInst.refreshDataTableProiecteTaskuriNotite();
        }
      })
    },

    addRowProiecteTaskuriPontare() {
      const vueInst = this;
      vueInst.appid_pontare = 0;      
      vueInst.proiect_detail_info = {
        appid: vueInst.proiectTaskDetail.appid as number,
        appid_etapa: vueInst.proiectTaskDetail.appid_etapa as number,
        appid_task: vueInst.proiectTaskDetail.appid_task as number,
        denumire: vueInst.proiectTaskDetail.denumire as string,
        denumire_etapa: vueInst.proiectTaskDetail.denumire_etapa as string,
        denumire_task: vueInst.proiectTaskDetail.denumire_task as string
      };
      vueInst.visibleDialogEditProiecteTaskuriPontari = true;      
    },

    renuntEditareProiecteTaskuriPontare() {        
      const vueInst = this;
      vueInst.appid_pontare = 0;
      vueInst.proiect_detail_info = {
        appid: null as number|null,
        appid_etapa: null as number|null,
        appid_task: null as number|null,
        denumire: '',
        denumire_etapa: '',
        denumire_task: ''
      };
      vueInst.visibleDialogEditProiecteTaskuriPontari = false;      
      vueInst.refreshDataTableProiecteTaskuriPontari();  
    },

    refreshDataTableProiecteTaskuriPontari(){
      const vueInst = this;      
      vueInst.visibleDialogEditProiecteTaskuriPontari = false;
      vueInst.tableDataProiecteTaskuriPontari = [] as IProiecteTaskuriPontari[];
      ServiceProiecte.getProiecteTaskuriPontari(Number(vueInst.proiectTaskDetail.appid_task)).then((presponse)=>{   
        vueInst.loadingProiectTaskData=false;   
        if (presponse.status === 'success'){
          vueInst.tableDataProiecteTaskuriPontari = JSON.parse(presponse.rows) as IProiecteTaskuriPontari[];

          (vueInst.proiectTaskDetail.ora_consumate as number) = vueInst.tableDataProiecteTaskuriPontari.reduce((accumulator, current) => {
            return accumulator + current.ora_consumate;
          }, 0);
        }
      })
    },

    // getScanDocuments(){
    //   const vueInst = this;
    //   vueInst.uploadingFileToServer = true;
    //   ServiceProiecte.getScansProiecte(Number(vueInst.proiectTaskDetail.appid)).then((presponse)=>{
    //     vueInst.loadingProiectTaskData = false;
    //     vueInst.uploadingFileToServer = false;
    //     if(presponse.status==='success'){
    //       vueInst.proiectTaskDetail.scan_documents = JSON.parse(JSON.stringify(presponse.rows));
    //     }
    //   });
    // },

    // getScanDocumentsEtapa(){
    //   const vueInst = this;
    //   vueInst.uploadingFileToServer = true;
    //   ServiceProiecte.getScansProiecteEtape(Number(vueInst.proiectTaskDetail.appid_etapa)).then((presponse)=>{
    //     vueInst.loadingProiectTaskData = false;
    //     vueInst.uploadingFileToServer = false;
    //     if(presponse.status==='success'){
    //       vueInst.proiectTaskDetail.scan_documents_etapa = JSON.parse(JSON.stringify(presponse.rows));
    //     }
    //   });
    // },

    getScanDocumentsTask(){
      const vueInst = this;
      vueInst.uploadingFileToServer = true;
      ServiceProiecte.getScansProiecteTaskuri(Number(vueInst.proiectTaskDetail.appid_task)).then((presponse)=>{
        vueInst.loadingProiectTaskData = false;
        vueInst.uploadingFileToServer = false;
        if(presponse.status==='success'){
          vueInst.proiectTaskDetail.scan_documents_task = JSON.parse(JSON.stringify(presponse.rows));
        }
      });
    },

    resetProiectTaskDetail() {
      this.proiectTaskDetail = {
        appid: 0,
        id_proiect: 0,  
        denumire: '',
        descriere: '',
        id_responsabil: 0,
        data_start: '',
        data_end: '',
        track_user: 0,
        track_date: '',
        durata: 0,
        responsabil: '',
        
        appid_etapa: 0,
        id_proiect_etapa: 0,  
        denumire_etapa: '',
        descriere_etapa: '',
        termen: '',
        id_responsabil_etapa: 0,
        estimare_ora: 0,
        track_user_etapa: 0,
        track_date_etapa: '',
        responsabil_etapa: '',

        appid_task: 0,
        id_proiect_task: 0,  
        denumire_task: '',
        descriere_task: '',
        termen_task: '',
        id_responsabil_task: 0,
        estimare_ora_task: 0,
        track_user_task: 0,
        track_date_task: '',
        responsabil_task: '',
        stare: '',
        progres: 0,
        ora_consumate: 0,
        progres_estimat_task: 0,

        denumire_user_created: '',
        scan_documents: [],
        scan_documents_etapa: [],
        scan_documents_task: []
      } as IProiecteTaskuriDetailExtended;
    },

    humanReadableBytes(nrPfBytes:number): string{
      return humanReadableBytes(nrPfBytes);
    },    

    checkIfFileIsSelected():boolean{
      return this.filesSelectedForUpload != undefined && this.filesSelectedForUpload.length != 0;
    },

    onProgress(event:any) {
      // Handdle the progress
    },

    openEditPontareTask(pPontareTask: IProiecteTaskuriPontari) {
      const vueInst = this;
    
      vueInst.appid_pontare = pPontareTask.appid;
      vueInst.proiect_detail_info = {
        appid: vueInst.proiectTaskDetail.appid as number,
        appid_etapa: vueInst.proiectTaskDetail.appid_etapa as number,
        appid_task: vueInst.proiectTaskDetail.appid_task as number,
        denumire: vueInst.proiectTaskDetail.denumire as string,
        denumire_etapa: vueInst.proiectTaskDetail.denumire_etapa as string,
        denumire_task: vueInst.proiectTaskDetail.denumire_task as string
      };
      vueInst.visibleDialogEditProiecteTaskuriPontari = true;
    },

    addRowProiecteTaskuriNota() {
      const vueInst = this;
      vueInst.appid_nota = 0;      
      vueInst.proiect_detail_info = {
        appid: vueInst.proiectTaskDetail.appid as number,
        appid_etapa: vueInst.proiectTaskDetail.appid_etapa as number,
        appid_task: vueInst.proiectTaskDetail.appid_task as number,
        denumire: vueInst.proiectTaskDetail.denumire as string,
        denumire_etapa: vueInst.proiectTaskDetail.denumire_etapa as string,
        denumire_task: vueInst.proiectTaskDetail.denumire_task as string
      };
      vueInst.visibleDialogEditProiecteTaskuriNotite = true;      
    },

    renuntEditareProiecteTaskuriNota() {        
      const vueInst = this;
      vueInst.appid_nota = 0;
      vueInst.proiect_detail_info = {
        appid: null as number|null,
        appid_etapa: null as number|null,
        appid_task: null as number|null,
        denumire: '',
        denumire_etapa: '',
        denumire_task: ''
      };
      vueInst.visibleDialogEditProiecteTaskuriNotite = false;      
      vueInst.refreshDataTableProiecteTaskuriNotite();  
    },

    refreshDataTableProiecteTaskuriNotite(){
      const vueInst = this;
      vueInst.visibleDialogEditProiecteTaskuriNotite = false;
      vueInst.tableDataProiecteTaskuriNotite = [] as IProiecteTaskuriNotiteExtended[];
      ServiceProiecte.getProiecteTaskuriNotite(Number(vueInst.proiectTaskDetail.appid_task)).then((presponse)=>{   
        vueInst.loadingProiectTaskData=false;   
        if (presponse.status === 'success'){
          //vueInst.tableDataProiecteTaskuriNotite = JSON.parse(presponse.rows) as IProiecteTaskuriNotiteExtended[];
          (JSON.parse(presponse.rows) as IProiecteTaskuriNotiteExtended[]).forEach((notite, index)=>{            
            vueInst.tableDataProiecteTaskuriNotite.push({
              ...notite,              
              utilizator: denPersoanaUtilizatorFromMarca(Number(notite.track_user)),              
            });
          });          
        }
      }).then(() => {
        if (vueInst.tableDataProiecteTaskuriNotite.length > 0) {
          vueInst.tableDataProiecteTaskuriNotite.forEach(notite => {
            setTimeout(() => {
              ServiceProiecte.getScansProiecteTaskuriNotite(notite.appid).then((presponse) => {
                vueInst.loadingProiectTaskData = false;
                vueInst.uploadingFileToServer = false;
                if (presponse.status === 'success') {
                  notite.scans = JSON.parse(JSON.stringify(presponse.rows));
                }
              });
            }, 200);
          });
        }
      });
    },
  },

  computed: { 
    APP_NAME(): string{
      return APP_NAME;
    },

    getLinkToQrCode(): string {      
      return CONFIG_ENV.URL_PROIECTE.qr_code + '/' + this.proiectTaskDetail.appid;
    },
    
    optionsStare() {      
      const stareArray = [  
        {status: 'neinceput', label: 'Neinceput'},        
        {status: 'inlucru', label: 'In lucru'}, 
        {status: 'finalizat', label: 'Finalizat'}
      ];

      return stareArray;
    }    
  },

  watch: {
    appid: {
      immediate: true,
      handler (newVal: number, oldVal: number) {        
        const vueInst = this;                
        vueInst.resetProiectTaskDetail();    
        // vueInst.scan_documents = [];
        vueInst.loadingProiectTaskData = true;
        if(newVal != 0 && vueInst.appid != 0) {
          vueInst.getProiectTaskDetail();    
        }
      }
    }
  }
});
