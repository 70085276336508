import {defineComponent, defineAsyncComponent, inject, ref} from 'vue';
import {getModule} from 'vuex-module-decorators';
import INomDeplasari from '@/types/INomDeplasari';
import INomDeplasariUsers from '@/types/INomDeplasariUsers';
import {ServiceNomDeplasari} from '@/modules/ServiceNomDeplasari';
import {ServiceNomDeplasariUsers} from '@/modules/ServiceNomDeplasariUsers';
import {APP_NAME, CONFIG_ENV} from '@/config';
import Nomenclatoare from '@/store/Nomenclatoare';
import User from '@/store/User';
import IUser from '@/types/IUser';;
import {humanReadableBytes} from '@/modules/Utils';
import moment from 'moment';
import LoadingComponent from '@/components/LoadingComponent.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';
import {dateToStringDDMonYYYY, getRowNumberTable} from '@/modules/Utils';
import ILocalitatiView from '@/types/ILocalitatiView';
import ITari from '@/types/ITari';
import { ServiceTari } from '@/modules/ServiceTari';
import { ServiceLocalitati } from '@/modules/ServiceLocalitati';
import IActionOnStepTask from '@/types/IActionOnStepTask';
import IScanNomDeplasari from '@/types/IScanNomDeplasari';
import IJudete from '@/types/IJudete';
import { ServiceJudete } from '@/modules/ServiceJudete';
import { ServiceNomTipMijlocDeTransport } from '@/modules/ServiceNomTipMijlocDeTransport';
import INomTipMijlocDeTransport from '@/types/INomTipMijlocDeTransport';
import Notify from 'quasar/src/plugins/Notify.js';;
import IFoaieParcurs from '@/types/IFoaieParcurs';
import { ServiceFoiParcurs } from '@/modules/ServiceFoiParcurs';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type INomDeplasariNull = OrNull<INomDeplasari>;
// type INomDeplasariUsersNull = OrNull<INomDeplasariUsers>;

interface INomDeplasariExtended extends INomDeplasariNull{ 
  persoane: INomDeplasariUsers[]|null,
  scan_documents:IScanNomDeplasari[]|null,
}

const DialogOpenScan = defineAsyncComponent({
  loader: () => import('@/components/DialogOpenScan/DialogOpenScan.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

const TaskList = defineAsyncComponent({
  loader: () => import('@/components/Tasks/TaskList.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

const EditFoaieParcurs = defineAsyncComponent({
  loader: () => import('@/views/Deplasari/EditFoaieParcurs.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

const myForm = ref({} as any);
const myForm1 = ref({} as any);
const qDateProxy1 = ref(null);
const qDateProxy2 = ref(null);

export default defineComponent({
  name: 'EditDeplasareDetail',

  components: {
    DialogOpenScan, TaskList, EditFoaieParcurs,
  },

  props: {    
	  appid: {
      type: Number,
      required: true,
      default: 0,
      readonly: true
    },
    closeWindow: {
      type: Function,
      default: undefined
    },
    task: {
      type: Object,
      task_id: 0,
      task_type: '',
      action_on_step: {} as IActionOnStepTask
    }
  },

  setup() { 
    const emitter = inject("emitter") as any;
    return {
      emitter,
      myForm,
      myForm1,
      qDateProxy1,
      qDateProxy2
    }  
  },

  data() {    
    return {
      deplasare: {
        appid: 0,
        id_deplasare: 0,
        data_start: '',
        data_end: '',
        denumire_pentru: '',        
        id_tara: 189,
        id_judet: null,
        id_localitate: null,
        id_mijl_transport: null,
        detalii: '',
        track_user: 0,
        track_date: '',
        stare: null,
        responsabil: '', 
        denumire_tara: '',
        denumire_judet: '',
        denumire_localitate: '',
        localitate: '',
        angajati: '',
        appid_tip_deplasare: null,
        tip_deplasare: null,
        persoane: [] as INomDeplasariUsers[],
        scan_documents: [] as IScanNomDeplasari[]
      } as INomDeplasariExtended,
      myLocale: CONFIG_ENV.myLocale,      
      nomenclatoareStore: getModule(Nomenclatoare),
      loadingData: false,
      uploadScanTitle: '',
      urlForUpload: CONFIG_ENV.URL_NOMDEPLASARE_SCAN.uploadScanDeplasare,
      uploadingFileToServer: false,
      userStore: getModule(User),
      loadingPdfForDownload: false,
      tari: [] as ITari[],
      judete: [] as IJudete[],
      localitati: [] as ILocalitatiView[],
      isSuccess: false,
      nomTipMijlocDeTransport: [] as INomTipMijlocDeTransport[],
      showPreviewFile: false,
      titleForPreviewForm: '',
      downloadingFile: false,
      urlBlobForPreviewFile: '',
      isPDFFileType: false,
      loadingFoieParcurs: false,
      myPagination: {
        rowsPerPage: getRowNumberTable()
      },
      filterFoiParcursList: '',
      loadingFoiParcurs: false,
      visibleFoiParcursListColumns: ['appid', 'data_start', 'data_end', 'denumire_pentru', 'detalii', 'adresa', 'distanta', 'nume_user'],
      foaieParcursListColumns: [        
        { name: 'nume_user', label: 'Nume Prenume', field: 'nume_user', align: 'left', sortable: true },
        { name: 'data_start', label: 'De la', field: 'data_start', align: 'center', sortable: true },
        { name: 'data_end', label: 'Pana la', field: 'data_start', align: 'center', sortable: true },
        { name: 'denumire_pentru', label: 'Scop', field: 'denumire_pentru', align: 'left', sortable: true, style: 'max-width: 200px', headerStyle: 'max-width: 200px' },
        { name: 'adresa', label: 'Destinatia', field: 'adresa', align: 'left', sortable: true },
        { name: 'distanta', label: 'Distanta', field: 'distanta', align: 'left', sortable: true, style: 'max-width: 200px', headerStyle: 'max-width: 200px' },
        { name: 'appid', label: 'Actiuni', field: 'appid' },
      ],
      tableDataFoiParcurs: [] as IFoaieParcurs[],
      visibleDialogEditFoaieParcurs: false,
      appid_foaie_parcurs: 0,
    }
  },

  methods: {        
    displayData(pdata:string) :string{
      if(pdata){
        const day = moment(pdata.substring(0,10), 'YYYY-MM-DD');
        return dateToStringDDMonYYYY(day);
      }else{ 
        return '';
      }
    },

    getDeplasare() {
      const vueInst=this;
      ServiceNomDeplasari.getNomDeplasare(Number(vueInst.deplasare.appid)).then((presponse)=>{
        vueInst.loadingData = false;
        if(presponse.status==='success'){
          vueInst.deplasare = JSON.parse(JSON.stringify(presponse.deplasare));
          vueInst.deplasare.data_start = vueInst.deplasare.data_start == null ? null : vueInst.displayData(vueInst.deplasare.data_start);
          vueInst.deplasare.data_end = vueInst.deplasare.data_end == null ? null : vueInst.displayData(vueInst.deplasare.data_end);
          
          vueInst.getScanDocuments();
          vueInst.getNomDeplasariUsers();
          vueInst.isSuccess = true;
          if(['APP_BRANDTEAM'].includes(APP_NAME)){
            vueInst.getFoiParcurs();
          }
        }
      })
    },

    getScanDocuments(){
      const vueInst = this;
      vueInst.uploadingFileToServer = true;
      ServiceNomDeplasari.getScansNomDeplasari(Number(vueInst.deplasare.appid)).then((presponse)=>{
        vueInst.loadingData = false;
        vueInst.uploadingFileToServer = false;
        if(presponse.status==='success'){
          vueInst.deplasare.scan_documents = JSON.parse(JSON.stringify(presponse.rows));          
        }
      });
    },

    humanReadableBytes(nrPfBytes:number): string{
      return humanReadableBytes(nrPfBytes);
    },
    
    getNomDeplasariUsers(){
      const vueInst = this;         
      ServiceNomDeplasariUsers.getNomDeplasariUsers(Number(vueInst.deplasare.appid)).then((presponse)=>{
        vueInst.loadingData = false;        
        if(presponse.status==='success'){
          vueInst.deplasare.persoane = JSON.parse(presponse.rows);
        }
      });
    },

    onState(operator: string){
      switch(operator) {
        case 'Refresh':
          this.getDeplasare();
          break;
        case 'Close':          
          this.closeWindow?.();
          break;
      }
    }, 

    onValueChangeTara(idTara: number) {  
      ServiceJudete.getJudete(idTara).then((presponse)=>{      
        if (presponse.status === 'success'){
          this.judete = JSON.parse(presponse.rows) as IJudete[];
  
          this.judete.unshift({
            appid: 0, 
            id_judet: 0,   
            id_tara: 0,
            cod_judet: '',
            denumire: ''
          });
        }
      })             
    },

    onValueChangeJudet(idJudet: number) { 
      ServiceLocalitati.getLocalitati(this.deplasare.id_tara as number, idJudet).then((presponse)=>{
        if (presponse.status === 'success'){
          this.localitati = JSON.parse(presponse.rows) as ILocalitatiView[];
  
          this.localitati.unshift({
            appid: 0, 
            id_localitate: 0,             
            id_tara: 0,
            id_judet: 0,
            denumire: '',
            cod_postal: '',
            denumire_tara: '',
            denumire_judet: ''
          });
        }
      })
    },

    getDenTara(pIdTara: number): string{
      const vueInst = this;
      let result = '';
      const Tara = vueInst.tari.find( tara => {
        return tara.id_tara == pIdTara;
      });
      if(Tara){
        result = Tara.denumire;
      }
      return result;
    },

    getDenJudet(pIdTara: number, pIdJudet: number): string{
      const vueInst = this;
      let result = '';
      const Judet = vueInst.judete.find( judet => {
        return judet.id_tara == pIdTara && judet.id_judet == pIdJudet;
      });
      if(Judet){
        result = Judet.denumire;
      }
      return result;
    },

    getDenLocalitate(pIdTara: number, pIdJudet: number, pIdLocalitate: number): string{
      const vueInst = this;
      let result = '';
      const Localitate = vueInst.localitati.find( localitate => {
        return (localitate.id_tara == pIdTara && localitate.id_judet == pIdJudet && localitate.id_localitate == pIdLocalitate);
      });
      if(Localitate){
        result = Localitate.denumire;
      }
      return result;
    },

    getDenMijlocTransport(pIdMijlTrans: number): string{
      const vueInst = this;
      let result = '';
      const MijlTransport = vueInst.optionsTipMijlTransport.find( mijlTransport => {
        return mijlTransport.appid == pIdMijlTrans;
      });
      if(MijlTransport){
        result = MijlTransport.denumire;
      }
      return result;
    },

    getNomTipMijlocDeTransport() {
      const vueInst = this;
      ServiceNomTipMijlocDeTransport.getNomTipMijlocDeTransport().then((presponse)=>{      
        if (presponse.status === 'success'){
          vueInst.nomTipMijlocDeTransport = JSON.parse(presponse.rows) as INomTipMijlocDeTransport[];
        }
      });
    },

    onPreviewFoaieDeParcurs() {
      const vueInst = this;         
      //if(vueInst.timeRequest.stare !== 'initiat'){
      vueInst.titleForPreviewForm = 'Foaie de parcurs';
      vueInst.downloadingFile = true;
      vueInst.showPreviewFile = true;
      
      let urlToGetFile = `${CONFIG_ENV.URL_DEPLASARI.downloadScanForPreviewFromApp}/${vueInst.deplasare.appid}`;

      fetch(urlToGetFile, { 
        headers: 
          // (['APP_DEV', 'APP_URBANESQ'].includes(APP_NAME)) ? 
          //   {
          //     odocsuserid: vueInst.userStore.User.userid.toString(),
          //     odocsusertoken: vueInst.userStore.User.csrf_token
          //   } : 
            {
              Odocsuserid: vueInst.userStore.User.userid.toString(),
              Odocsusertoken: vueInst.userStore.User.csrf_token
            }
       })
      .then(resp => resp.blob())
      .then(blob => {
        vueInst.urlBlobForPreviewFile = window.URL.createObjectURL(blob);
        vueInst.isPDFFileType = blob.type == 'application/pdf';

        if (vueInst.isPDFFileType) {
          if (this.$q.platform.is.mobile) {
            setTimeout(function () {
              vueInst.downloadingFile = false;
              const divPreview = window.document.getElementById('iframe_preview_scan') as HTMLIFrameElement
              if(divPreview){
                divPreview.src = '/' + 'pdf_viewer/web/viewer.html?file=' + vueInst.urlBlobForPreviewFile; 
              }
            }, 500);
          } else {// is desktop
            setTimeout(function () {
              vueInst.downloadingFile = false;
              const divPreview = window.document.getElementById('iframe_preview_scan') as HTMLIFrameElement;
              if(divPreview){
                divPreview.src = vueInst.urlBlobForPreviewFile; 
              }
            }, 500);
          }
        } else {
          blob.text().then(text => {
            Notify.create({
              color: 'red',
              textColor: 'white',
              type: 'negative',
              message: text,
              position: 'top',
              timeout: 3500,
              html: true
            });

            setTimeout(() => {
              vueInst.showPreviewFile = false;
            }, 1000);
          });
        }
      })
      .catch((err) => {
          Notify.create({
            color: 'red',
            textColor: 'white',
            type: 'negative',
            message: err.message ? 'Eroare! Fisierul nu poate fi descarcat! ' + err.message : 'Eroare! Fisierul nu poate fi descarcat!',
            position: 'top',
            timeout: 3500,
            html: true
          })
      });
    },

    openFormFoaieParcurs() {
      const vueInst = this;
      vueInst.visibleDialogEditFoaieParcurs = true;
    },

    renuntEditareFoaieParcurs() {
      const vueInst = this;
      vueInst.visibleDialogEditFoaieParcurs= false;
      vueInst.appid_foaie_parcurs = 0;
    },

    getFoiParcurs() {
      const vueInst = this;
      ServiceFoiParcurs.getFoiParcurs(vueInst.deplasare.appid as number).then((presponse)=>{      
        if (presponse.status === 'success'){
          vueInst.tableDataFoiParcurs = JSON.parse(presponse.rows) as IFoaieParcurs[];
        }
      });
    },

    onSaveFoaieParcurs() {
      const vueInst = this;
      vueInst.getFoiParcurs();
      vueInst.appid_foaie_parcurs = 0;
      vueInst.visibleDialogEditFoaieParcurs = false;
    },

    onPressEditRowFoaieParcurs(foaieParcurs: IFoaieParcurs) {
      const vueInst = this;
      vueInst.appid_foaie_parcurs = foaieParcurs.appid;
      vueInst.visibleDialogEditFoaieParcurs = true;
    },
  

    askIfRemoveFoaieParcurs(foaieParcurs: IFoaieParcurs) {
      const vueInst = this;
      vueInst.$q.dialog({
        title: 'Confirm',
        message: `Stergeti foaia de parcurs ${foaieParcurs.detalii}?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        ServiceFoiParcurs.deleteFoaieParcurs(foaieParcurs.appid).then(presponse=>{
          if(presponse.status==='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });

            vueInst.getFoiParcurs();
            //setTimeout(function(){ vueInst.closeWindow?.();}, 200);
          }
        });
      })
    },
    getFoaieParcursPDF(foaieParcurs: IFoaieParcurs) {
      const vueInst = this;         
      //if(vueInst.timeRequest.stare !== 'initiat'){
      vueInst.titleForPreviewForm = 'Foaie de parcurs';
      vueInst.downloadingFile = true;
      vueInst.showPreviewFile = true;
      
      let urlToGetFile = `${CONFIG_ENV.URL_FOI_PARCURS.downloadScanForPreviewFromApp}/${foaieParcurs.appid}`;

      fetch(urlToGetFile, { 
        headers: 
          // (['APP_DEV', 'APP_URBANESQ'].includes(APP_NAME)) ? 
          //   {
          //     odocsuserid: vueInst.userStore.User.userid.toString(),
          //     odocsusertoken: vueInst.userStore.User.csrf_token
          //   } : 
            {
              Odocsuserid: vueInst.userStore.User.userid.toString(),
              Odocsusertoken: vueInst.userStore.User.csrf_token
            }
       })
      .then(resp => resp.blob())
      .then(blob => {
        vueInst.urlBlobForPreviewFile = window.URL.createObjectURL(blob);
        vueInst.isPDFFileType = blob.type == 'application/pdf';

        if (vueInst.isPDFFileType) {
          if (this.$q.platform.is.mobile) {
            setTimeout(function () {
              vueInst.downloadingFile = false;
              const divPreview = window.document.getElementById('iframe_preview_scan') as HTMLIFrameElement
              if(divPreview){
                divPreview.src = '/' + 'pdf_viewer/web/viewer.html?file=' + vueInst.urlBlobForPreviewFile; 
              }
            }, 500);
          } else {// is desktop
            setTimeout(function () {
              vueInst.downloadingFile = false;
              const divPreview = window.document.getElementById('iframe_preview_scan') as HTMLIFrameElement;
              if(divPreview){
                divPreview.src = vueInst.urlBlobForPreviewFile; 
              }
            }, 500);
          }
        } else {
          blob.text().then(text => {
            Notify.create({
              color: 'red',
              textColor: 'white',
              type: 'negative',
              message: text,
              position: 'top',
              timeout: 3500,
              html: true
            });

            setTimeout(() => {
              vueInst.showPreviewFile = false;
            }, 1000);
          });
        }
      })
      .catch((err) => {
          Notify.create({
            color: 'red',
            textColor: 'white',
            type: 'negative',
            message: err.message ? 'Eroare! Fisierul nu poate fi descarcat! ' + err.message : 'Eroare! Fisierul nu poate fi descarcat!',
            position: 'top',
            timeout: 3500,
            html: true
          })
      });
    },
  },

  computed: {
    user(): IUser {
      return this.userStore.User;
    },

    optionsTari(): ITari[] {
      return this.tari;
    },
    
    optionsJudete(): IJudete[] {
      return this.judete;
    },

    optionsLocalitati(): ILocalitatiView[] {
      return this.localitati;
    },    

    optionsTipMijlTransport(): INomTipMijlocDeTransport[] {
      return this.nomTipMijlocDeTransport;
    },
    APP_NAME(): string{
      return APP_NAME;
    },
  },

  mounted() {
    const vueInst = this;
    vueInst.nomenclatoareStore.set_currentpagetitle('Ordin de deplasare');

    vueInst.getNomTipMijlocDeTransport();

    ServiceTari.getTari().then((presponse)=>{      
      if (presponse.status === 'success'){
        vueInst.tari = JSON.parse(presponse.rows) as ITari[];

        vueInst.tari.unshift({
          appid: 0,    
          id_tara: 0,
          cod_tara: '',
          denumire: ''
        });

        vueInst.onValueChangeTara(vueInst.deplasare.id_tara as number);

        vueInst.onValueChangeJudet(vueInst.deplasare.id_judet as number);
      }
    })
  },

  watch: {
    appid: {
      immediate: true,
      handler (newVal: number, oldVal: number) {        
        const vueInst = this;        
        // vueInst.scan_documents = [];
        vueInst.loadingData = true;
        if(newVal != 0 && vueInst.appid != 0) {                    
          vueInst.deplasare.appid = vueInst.appid;            
          vueInst.getDeplasare();    
        }
      }
    }
  }
});