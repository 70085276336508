<template>
    <div class="q-pa-sm">
        <q-spinner-gears
                v-if="loadingProiectData"
                color="primary"
                size="4em"
                class="absolute-center"
        />

        <div v-else>
           <div class="well well-sm">
             <div class="panel">
                <!-- <div style="width:65%;display: flex;flex-direction: column;"> -->
                <div style="display:flex; align-items:center;paddingLeft: 15px;paddingRight: 15px;paddingTop: 15px;">                        
                 <div style="width:70%;display: flex;flex-direction: row;">
                         <div>
                    <div class="row">
                        <div style="min-width: 90px; min-height: 90px;paddingRight: 5px;">
                                <img :src="getLinkToQrCode" />
                        </div>
                        <div style="display: flex;flex-direction: column">
                         <div v-bind:class="{ 'column': $q.platform.is.mobile, 'row': $q.platform.is.desktop }">
                                <span class="text__title--semismall_bold__black" style='paddingTop: 10px;'>PROIECT:</span>
                                <span class="text__title--semismall_bold__black" style='paddingTop: 10px;paddingLeft: 5px;'>{{ proiect.denumire }}</span>
                         </div>

                         <div v-bind:class="{ 'column': $q.platform.is.mobile, 'row': $q.platform.is.desktop }">
                                <span class="text__title--small_normal__black">ID {{ proiect.id_proiect }} / {{ proiect.track_date }}</span>                           
                         </div>

                         <div v-bind:class="{ 'column': $q.platform.is.mobile, 'row': $q.platform.is.desktop }">
                                <span class="text__title--small_normal">Start:</span>
                                <span class="text__title--small_bold__black" style='paddingLeft: 5px;'>{{ proiect.data_start }}</span>

                                <span class="text__title--small_normal" style='paddingLeft: 15px;'>End:</span>
                                <span class="text__title--small_bold__black" style='paddingLeft: 5px;'>{{ proiect.data_end }}</span>

                                <span class="text__title--small_normal" style='paddingLeft: 15px;'>Total zile:</span>
                                <span class="text__title--small_bold__black" style='paddingLeft: 5px;'>{{ proiect.durata }}</span>
                         </div>

                         <div v-bind:class="{ 'column': $q.platform.is.mobile, 'row': $q.platform.is.desktop }">
                                <span class="text__title--small_normal">Creat de:</span>
                                <span class="text__title--small_bold__black" style='paddingLeft: 5px;'>{{ proiect.denumire_user_created }}</span>

                                <span class="text__title--small_normal" style='paddingLeft: 15px;'>Manager Proiect:</span>
                                <span class="text__title--small_bold__black" style='paddingLeft: 5px;'>{{ proiect.responsabil }}</span>
                         </div>                                                                 
                       </div>                      
                     </div> 
                     <div class="row">
                        <div class="row" style="display:flex; paddingLeft: 15px;paddingRight: 15px;paddingBottom: 15px; max-width: 55vw;">                  
                         <div style="display: flex;flex-direction: column">
                           <div v-bind:class="{ 'column': $q.platform.is.mobile, 'row': $q.platform.is.desktop }">
                                <span class="text__title--normal__black">Descriere:&nbsp;</span>                           
                                <span class="text__title--normal">{{ proiect.descriere }}</span>                           
                           </div>
                         </div>
                         </div>  
                     </div>   
                         </div>          
                 </div>
                 <div style="width:25%;display: flex;flex-direction: row;">
                    <div>
                        <div class="row" style="display: flex;flex-direction: row;justify-content: center; align-items:center;"> 
                                <div v-bind:class="{ column: $q.platform.is.mobile, row: $q.platform.is.desktop }">
                                        <span class="text__title--normal__black">Stare:</span>
                                        <span v-bind:class="displayStatusColor(proiect.stare as string)" style="paddingLeft: 10px;font-family: Nunito Sans;font-size: 1rem;font-weight: bold;">{{ getDenStatus(proiect.stare as string) }}</span>
                                </div>                                
                        </div> 
                        <div class="row" style="display: flex;flex-direction: row;justify-content: center; align-items:center;"> 
                                <div v-bind:class="{ column: $q.platform.is.mobile, row: $q.platform.is.desktop }">
                                        <span class="text__title--normal__black">Progres:</span>
                                        <span v-bind:class="displayProgres(proiect.progres as number)" style="paddingLeft: 10px;font-family: 'Nunito Sans';font-size: 1rem;font-weight: bold;">{{ proiect.progres }}%</span>
                                </div>                                
                        </div> 
                        <div class="row">
                           <div style="display: flex;flex-direction: row;justify-content: center; align-items:center;">
                                <div style="display: flex;flex-direction: row">                        
                                        <div v-bind:class="{ column: $q.platform.is.mobile, row: $q.platform.is.desktop }">
                                        <div style="display: flex;align-items: center;justify-content: center; width: 100%;">
                                                <div>
                                                        <span class="text__title--normal">Documente proiect</span>
                                                        <div v-show="proiect.scan_documents != null && proiect.scan_documents.length==0 && !uploadingFileToServer" class="text__label--thin" style="width: 100%; text-align: center;">Nimic atasat</div>
                                                        <!-- <div v-show="proiect.scan_documents != null && proiect.scan_documents.length==0">Pentru a finaliza inregistrarea este necesara incarcarea fisierului cu documentul scanat.</div> -->
                                                </div>
                                                <div v-if="user.auth_for_upload_scan=='y' ">
                                                        <q-btn flat color="primary" icon="add_box" no-caps :disable="loadingProiectData" @click="openFormUploadFile" >
                                                        <q-tooltip
                                                                transition-show="rotate"
                                                                transition-hide="rotate"
                                                        >
                                                                Incarca document nou
                                                        </q-tooltip>
                                                        </q-btn>
                                                </div>
                                        </div>

                                        <div style="display: flex;align-items: center;justify-content: center;width: 100%;">
                                                <div>
                                                <q-spinner
                                                        v-if="uploadingFileToServer"
                                                        color="primary"
                                                        size="3em"
                                                        :thickness="10"
                                                />
                                                <table v-else class="table table-bordered table-condensed table-responsive" style="width: 100%; max-height: 50vh;">
                                                <tbody>
                                                        <tr v-for="scan_document in proiect.scan_documents" v-bind:key="scan_document.appid">
                                                        <td>
                                                                <!-- v-if="user.is_admin=='y'" -->
                                                                <span class="material-icons cursor-pointer" style="color: red; font-size: 1.3rem;" @click="askIfRemoveScan(scan_document)">delete_forever</span>
                                                        </td>
                                                        <td>
                                                                {{scan_document.file_ext}}
                                                        </td>
                                                        <td>
                                                                {{humanReadableBytes(scan_document.file_size)}}
                                                        </td>
                                                        <td>
                                                                <div class="desktop-only" style="max-width:30vw;">{{scan_document.description}}</div>
                                                                <div class="mobile-only">{{scan_document.description}}</div>
                                                        </td>
                                                        <td>
                                                                <q-btn round color="black" icon="cloud_download" size="xs"/>
                                                                <q-menu fit
                                                                transition-show="scale"
                                                                transition-hide="scale"
                                                                >
                                                                <DialogOpenScan :appid="scan_document.appid" type_scan="Proiecte"/>
                                                                </q-menu>
                                                        </td>
                                                        </tr>
                                                </tbody>
                                                </table>
                                                </div>
                                        </div>
                                        </div>                            
                                </div>                      
                             </div>
                        </div>
                     </div>
                 </div>                  
                </div>                 
             </div>

             <q-table
                        dense
                        :rows-per-page-options="[0]"
                        :rows="tableDataProiecteEtape"
                        :columns="proiecteEtapeColumns"
                        :visible-columns="visibleProiecteEtapeColumns"
                        row-key="appid"
                        :filter="filterEtape"
                        :loading="loadingProiectData"
                        :pagination.sync="myPagination"
                >
                        <template v-slot:top>
                                <q-btn flat color="primary" icon="add_box" no-caps size="lg" :disable="loadingProiectData" @click="addRowProiecteEtapa" >
                                        <q-tooltip
                                                transition-show="rotate"
                                                transition-hide="rotate"
                                        >
                                        Adauga etape proiect noua
                                        </q-tooltip>
                                </q-btn>
                                <span class="text__title--medium__black">Etape proiect</span>
                                <q-space />
                                <!--test-->
                                <q-input dense debounce="300" color="primary" v-model="filterEtape">
                                <template v-slot:append>
                                        <q-icon name="search" />
                                </template>
                                </q-input>
                        </template>

                        <template v-slot:body="props">
                                <q-tr :props="props">
                                <q-td key="id_proiect_etapa" :props="props">
                                        {{ props.row.id_proiect_etapa }}
                                        <q-btn color="accent" size="xs" round dense @click="props.expand = !props.expand" :icon="props.expand ? 'keyboard_arrow_down' : 'keyboard_arrow_right'" />
                                </q-td>
                                <q-td key="denumire" :props="props" :sortable="true" style="white-space:pre-wrap; word-wrap:break-word">
                                        {{ props.row.denumire }}
                                </q-td>
                                <q-td key="task" :props="props" :sortable="true">
                                        {{ props.row.task }}
                                </q-td> 
                                <q-td key="responsabil" :props="props" :sortable="true">
                                        {{ props.row.responsabil }}
                                </q-td>      
                                <q-td key="termen" :props="props" :sortable="true">                        
                                        {{ displayData(props.row.termen) }}
                                </q-td>                               
                                <q-td key="progres" :props="props" :sortable="true"
                                        v-bind:class="displayProgres(props.row.progres)">                        
                                        {{ props.row.progres }}%
                                </q-td>
                                <q-td key="stare" :props="props" :sortable="true"
                                        v-bind:class="displayStatusColor(props.row.stare)">                                                                        
                                        {{ getDenStatus(props.row.stare) }}
                                </q-td>
                                <q-td key="appid" :props="props">
                                        <q-icon name="edit" class="text-blue cursor-pointer" style="font-size: 1.2rem; margin-left: auto;margin-right:5px;" @click="askIfEditThisProiectEtapa(props.row)" />
                                        <q-icon name="add" class="text-blue cursor-pointer" style="font-size: 1.3rem; margin-left: auto;" @click="addRowProiecteTask(props.row)" />
                                </q-td>
                                </q-tr>

                                <q-tr v-show="props.expand" :props="props" class="q-virtual-scroll--with-prev">
                                        <q-td colspan="100%">
                                                <div class="q-pa-md">
                                                        <q-table
                                                                dense
                                                                :rows-per-page-options="[0]"
                                                                :rows="props.row.proiecteTaskuri?.[0]"
                                                                :columns="proiecteTaskuriColumns"
                                                                :visible-columns="visibleProiecteTaskuriColumns"
                                                                row-key="appid"
                                                                :filter="filterTaskuri"
                                                                :loading="loadingProiectData"
                                                                :pagination.sync="myPagination"                                                        
                                                        >                                                        
                                                                <template v-slot:body="props">
                                                                        <q-tr :props="props">
                                                                        <q-td key="id_proiect_task" :props="props">
                                                                                {{ props.row.id_proiect_task }}
                                                                        </q-td>                                                                
                                                                        <q-td key="denumire" :props="props" :sortable="true" style="white-space:pre-wrap; word-wrap:break-word">
                                                                                {{ props.row.denumire }}
                                                                        </q-td>
                                                                        <q-td key="responsabil" :props="props" :sortable="true">
                                                                                {{ props.row.responsabil }}
                                                                        </q-td>      
                                                                        <q-td key="termen" :props="props" :sortable="true">                        
                                                                                {{ displayData(props.row.termen) }}
                                                                        </q-td>
                                                                        <q-td key="stare" :props="props" :sortable="true"
                                                                                v-bind:class="displayStatusColor(props.row.stare)">                                                                        
                                                                                {{ getDenStatus(props.row.stare) }} 
                                                                        </q-td>
                                                                        <q-td key="progres" :props="props" :sortable="true"
                                                                                v-bind:class="displayProgres(props.row.progres)">                        
                                                                                {{ props.row.progres }}%
                                                                        </q-td>
                                                                        <q-td key="appid" :props="props">
                                                                                <q-icon name="edit" class="text-blue cursor-pointer" style="font-size: 1.0rem; margin-left: auto;" @click="askIfEditThisProiectTask(props.row)" />                                                                        
                                                                        </q-td>
                                                                        </q-tr>
                                                                </template>
                                                        </q-table>
                                                </div>
                                        </q-td>
                                </q-tr>                      
                        </template>
                </q-table>

                <q-dialog v-model="visibleDialogEditProiectEtapa" persistent >
                        <q-card style="min-width: 60vw; max-width: 80vw;">
                                <q-bar>
                                <q-icon name="add_box" />
                                <div>Editare proiect etapa</div>

                                <q-space />

                                <q-btn dense flat icon="close" v-close-popup @click="refreshDataTableProiecteEtape">
                                        <q-tooltip>Close</q-tooltip>
                                </q-btn>
                                </q-bar>

                                <q-card-section>
                                <EditProiectEtapa :foncancel="renuntEditareProiecteEtapa" :fonsave="refreshDataTableProiecteEtape" :appid="appidProiectEtapa" :currentRowView="proiect" />
                                </q-card-section>
                        </q-card>
                </q-dialog>

                <q-dialog v-model="visibleDialogEditProiectTask" persistent >
                        <q-card style="min-width: 60vw; max-width: 80vw;">
                                <q-bar>
                                <q-icon name="add_box" />
                                <div>Editare proiect task</div>

                                <q-space />

                                <q-btn dense flat icon="close" v-close-popup @click="refreshDataTableProiecteTaskuri">
                                        <q-tooltip>Close</q-tooltip>
                                </q-btn>
                                </q-bar>

                                <q-card-section>
                                <EditProiectTask :foncancel="renuntEditareProiecteTask" :fonsave="refreshDataTableProiecteTaskuri" :appid="appidProiectTask" :currentRowViewProiect="proiect" :currentRowViewProiectEtapa="proiectEtapa" />
                                </q-card-section>
                        </q-card>
                </q-dialog>
           </div>
        </div>

        <q-dialog v-model="viewDialogSelectPersoana" position="bottom">
                <q-card style="min-width: 70vw;"
                        v-bind:class="{ 'partial80_height': $q.platform.is.mobile, 'partial60_height': $q.platform.is.desktop }"
                >
                <div style="display:flex;justify-content: flex-end;">
                        <span class="material-icons cursor-pointer" @click="viewDialogSelectPersoana=false" style="color:#747474;font-weight: bolder;font-size: 1.5em;">close</span>
                </div>

                <q-card-section>
                        <ComboUser :selectHandler="selectPersoana" title="Selectati persoana responsabil" />
                </q-card-section>
                </q-card>
        </q-dialog>

        <q-dialog v-model="dialogUploadFile" position="top">
            <q-card style="width: 350px">
                <q-linear-progress :value="0.6" color="pink" />

                <q-card-section class="column no-wrap">
                    <div class="text-weight-bold">Incarca fisier nou</div>
                    <div class="text-grey" style="margin-top: 4px; margin-bottom: 10px;"><q-input counter outlined v-model="uploadScanTitle" label="Titlu document (minim 4 caractere)" dense maxlength="200" /></div>
                    <input v-show="uploadScanTitle.length>3" type="file" name="fileToUpload" id="fileToUpload" ref="myFileToUpload" @change="prepareForUpload($event)" style="margin-bottom: 10px;" />
                    <q-btn :disable="!(uploadScanTitle.length>3)" v-show="checkIfFileIsSelected" icon="cloud_upload" color="white" text-color="black" label="Salveaza pe server" @click="uploadFile()" :loading="uploadingFileToServer" style="margin-bottom: 5px;" />
                </q-card-section>
            </q-card>
        </q-dialog>
    </div>
</template>

<script lang="ts" src="./EditProiectDetail.ts" />

<style scoped>
    .q-field--with-bottom {
        padding-bottom: 20px;
    }

    .q-field__native{
        font-family: "Nunito Sans";
        font-weight: bolder;
    }
</style>