import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import IUser from '@/types/IUser';
import ICompanyDrept from '@/types/ICompanyDrept';

interface TAjaxResponseGetUser {
  status: string;
  message: string;
  user: IUser
}

interface TAjaxResponseUser {
  status: string;
  message: string;
}
export class ServiceUser {

  public static async loginUser(puserId: string, password: string): Promise<TAjaxResponseGetUser> {
    const userLocale=localStorage.getItem('userlocale');
    const params = new URLSearchParams();
    params.set('user', puserId);
    params.set('password', password);
    params.set('userLocale', userLocale as string);
    const response = await axios.post(`${CONFIG_ENV.URL_USER.login}`,params);
    return response.data;
  }

  public static async loginUserWithGoogle(pEmail: string, token: string): Promise<TAjaxResponseGetUser> {
    const userLocale=localStorage.getItem('userlocale');
    const params = new URLSearchParams();
    params.set('email', pEmail);
    params.set('token', token);
    params.set('userLocale', userLocale as string);
    const response = await axios.post(`${CONFIG_ENV.URL_USER.login_google}`,params);
    return response.data;
  }

  public static async checkToken(): Promise<TAjaxResponseGetUser> {
    const userToken=localStorage.getItem('token');
    const userId=localStorage.getItem('userid');
    const userLocale=localStorage.getItem('userlocale');
    const params = new URLSearchParams();
    params.set('userToken', userToken as string);
    params.set('userId', userId as string);
    params.set('userLocale', userLocale as string);
    const response = await axios.post(`${CONFIG_ENV.URL_USER.checkToken}`,params);
    return response.data;
  }

  public static async logOut(puserId: string): Promise<TAjaxResponseUser> {
    const params = new URLSearchParams();
    params.set('user', puserId);
    const response = await axios.post(`${CONFIG_ENV.URL_USER.logOut}`,params);
    return response.data;
  }
}
