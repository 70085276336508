import {defineAsyncComponent, defineComponent, inject, ref} from 'vue';
import User from '@/store/User';
import Nomenclatoare from '@/store/Nomenclatoare';
import {ServiceTimesRequests} from '@/modules/ServiceTimesRequests';
import {humanReadableBytes} from '@/modules/Utils';
import {getModule} from 'vuex-module-decorators';
import {CONFIG_ENV, APP_NAME} from '@/config';
import {FileUploadService} from "v-file-upload";
import { ServiceNomFlux } from '@/modules/ServiceNomFlux';
import ICreateTask from '@/types/ICreateTask';
import moment from 'moment';
import {dateToStringDDMMYYYY} from '@/modules/Utils';
import IScanTimeRequest from '@/types/IScanTimeRequest';
import IUser from '@/types/IUser';
import LoadingComponent from '@/components/LoadingComponent.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';
import Notify from 'quasar/src/plugins/Notify.js';;
import IConcediu from '@/types/IConcediu';
import INomConcedii from '@/types/INomConcedii';
import { ServiceNomConcedii } from '@/modules/ServiceNomConcedii';
import IPersoana from '@/types/IPersoana';

const qDateProxy1 = ref(null);
const qDateProxy2 = ref(null);
const myForm = ref({} as any);

// type TScan={
//     fileTitle: string,
//     file: any,
//     fileTmpId: number
// }

const DialogOpenScan = defineAsyncComponent({
  loader: () => import('@/components/DialogOpenScan/DialogOpenScan.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

export default defineComponent({
    name: 'EditTimeRequest',

    components: {
      DialogOpenScan
    },

    props: {
      appid: {
        type: Number,
        required: true,
        default: 0,
        readonly: false
      },
      saveNewRequest: {
        type: Function,
        default: undefined
      },
      cancelNewRequest: {
        type: Function,
        default: undefined
      },
      is_all: {
        type: Number,
        required: true,
        default: 0,
        readonly: true
      }
    },

    setup() { 
      const emitter = inject("emitter") as any;        
      return {
        emitter,
        qDateProxy1,
        qDateProxy2,
        myForm
      };
    },

    data() {
      return {
        seCalculeazaNrZileLucratoare: false,
        myLocale: CONFIG_ENV.myLocale,
        timeRequest: {
          appid: 0,
          time_code: '',
          tip_concediu: '',
          marca: 0,
          an: 0,
          start_date: '',
          end_date: '',
          nr_working_days: 0,
          track_creation_date: '',
          track_date_status: '',
          observatie: '',
          stare: '',
          first_name: '',
          last_name: '',
          nr_zile_drept: 0,
          org_unit_id: 0,
          organization_unit_descriere: '',
          recall_date: null,
          track_user_recall: 0,
          description_recall: '',
          nr_recall_days: 0,
          scansTimeRequests: [] as IScanTimeRequest[],
          appid_nomconcediu: null,
          nume_prenume_rechemat: '',
          appid_document: null,
          job_id: '',
          job_title: '',
          flag: 0,
          is_recall_date: false,
          is_reschedule_date: false,
          nr_days_recalculated: 0
        } as IConcediu,
        loadingData: false,
        dialogUploadFile: false,
        filesSelectedForUpload: [] as any,
        uploadScanTitle: '',
        scans: [] as IScanTimeRequest[],
        userStore: getModule(User),
        nomenclatoareStore: getModule(Nomenclatoare),
        urlForUpload: CONFIG_ENV.URL_TIME_REQUESTS_SCAN.uploadScan,
        headers: {},        
        uploadingFileToServer: false,            
        loadingPdfForDownload: false,
        nomConcedii: [] as INomConcedii[],
        isClosed: false,
        showPreviewFile: false,
        titleForPreviewForm: '',
        downloadingFile: false,
        urlBlobForPreviewFile: '',
        confirmVizualizareCererea: false,
        persoane: [] as IPersoana[],
        selectedNomConcediiParent: null as number|null,
        selectedNomConcediiChild: null as number|null,
        nomConcediiChild: [] as INomConcedii[],
        isVisibleNomConcediiChild: false,
        stareFlagPlanAnualRegular: false,
        isVisibleFlagPlanAnualRegular: false,
        isAllowedPlanAnual: false,
        isPDFFileType: false
      }
    },

    methods: {
      calculateNrOfWorkingDays(){
        const vueInst=this;
        vueInst.seCalculeazaNrZileLucratoare = true;
        let findNomConcedii = null as INomConcedii|null;

        if (vueInst.selectedNomConcediiParent != null && vueInst.selectedNomConcediiChild == null) {
          findNomConcedii = vueInst.nomConcedii.filter(f => f.appid == vueInst.selectedNomConcediiParent)[0] as INomConcedii;
        } else if (vueInst.selectedNomConcediiParent != null && vueInst.selectedNomConcediiChild != null) {
          findNomConcedii = vueInst.nomConcedii.filter(f => f.appid == vueInst.selectedNomConcediiChild)[0] as INomConcedii;
        }

        if(vueInst.timeRequest.start_date && vueInst.timeRequest.end_date){
          ServiceTimesRequests.getWorkingDays(vueInst.timeRequest.start_date, vueInst.timeRequest.end_date, findNomConcedii?.time_code as string).then((presponse)=>{
            vueInst.seCalculeazaNrZileLucratoare=false;
            if(presponse.status==='success'){
              //console.log(presponse.nrZileLucratoare);
              vueInst.timeRequest.nr_working_days = presponse.nrZileLucratoare;
            }
          });
        }
      },

      prepareForUpload(event:any){
        this.filesSelectedForUpload = event.target.files[0];
      },
  
      humanReadableBytes(nrPfBytes:number): string{
        return humanReadableBytes(nrPfBytes);
      },

      onSave() {
        const vueInst=this;
        vueInst.myForm.validate().then((success: any) => {
          if (success) {            
            if ((vueInst.selectedNomConcediiParent == null && vueInst.selectedNomConcediiChild == null) || 
              (vueInst.selectedNomConcediiParent == null && vueInst.selectedNomConcediiChild != null)) {
              vueInst.$q.notify({
                color: 'red',
                textColor: 'white',
                type: 'negative',
                message: "Trebuie completati tipul de cerere sau tipul de concediu!",
                position: 'top',
                timeout: 1500,
                html: true
              });
            } else {
              vueInst.loadingData = true;
              const year = new Date().getFullYear();
              let findNomConcedii = null as INomConcedii|null;

              if (vueInst.selectedNomConcediiParent != null && vueInst.selectedNomConcediiChild == null) {
                findNomConcedii = vueInst.nomConcedii.filter(f => f.appid == vueInst.selectedNomConcediiParent)[0] as INomConcedii;
              } else if (vueInst.selectedNomConcediiParent != null && vueInst.selectedNomConcediiChild != null) {
                findNomConcedii = vueInst.nomConcedii.filter(f => f.appid == vueInst.selectedNomConcediiChild)[0] as INomConcedii;
              }

              vueInst.timeRequest.flag = vueInst.stareFlagPlanAnualRegular ? (vueInst.timeRequest.flag | 1) : (vueInst.timeRequest.flag & ~1); //scoatem | adaugam bitul stare plan anual sau plan regular

              ServiceTimesRequests.postRequest(vueInst.timeRequest.appid, year, vueInst.timeRequest.start_date, vueInst.timeRequest.end_date, Number(findNomConcedii?.appid), findNomConcedii?.time_code as string, vueInst.timeRequest.marca, vueInst.timeRequest.flag, vueInst.timeRequest.observatie).then((presponse)=>{
                vueInst.loadingData=false;
                if(presponse.status==='success'){
                  // const appidTimeRequest=presponse.appid;
                  // const arrPromiseUploadFiles=[] as Array<Promise<string>>;
                  vueInst.$q.notify({
                    color: 'teal',
                    textColor: 'white',
                    type: 'positive',
                    message: presponse.message,
                    position: 'top',
                    timeout: 1500,
                    html: true
                  });

                  if (vueInst.timeRequest.appid == 0){
                    vueInst.timeRequest.appid = presponse.appid;
                    vueInst.getTimeRequest();
                  } else {
                    vueInst.isClosed = true;
                    vueInst.saveNewRequest?.();
                  }
                }
              });
            }
          }
        })
      },

      onCancel() { 
        const vueInst = this;  
        vueInst.isClosed = true;
        vueInst.cancelNewRequest?.();        
      },

      onSendApproved() {
        const vueInst = this;         
        if(vueInst.timeRequest.stare === 'initiat'){
          const createTask = {
            appid: vueInst.timeRequest.appid,  
            flux_type: 'Concediu',
            entity_type: 'CONCEDIU',
            appid_nom_tip_entitate: vueInst.timeRequest.appid_nomconcediu,
            appid_nom_tip_entitate_other: null
          } as ICreateTask;
          
          ServiceNomFlux.postCreateTask(createTask).then(presponse=>{
            if(presponse.status=='success'){
              vueInst.$q.notify({
                color: 'teal',
                textColor: 'white',
                type: 'positive',
                message: presponse.message,
                position: 'top',
                timeout: 1000,
                html: true
              });
              // vueInst.isClosed = true;
              // vueInst.saveNewRequest?.();
              vueInst.confirmVizualizareCererea = true;
            }
          });          
        }            
      },

      onPreviewCerere() {
        const vueInst = this;

        vueInst.titleForPreviewForm = 'Vizualizare';
        vueInst.downloadingFile = true;
        vueInst.showPreviewFile = true;
        
        let urlToGetFile = `${CONFIG_ENV.URL_TIME_REQUESTS.downloadScanForPreviewFromAppCO}/${vueInst.timeRequest.appid}`;        

        fetch(urlToGetFile, { 
          headers: 
            (['APP_DEV', 'APP_URBANESQ'].includes(APP_NAME)) ? 
              {
                odocsuserid: vueInst.userStore.User.userid.toString(),
                odocsusertoken: vueInst.userStore.User.csrf_token
              } : 
              {
                Odocsuserid: vueInst.userStore.User.userid.toString(),
                Odocsusertoken: vueInst.userStore.User.csrf_token
              }
         })
        .then(resp => resp.blob())
        .then(blob => {
          vueInst.urlBlobForPreviewFile = window.URL.createObjectURL(blob);
          vueInst.isPDFFileType = blob.type == 'application/pdf';

          if (vueInst.isPDFFileType) {
            if (this.$q.platform.is.mobile) {
              setTimeout(function () {
                vueInst.downloadingFile = false;
                const divPreview = window.document.getElementById('iframe_preview_scan') as HTMLIFrameElement
                if(divPreview){
                  divPreview.src = '/' + 'pdf_viewer/web/viewer.html?file=' + vueInst.urlBlobForPreviewFile; 
                }
              }, 500);
            } else {// is desktop
              setTimeout(function () {
                vueInst.downloadingFile = false;
                const divPreview = window.document.getElementById('iframe_preview_scan') as HTMLIFrameElement;
                if(divPreview){
                  divPreview.src = vueInst.urlBlobForPreviewFile; 
                }
              }, 500);
            }
          } else {
            blob.text().then(text => {
              Notify.create({
                color: 'red',
                textColor: 'white',
                type: 'negative',
                message: text,
                position: 'top',
                timeout: 3500,
                html: true
              });
  
              setTimeout(() => {
                vueInst.showPreviewFile = false;
              }, 1000);
            });
          }
        })
        .catch((err) => {
            Notify.create({
              color: 'red',
              textColor: 'white',
              type: 'negative',
              message: err.message ? 'Eroare! Fisierul nu poate fi descarcat! ' + err.message : 'Eroare! Fisierul nu poate fi descarcat!',
              position: 'top',
              timeout: 3500,
              html: true
            })
        });
      },

      onSaveCerere() {
        const vueInst = this;

        vueInst.confirmVizualizareCererea = false;
        vueInst.isClosed = true;

        vueInst.saveNewRequest?.();
        
        let filename = '';

        fetch(`${CONFIG_ENV.URL_TIME_REQUESTS.downloadScanFromApp}/${vueInst.timeRequest.appid}`, { 
          headers: 
            (['APP_DEV', 'APP_URBANESQ'].includes(APP_NAME)) ? 
              {
                odocsuserid: vueInst.userStore.User.userid.toString(),
                odocsusertoken: vueInst.userStore.User.csrf_token
              } : 
              {
                Odocsuserid: vueInst.userStore.User.userid.toString(),
                Odocsusertoken: vueInst.userStore.User.csrf_token
              }
         })
        .then(resp => {
          const header = resp.headers.get('Content-Disposition') as string;
          if (header != null) {
            filename = header.split(';').find(n => n.includes('filename='))?.replace('filename=', '').replaceAll("'", "").replaceAll('"', '').trim() as string;
            // console.log(header);
          }
          return resp.blob();})
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          // the filename you want
          a.download = filename !== '' ? filename : vueInst.timeRequest.tip_concediu + ".pdf";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          Notify.create({
            color: 'teal',
            textColor: 'white',
            type: 'positive',
            message: 'Fisierul a fost descarcat!',
            position: 'top',
            timeout: 500,
            html: true
          })
        })
        .catch((err) => {
          Notify.create({
            color: 'red',
            textColor: 'white',
            type: 'negative',
            message: err.message ? 'Eroare! Fisierul nu poate fi descarcat! ' + err.message : 'Eroare! Fisierul nu poate fi descarcat!',
            position: 'top',
            timeout: 3500,
            html: true
          })
        });
      },

      onCancelPreview() {
        const vueInst = this;
        vueInst.confirmVizualizareCererea = false;
        vueInst.isClosed = true;
        vueInst.saveNewRequest?.();
      },

      displayData(pdata:string) :string{
        if(pdata){        
          const day = moment(pdata.substring(0,10), 'YYYY-MM-DD');
          return dateToStringDDMMYYYY(day);
        }else{ 
          return '';
        }
      },

      getScanDocuments(){
        const vueInst = this;
        vueInst.uploadingFileToServer = true;
        ServiceTimesRequests.getScans(Number(vueInst.timeRequest.appid)).then((presponse)=>{
          vueInst.loadingData = false;
          vueInst.uploadingFileToServer = false;
          if(presponse.status==='success'){
            vueInst.scans = JSON.parse(JSON.stringify(presponse.rows));            
          }
        });
      },

      getTimeRequest() {
        const vueInst = this;
        vueInst.loadingData=true;
        ServiceTimesRequests.getTimeRequest(vueInst.timeRequest.appid).then((presponse)=>{
          vueInst.loadingData=false;
          if(presponse.status==='success'){
            vueInst.timeRequest = presponse.timeRequest;
            //vueInst.startDate=presponse.timeRequest.startDate;
            //vueInst.endDate=presponse.timeRequest.endDate;                    
            vueInst.timeRequest.start_date = vueInst.timeRequest.start_date == null ? '' : vueInst.displayData(vueInst.timeRequest.start_date);
            vueInst.timeRequest.end_date = vueInst.timeRequest.end_date == null ? '' : vueInst.displayData(vueInst.timeRequest.end_date);
            vueInst.timeRequest.time_code = vueInst.timeRequest.time_code;
            vueInst.timeRequest.observatie = vueInst.timeRequest.observatie;                                 
            vueInst.timeRequest.stare = vueInst.timeRequest.stare;
            vueInst.timeRequest.nr_working_days = vueInst.timeRequest.nr_working_days;
            vueInst.stareFlagPlanAnualRegular = vueInst.timeRequest.time_code == 'co' ? (vueInst.timeRequest.flag & 1) != 0 : false; //scoatem | adaugam bitul stare plan anual sau plan regular numai pentru tip concediu de odihna este valabil

            const findNomConcedii = vueInst.nomConcedii.filter(f => f.appid == vueInst.timeRequest.appid_nomconcediu)[0] as INomConcedii;
            
            if (findNomConcedii != undefined && findNomConcedii.appid != null && findNomConcedii.parent_appid == null) {
              vueInst.selectedNomConcediiParent = findNomConcedii.appid;
              vueInst.onValueChangeTimeRequestParent(vueInst.selectedNomConcediiParent);              
            } else if (findNomConcedii != undefined && findNomConcedii.appid != null && findNomConcedii.parent_appid != null) {
              vueInst.selectedNomConcediiParent = findNomConcedii.parent_appid;
              vueInst.onValueChangeTimeRequestParent(vueInst.selectedNomConcediiParent);
              vueInst.selectedNomConcediiChild = findNomConcedii.appid;
            }
          }
        });
      },

      checkIfFileIsSelected():boolean{
        return this.filesSelectedForUpload != undefined && this.filesSelectedForUpload.length != 0;
      },

      onProgress(event:any) {
        // Handdle the progress
      },

      uploadFile() {
        const vueInst = this;        
        
        if (vueInst.uploadScanTitle.length == 0) {
          Notify.create({
            color: 'orange',
            textColor: 'white',
            type: 'warning',
            message: 'Trebuie sa completati titlu documentului!',
            position: 'top',
            timeout: 3500,
            html: true
          });
        } else if (vueInst.checkIfFileIsSelected()) {
          vueInst.uploadingFileToServer = true;
        
          const fileUpload = new FileUploadService(
            this.urlForUpload,
            this.headers,
            this.onProgress
          );
          
          fileUpload.upload(vueInst.filesSelectedForUpload as File, { appidTimeRequest: vueInst.timeRequest.appid, description: vueInst.uploadScanTitle, userid: vueInst.user.userid, token: vueInst.user.csrf_token})
            .then((e: any) => {
              vueInst.dialogUploadFile = false;          
              vueInst.getScanDocuments();
              if(e.target && e.target.response.status && e.target.response.status=='error'){
                Notify.create({
                  color: 'red',
                  textColor: 'white',
                  type: 'negative',
                  message: e.target.response.message,
                  position: 'top',
                  timeout: 3500,
                  html: true
                })
              }else{
                vueInst.$q.notify({
                  color: 'teal',
                  textColor: 'white',
                  type: 'positive',
                  message: e.target.response.message,
                  position: 'top',
                  timeout: 1000,
                  html: true
                });
                
                ServiceTimesRequests.getScans(Number(vueInst.timeRequest.appid)).then(
                  presponse=>{
                    vueInst.scans = JSON.parse(JSON.stringify(presponse.rows))                
                  }
                )
              }
            })
            .catch((e: any) => {
            })
        } else {
          Notify.create({
            color: 'orange',
            textColor: 'white',
            type: 'warning',
            message: 'Trebuie sa selectati fisierul!',
            position: 'top',
            timeout: 3500,
            html: true
          });
        }
      },

      askIfRemoveScan(pscan:IScanTimeRequest){
        const vueInst = this;
        vueInst.$q.dialog({
          title: 'Confirm',
          message: `Stergeti fisierul reprezentand ${pscan.description}?`,
          cancel: true,
          persistent: true,
          html: true
        }).onOk(() => {
          ServiceTimesRequests.deleteScan(pscan.appid, { userid: vueInst.user.userid, token: vueInst.user.csrf_token }).then(presponse=>{
            if(presponse.status==='success'){
              vueInst.$q.notify({
                color: 'teal',
                textColor: 'white',
                type: 'positive',
                message: presponse.message,
                position: 'top',
                timeout: 1000,
                html: true
              });
    
              this.getScanDocuments();
            }
          });
        })
      },

      openFormUploadFile() {
        this.filesSelectedForUpload = [];
        this.uploadScanTitle = '';
        this.dialogUploadFile = true;      
      },

      onChangeStartDate() {
        const vueInst = this;        
        // @ts-ignore
        vueInst.$refs.qDateProxy1.hide();
        if (vueInst.timeRequest.start_date !== '') {
          const date = new Date(moment(moment(vueInst.timeRequest.start_date, 'DD-MM-YYYY')).format('YYYY-MM-DD'));          
          date.setDate(date.getDate() + 1);
          // console.log(moment(date).format('YYYY-MM-DD'), vueInst.timeRequest.start_date);
          vueInst.timeRequest.end_date = vueInst.displayData(moment(date).format('YYYY-MM-DD'));
        }
        vueInst.calculateNrOfWorkingDays();
      },

      onChangeEndDate() {
        const vueInst = this;        
        // @ts-ignore
        vueInst.$refs.qDateProxy2.hide();
        vueInst.calculateNrOfWorkingDays();
      },

      filterPersoane(value: string, update: Function) {                
        if (value === '' || value === null) {
          update(() => {
            this.persoane = this.nomenclatoareStore.NomPersoaneUtilizatoare;
          });
        } else {
          update(() => {
            const valueLowerCase = value.toLowerCase();          
            this.persoane = this.nomenclatoareStore.NomPersoaneUtilizatoare.filter(f => f.first_name?.toString().toLowerCase().startsWith(valueLowerCase) || f.last_name?.toString().toLowerCase().startsWith(valueLowerCase));
          }); 
        }
      },

      onValueChangeTimeRequestParent(appid: number) {
        const vueInst = this;
        vueInst.selectedNomConcediiChild = null;
        vueInst.nomConcediiChild = vueInst.nomConcedii.filter(f => f.parent_appid != null && f.parent_appid == appid);
        vueInst.isVisibleNomConcediiChild = vueInst.nomConcediiChild.length > 0;
        vueInst.isVisibleFlagPlanAnualRegular = vueInst.nomConcedii.find(f => f.appid == appid)?.time_code == 'co';
        if (!this.isVisibleFlagPlanAnualRegular) {
          vueInst.stareFlagPlanAnualRegular = false;
        }
        vueInst.isAllowedPlanAnual = vueInst.nomConcedii.find(f => f.appid == appid && f.time_code == 'co')?.is_allowed_plan_anual == true;
      },

      onValueChangeTimeRequestStatusPlanRegularOrAnual() {
        const vueInst = this;
        if (vueInst.timeRequest.appid != 0) {
          let origFlag = vueInst.timeRequest.flag;
          let flag = vueInst.stareFlagPlanAnualRegular ? (vueInst.timeRequest.flag | 1) : (vueInst.timeRequest.flag & ~1); //scoatem | adaugam bitul stare plan anual sau plan regular
          vueInst.loadingData=true;
          ServiceTimesRequests.updateStatusPlanReqularAnual(vueInst.timeRequest.appid, vueInst.timeRequest.marca, flag).then(presponse => {
            vueInst.loadingData=false;
            if (presponse.status == 'success') {
              vueInst.$q.notify({
                color: 'teal',
                textColor: 'white',
                type: 'positive',
                message: presponse.message,
                position: 'top',
                timeout: 1000,
                html: true
              });              
            } else {
              vueInst.timeRequest.flag = origFlag;
              vueInst.stareFlagPlanAnualRegular = vueInst.timeRequest.time_code == 'co' ? (vueInst.timeRequest.flag & 1) != 0 : false;
            }
          });
        }
      }
    },

    computed: {
      APP_NAME(): string{
        return APP_NAME;
      },
         
      user(): IUser {
        return this.userStore.User;
      },

      optionsTimeRequestParent():INomConcedii[]{
        return this.nomConcedii.filter(f => f.parent_appid == null);
      },

      optionsTimeRequestChild():INomConcedii[]{
        return this.nomConcediiChild;
      },

      requestIsFilled():boolean{
        const vueInst = this;        
        if (Object.keys(vueInst.timeRequest).length == 0) {
          return false;
        } else {
          return vueInst.timeRequest.start_date?.length > 0 && vueInst.timeRequest.end_date?.length > 0 && vueInst.selectedNomConcediiParent != null;
          // && vueInst.timeRequest.time_code.length>0;
        }
      },

      SePoateAfisaNrZileLucratoare():boolean{
        const vueInst = this;
        if (Object.keys(vueInst.timeRequest).length == 0) {
          return false;
        } else {
          return (vueInst.timeRequest.start_date.length==10 && vueInst.timeRequest.end_date.length==10 && vueInst.timeRequest.nr_working_days>0);
        }
      },
      
      optionsPersoane(): IPersoana[] {
        return this.persoane;
      }
    },

    mounted() {
      const vueInst = this;
      vueInst.nomenclatoareStore.set_currentpagetitle(vueInst.is_all == 1 ? 'Cererile de concediu' : 'Cererile mele de concediu');
      vueInst.persoane = vueInst.nomenclatoareStore.NomPersoaneUtilizatoare;
      vueInst.selectedNomConcediiParent = null;
      vueInst.selectedNomConcediiChild = null;
      
      ServiceNomConcedii.getNomConcedii().then((presponse)=>{      
        if (presponse.status === 'success'){
          vueInst.nomConcedii = JSON.parse(presponse.rows) as INomConcedii[];
        }
      });

      // console.log(vueInst.appid);
      if (vueInst.appid != 0) {        
        vueInst.timeRequest.appid = vueInst.appid;          
        vueInst.getTimeRequest();
        vueInst.getScanDocuments();
      } else {
        vueInst.timeRequest.marca = vueInst.userStore.User.userid;
        vueInst.timeRequest.start_date = '';
        vueInst.timeRequest.end_date = '';        
        vueInst.timeRequest.time_code = '';
        vueInst.timeRequest.observatie = ''; 
        vueInst.timeRequest.stare = '';       
        vueInst.timeRequest.nr_working_days = 0;
      }        
    },

    unmounted() {
      const vueInst = this;
      if (!vueInst.isClosed) {
        vueInst.cancelNewRequest?.();
      }
    }
});
