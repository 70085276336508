<template>
  <div>
    <div style="display: flex;justify-content: center;">
      <div style="display: flex;justify-content: center;min-width:90%;"><span style="font-weight: bolder; fontSize:14px;"></span></div>
      <!--
      <div style="margin-left: auto; padding: 10px;">
        <q-btn
            padding="none"
            color="primary"
            icon="add"
            @click="onOpenFormNewRequest"
        >
          <q-tooltip
              transition-show="rotate"
              transition-hide="rotate"
          >
            Creaza o cerere noua
          </q-tooltip>
        </q-btn>
      </div>
      -->
    </div>
    <div>
      <q-linear-progress v-show="loadingData" indeterminate color="warning" class="q-mt-sm" />
      <div style="display: flex;justify-content: flex-end;">
        <div class="text-subtitle2 card_balanta" v-for="(timeAccount,index) in timeAccounts" v-bind:key="index">
          <div><b>Balanta {{timeAccount.timeName}} {{timeAccount.year}}</b></div>
          <div>Drept: {{timeAccount.nrDaysPerYear}}, Planificat: {{timeAccount.nrDaysPlanned}}, Aprobat: {{timeAccount.nrDaysApproved}}, Consumat: {{timeAccount.nrDaysConsumed}}, Rest zile: {{timeAccount.nrDaysAvailable}}</div>
        </div>
      </div>
      <div v-if="!loadingData && timeAccounts.length == 0" class="bg-red-2">Nu exista balante de timp definite pentru aceasta persoana!</div>
    </div>
  </div>
</template>

<script lang="ts" src="./HeaderTimeAccountsMarca.ts" />

<style scoped>
  .card_balanta{
    border: 1px solid #dddddd;
    border-radius: 3px;
    margin: 5px;
    padding: 3px;
    display: flex;
    flex-direction: column;
}
</style>
