import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import ICereri from '@/types/ICereri';
import IScanCereri from '@/types/IScanCereri';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type ICereriNull = OrNull<ICereri>;

interface TAjaxResponseCereri {
  status: string;
  message: string;
  rows: string;
}

interface TAjaxResponseEditCerere {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetCerere {
  status: string;
  message: string;
  cerere: ICereriNull;
}

interface TAjaxResponseGetScansCereri {
  status: string;
  message: string;
  nr_of_records: number,
  rows: IScanCereri[];
}

interface TAjaxResponseGetInfoScanCereri {
  status: string;
  message: string;
  scan: IScanCereri;
}

interface TAjaxSimpleResponse {
  status: string;
  message: string;
}

export class ServiceCereri {
  public static async getCereri(is_all: number): Promise<TAjaxResponseCereri> {
    const response = await axios.get(`${CONFIG_ENV.URL_CERERI.cereri}/${is_all}`);
    return response.data;
  }

  // public static async deleteCerere(appid: number): Promise<TAjaxResponseEditCerere> {    
  //   const response = await axios.delete(`${CONFIG_ENV.URL_CERERI.cerere}/${appid}`);
  //   return response.data;
  // }

  public static async postCerere(cerere: ICereri): Promise<TAjaxResponseEditCerere> {
    const response = await axios.post(`${CONFIG_ENV.URL_CERERI.cerere}`, cerere);
    return response.data;
  }

  public static async getCerere(appid: number): Promise<TAjaxResponseGetCerere> {
    const response = await axios.get(`${CONFIG_ENV.URL_CERERI.cerere}/${appid}`);
    return response.data;
  }

  public static async getScansCereri(appid:number): Promise<TAjaxResponseGetScansCereri> {
    const response = await axios.get(`${CONFIG_ENV.URL_CERERE_SCAN.getScansCereri}/${appid}`);
    return response.data;
  }

  public static async getInfoScan(appid:number): Promise<TAjaxResponseGetInfoScanCereri> {
    const response = await axios.get(`${CONFIG_ENV.URL_CERERE_SCAN.getInfoScan}/${appid}`);
    return response.data;
  }

  public static async deleteScan(appid:number, headers: any): Promise<TAjaxSimpleResponse> {
    const response = await axios.delete(`${CONFIG_ENV.URL_CERERE_SCAN.getInfoScan}/${appid}`, { headers });
    return response.data;
  }
}