import store from './index';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import IEventSourceNrBadge from '@/types/IEventSourceNrBadge';

@Module({ namespaced: true, dynamic: true, store, name: 'eventSourceNrBadgeStore'})
export default class EventSourceNrBadge extends VuexModule {
  public EventSourceNrBadge: IEventSourceNrBadge = {
    nrOfMyDocuments: 0,
    nrOfPendingToRegister: 0,
    nrDocumenteDeRepartizate: 0,
    nrAllAllocatedDocs: 0,
    nrOfTask: 0,
    nrOfTaskIstoric: 0,
    nrDocumenteNeFinalizate: 0,
    nrProjectTask: 0,
    nrAssetEvenimente: 0
  };

  @Mutation
  public SET_EVENTSOURCENRBADGE(pEventSourceNrBadge: IEventSourceNrBadge) {
    this.EventSourceNrBadge = pEventSourceNrBadge;
  }

  @Action
  public set_eventsourcenrbadge(pEventSourceNrBadge: IEventSourceNrBadge) {
    this.context.commit('SET_EVENTSOURCENRBADGE', pEventSourceNrBadge);
  }

  @Mutation
  public SET_EVENTMYDOCUMENTS(pMyDocuments: number) {
    this.EventSourceNrBadge.nrOfMyDocuments = pMyDocuments;
  }

  @Action
  public set_eventmydocuments(pMyDocuments: number) {
    this.context.commit('SET_EVENTMYDOCUMENTS', pMyDocuments);
  }

  @Mutation
  public SET_EVENTPENDINGTOREGISTERDOCUMENTS(pPendingToRegister: number) {
    this.EventSourceNrBadge.nrOfPendingToRegister = pPendingToRegister;
  }

  @Action
  public set_eventpendingtoregisterdocuments(pPendingToRegister: number) {
    this.context.commit('SET_EVENTPENDINGTOREGISTERDOCUMENTS', pPendingToRegister);
  }

  @Mutation
  public SET_EVENTTASK(pTask: number) {
    this.EventSourceNrBadge.nrOfTask = pTask;
  }

  @Action
  public set_eventtask(pTask: number) {
    this.context.commit('SET_EVENTTASK', pTask);
  }

  @Mutation
  public SET_EVENTTASKISTORIC(pTaskIstoric: number) {
    this.EventSourceNrBadge.nrOfTaskIstoric = pTaskIstoric;
  }

  @Action
  public set_eventtaskistoric(pTaskIstoric: number) {
    this.context.commit('SET_EVENTTASKISTORIC', pTaskIstoric);
  }
  
  @Mutation
  public SET_EVENTPROJECTTASK(pProjectTask: number) {
    this.EventSourceNrBadge.nrProjectTask = pProjectTask;
  }

  @Action
  public set_eventprojecttask(pProjectTask: number) {
    this.context.commit('SET_EVENTPROJECTTASK', pProjectTask);
  }
  
  @Mutation
  public SET_EVENTASSETEVENIMENTE(pAssetEvenimente: number) {
    this.EventSourceNrBadge.nrAssetEvenimente = pAssetEvenimente;
  }

  @Action
  public set_eventassetevenimente(pAssetEvenimente: number) {
    this.context.commit('SET_EVENTASSETEVENIMENTE', pAssetEvenimente);
  }
}