import { getModule } from 'vuex-module-decorators';
import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import Nomenclatoare from '@/store/Nomenclatoare';

interface TAjaxResponseTari {
  status: string;
  message: string;
  rows: string;
  //rows: iTari[];
}

interface TAjaxResponseEditTari {
  status: string;
  message: string;
}

export class ServiceTari {
  public static async getTari(): Promise<TAjaxResponseTari> {
    const response = await axios.get(`${CONFIG_ENV.URL_TARI.tari}`);
    return response.data;
  }

  public static async updateStoreTariFromDB() {
    // console.log('%cServiceTari running updateStoreTariFromDB<-------------------------', "color: red;font-size:16px;")
    const nomenclatoareStore = getModule(Nomenclatoare);
    this.getTari().then((presponse) => {
      if (presponse.status === 'success') {
        nomenclatoareStore.set_tari(JSON.parse(presponse.rows));
      }
    })
  }
}