import {defineComponent, defineAsyncComponent, inject, ref} from 'vue';
import {getModule} from 'vuex-module-decorators';
import moment from 'moment';
import {CONFIG_ENV, APP_NAME} from '@/config';
import Nomenclatoare from '@/store/Nomenclatoare';;
import {dateToStringDDMonYYYY, getRowNumberTable} from '@/modules/Utils';
import LoadingComponent from '@/components/LoadingComponent.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';
import IFactura from '@/types/IFactura';
import { ServiceFacturi } from '@/modules/ServiceFacturi';
import IFacturaArticole from '@/types/IFacturaArticole';
import { ServiceFacturaArticole } from '@/modules/ServiceFacturaArticole';
import { ServiceSetariCompanie } from '@/modules/ServiceSetariCompanie';
import ISetariCompanie from '@/types/ISetariCompanie';
import ISetariCompanieConturi from '@/types/ISetariCompanieConturi';
import IPartener from '@/types/IPartener';
import IPartenerConturi from '@/types/IPartenerConturi';
import { ServiceSetariCompanieConturi } from '@/modules/ServiceSetariCompanieConturi';
import { ServiceParteneri } from '@/modules/ServiceParteneri';
import { ServiceParteneriConturi } from '@/modules/ServicePartenerConturi';


const qDateProxy0 = ref(null);

const EditArticolFactura = defineAsyncComponent({
  loader: () => import('@/views/Facturi/EditArticolFactura.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});


export default defineComponent({
  name: 'WorkOnFactura',

  components: {
    EditArticolFactura
  },

  props: {
	  appid: {
      type: Number,
      required: true,
      default: 0,
      readonly: true
    },

    closeWindow: {
      type: Function,
      default: undefined
    }
  },

  setup() {
    const emitter = inject("emitter") as any;
    return {
      emitter,
      qDateProxy0,
      slide: ref(0),
      fullscreen: ref(false)
    }
  },

  data() {
    return {
      factura: {
        appid: 0,
        seria: '',
        nr: 0,
        tip: '',
        tip_factura: '',
        stare: '',
        appid_furnizor: 0,
	      appid_cumparator: 0,
	      appid_cont_furnizor: 0,
	      appid_cont_cumparator: 0,
        data_eliberare: '',
        termen_plata:  0,
	      moneda: '',
      } as IFactura,
      loadingFacturaData: false,
      myLocale: CONFIG_ENV.myLocale,
      loadingFacturaArticole: false,
      nomenclatoareStore: getModule(Nomenclatoare),
      isFinalizat: false,
      loadingPdfForDownload: false,
      isDocument: '',
      visibleDialogFacturaArticole: false,
      facturaArticole: [] as IFacturaArticole[],
      visibleDialogCarouselFullScreen: false,
      appid_factura_articol: 0,
      myPagination: {
        rowsPerPage: getRowNumberTable()
      },
      filterFacturaArticoleList: '',
      visibleFacturaArticoleListColumns: ['appid', 'denumire', 'unitate_masura', 'cantitate', 'pret_fara_tva', 'tva'],
      facturaArticoleListColumns: [
        { name: 'appid', label:'Actiuni', field: 'appid', align: 'left', sortable: true },
        { name: 'denumire', label:'Denumire', field: 'denumire', align: 'left', sortable: true },
        { name: 'unitate_masura', label: 'UM', field: 'unitate_masura', align: 'left', sortable: true },
        { name: 'cantitate', label: 'Cantitatea', field: 'cantitate', align: 'left', sortable: true, style: 'max-width: 200px', headerStyle: 'max-width: 200px' },
        { name: 'pret_fara_tva', label: 'Pret fara TVA', field: 'pret_fara_tva', align: 'left', sortable: true, style: 'max-width: 200px', headerStyle: 'max-width: 200px' },
        { name: 'tva', label: 'TVA', field: 'tva', align: 'left', sortable: true, style: 'max-width: 200px', headerStyle: 'max-width: 200px' },
      ],
      furnizor: {} as ISetariCompanie,
      contFurnizor: {} as ISetariCompanieConturi,
      cumparator: {} as IPartener,
      contCumparator: {} as IPartenerConturi,
      dataTermen: '',
      totalTva: 0,
      totalNet: 0,
      totalFactura :0,
      totalTvaFormated: '',
      totalNetFormated: '',
      totalFacturaFormated: '',
    }
  },

  methods: {
    resetFactura(){
      this.factura = {
        appid: 0,
        seria: '',
        nr: 0,
        tip: '',
        stare: '',
        appid_furnizor: 0,
	      appid_cumparator: 0,
	      appid_cont_furnizor: 0,
	      appid_cont_cumparator: 0,
        data_eliberare: '',
	      moneda: '',
      } as IFactura

    },

    getFactura() {
      const vueInst=this;
      ServiceFacturi.getFactura(Number(vueInst.appid)).then((presponse)=>{
        vueInst.loadingFacturaData = false;
        if(presponse.status==='success'){
          vueInst.factura = JSON.parse(JSON.stringify(presponse.factura));
          vueInst.getDataTermen();
          vueInst.getFacturaArticole();
          vueInst.getFurnizor();
          vueInst.getFurnizorCont();
          vueInst.getCumparator();
          vueInst.getCumparatorCont();
        }
      })
    },

    doNothing(){
    },

    getDataTermen() {
      const vueInst = this;
      var dataInitiala = new Date(vueInst.factura.data_eliberare as string)
      dataInitiala.setDate(dataInitiala.getDate() + vueInst.factura.termen_plata);
      vueInst.dataTermen = dataInitiala.toISOString().split('T')[0];
    },


    openFormFacturaArticol(){
      this.appid_factura_articol = 0;
      this.visibleDialogFacturaArticole= true;
    },

    renuntEditareFacturaArticol() {
      this.appid_factura_articol= 0;
      this.visibleDialogFacturaArticole = false;
      this.getFacturaArticole();
    },

    getFacturaArticole(){
      const vueInst = this;
      vueInst.visibleDialogFacturaArticole = false;
      ServiceFacturaArticole.getFacturaArticole(Number(vueInst.factura.appid)).then((presponse)=>{
        vueInst.loadingFacturaData = false;
        vueInst.loadingFacturaArticole = false;
        if(presponse.status==='success'){
          vueInst.facturaArticole = JSON.parse(JSON.stringify(presponse.rows));
          vueInst.calculFactura();
        }
      });
    },

    getFurnizor() {
      const vueInst = this;
      ServiceSetariCompanie.getSetariCompanie().then((presponse)=>{
        if(presponse.status==='success'){
          vueInst.furnizor= JSON.parse(JSON.stringify(presponse.setariCompanie));
        }
      });
    },

    getFurnizorCont() {
      const vueInst = this;
      ServiceSetariCompanieConturi.getSetariCompanieCont(vueInst.factura.appid_cont_furnizor).then((presponse)=>{
        if(presponse.status==='success'){
          vueInst.contFurnizor = JSON.parse(JSON.stringify(presponse.setariCompanieCont));
        }
      });
    },

    getCumparator() {
      const vueInst = this;
      ServiceParteneri.getPartener(vueInst.factura.appid_cumparator).then((presponse)=>{
        if(presponse.status==='success'){
          vueInst.cumparator = JSON.parse(JSON.stringify(presponse.partener));
        }
      });
    },

    getCumparatorCont() {
      const vueInst = this;
      ServiceParteneriConturi.getPartenerCont(vueInst.factura.appid_cont_cumparator).then((presponse)=>{
        if(presponse.status==='success'){
          vueInst.contCumparator = JSON.parse(JSON.stringify(presponse.partenerCont));
        }
      });
    },

    askIfRemoveFacturaArticol(pArticol:IFacturaArticole){
      const vueInst = this;
      vueInst.$q.dialog({
        title: 'Confirm',
        message: `Stergeti articolul din lista ${pArticol.denumire}?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        ServiceFacturaArticole.deleteFacturaArticol(pArticol.appid).then(presponse=>{
          if(presponse.status==='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });

            this.getFacturaArticole();
          }
        });
      })
    },

    onProgress(event:any) {
      // Handdle the progress
    },

    prepareCoverPdfForDownload(){
      const vueInst = this;
      vueInst.emitter.emit('eventToPreview', { action: 'show_preview_file', title: 'Factura', appid: vueInst.factura.appid, url_to_get_file: `${CONFIG_ENV.URL_FACTURI.downloadPDFFactura}` });
    },

    calculFactura(){
      const vueInst = this;
      vueInst.facturaArticole.forEach(articol => {
        vueInst.totalTva = vueInst.totalTva + articol.pret_fara_tva*articol.cantitate*(articol.tva/100);
        vueInst.totalNet = vueInst.totalNet + articol.pret_fara_tva*articol.cantitate;
        vueInst.totalFactura = vueInst.totalTva + vueInst.totalNet;
      });
      vueInst.totalTvaFormated = vueInst.totalTva.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      vueInst.totalNetFormated = vueInst.totalNet.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      vueInst.totalFacturaFormated = vueInst.totalFactura.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },

    getDenStatus(pStatusValue: string) {
      let result = '' as string;
      const Status = this.optionsStatus.find(statusValue =>{
        return statusValue.value == pStatusValue;
      });

      if(Status){
        result = Status.label;
      }

      return result;
    },

    onPressEditRowFacturaArticol(ifacturaArticol: IFacturaArticole) {
      const vueInst = this;
      vueInst.appid_factura_articol = ifacturaArticol.appid;
      vueInst.visibleDialogFacturaArticole = true;
    },


    displayData(pdata:string) :string{
      if(pdata){
        const day = moment(pdata.substring(0,10), 'YYYY-MM-DD');
        return dateToStringDDMonYYYY(day);
      }else{
        return '';
      }
    },

    tryUpdateStatus(scope:any){
      const vueInst = this;
      if(vueInst.factura.stare){
        ServiceFacturi.updateFactura(vueInst.appid, 'stare', scope.value).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            scope.set();
          }
        })
      }
    },

  },

  computed: {
    APP_NAME(): string{
      return APP_NAME;
    },

    optionsStatus() {
      const statusArray = [
        {value: 'initiat', label: 'Initiat'},
        {value: 'finalizat', label: 'Finalizat'}, 
        {value: 'trimis', label: 'Trimis'},
        {value: 'anulat', label: 'Anulat'}];

      return statusArray;
    },

    getLinkToQrCode(): string {
      return CONFIG_ENV.URL_FACTURI.qr_code + '/' + this.appid;
    },
  },

  watch: {
    appid: {
      immediate: true,
      handler (newVal: number, oldVal: number) {
        const vueInst = this;
        vueInst.resetFactura();
        vueInst.facturaArticole = [];
        vueInst.loadingFacturaData = true;
        vueInst.getFactura();
        // if(newVal != 0 && vueInst.appid != 0) {
         
        // }
      }
    }
  }
});