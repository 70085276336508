<template>
  <div class="q-pa-md">
    <q-spinner-gears
      v-if="loadingData"
      color="primary"
      size="4em"
      class="absolute-center"
    />
    <div v-else>
      <q-form ref="myForm">
        <div class="well well-sm">
          <div class="panel">          
            <div class="q-pa-md">
              <div style="display: flex; align-items: center; margin-bottom: 15px">
                <div
                  v-bind:class="{
                    column: $q.platform.is.mobile,
                    row: $q.platform.is.desktop,
                  }"
                >
                  <span class="text__title--medium">Servicii de transport - Actiune solicitata</span>
                </div>

                <div class="absolute-right q-pa-md">
                  <q-btn v-if="documenteServiciiDeTransport.appid != 0 && documenteServiciiDeTransport.stare != 'cancellation'"
                    label="Vizualizeaza AST"
                    color="brown-5"
                    icon="file_copy"
                    @click="onPreviewCerere"
                    style="margin-right: 5px;"
                    size="sm"
                  />
                </div>
              </div>

              <div style="display: flex;align-items: start;">
                <div style="width: 100%; display: flex; flex-direction: row;">
                  <div style="display: flex;flex-direction: column;width: 100%;">
                    <div class="row">
                      <span class="col-3 form__label">Id</span>
                      <span class="col-9 form__label__black">{{documenteServiciiDeTransport.appid}}</span>
                    </div>

                    <div class="row">
                      <span class="col-3 form__label">Numar</span>
                      <span class="col-9 form__label__black">{{documenteServiciiDeTransport.numar}}</span>
                    </div>

                    <div class="row">
                      <span class="col-3 form__label">Data</span>
                      <span class="col-9 form__label__black">{{displayDataRO(documenteServiciiDeTransport.data)}}</span>
                    </div>

                    <div class="row">
                      <span class="col-3 form__label">Nume</span>
                      <span class="col-9 form__label__black">{{documenteServiciiDeTransport.nume}}</span>
                    </div>

                    <div class="row">
                      <span class="col-3 form__label">Solicitant</span>
                      <span class="col-9 form__label__black">{{documenteServiciiDeTransport.solicitant}}</span>
                    </div>

                    <div class="row">
                      <span class="col-3 form__label">Adresa</span>
                      <span class="col-9 form__label__black">{{documenteServiciiDeTransport.adresa}}</span>
                    </div>

                    <div class="row">
                      <span class="col-3 form__label">Stare</span>
                      <span class="col-9 form__label__black">{{getDenStatus(documenteServiciiDeTransport.stare)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div clas="row" style="margin-top: 5px;margin-bottom: 15px;" v-if="documenteServiciiDeTransport.stare !== 'initiat'">
                <TaskList :appid="appid" :task="task" :onState="onState" :isSuccess="isSuccess"/>
            </div>
          </div>      
        </div>
      </q-form>

      <q-dialog
        v-model="showPreviewFile"
        persistent
        :maximized="true"
        transition-show="slide-up"
        transition-hide="slide-down"
      >
        <q-card class="bg-white">
          <q-bar>
            <h5>{{titleForPreviewForm}}</h5>
            <q-space />
            <q-btn dense flat icon="close" v-close-popup>
              <q-tooltip transition-show="rotate"
                transition-hide="rotate">Close</q-tooltip>
            </q-btn>
          </q-bar>

          <q-card-section>
            <q-linear-progress v-if="downloadingFile" query track-color="orange" color="purple" class="q-mt-sm"/>            
            <iframe v-if="isPDFFileType" id="iframe_preview_scan" type="application/pdf" width="100%" height="100%" style="width:100%; height:calc(100vh - 70px);overflow: auto;border: none;"></iframe>
            <img v-else id="iframe_preview_scan" width="100%" height="100%" style="width:100%; height:calc(100vh - 70px);overflow: auto;border: none;display: block;"/>
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>

<script lang="ts" src="./EditServiciiDeTransportDetail.ts" />

<style scoped>
.q-field--with-bottom {
  padding-bottom: 20px;
}

.q-field__native {
  font-family: "Nunito Sans";
  font-weight: bolder;
}
</style>