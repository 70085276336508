import {defineComponent, defineAsyncComponent, inject, ref} from 'vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';
import IReferat from '@/types/IReferat';
import IReferatServicii from '@/types/IReferatServicii';
import Nomenclatoare from '@/store/Nomenclatoare';
import { ServiceReferate } from '@/modules/ServiceReferate';
import {CONFIG_ENV} from '@/config';
import {getModule} from 'vuex-module-decorators';
import IActionOnStepTask from '@/types/IActionOnStepTask';
import { getRowNumberTable } from '@/modules/Utils';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type IReferatNull = OrNull<IReferat>;

const myForm = ref({} as any);
const myForm1 = ref({} as any);

const EditReferatServicii = defineAsyncComponent({
  loader: () => import('@/views/Referat/EditReferatServicii.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

const TaskList = defineAsyncComponent({
  loader: () => import('@/components/Tasks/TaskList.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

export default defineComponent({
  name: 'EditReferatDetail',

  components: {
    EditReferatServicii, TaskList
  },

  props: {
    appid: {
      type: Number,
      required: true,
      default: 0,
      readonly: true
    },
    withFinalizeButton: {
      type: String,
      required: true,
      default: 'no',
      readonly: true
    },
    closeWindow: {
      type: Function,
      default: undefined
    },
    task: {
      type: Object,
      task_id: 0,
      task_type: '',
      action_on_step: {} as IActionOnStepTask
    }
  },

  setup() {
    const emitter = inject("emitter") as any;  
    return {
      emitter,
      myForm,
      myForm1
    };
  },

  data() {
    return {
      referat: {
        appid: 0,
        id_referat: 0,  
        denumire: '',
        descriere: '',
        stare: 'Initiat',
        track_user: 0,
        track_date: '',
        valoare: 0,
        responsabil: '',
      } as IReferatNull,
      myLocale: CONFIG_ENV.myLocale,
      nomenclatoareStore: getModule(Nomenclatoare),      
      filterReferateServicii: '',
      loadingData: false,
      visibleDialogEditReferateServicii: false,    
      myPagination: {
        rowsPerPage: getRowNumberTable()
      },
      visibleColumns: ['id_referat_servici', 'id_tip', 'denumire', 'valoare'],
      columns: [        
        { name: 'id_referat_servici', label: 'Id', field: 'id_referat_servici', align: 'left', sortable: true, sort: (a: string, b: string) => parseInt(a, 10) - parseInt(b, 10) },
        { name: 'id_tip', label: 'Tip', field: 'id_tip', align: 'left', sortable: true, sort: (a: string, b: string) => parseInt(a, 10) - parseInt(b, 10) },        
        { name: 'denumire', label: 'Detalii', field: 'denumire', align: 'left', sortable: true, style: 'max-width: 400px', headerStyle: 'max-width: 400px' },
        { name: 'valoare', label: 'Pret estimat', field: 'valoare', align: 'right', sortable: true, sort: (a: string, b: string) => parseInt(a, 10) - parseInt(b, 10) },
        { name: 'appid', label: 'Actiuni', field: 'appid', align: 'right', sortable: false },
      ],       
      tableDataReferateServicii: [] as IReferatServicii[],
      isSuccess: false
    }
  },

  methods: {    
    onCancel() { 
      const vueInst = this;     
      vueInst.closeWindow?.();
    },

    onDownload() { 
      alert('In progress!')
    },
    
    getReferat() {
      const vueInst=this;
      ServiceReferate.getReferat(Number(vueInst.referat.appid)).then((presponse)=>{
        vueInst.loadingData = false;
        if(presponse.status==='success'){
          vueInst.referat = JSON.parse(JSON.stringify(presponse.referat));

          vueInst.refreshDataTableReferateServicii();
          vueInst.isSuccess = true;
        }
      })
    },

    refreshDataTableReferateServicii(){
      const vueInst = this;      
      ServiceReferate.getReferatServicii(vueInst.appid).then((presponse)=>{
        vueInst.loadingData=false;
        if (presponse.status === 'success'){
          vueInst.tableDataReferateServicii=JSON.parse(presponse.rows);
        }
      })
    },

    tipDenumire(pTipValue: number) {
      let result = '' as string;
      const tip = this.optionsTip.find(tipValue =>{
        return tipValue.id_tip == pTipValue;
      });
  
      if(tip){
        result = tip.label;
      }
  
      return result;      
    },

    onState(operator: string){
      switch(operator) {
        case 'Refresh':
          this.getReferat();
          break;
        case 'Close':          
          this.closeWindow?.();
          break;
      }
    },

    getDenStatus(pStatus: string): string{
      const vueInst=this;
      let result = '' as string;
      const Status = vueInst.optionsStare.find( stare =>{
        return stare.status == pStatus;
      });
      if(Status){
        result=Status.label;
      }
      return result;
    }
  },

  computed: {    
    optionsTip() {
      const tipArray = [
        {id_tip: 1, label: 'Servicii'}, 
        {id_tip: 2, label: 'Articol'}, 
        {id_tip: 3, label: 'Alte'}];
      
      return tipArray;
    },
    
    optionsStare() {      
      const stareArray = [  
        {status: 'initiat', label: 'Initiat'},        
        {status: 'rejected', label: 'Respins'}, 
        {status: 'approved', label: 'Aprobat'},         
        {status: 'inapproval', label: 'In aprobare'} 
      ];

      return stareArray;
    }    
  },

  mounted() { 
    if (this.appid != 0) {
      this.referat.appid = this.appid;      
      this.loadingData = true;
      this.getReferat();
    }
  },

  watch: {
    appid: {
      immediate: true,
      handler (newVal: number, oldVal: number) {        
        const vueInst = this;        
        // vueInst.scan_documents = [];
        vueInst.loadingData = true;
        if(newVal != 0 && vueInst.appid != 0) {                    
          vueInst.referat.appid = vueInst.appid;            
          vueInst.getReferat();    
        }
      }
    }
  }
});
