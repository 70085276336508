export default {
    en: {
        currencyFormat: {
            style: "currency",
            currency: "USD"
        }
    },
    ro: {
        currencyFormat: {
            style: "currency",
            currency: "RON"
        }
    },
    hu: {
        currencyFormat: {
            style: "currency",
            currency: "HUF"
        }
    }
}