import moment, {Moment} from 'moment';
import Nomenclatoare from '@/store/Nomenclatoare';
import {getModule} from 'vuex-module-decorators';

const nomenclatoareStore = getModule(Nomenclatoare);

export function dateToStringDDMonYYYY(pDate: Moment) {
  return moment(pDate).locale('ro-RO').format('D MMM YYYY');
}

export function dateToStringMonYYYY(pDate: Moment) {
  return moment(pDate).locale('ro-RO').format("MMMM YYYY");
}

export function dateToStringDDMonYYYYRo(pDate: Moment) {
  return moment(pDate).locale('ro-RO').format("DD MMMM YYYY");
}

export function dateToStringDDMMYYYY(pDate: Moment) {
  return moment(pDate).format('DD.MM.YYYY');
}

export function dateToStringYYYYMMDDStandard(pDate: Moment) {
  return moment(pDate).format('YYYY-MM-DD');
}

export function dateToStringDDMMYYYYHHMMSS(pDate: Moment) {
  return moment(pDate).format('DD.MM.YYYY HH:mm:ss');
}

export function dateToStringDDMMYYYYHHMM(pDate: Moment) {
  return moment(pDate).format('DD.MM.YYYY HH:mm');
}

export function humanReadableBytes(bytes: number):string {
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
}

// export function denDepartamentFromOrg(pOrgId: number):string {
//   const departament = nomenclatoareStore.NomOrganizatii.find(organizatie=>{
//     return organizatie.appid == pOrgId;
//   })
//   if(departament){
//     return departament.divizie+' / '+departament.departament;
//   }else{
//     return 'nedefinit';
//   }
// }

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function denPersoanaUtilizatorFromMarca(pMarca: number):string {
  const persoana = nomenclatoareStore.NomPersoaneUtilizatoare.find(ppersoana=>{
    return ppersoana.marca == pMarca;
  })
  if(persoana){
    return persoana.nume_prenume;
  }else{
    return 'nedefinit';
  }
}

export function denOrgUnitFromOrg(pOrgId: number):string {
  const orgUnit = nomenclatoareStore.NomOrgUnits.find(orgUnit=>{
    return orgUnit.id == pOrgId;
  })
  if(orgUnit){
    return orgUnit.organization_unit_descriere as string;
  }else{
    return 'nedefinit';
  }
}

export function validateEmail(mailString:string):boolean {
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (mailString.match(validRegex)) {
    return true;
  } else {
    return false;
  }
}
  // let result = false;
  // const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  // if (reg.test(mailString) == false){
  //   result=false;
  // }else{
  //   result=true;
  // }
  // return result;

export function validateCNP(p_cnp:string):boolean {
    let i=0, year=0, hashResult=0;
    const cnp=[] as number[], hashTable=[2,7,9,1,4,6,3,5,8,2,7,9];

    if( p_cnp.length !== 13 ) { 
      return false; 
    }

    for( i=0 ; i<13 ; i++ ) {
        cnp[i] = parseInt( p_cnp.charAt(i) , 10 );
        if( isNaN( cnp[i] ) ) { 
          return false; 
        }
        if( i < 12 ) { 
          hashResult = hashResult + ( cnp[i] * hashTable[i] ); 
        }
    }

    hashResult = hashResult % 11;
    if( hashResult === 10 ) { 
      hashResult = 1; 
    }
    year = (cnp[1]*10)+cnp[2];
    
    switch( cnp[0] ) {
        case 1  : case 2 : { year += 1900; } break;
        case 3  : case 4 : { year += 1800; } break;
        case 5  : case 6 : { year += 2000; } break;
        case 7  : case 8 : case 9 : { 
          year += 2000; 
          //if( year > ( parseInt( new Date().getYear() , 10 ) - 14 ) ) {
          if( year > ( parseInt( new Date().getFullYear().toString(), 10 ) - 14 ) ) {
            year -= 100; 
          } 
        } break;
        default : { 
          return false; 
        }
    }
    if( year < 1800 || year > 2099 ) { 
      return false; 
    }

    return ( cnp[12] === hashResult );
}

export function validateRomanianCIF (cui:string):boolean {
  if (typeof cui !== 'string') {
    //return 'Nu este un șir de caractere!'
    return false;
  }
  
  let cif = cui.toUpperCase()
  
  // remove RO from cif:
  const indexOfRo = cif.indexOf('RO')
  
  if (indexOfRo > -1) {
    cif = cif.substr(0, indexOfRo) + cif.substr(indexOfRo + 2)
  }

  cif = cif.replace('R', '');
  
  // remove spaces:
  cif = cif.replace(' ', '')
  
  // validate character length:
  if (cif.length < 2 || cif.length > 10) {
    return false;
    //return 'Lungimea corectă fără RO, este între 2 și 10 caractere!'
  }
  
  // validate that so far the resulting CIF looks like an integer value:
  if (Number.isNaN(parseInt(cif))) {
    return false;
    //return 'Nu este număr!'
  }
  
  // begin testing:
  const testKey = '753217532'
  const controlNumber = parseInt(cif.substr(cif.length - 1))
  
  // remove control number:
  cif = cif.substr(0, cif.length - 1)
  
  // pad left with zeros to reach testKey length:
  while (cif.length !== testKey.length) {
    cif = '0' + cif
  }
  
  let sum = 0
  let i = cif.length
  
  while (i--) {
    sum = sum + (Number(cif.charAt(i)) * Number(testKey.charAt(i)))
  }
  
  let calculatedControlNumber = sum * 10 % 11
  
  if (calculatedControlNumber === 10) {
    calculatedControlNumber = 0
  }
  
  return controlNumber === calculatedControlNumber;
  //return controlNumber === calculatedControlNumber || `CIF invalid! Cifra de control calculată (${calculatedControlNumber}) diferă de cea introdusă (${controlNumber})!`
}

export function getRowNumberTable(): number {
  return (localStorage.getItem('nrRanduriTabel')) ? Number(localStorage.getItem("nrRanduriTabel")) : 10;
}

var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

export function abbreviateNumber(value: number): string {
  // what tier? (determines SI symbol)
  if (value != 0) {
    var tier = Math.log10(Math.abs(value)) / 3 | 0;

    // if zero, we don't need a suffix
    if(tier == 0) return value.toString();

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = value / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
  } else {
    return value.toString();
  }
}

export function getUsersIdArray(value: string) {
  var arr = value.split(/[\s,{}]+/)
  var newArr : number[] = []
  arr.map(el => {
    if(el != ""){
      newArr.push(parseInt(el, 10))
    }
  })

  return(newArr)
}
