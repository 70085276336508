import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import IProiecte from '@/types/IProiecte';
import IProiecteEtape from '@/types/IProiecteEtape';
import IProiecteTaskuri from '@/types/IProiecteTaskuri';
import IProiecteTaskuriDetail from '@/types/IProiecteTaskuriDetail';
import IProiecteTaskuriPontari from '@/types/IProiecteTaskuriPontari';
import IProiecteTaskuriNotite from '@/types/IProiecteTaskuriNotite';
import IScanProiecte from '@/types/IScanProiecte';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type IProiecteNull = OrNull<IProiecte>;
type IProiecteEtapeNull = OrNull<IProiecteEtape>;
type IProiecteTaskuriNull = OrNull<IProiecteTaskuri>;
type IProiecteTaskuriDetailNull = OrNull<IProiecteTaskuriDetail>;
type IProiecteTaskuriPontariNull = OrNull<IProiecteTaskuriPontari>;
type IProiecteTaskuriNotiteNull = OrNull<IProiecteTaskuriNotite>;

interface TAjaxResponseProiecte {
  status: string;
  message: string;
  rows: string;
}

interface TAjaxResponseMyProiecteTaskuri {
  status: string;
  message: string;
  rows: string;
}

interface TAjaxResponseEditProiect {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetProiect {
  status: string;
  message: string;
  proiect: IProiecteNull;
}

interface TAjaxResponseProiecteEtape {
  status: string;
  message: string;
  rows: string;
}

interface TAjaxResponseEditProiectEtapa {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetProiectEtapa {
  status: string;
  message: string;
  proiectEtapa: IProiecteEtapeNull;
}

interface TAjaxResponseProiecteTaskuri {
  status: string;
  message: string;
  rows: string;
}

interface TAjaxResponseEditProiectTask {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetProiectTask {
  status: string;
  message: string;
  proiectTask: IProiecteTaskuriNull;
}

interface TAjaxResponseGetProiectTaskDetail {
  status: string;
  message: string;
  proiectTaskDetail: IProiecteTaskuriDetailNull;
}

interface TAjaxResponseProiecteTaskuriPontari {
  status: string;
  message: string;
  rows: string;
}

interface TAjaxResponseEditProiecteTaskuriPontare {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetScansProiecte {
  status: string;
  message: string;
  nr_of_records: number,
  rows: IScanProiecte[];
}

interface TAjaxResponseGetInfoScanProiect {
  status: string;
  message: string;
  scan: IScanProiecte;
}

interface TAjaxSimpleResponse {
  status: string;
  message: string;
}

interface TAjaxResponseNrProjectTask {
  status: string;
  message: string;
  nr_project_task: number;
}

interface TAjaxResponseGetProiectTaskPontare {
  status: string;
  message: string;
  proiectTaskPontare: IProiecteTaskuriPontariNull;
}

interface TAjaxResponseProiecteTaskuriNotite {
  status: string;
  message: string;
  rows: string;
}

interface TAjaxResponseEditProiecteTaskuriNota {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetProiectTaskNota {
  status: string;
  message: string;
  proiectTaskNota: IProiecteTaskuriNotiteNull;
}

export class ServiceProiecte {
  public static async getProiecte(): Promise<TAjaxResponseProiecte> {
    const response = await axios.get(`${CONFIG_ENV.URL_PROIECTE.proiecte}`);
    return response.data;
  }

  // public static async deleteProiect(appid: number): Promise<TAjaxResponseEditProiect> {    
  //   const response = await axios.delete(`${CONFIG_ENV.URL_PROIECTE.proiect}/${appid}`);
  //   return response.data;
  // }

  public static async postProiect(proiect: IProiecteNull): Promise<TAjaxResponseEditProiect> {
    const response = await axios.post(`${CONFIG_ENV.URL_PROIECTE.proiect}`, proiect);
    return response.data;
  }

  public static async getProiect(appid: number): Promise<TAjaxResponseGetProiect> {
    const response = await axios.get(`${CONFIG_ENV.URL_PROIECTE.proiect}/${appid}`);
    return response.data;
  }

  public static async getProiecteCombo(is_all: number): Promise<TAjaxResponseProiecte> {    
    const response = await axios.get(`${CONFIG_ENV.URL_PROIECTE.proiectecombo}/${is_all}`);
    return response.data;
  }

  public static async getProiectDetail(appid: number): Promise<TAjaxResponseGetProiect> {
    const response = await axios.get(`${CONFIG_ENV.URL_PROIECTE.proiect_detail}/${appid}`);
    return response.data;
  }

  public static async getProiecteEtape(idProiect: number): Promise<TAjaxResponseProiecteEtape> {
    const response = await axios.get(`${CONFIG_ENV.URL_PROIECTE.proiecte_etape}/${idProiect}`);
    return response.data;
  }
  
  public static async getProiectEtapa(appid: number): Promise<TAjaxResponseGetProiectEtapa> {
    const response = await axios.get(`${CONFIG_ENV.URL_PROIECTE.proiect_etapa}/${appid}`);
    return response.data;
  }

  public static async postProiectEtapa(proiectEtapa: IProiecteEtapeNull): Promise<TAjaxResponseEditProiectEtapa> {
    const response = await axios.post(`${CONFIG_ENV.URL_PROIECTE.proiect_etapa}`, proiectEtapa);
    return response.data;
  }

  public static async getProiecteTaskuri(idProiect: number): Promise<TAjaxResponseProiecteTaskuri> {
    const response = await axios.get(`${CONFIG_ENV.URL_PROIECTE.proiecte_taskuri}/${idProiect}`);
    return response.data;
  }
  
  public static async getProiectTask(appid: number): Promise<TAjaxResponseGetProiectTask> {
    const response = await axios.get(`${CONFIG_ENV.URL_PROIECTE.proiect_task}/${appid}`);
    return response.data;
  }

  public static async postProiectTask(proiectTask: IProiecteTaskuriNull): Promise<TAjaxResponseEditProiectTask> {
    const response = await axios.post(`${CONFIG_ENV.URL_PROIECTE.proiect_task}`, proiectTask);
    return response.data;
  }

  public static async getMyProiecteTaskuri(): Promise<TAjaxResponseMyProiecteTaskuri> {
    const response = await axios.get(`${CONFIG_ENV.URL_PROIECTE.my_proiecte_taskuri}`);
    return response.data;
  }

  public static async getAllProiecteTaskuri(): Promise<TAjaxResponseMyProiecteTaskuri> {
    const response = await axios.get(`${CONFIG_ENV.URL_PROIECTE.all_proiecte_taskuri}`);
    return response.data;
  } 

  public static async getProiectTaskDetail(appid: number): Promise<TAjaxResponseGetProiectTaskDetail> {
    const response = await axios.get(`${CONFIG_ENV.URL_PROIECTE.proiect_task_detail}/${appid}`);
    return response.data;
  }

  public static async getProiecteTaskuriPontari(appid: number): Promise<TAjaxResponseProiecteTaskuriPontari> {
    const response = await axios.get(`${CONFIG_ENV.URL_PROIECTE.proiecte_taskuri_pontari}/${appid}`);
    return response.data;
  }

  public static async postProiecteTaskuriPontare(proiect: IProiecteTaskuriPontariNull): Promise<TAjaxResponseEditProiecteTaskuriPontare> {
    const response = await axios.post(`${CONFIG_ENV.URL_PROIECTE.proiecte_taskuri_pontare}`, proiect);
    return response.data;
  }

  public static async getScansProiecte(appid:number): Promise<TAjaxResponseGetScansProiecte> {
    const response = await axios.get(`${CONFIG_ENV.URL_PROIECT_SCAN.getScansProiecte}/${appid}`);
    return response.data;
  }

  public static async getScansProiecteEtape(appid:number): Promise<TAjaxResponseGetScansProiecte> {
    const response = await axios.get(`${CONFIG_ENV.URL_PROIECT_SCAN.getScansProiecteEtape}/${appid}`);
    return response.data;
  }

  public static async getScansProiecteTaskuri(appid:number): Promise<TAjaxResponseGetScansProiecte> {
    const response = await axios.get(`${CONFIG_ENV.URL_PROIECT_SCAN.getScansProiecteTaskuri}/${appid}`);
    return response.data;
  }

  public static async getInfoScan(appid:number): Promise<TAjaxResponseGetInfoScanProiect> {
    const response = await axios.get(`${CONFIG_ENV.URL_PROIECT_SCAN.getInfoScan}/${appid}`);
    return response.data;
  }

  public static async deleteScan(appid:number, headers: any): Promise<TAjaxSimpleResponse> {
    const response = await axios.delete(`${CONFIG_ENV.URL_PROIECT_SCAN.getInfoScan}/${appid}`, { headers });
    return response.data;
  }

  public static async getNumberProjectTask(): Promise<TAjaxResponseNrProjectTask> {
    const response = await axios.get(`${CONFIG_ENV.URL_PROIECTE.proiect_task_nr}`);
    return response.data;
  }

  public static async getProiectTaskPontare(appid: number): Promise<TAjaxResponseGetProiectTaskPontare> {
    const response = await axios.get(`${CONFIG_ENV.URL_PROIECTE.proiecte_taskuri_pontare}/${appid}`);
    return response.data;
  }

  public static async getProiecteTaskuriNotite(appid: number): Promise<TAjaxResponseProiecteTaskuriNotite> {
    const response = await axios.get(`${CONFIG_ENV.URL_PROIECTE.proiecte_taskuri_notite}/${appid}`);
    return response.data;
  }

  public static async postProiecteTaskuriNota(proiect: IProiecteTaskuriNotiteNull): Promise<TAjaxResponseEditProiecteTaskuriNota> {
    const response = await axios.post(`${CONFIG_ENV.URL_PROIECTE.proiecte_taskuri_nota}`, proiect);
    return response.data;
  }

  public static async getProiectTaskNota(appid: number): Promise<TAjaxResponseGetProiectTaskNota> {
    const response = await axios.get(`${CONFIG_ENV.URL_PROIECTE.proiecte_taskuri_nota}/${appid}`);
    return response.data;
  }

  public static async getScansProiecteTaskuriNotite(appid:number): Promise<TAjaxResponseGetScansProiecte> {
    const response = await axios.get(`${CONFIG_ENV.URL_PROIECT_SCAN.getScansProiecteTaskuriNotite}/${appid}`);
    return response.data;
  }

  public static async getProiecteTaskuriCombo(idProiect: number, idProiectEtapa: number): Promise<TAjaxResponseProiecteTaskuri> {    
    const response = await axios.get(`${CONFIG_ENV.URL_PROIECTE.proiectetaskuricombo}/${idProiect}/${idProiectEtapa}`);
    return response.data;
  }

  public static async getProiecteTaskuriPontariList(is_all: number, searchParams:any): Promise<TAjaxResponseProiecteTaskuriPontari> {
    const response = await axios.post(`${CONFIG_ENV.URL_PROIECTE.proiecte_taskuri_pontari_view}/${is_all}`, searchParams);
    return response.data;
  }
}