import {defineComponent, defineAsyncComponent, inject, ref} from 'vue';
import IProiecte from '@/types/IProiecte';
import IPersoana from '@/types/IPersoana';
import LoadingComponent from '@/components/LoadingComponent.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';
import Nomenclatoare from '@/store/Nomenclatoare';
import ComboUser from '@/components/ComboUser/ComboUser.vue';
import {ServiceProiecte} from '@/modules/ServiceProiecte';
import {CONFIG_ENV, APP_NAME} from '@/config';
import {getModule} from 'vuex-module-decorators';
import moment from 'moment';
import {dateToStringDDMMYYYY, dateToStringDDMonYYYY, denPersoanaUtilizatorFromMarca, getRowNumberTable} from '@/modules/Utils';
import User from '@/store/User';
import IUser from '@/types/IUser';
import Notify from 'quasar/src/plugins/Notify.js';;
import {FileUploadService} from 'v-file-upload';
import {humanReadableBytes} from '@/modules/Utils';
import IScanProiecte from '@/types/IScanProiecte';
import IProiecteEtape from '@/types/IProiecteEtape';
import IProiecteTaskuri from '@/types/IProiecteTaskuri';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type IProiecteNull = OrNull<IProiecte>;

interface IProiecteExtended extends IProiecteNull{ 
  denumire_user_created: string,
  scan_documents:IScanProiecte[]|null
}

interface IProiecteEtapeExtended extends IProiecteEtape{ 
  proiecteTaskuri: Array<IProiecteTaskuri>[]
}

const myForm = ref({} as any);
const qDateProxy1 = ref(null);
const qDateProxy2 = ref(null);

const EditProiectEtapa = defineAsyncComponent({
  loader: () => import('@/views/Proiect/EditProiectEtapa.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

const EditProiectTask = defineAsyncComponent({
  loader: () => import('@/views/Proiect/EditProiectTask.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

const DialogOpenScan = defineAsyncComponent({
  loader: () => import('@/components/DialogOpenScan/DialogOpenScan.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

export default defineComponent({
  name: 'EditProiectDetail',

  components: {
    ComboUser, DialogOpenScan, EditProiectEtapa, EditProiectTask
  },

  props: {
    appid: {
      type: Number,
      required: true,
      default: 0,
      readonly: true
    },
    closeWindow: {
      type: Function,
      default: undefined
    },
  },

  setup() {  
    const emitter = inject("emitter") as any;
    return {
      emitter,
      myForm,
      qDateProxy1,
      qDateProxy2
    };
  },

  data() {
    return {
      proiect: {
        appid: 0,
        id_proiect: 0,  
        denumire: '',
        descriere: '',
        id_responsabil: 0,
        data_start: '',
        data_end: '',
        track_user: 0,
        track_date: '',
        responsabil: '',
        durata: 0,
        stare: '',
        progres: 0,
        denumire_user_created: '',
        scan_documents: [] as IScanProiecte[]
      } as IProiecteExtended,
      myLocale: CONFIG_ENV.myLocale,
      nomenclatoareStore: getModule(Nomenclatoare),
      viewDialogSelectPersoana: false,
      loadingProiectData: false,
      uploadScanTitle: '',
      urlForUpload: CONFIG_ENV.URL_PROIECT_SCAN.uploadScanProiect,
      uploadingFileToServer: false,
      headers: {} as any,
      userStore: getModule(User),
      filesSelectedForUpload: [] as any,
      dialogUploadFile: false,   
      loadingPdfForDownload: false,
      filterEtape: '',
      filterTaskuri: '',    
      visibleDialogEditProiectEtapa: false,
      visibleDialogEditProiectTask: false,
      myPagination: {
        rowsPerPage: getRowNumberTable()
      },
      tableDataProiecteEtape: [] as IProiecteEtapeExtended[],
      tableDataProiecteTaskuri: [] as IProiecteTaskuri[],
      visibleProiecteEtapeColumns: ['id_proiect_etapa', 'denumire', 'task', 'responsabil', 'termen', 'progres', 'stare', 'appid'],
      visibleProiecteTaskuriColumns: ['id_proiect_task', 'denumire', 'responsabil', 'termen', 'progres', 'stare', 'appid'],
      proiecteEtapeColumns: [
        { name: 'id_proiect_etapa', label: 'Id', field: 'id_proiect_etapa', align: 'left', sortable: true, sort: (a: string, b: string) => parseInt(a, 10) - parseInt(b, 10) },
        { name: 'denumire', label: 'Etapa', field: 'denumire', align: 'left', sortable: true, style: 'max-width: 200px', headerStyle: 'max-width: 200px' },
        { name: 'task', label: 'Task', field: 'task', align: 'left', sortable: true, sort: (a: string, b: string) => parseInt(a, 10) - parseInt(b, 10) },
        { name: 'responsabil', label: 'Responsabil', field: 'responsabil', align: 'left', sortable: true },        
        { name: 'termen', label: 'Termen', field: 'termen', align: 'left', sortable: true },
        { name: 'progres', label: 'Progres', field: 'progres', align: 'left', sortable: true, sort: (a: string, b: string) => parseInt(a, 10) - parseInt(b, 10) },
        { name: 'stare', label: 'Stare', field: 'stare', align: 'left', sortable: true },        
        { name: 'appid', label: 'Actiuni', field: 'appid', align: 'left', sortable: false },
      ],
      proiecteTaskuriColumns: [
        { name: 'id_proiect_task', label: 'Id', field: 'id_proiect_task', align: 'left', sortable: true, sort: (a: string, b: string) => parseInt(a, 10) - parseInt(b, 10) },
        { name: 'denumire', label: 'Task', field: 'denumire', align: 'left', sortable: true, style: 'max-width: 200px', headerStyle: 'max-width: 200px' },        
        { name: 'responsabil', label: 'Responsabil', field: 'responsabil', align: 'left', sortable: true },        
        { name: 'termen', label: 'Termen', field: 'termen', align: 'left', sortable: true },
        { name: 'progres', label: 'Progres', field: 'progres', align: 'left', sortable: true, sort: (a: string, b: string) => parseInt(a, 10) - parseInt(b, 10) },
        { name: 'stare', label: 'Stare', field: 'stare', align: 'left', sortable: true },        
        { name: 'appid', label: 'Actiuni', field: 'appid', align: 'left', sortable: false },
      ],
      appidProiectEtapa: 0,
      appidProiectTask: 0,
      proiectEtapa: {
        appid: 0,
        id_proiect_etapa: 0,  
        id_proiect: 0,  
        denumire: '',
        descriere: '',
        termen: '',
        id_responsabil: 0,
        estimare_ora: 0,
        track_user: 0,
        track_date: '',
        task: 0,
        responsabil: '',
        stare: '',
        progres: 0,
      } as IProiecteEtape
    }
  },

  methods: {
    inputPersoana(pval:string){
      this.viewDialogSelectPersoana = true;
    },

    selectPersoana(persoanaUtilizatoare: IPersoana){
      const vueInst = this;
      vueInst.proiect.id_responsabil = persoanaUtilizatoare.marca;
      vueInst.proiect.responsabil = persoanaUtilizatoare.nume_prenume;
      vueInst.viewDialogSelectPersoana = false;
    },

    displayData(pdata:string) :string{
      if(pdata){        
        const day = moment(pdata.substring(0,10), 'YYYY-MM-DD');
        return dateToStringDDMonYYYY(day);
      }else{ 
        return '';
      }
    },

    displayDataNormal(pdata:string) :string{
      if(pdata){        
        const day = moment(pdata.substring(0,10), 'YYYY-MM-DD');
        return dateToStringDDMMYYYY(day);
      }else{ 
        return '';
      }
    },

    displayStatusColor(pStare:string) {
      switch(pStare) { 
        case 'neinceput': { 
           return 'app__status--proiecte--gray'
        } 
        case 'inlucru': { 
          return 'app__status--proiecte--orange'
        }
        case 'finalizat': { 
          return 'app__status--proiecte--green'
       } 
        default: { 
          return 'app__status--proiecte--gray'
        } 
     } 
    },

    displayProgres(pProgres:number) {
      if (pProgres < 30) {
        return 'app__status--proiecte--gray';
      } else if (pProgres >= 30 && pProgres < 60) {
        return 'app__status--proiecte--red';
      } else if (pProgres >= 60) {
        return 'app__status--proiecte--orange';
      } else {
        return 'app__status--proiecte--gray';
      }
    },

    getDenStatus(pStatus: string): string{
      const vueInst=this;
      let result = '' as string;
      const Status = vueInst.optionsStare.find( stare =>{
        return stare.status == pStatus;
      });
      if(Status){
        result=Status.label;
      }
      return result;
    },

    getProiect() {
      const vueInst=this;
      ServiceProiecte.getProiectDetail(vueInst.appid).then((presponse)=>{
        vueInst.loadingProiectData = false;
        if(presponse.status==='success'){
          vueInst.proiect = JSON.parse(JSON.stringify(presponse.proiect))[0];
          vueInst.proiect.data_start = vueInst.proiect.data_start == null ? null : vueInst.displayData(vueInst.proiect.data_start);
          vueInst.proiect.data_end = vueInst.proiect.data_end == null ? null : vueInst.displayData(vueInst.proiect.data_end);
          vueInst.proiect.track_date = vueInst.proiect.track_date == null ? null : vueInst.displayDataNormal(vueInst.proiect.track_date);
          vueInst.proiect.responsabil = denPersoanaUtilizatorFromMarca(Number(vueInst.proiect.id_responsabil));
          vueInst.proiect.denumire_user_created = denPersoanaUtilizatorFromMarca(Number(vueInst.proiect.track_user));

          vueInst.getScanDocuments();
          vueInst.refreshDataTableProiecteEtape(); 
          vueInst.refreshDataTableProiecteTaskuri();         
        }
      })
    },

    addRowProiecteEtapa() {
      this.appidProiectEtapa = 0;
      this.visibleDialogEditProiectEtapa = true;
    },

    renuntEditareProiecteEtapa() {        
      const vueInst = this;
      vueInst.appidProiectEtapa = 0;
      vueInst.visibleDialogEditProiectEtapa = false;      
      vueInst.refreshDataTableProiecteEtape();
      vueInst.refreshDataTableProiecteTaskuri();
    },

    askIfEditThisProiectEtapa(pProiectEtapa: IProiecteEtape) {      
      const vueInst = this;
      vueInst.appidProiectEtapa = pProiectEtapa.appid;
      vueInst.proiectEtapa = pProiectEtapa;
      vueInst.visibleDialogEditProiectEtapa = true;
    },

    refreshDataTableProiecteEtape(){
      const vueInst = this;
      vueInst.visibleDialogEditProiectEtapa = false;
      vueInst.tableDataProiecteEtape = [] as IProiecteEtapeExtended[];
      ServiceProiecte.getProiecteEtape(Number(vueInst.proiect.appid)).then((presponse)=>{   
        vueInst.loadingProiectData=false;   
        if (presponse.status === 'success'){
          vueInst.tableDataProiecteEtape = JSON.parse(presponse.rows) as IProiecteEtapeExtended[];          
        }
        vueInst.refreshDataTableProiecteTaskuri();
      });
    },

    addRowProiecteTask(pProiectEtapa: IProiecteEtape) {
      this.appidProiectTask = 0;
      this.proiectEtapa = pProiectEtapa;
      this.visibleDialogEditProiectTask = true;      
    },

    renuntEditareProiecteTask() {        
      const vueInst = this;
      vueInst.appidProiectTask = 0;
      vueInst.proiectEtapa = {
        appid: 0,
        id_proiect_etapa: 0,  
        id_proiect: 0,  
        denumire: '',
        descriere: '',
        termen: '',
        id_responsabil: 0,
        estimare_ora: 0,
        track_user: 0,
        track_date: '',
        task: 0,
        responsabil: '',
        stare: '',
        progres: 0,
      };
      vueInst.visibleDialogEditProiectTask = false;      
      vueInst.refreshDataTableProiecteTaskuri();
    },

    askIfEditThisProiectTask(pProiectTask: IProiecteTaskuri) {      
      const vueInst = this;
      vueInst.appidProiectTask = pProiectTask.appid;      
      vueInst.proiectEtapa = vueInst.tableDataProiecteEtape.filter(f => f.id_proiect == pProiectTask.id_proiect && f.appid == pProiectTask.id_proiect_etapa)[0] as IProiecteEtape;
      vueInst.visibleDialogEditProiectTask = true;
    },

    refreshDataTableProiecteTaskuri(){
      const vueInst = this;      
      vueInst.visibleDialogEditProiectTask = false;
      vueInst.tableDataProiecteTaskuri = [] as IProiecteTaskuri[];
      ServiceProiecte.getProiecteTaskuri(Number(vueInst.proiect.appid)).then((presponse)=>{   
        vueInst.loadingProiectData=false;   
        if (presponse.status === 'success'){
          vueInst.tableDataProiecteTaskuri = JSON.parse(presponse.rows) as IProiecteTaskuri[];

          setTimeout(() => {
            vueInst.tableDataProiecteEtape.forEach(etapa => {
              etapa.proiecteTaskuri = [];
              etapa.proiecteTaskuri.push(vueInst.tableDataProiecteTaskuri.filter(f => f.id_proiect == this.proiect.appid && f.id_proiect_etapa == etapa.appid));            
            });          
          }, 500)
        }
      });
    },

    openFormUploadFile() {
      this.filesSelectedForUpload = [];
      this.uploadScanTitle = '';
      this.dialogUploadFile = true;      
    },

    getScanDocuments(){
      const vueInst = this;
      vueInst.uploadingFileToServer = true;
      ServiceProiecte.getScansProiecte(Number(vueInst.appid)).then((presponse)=>{
        vueInst.loadingProiectData = false;
        vueInst.uploadingFileToServer = false;
        if(presponse.status==='success'){
          vueInst.proiect.scan_documents = JSON.parse(JSON.stringify(presponse.rows));
        }
      });
    },

    askIfRemoveScan(pscan:IScanProiecte){
      const vueInst = this;
      vueInst.$q.dialog({
        title: 'Confirm',
        message: `Stergeti fisierul reprezentand ${pscan.description}?`,
        cancel: true,
        persistent: true,
        html: true
      }).onOk(() => {
        ServiceProiecte.deleteScan(pscan.appid, { userid: vueInst.user.userid, token: vueInst.user.csrf_token }).then(presponse=>{
          if(presponse.status==='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
  
            this.getScanDocuments();
          }
        });
      })
    },

    prepareForUpload(event:any){
      this.filesSelectedForUpload = event.target.files[0];
    },

    uploadFile() {
      const vueInst = this;      
        
      if (vueInst.uploadScanTitle.length == 0) {
        Notify.create({
          color: 'orange',
          textColor: 'white',
          type: 'warning',
          message: 'Trebuie sa completati titlu documentului!',
          position: 'top',
          timeout: 3500,
          html: true
        });
      } else if (vueInst.checkIfFileIsSelected()) {
        vueInst.uploadingFileToServer = true;
      
        const fileUpload = new FileUploadService(
          this.urlForUpload,
          this.headers,
          this.onProgress
        );
        
        fileUpload.upload(vueInst.filesSelectedForUpload as File, { appidProiect: vueInst.appid, description: vueInst.uploadScanTitle, userid: vueInst.user.userid, token: vueInst.user.csrf_token})
          .then((e: any) => {
            vueInst.dialogUploadFile = false;          
            vueInst.getScanDocuments();
            if(e.target && e.target.response.status && e.target.response.status=='error'){
              Notify.create({
                color: 'red',
                textColor: 'white',
                type: 'negative',
                message: e.target.response.message,
                position: 'top',
                timeout: 3500,
                html: true
              })
            }else{
              vueInst.$q.notify({
                color: 'teal',
                textColor: 'white',
                type: 'positive',
                message: e.target.response.message,
                position: 'top',
                timeout: 1000,
                html: true
              });
              
              ServiceProiecte.getScansProiecte(Number(vueInst.appid)).then(
                presponse=>{
                  vueInst.proiect.scan_documents = JSON.parse(JSON.stringify(presponse.rows))
                }
              )
            }
          })
          .catch((e: any) => {
          })
      } else {
        Notify.create({
          color: 'orange',
          textColor: 'white',
          type: 'warning',
          message: 'Trebuie sa selectati fisierul!',
          position: 'top',
          timeout: 3500,
          html: true
        });
      }
    },

    resetProiect() {
      this.proiect = {
        appid: 0,
        id_proiect: 0,  
        denumire: '',
        descriere: '',
        id_responsabil: 0,
        data_start: '',
        data_end: '',
        track_user: 0,
        track_date: '',
        responsabil: '',
        durata: 0,
        stare: '',
        progres: 0,
        denumire_user_created: '',
        scan_documents: []
      };
    },

    humanReadableBytes(nrPfBytes:number): string{
      return humanReadableBytes(nrPfBytes);
    },    

    checkIfFileIsSelected():boolean{
      return this.filesSelectedForUpload != undefined && this.filesSelectedForUpload.length != 0;
    },

    onProgress(event:any) {
      // Handdle the progress
    },
  },

  computed: { 
    APP_NAME(): string{
      return APP_NAME;
    },
       
    user(): IUser {
      return this.userStore.User;
    },

    getLinkToQrCode(): string {      
      return CONFIG_ENV.URL_PROIECTE.qr_code + '/' + this.appid;
    },

    optionsStare() {      
      const stareArray = [  
        {status: 'neinceput', label: 'Neinceput'},        
        {status: 'inlucru', label: 'In lucru'}, 
        {status: 'finalizat', label: 'Finalizat'}
      ];

      return stareArray;
    } 
  },

  watch: {
    appid: {
      immediate: true,
      handler (newVal: number, oldVal: number) {        
        const vueInst = this;        
        vueInst.resetProiect();    
        // vueInst.scan_documents = [];
        vueInst.loadingProiectData = true;
        if(newVal != 0 && vueInst.appid != 0) { 
          vueInst.getProiect();    
        }
      }
    }
  }
});
