import store from './index';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import ICompanyDrept from '@/types/ICompanyDrept';

@Module({ namespaced: true, dynamic: true, store, name: 'companyStore'})
export default class Company extends VuexModule {
  public Company: ICompanyDrept = {
    denumire: '',
    is_activ_companie: '',
    nume_prenume: '',
    is_activ_user: '',
    appid: 0,
    appid_company: 0,
    appid_user: 0,
    is_right: 0,
    is_default: 0,
    flag: 0,
    organization_unit_descriere: '',
    tip_org_unit_id: ''
  };

  @Mutation
  public SET_COMPANY(pCompanyDrept: ICompanyDrept) {
    this.Company = pCompanyDrept;
  }

  @Action
  public set_company(pCompanyDrept: ICompanyDrept) {
    this.context.commit('SET_COMPANY', pCompanyDrept);
  }
}
