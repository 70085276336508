import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import IAssets from '@/types/IAssets';
import IScan from '@/types/IScan';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type IAssetsNull = OrNull<IAssets>;

interface TAjaxResponseAsset {
  status: string;
  message: string;
  rows: string;
  // rows: IAssets[];
}

interface TAjaxResponseEditAsset {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetAsset {
  status: string;
  message: string;
  asset: IAssetsNull;
}

interface TAjaxResponseUpdateAsset {
  status: string;
  message: string;
}

interface TAjaxResponseGetScans {
  status: string;
  message: string;
  nr_of_records: number,
  rows: IScan[];
}

interface TAjaxResponseGetInfoScan {
  status: string;
  message: string;
  scan: IScan;
}

interface TAjaxSimpleResponse {
  status: string;
  message: string;
}

export class ServiceAssets {
  public static async getAssets(searchParams:any): Promise<TAjaxResponseAsset> {
    //const response = await axios.get(`${CONFIG_ENV.URL_ASSETS.assets}`);
    const response = await axios.post(`${CONFIG_ENV.URL_ASSETS.assets}`, searchParams);
    return response.data;
  }

  public static async deleteAsset(pappid: number): Promise<TAjaxResponseEditAsset> {
    const response = await axios.delete(`${CONFIG_ENV.URL_ASSETS.asset}/${pappid}`);
    return response.data;
  }  

  public static async postAsset(pAsset: IAssets): Promise<TAjaxResponseEditAsset> {
    const response = await axios.post(`${CONFIG_ENV.URL_ASSETS.asset}`, pAsset);
    return response.data;
  }

  public static async getAsset(appid: number): Promise<TAjaxResponseGetAsset> {
    const response = await axios.get(`${CONFIG_ENV.URL_ASSETS.asset}/${appid}`);
    return response.data;
  }

  public static async updateAsset(appid:number, tipupdate:string, ptext: string): Promise<TAjaxResponseUpdateAsset> {
    const params = new URLSearchParams();
    params.set('new_value', ptext);
    const response = await axios.post(`${CONFIG_ENV.URL_ASSETS.update_asset}/${appid}/${tipupdate}`, params);
    return response.data;
  }

  public static async getScans(appidAsset:number, isDocument:string): Promise<TAjaxResponseGetScans> {
    const response = await axios.get(`${CONFIG_ENV.URL_ASSET_SCAN.getScans}/${appidAsset}/${isDocument}`);
    return response.data;
  }

  public static async getAssetNotificationScans(appidAssetNotification:number, isDocument:string): Promise<TAjaxResponseGetScans> {
    const response = await axios.get(`${CONFIG_ENV.URL_ASSET_SCAN.getAssetNotificationScans}/${appidAssetNotification}/${isDocument}`);
    return response.data;
  }

  public static async getInfoScan(appid:number): Promise<TAjaxResponseGetInfoScan> {
    const response = await axios.get(`${CONFIG_ENV.URL_ASSET_SCAN.getInfoScan}/${appid}`);
    return response.data;
  }

  public static async deleteScan(appid:number, headers: any): Promise<TAjaxSimpleResponse> {
    const response = await axios.delete(`${CONFIG_ENV.URL_ASSET_SCAN.getInfoScan}/${appid}`, { headers });
    return response.data;
  }
}