<template>
<div>
    <q-spinner-gears
        v-if="loadingExpeditii"
        color="primary"
        size="2em"
    />
    <div v-else flat bordered>
        <q-card-section style="display: flex;">
            <div class="text__title--medium" style="text-align: center; width: 100%; margin-left: 25px;">Expeditii</div>
            <div style="margin-left: auto;">
                <div>
                    <span v-if="user.auth_for_expeditie=='y' && isAllowOperator" class="material-icons" style="font-size: 2em;cursor: pointer;">local_shipping
                        <q-tooltip
                            v-if="user.auth_for_expeditie=='y'"
                            transition-show="scale"
                            transition-hide="scale"
                        >
                            Adauga expeditie
                        </q-tooltip>
                    </span>
                    <q-popup-proxy v-if="user.auth_for_expeditie=='y' && isAllowOperator" ref="refNewExpeditie" transition-show="scale" transition-hide="scale" style="min-width: 600px !important;" >
                        <q-card class="my-card" flat bordered >
                            <q-item>
                                <q-item-section>
                                    <q-item-label>Inregistrare Expeditie</q-item-label>
                                </q-item-section>
                            </q-item>

                            <q-separator />

                            <q-card-section horizontal>
                                <q-card-section>
                                    <table class="table table-condensed" style="min-width: 400px;">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Numar
                                                </td>
                                                <td>
                                                    <q-input
                                                        outlined
                                                        v-model="newExpeditie.awb_nr"
                                                        lazy-rules
                                                        counter
                                                        maxlength="30"
                                                        dense
                                                        :input-style="{ fontFamily: 'Nunito Sans',fontWeight: 'bolder', color: 'black' }"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Data
                                                </td>
                                                <td>
                                                    <q-input outlined v-model="newExpeditie.awb_data" dense mask="##.##.####"
                                                        :input-style="{ fontFamily: 'Nunito Sans',fontWeight: 'bolder', color: 'black' }"
                                                        style="max-width: 150px;"
                                                    >
                                                        <q-popup-proxy ref="qDateProxy1" transition-show="scale" transition-hide="scale">
                                                            <q-date minimal v-model="newExpeditie.awb_data" mask="DD.MM.YYYY" @update:model-value="($refs['qDateProxy1'] as any).hide()" :locale="myLocale" />
                                                        </q-popup-proxy>
                                                        <template v-slot:append>
                                                            <q-icon name="event" class="cursor-pointer"></q-icon>
                                                        </template>
                                                    </q-input>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Detalii
                                                </td>
                                                <td>
                                                    <q-input
                                                        class="col-10"
                                                        outlined
                                                        style="max-height: 10vh;"
                                                        v-model="newExpeditie.awb_detalii"
                                                        type="textarea"
                                                        lazy-rules
                                                        counter
                                                        maxlength="800"
                                                        rows="3"
                                                        dense
                                                        :input-style="{ fontFamily: 'Nunito Sans',fontWeight: 'bolder', color: 'black' }"
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </q-card-section>
                            </q-card-section>
                            <q-separator />
                            <div class="absolute-bottom-right q-pa-sm">
                                <!-- <q-btn label="renunt" size="sm" no-caps v-close-popup flat /> -->
                                <q-btn label="Salveaza" size="sm" type="submit" color="primary" @click="saveNewExpeditie"/>
                                <q-btn label="Renunt" size="sm" type="reset" flat @click="onCancel" style="margin-left: 5px"/>
                            </div>
                        </q-card>
                    </q-popup-proxy>
                </div>
            </div>
        </q-card-section>

        <q-card-section class="q-pt-none" style="margin:0px;padding:0px;">
            <div>
                <div v-if="arrExpeditii.length==0 && !loadingExpeditii" class="text__label--thin" style="text-align: center;">Nicio expeditie</div>
                <div style="display: flex; flex-direction: row; flex-wrap: wrap;min-height: 50px;max-height: 100px; max-width: 250px;width: 250px;overflow-y: auto;">
                    <div v-for="(expeditie,index) in arrExpeditii" v-bind:key="index" class="expeditie">
                        <span v-if="user.auth_for_expeditie=='y' && isAllowOperator" class="material-icons cursor-pointer">
                            <!-- @click="removeExpeditie(expeditie,index)"
                            style="color: red; font-weight: bold;position: relative; font-size: 1em;left:-5px;" -->
                            <q-icon name="delete_forever" class="text-red cursor-pointer" style="font-weight: bold;position: relative; font-size: 1.3rem; top:-2px;" @click="removeExpeditie(expeditie,index)">
                                <q-tooltip
                                    transition-show="rotate"
                                    transition-hide="rotate"
                                >Sterge expeditia!</q-tooltip>
                            </q-icon>
                        </span>
                        <div style="font-size: 0.8em;">{{expeditie.awb_nr}}/{{expeditie.awb_data}} {{expeditie.awb_detalii}}</div>
                    </div>
                </div>
            </div>
        </q-card-section>
    </div>
</div>
</template>

<script lang="ts" src="./ExpeditiiDocument.ts" />

<style scoped>
    .q-field--with-bottom {
        padding-bottom: 20px;
    }

    .q-field__native{
        font-family: "Nunito Sans";
        font-weight: bolder;
    }

    .expeditie{
        display: flex;
        justify-content: flex-start;
        border: 1px solid #d9edf7;
        border-radius: 2px;
        margin: 2px 5px;
        padding: 2px;
    }
    .q-menu{
        width: 400px !important;

    }
</style>
