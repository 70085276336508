import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import IPartenerConturi from '@/types/IPartenerConturi';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type IPartenerConturiNull = OrNull<IPartenerConturi>;

interface TAjaxResponsePartenerCont {
  status: string;
  message: string;
  rows: IPartenerConturi[];
}

interface TAjaxResponseEditPartenerCont {
  status: string;
  message: string;
  appid: number;
}

interface TAjaxResponseGetPartenerCont {
  status: string;
  message: string;
  partenerCont: IPartenerConturiNull;
}

interface TAjaxSimpleResponse {
    status: string;
    message: string;
}

export class ServiceParteneriConturi {
  public static async getPartenerConturi(appid: number): Promise<TAjaxResponsePartenerCont> {
    const response = await axios.get(`${CONFIG_ENV.URL_PARTENERI.partenerConturi}/partener_conturi/${appid}`);
    return response.data;
  }

  public static async getPartenerCont(appid: number): Promise<TAjaxResponseGetPartenerCont> {
    const response = await axios.get(`${CONFIG_ENV.URL_PARTENERI.partenerConturi}/partener_cont/${appid}`);
    return response.data;
  }
  
  public static async postPartenerCont(pPartenerCont: IPartenerConturi): Promise<TAjaxResponseEditPartenerCont> {
    const response = await axios.post(`${CONFIG_ENV.URL_PARTENERI.partenerConturi}/partener_cont`, pPartenerCont);
    return response.data;
  }

  public static async deletePartenerCont(appid: number): Promise<TAjaxSimpleResponse> {
    const response = await axios.delete(`${CONFIG_ENV.URL_PARTENERI.partenerConturi}/partener_cont/${appid}`);
    return response.data;
  }
}
