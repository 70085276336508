// import { getModule } from 'vuex-module-decorators';
import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import IOrganizatie from '@/types/IOrganizatie';
// import Nomenclatoare from '@/store/Nomenclatoare';
import IOrgUnit from "@/types/IOrgUnit";
import ITipOrgUnit from "@/types/ITipOrgUnit";

interface TAjaxResponseSimple {
  status: string;
  message: string;
}

// interface TAjaxResponseGetNomOrg {
//   status: string;
//   message: string;
//   rows:IOrganizatie[];
// }

interface TAjaxResponseGetNomOrgUnits {
  status: string;
  message: string;
  rows:IOrgUnit[];
}

interface TAjaxResponseGetNomOrgUnitsTypes {
  status: string;
  message: string;
  rows:ITipOrgUnit[];
}

// interface TAjaxResponseNomHrOrg {
//   status: string;
//   message: string;
//   hr_org: any;
//   //rows: iNomCateg[];
// }

// interface IDepartament {
//   appid:number,
//   appid_divizie:number,
//   departament:string
// }

// interface IDivizie {
//   appid:number,
//   denumire:string
// }

// interface TAjaxResponseAllOrgForTree {
//   status: string;
//   message: string;
//   departamente:IDepartament[];
//   divizii:IDivizie[];
// }

export class ServiceHrOrg {
  // public static async getHrOrg(): Promise<TAjaxResponseNomHrOrg> {
  //   const response = await axios.get(`${CONFIG_ENV.URL_NOM.hr_nom_org}`);
  //   return response.data;
  // }

  // public static async searchNomOrganizatii(): Promise<TAjaxResponseGetNomOrg> {
  //   const params = new URLSearchParams();
  //   params.set('search_text', '%');
  //   const response = await axios.post(`${CONFIG_ENV.URL_SEARCH.optionsOrganizatii}`, params);
  //   return response.data;
  // }

  // public static async updateDivision(id_org:number,division_name:string): Promise<TAjaxResponseSimple> {
  //   const params = new URLSearchParams();
  //   params.set('division_name', division_name);
  //   const response = await axios.post(`${CONFIG_ENV.URL_NOM.updateDivision}/${id_org}`, params);
  //   return response.data;
  // }

  public static async updateOrgUnit(id_org:number,organization_unit_descriere:string, parent_id: number, tip_org_unit_id:string): Promise<TAjaxResponseSimple> {
    const params = new URLSearchParams();
    params.set('organization_unit_descriere', organization_unit_descriere);
    params.set('parent_id', parent_id.toString());
    params.set('tip_org_unit_id', tip_org_unit_id);
    const response = await axios.post(`${CONFIG_ENV.URL_NOM.updateOrgUnit}/${id_org}`, params);
    return response.data;
  }

  // public static async updateDepartament(parent_id_org:number,id_org:number,departament_name:string): Promise<TAjaxResponseSimple> {
  //   const params = new URLSearchParams();
  //   params.set('division_appid', parent_id_org.toString());
  //   params.set('departament_name', departament_name);
  //   const response = await axios.post(`${CONFIG_ENV.URL_NOM.updateDepartament}/${id_org}`, params);
  //   return response.data;
  // }

  // public static async deleteDivision(id_org:number): Promise<TAjaxResponseSimple> {
  //   const response = await axios.delete(`${CONFIG_ENV.URL_NOM.deleteDivision}/${id_org}`);
  //   return response.data;
  // }

  public static async deleteOrgUnit(id_org:number): Promise<TAjaxResponseSimple> {
    const response = await axios.delete(`${CONFIG_ENV.URL_NOM.deleteOrgUnit}/${id_org}`);
    return response.data;
  }

  // public static async deleteDepartament(id_org:number): Promise<TAjaxResponseSimple> {
  //   const response = await axios.delete(`${CONFIG_ENV.URL_NOM.deleteDepartament}/${id_org}`);
  //   return response.data;
  // }

  // public static async getNomOrganizatiiForTree(): Promise<TAjaxResponseAllOrgForTree> {
  //   const response = await axios.get(`${CONFIG_ENV.URL_NOM.allOrgForTree}`);
  //   return response.data;
  // }

  // public static async getNomOrganizatii(): Promise<TAjaxResponseGetNomOrg> {
  //   const response = await axios.get(`${CONFIG_ENV.URL_NOM.toateDepartamentele}`);
  //   return response.data;
  // }

  public static async getNomOrgUnits(): Promise<TAjaxResponseGetNomOrgUnits> {
    const response = await axios.get(`${CONFIG_ENV.URL_NOM.toateOrgUnits}`);
    return response.data;
  }

  public static async getNomOrgUnitsTree(cele_inactive: number): Promise<TAjaxResponseGetNomOrgUnits> {
    const response = await axios.get(`${CONFIG_ENV.URL_NOM.toateOrgUnitsTree}/${cele_inactive}`);
    return response.data;
  }

  public static async getNomOrgUnitsTypes(): Promise<TAjaxResponseGetNomOrgUnitsTypes> {
    const response = await axios.get(`${CONFIG_ENV.URL_NOM.toateTipurileOrgUnits}`);
    return response.data;
  }

  public static async updateStateOrgUnit(id: number, state: number): Promise<TAjaxResponseSimple> {
    const response = await axios.post(`${CONFIG_ENV.URL_NOM.updateStateOrgUnit}`, {id, state});
    return response.data;
  }
}
