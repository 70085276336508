import { getModule } from 'vuex-module-decorators';
import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import Nomenclatoare from '@/store/Nomenclatoare';

interface TAjaxResponseNomCategActive {
  status: string;
  message: string;
  rows: string;
  //rows: iNomCategActive[];
}

interface TAjaxResponseEditCategActive {
  status: string;
  message: string;
}

export class ServiceNomCategActive {
  public static async getNomCategActive(): Promise<TAjaxResponseNomCategActive> {
    const response = await axios.get(`${CONFIG_ENV.URL_ASSETS.categoriiActive}`);
    return response.data;
  }

  public static async deleteNomCategActive(pappid: number): Promise<TAjaxResponseEditCategActive> {
    const response = await axios.delete(`${CONFIG_ENV.URL_ASSETS.categorieActive}/${pappid}`);
    return response.data;
  }

  public static async putNewStatusNomTipActive(appid:number, pNewStatus:string): Promise<TAjaxResponseEditCategActive> {
    const response = await axios.put(`${CONFIG_ENV.URL_ASSETS.categorieActive}/${appid}/${pNewStatus}`);
    return response.data;
  }

  public static async postNomCategActive(appid: number, den_lang_1: string, den_lang_2: string): Promise<TAjaxResponseEditCategActive> {
    const params = new URLSearchParams();
    params.set('appid', appid.toString());
    params.set('den_lang_1', den_lang_1);
    params.set('den_lang_2', den_lang_2);
    const response = await axios.post(`${CONFIG_ENV.URL_ASSETS.categorieActive}`, params);
    return response.data;
  }

  public static async updateStoreNomCategActiveFromDB() {
    // console.log('%cServiceNomCategActive running updateStoreNomCategActiveFromDB<-------------------------', "color: red;font-size:16px;")
    const nomenclatoareStore = getModule(Nomenclatoare);
    this.getNomCategActive().then((presponse) => {
      if (presponse.status === 'success') {
        nomenclatoareStore.set_nomcategorii_active(JSON.parse(presponse.rows));
      }
    })
  }
}