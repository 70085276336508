import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import IUser from '@/types/IUser';
import {IParamsLabelValue} from "@/types/IParamsLabelValue";
import IPersoana from '@/types/IPersoana';
import ICompanyDrept from '@/types/ICompanyDrept';

interface TAjaxResponseSimple {
  status: string;
  message: string;
}

interface TAjaxResponseWithTokenSimple {
  status: string;
  message: string;
  csrf_token: string;
}

interface TAjaxResponseNomUsers {
  status: string;
  message: string;
  rows: IUser[];
}

interface TAjaxResponsePersoana {
  status: string;
  message: string;
  rows: IPersoana[];
}

interface TAjaxResponseGetPersoana {
  status: string;
  message: string;
  persoana: IPersoana
}

interface TAjaxResponseGetUser {
  status: string;
  message: string;
  user: IUser
}

// interface TAjaxResponseGetGrantedRegistre {
//   status: string;
//   message: string;
//   user_name: string;
//   rows: any[number];//appid granted registre
// }

export class ServiceNomUsers {
  public static async getNomPersoaneUtilizatoare(): Promise<TAjaxResponsePersoana> {
    const response = await axios.get(`${CONFIG_ENV.URL_NOM.persoane_utilizatoare}`);
    return response.data;
  }

  public static async getNomPersoaneUtilizatoareRestrictii(is_all: number): Promise<TAjaxResponsePersoana> {
    const response = await axios.get(`${CONFIG_ENV.URL_NOM.persoane_utilizatoare_restrictii}/${is_all}`);
    return response.data;
  }

  public static async getNomUsers(): Promise<TAjaxResponseNomUsers> {
    const response = await axios.get(`${CONFIG_ENV.URL_NOM.nom_users}`);
    return response.data;
  }

  public static async getNomUser(userid: number): Promise<TAjaxResponseGetUser> {
    const response = await axios.get(`${CONFIG_ENV.URL_NOM.nom_user}/${userid}`);
    return response.data;
  }

  public static async deleteNomUser(userid: number): Promise<TAjaxResponseSimple> {
    const response = await axios.delete(`${CONFIG_ENV.URL_NOM.nom_users}/${userid}`);
    return response.data;
  }

  public static async resetPassword(puserid: number): Promise<TAjaxResponseSimple> {
    const response = await axios.post(`${CONFIG_ENV.URL_NOM.reset_password}/${puserid}`);
    return response.data;
  }

  public static async changePassword(puserid: number, old_password: string, new_password: string): Promise<TAjaxResponseWithTokenSimple> {
    const params = new URLSearchParams();
    params.set('old_password', old_password);
    params.set('new_password', new_password);
    const response = await axios.post(`${CONFIG_ENV.URL_NOM.change_password}/${puserid}`, params);
    return response.data;
  }

  public static async getCurrentUserFromAD(): Promise<TAjaxResponseGetUser> {
    const userLocale=localStorage.getItem('userlocale');
    const params = new URLSearchParams();
    params.set('userLocale', userLocale as string);
    const response = await axios.post(`${CONFIG_ENV.URL_USER.getCurrentUserFromAD}`, params);
    return response.data;
  }

  public static async getUserByMarca(puserid: number): Promise<TAjaxResponseGetPersoana> {
    const response = await axios.get(`${CONFIG_ENV.URL_USER.getUserByMarca}/${puserid}`);
    return response.data;
  }

  public static async putStatusAuth(authName:string,pNewStatus:string,puserid:number): Promise<TAjaxResponseSimple> {
    const response = await axios.put(`${CONFIG_ENV.URL_NOM.nom_users}/change_auth/${authName}/${pNewStatus}`,puserid);
    return response.data;
  }

  // public static async chgDepartamentForUser(puserid:number,p_org_id:number): Promise<TAjaxResponseSimple> {
  //   const response = await axios.put(`${CONFIG_ENV.URL_NOM.chg_user_departament}/${puserid}/${p_org_id}`,puserid);
  //   return response.data;
  // }

  public static async chgEmailForUser(puserid:number,pemail:string): Promise<TAjaxResponseSimple> {
    const params = new URLSearchParams();
    params.set('new_email', pemail);
    const response = await axios.post(`${CONFIG_ENV.URL_NOM.chg_user_email}/${puserid}`,params);
    return response.data;
  }

  public static async chgDrepturiForUser(puserid:number, drepturi: IParamsLabelValue []): Promise<TAjaxResponseSimple> {
    const params = new URLSearchParams();
    drepturi.forEach(drept=>params.set(drept.label, drept.value));
    const response = await axios.post(`${CONFIG_ENV.URL_NOM.chg_drepturi}/${puserid}`,params);
    return response.data;
  }

  public static async chgOrgUnitForUser(puserid:number, pNewOrgUnitId:number): Promise<TAjaxResponseSimple> {
    const params = new URLSearchParams();
    params.set('org_unit_id', pNewOrgUnitId.toString());
    const response = await axios.post(`${CONFIG_ENV.URL_NOM.chg_org_unit}/${puserid}`,params);
    return response.data;
  }

  public static async chgOrgSuperiorForUser(puserid:number, pNewSuperiorUserId1:number, pNewSuperiorUserId2:number): Promise<TAjaxResponseSimple> {
    const params = new URLSearchParams();
    params.set('superior_user_id1', pNewSuperiorUserId1.toString());
    params.set('superior_user_id2', pNewSuperiorUserId2.toString());
    const response = await axios.post(`${CONFIG_ENV.URL_NOM.chg_superior_user}/${puserid}`,params);
    return response.data;
  }

  public static async chgJobForUser(puserid:number,pNewJobId:number): Promise<TAjaxResponseSimple> {
    const params = new URLSearchParams();
    params.set('job_id', pNewJobId.toString());
    const response = await axios.post(`${CONFIG_ENV.URL_NOM.chg_job}/${puserid}`,params);
    return response.data;
  }

  public static async chgRangForUser(puserid:number,newRang:number): Promise<TAjaxResponseSimple> {
    const params = new URLSearchParams();
    params.set('new_rang', newRang.toString());
    const response = await axios.post(`${CONFIG_ENV.URL_NOM.chg_rang}/${puserid}`,params);
    return response.data;
  }

  public static async chgUserWanForUser(puserid:number,puserwan:string): Promise<TAjaxResponseSimple> {
    const params = new URLSearchParams();
    params.set('user_wan', puserwan);
    const response = await axios.post(`${CONFIG_ENV.URL_NOM.chg_user_wan}/${puserid}`,params);
    return response.data;
  }

  public static async chgNumeForUser(userid:number, new_first_name:string,new_last_name:string): Promise<TAjaxResponseSimple> {
    const params = new URLSearchParams();
    params.set('new_first_name', new_first_name);
    params.set('new_last_name', new_last_name);
    const response = await axios.post(`${CONFIG_ENV.URL_NOM.chg_user_name}/${userid}`,params);
    return response.data;
  }

  public static async deleteUser(puserid:number): Promise<TAjaxResponseSimple> {
    const response = await axios.delete(`${CONFIG_ENV.URL_NOM.delete_user}/${puserid}`);
    return response.data;
  }

  // public static async getInfoGrantedRegistreForUser(puserid:number): Promise<TAjaxResponseGetGrantedRegistre> {
  //   const response = await axios.get(`${CONFIG_ENV.URL_USER.granted_registre_marca}/${puserid}`);
  //   return response.data;
  // }

  // public static async deleteGrantRegistruUser(puserid:number,pRegistruId:number): Promise<TAjaxResponseSimple> {
  //   const response = await axios.delete(`${CONFIG_ENV.URL_USER.granted_registre_marca}/${puserid}/${pRegistruId}`);
  //   return response.data;
  // }

  // public static async putGrantRegistruUser(puserid:number,pRegistruId:number): Promise<TAjaxResponseSimple> {
  //   const response = await axios.put(`${CONFIG_ENV.URL_USER.granted_registre_marca}/${puserid}/${pRegistruId}`);
  //   return response.data;
  // }

  public static async postUser(puser: IUser, isEdit: boolean): Promise<TAjaxResponseSimple> {
    const response = await axios.post(`${CONFIG_ENV.URL_NOM.nom_users}/${isEdit}`, puser);
    return response.data;
  }

  public static async chgSendEmailNotificationForUser(puserid: number, newFlag: number): Promise<TAjaxResponseSimple> {
    const params = new URLSearchParams();
    params.set('flag', newFlag.toString());
    const response = await axios.post(`${CONFIG_ENV.URL_NOM.chg_send_email_notification}/${puserid}`, params);
    return response.data;
  }
}
