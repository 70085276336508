import {defineComponent, defineAsyncComponent, inject, ref} from 'vue';
import ICereri from '@/types/ICereri';
import Nomenclatoare from '@/store/Nomenclatoare';
import { ServiceCereri } from '@/modules/ServiceCereri';
import {CONFIG_ENV} from '@/config';
import {getModule} from 'vuex-module-decorators';
import moment from 'moment';
import LoadingComponent from '@/components/LoadingComponent.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';
import {dateToStringDDMonYYYY, dateToStringDDMMYYYY} from '@/modules/Utils';
import User from '@/store/User';
import IUser from '@/types/IUser';
import {humanReadableBytes} from '@/modules/Utils';
import ComboDeplasari from '@/components/ComboDeplasari/ComboDeplasari.vue'
import IActionOnStepTask from '@/types/IActionOnStepTask';
import IScanCereri from '@/types/IScanCereri';
import { ServiceNomTipMijlocDeTransport } from '@/modules/ServiceNomTipMijlocDeTransport';
import INomTipMijlocDeTransport from '@/types/INomTipMijlocDeTransport';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type ICereriNull = OrNull<ICereri>;

interface ICereriExtended extends ICereriNull{ 
  scan_documents:IScanCereri[]|null
}

const DialogOpenScan = defineAsyncComponent({
  loader: () => import('@/components/DialogOpenScan/DialogOpenScan.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

const TaskList = defineAsyncComponent({
  loader: () => import('@/components/Tasks/TaskList.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

const myForm = ref({} as any);
const myForm1 = ref({} as any);
const qDateProxy1 = ref(null);
const qDateProxy2 = ref(null);

export default defineComponent({
  name: 'EditCerereDetail',

  components: {
    DialogOpenScan, ComboDeplasari, TaskList
  },

  props: {
    appid: {
      type: Number,
      required: true,
      default: 0,
      readonly: true
    },
    closeWindow: {
      type: Function,
      default: undefined
    },
    task: {
      type: Object,
      task_id: 0,
      task_type: '',
      action_on_step: {} as IActionOnStepTask
    }
  },

  setup() {
    const emitter = inject("emitter") as any;  
    return {
      emitter,
      myForm,
      myForm1,
      qDateProxy1,
      qDateProxy2
    };
  },

  data() {
    return {
      cerere: {
        appid: 0,
        id_cerere: 0,
        id_deplasare: null,
        data_start: '',
        data_end: '',  
        id_tara: null,
        id_localitate: null,
        id_mijl_transport: null,
        detalii: '',
        descriere: '',
        valoare_transport: null,
        valoare_diurna: null,
        valoare_cazare: null,
        track_user: 0,
        track_date: '',
        stare: 'inapproval',
        valoare: null,
        responsabil: '', 
        denumire_tara: '', 
        denumire_localitate: '',
        localitate: '',
        denumire_pentru: '',
        scan_documents: []
      } as ICereriExtended,
      myLocale: CONFIG_ENV.myLocale,
      nomenclatoareStore: getModule(Nomenclatoare),      
      loadingData: false,
      uploadScanTitle: '',
      urlForUpload: CONFIG_ENV.URL_CERERE_SCAN.uploadScanCerere,
      uploadingFileToServer: false,      
      userStore: getModule(User),
      loadingPdfForDownload: false,
      viewDialogSelectDeplasari: false,
      isSuccess: false,
      nomTipMijlocDeTransport: [] as INomTipMijlocDeTransport[]
    }
  },

  methods: {    
    resetCerere() {
      this.cerere = {
        appid: 0,
        id_cerere: 0,
        id_deplasare: null,
        data_start: '',
        data_end: '',  
        id_tara: null,
        id_localitate: null,
        id_mijl_transport: null,
        detalii: '',
        descriere: '',
        valoare_transport: null,
        valoare_diurna: null,
        valoare_cazare: null,
        track_user: 0,
        track_date: '',
        stare: null,
        valoare: null,
        responsabil: '', 
        denumire_tara: '', 
        denumire_localitate: '',
        localitate: '',
        denumire_pentru: '',
        scan_documents: []
      } as ICereriExtended;
      //this.tasks = [];
    },

    onCancel() { 
      const vueInst = this;     
      vueInst.closeWindow?.();
    },

    displayData(pdata:string) :string{
      if(pdata){        
        const day = moment(pdata.substring(0,10), 'YYYY-MM-DD');
        return dateToStringDDMonYYYY(day);
      }else{ 
        return '';
      }
    },

    getCerere() {
      const vueInst=this;
      ServiceCereri.getCerere(Number(vueInst.cerere.appid)).then((presponse)=>{
        vueInst.loadingData = false;
        if(presponse.status==='success'){
          vueInst.cerere = JSON.parse(JSON.stringify(presponse.cerere))[0];
          //vueInst.cerere.data_start = vueInst.cerere.data_start == null ? null : vueInst.displayData(vueInst.cerere.data_start);
          //vueInst.cerere.data_end = vueInst.cerere.data_end == null ? null : vueInst.displayData(vueInst.cerere.data_end);
          
          vueInst.getScanDocuments();
          vueInst.isSuccess = true;
        }
      })
    },

    getScanDocuments(){
      const vueInst = this;
      vueInst.uploadingFileToServer = true;
      ServiceCereri.getScansCereri(Number(vueInst.cerere.appid)).then((presponse)=>{
        vueInst.loadingData = false;
        vueInst.uploadingFileToServer = false;
        if(presponse.status==='success'){
          vueInst.cerere.scan_documents = JSON.parse(JSON.stringify(presponse.rows));          
        }
      });
    },

    humanReadableBytes(nrPfBytes:number): string{
      return humanReadableBytes(nrPfBytes);
    }, 

    onDownload() { 
      alert('In progress!')
    },

    getDenMijlocTransport(pIdMijlTrans: number): string{
      const vueInst = this;
      let result = '';
      const MijlTransport = vueInst.optionsTipMijlTransport.find( mijlTransport => {
        return mijlTransport.appid == pIdMijlTrans;
      });
      if(MijlTransport){
        result = MijlTransport.denumire;
      }
      return result;
    },

    getNomTipMijlocDeTransport() {
      const vueInst = this;
      ServiceNomTipMijlocDeTransport.getNomTipMijlocDeTransport().then((presponse)=>{      
        if (presponse.status === 'success'){
          vueInst.nomTipMijlocDeTransport = JSON.parse(presponse.rows) as INomTipMijlocDeTransport[];
        }
      });
    },

    onState(operator: string){
      switch(operator) {
        case 'Refresh':
          this.getCerere();
          break;
        case 'Close':
          this.closeWindow?.();
          break;
      }
    }
  },

  computed: {    
    user(): IUser {
      return this.userStore.User;
    },

    optionsTipMijlTransport(): INomTipMijlocDeTransport[] {
      return this.nomTipMijlocDeTransport;
    }
  },

  mounted() { 
    this.getNomTipMijlocDeTransport();

    if (this.appid != 0) {
      this.cerere.appid = this.appid;      
      this.loadingData = true;
      this.getCerere();
    }
  },

  watch: {
    appid: {
      immediate: true,
      handler (newVal: number, oldVal: number) {        
        const vueInst = this;        
        // vueInst.scan_documents = [];
        vueInst.loadingData = true;
        if(newVal != 0 && vueInst.appid != 0) {                    
          vueInst.cerere.appid = vueInst.appid;            
          vueInst.getCerere();    
        }
      }
    }
  }
});
